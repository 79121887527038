<template>
    <div :draggable="enabled"
         @dragstart.self="onDragStart"
         @dragend.self="onDragEnd"
         @dragover.prevent
         @dragenter.prevent
         :class="{'ib-drag' : enabled}">
        <slot/>
    </div>
</template>

<script>
/**
 * Component for handling drag events in a uniform way.
 */
export default {
    name: 'IbDrag',

    props: {

        /**
         * Flag determines if the element can be dragged.
         */
        enabled: {
            type: Boolean
        },

        /**
         * The data transfer in the drag event.
         */
        transferData: {
            type:     Object,
            required: true
        }
    },

    /**
     * Event handler for the drag start event.
     * 
     * Attaches the transfer data to the drag start event.
     */
    methods: {
        onDragStart(e) {
            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.dropEffect    = 'move';

            e.dataTransfer.setData('payload', JSON.stringify(this.transferData));
        },
        onDragEnd(e) {
            console.debug("drag stopped");
            this.$emit('dragEnd');
        }
    }
}
</script>

<style lang="less" scoped>

.ib-drag {
    cursor: move;
}

</style>