<template>
<div>
    <order-card v-bind:items="basket.items" :basketId="basket.basketId">
        <template v-slot:content>
            <div v-if="audienceIsBuyer" class="mb-1">
                <div class="row">
                    <div class="col-4">
                        <span v-if="order.serverInfo.sellerDepotName == '' || order.serverInfo.sellerDepotName == null" title="Seller information will be available after the seller accepts the order">
                        Seller: {{order.serverInfo.sellerCompanyName}} 
                        </span>
                        <span v-else>
                        Seller: {{order.serverInfo.sellerCompanyName}} 
                        </span>
                        <span v-if="order.serverInfo.sellerDepotName !== null && order.serverInfo.sellerDepotName !== ''">
                             - {{order.serverInfo.sellerDepotName}}
                        </span>
                    </div>
                    <div class="col-4">
                        Buying depot: {{order.serverInfo.buyerDepotName}} 
                    </div>
                    <div class="col-4">
                        Status: {{getStatusName(order.serverInfo.status)}} 
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        Sellers Address: 
                        <span v-if="order.serverInfo.sellerDepotName == '' || order.serverInfo.sellerDepotName == null"> 
                            N/A
                        </span>
                        <span v-else>
                             <u v-on:click="showDeliveryAddressModal = true" class="clickable-text" title="Show full address and delivery terms">{{order.serverInfo.sellerDeliveryPostcode}}</u>
                        </span>
                    </div>
                    <div class="col-4">
                        Date Requested: {{order.serverInfo.orderCreated | formatDate(DateFormat.DATE_ONLY) }}
                    </div>
                    <div class="col-4" v-if="order.serverInfo.status!='0'">
                        Actioned: {{order.serverInfo.lastActioned | formatDate(DateFormat.DATE_ONLY) }}
                    </div>  
                    <div class="col-4" v-else>
                        Actioned: N/A 
                    </div>  
                </div>
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4">
                        Marketplace Total: {{(marketplaceTotal).toFixed(2)}}
                    </div>
                   <!-- <div class="col-4">
                        Unit Price: {{(order.serverInfo.price).toFixed(2)}}
                    </div>-->
                    <div class="col-4">
                         Marketplace ref #: {{shortenGuid(basket.basketId)}} 
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4">
                    </div>
                    <div class="col-4 ">
                        Purchase Order #: {{order.clientInfo.purchaseOrderNumber}} 
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4">
                    </div>  
                    <!--<div class="col-4">
                        Ordered: {{order.serverInfo.quantity}}
                    </div>-->
                    <div class="col-4 ">
                        Supplier account #:{{order.clientInfo.supplierNumber}} 
                    </div>
                </div>
            </div>

            <div v-else class="mb-1">
                <div class="row">
                    <div class ="col-4">
                        <span v-if="order.serverInfo.buyerDepotName == '' || order.serverInfo.buyerDepotName == null" title="Buyer information will be available after the seller accepts the order">
                            Buyer: {{order.serverInfo.buyerCompanyName}} 
                            </span>
                            <span v-else>
                            Buyer: {{order.serverInfo.buyerCompanyName}} 
                            </span>
                            <span v-if="order.serverInfo.buyerDepotName !== null && order.serverInfo.buyerDepotName !== ''">
                                - {{order.serverInfo.buyerDepotName}}
                            </span>
                            </div>
                        <div class="col-4">
                            Selling depot: {{order.serverInfo.sellerDepotName}} 
                        </div>
                        <div class="col-4">
                            Status: {{getStatusName(order.serverInfo.status)}} 
                        </div>
                 
                    <!--
                    <div class="col-4">
                        {{order.serverInfo.orderedDate | formatDate(DateFormat.DATE_TIME) }}
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-4">
                        Delivery Address: 
                        <span v-if="order.serverInfo.buyerDepotName == '' || order.serverInfo.buyerDepotName == null"> 
                            N/A
                        </span>
                        <span v-else>
                            <u v-on:click="showDeliveryAddressModal = true" class="clickable-text" title="Show delivery address">{{order.serverInfo.buyerDeliveryPostcode}}</u>
                        </span>
                    </div>

                    <div class="col-4">
                        Date Requested: {{order.serverInfo.orderCreated | formatDate(DateFormat.DATE_ONLY) }}
                    </div>
                    <div class="col-4" v-if="order.serverInfo.status!='0'">
                        Actioned: {{order.serverInfo.lastActioned | formatDate(DateFormat.DATE_ONLY) }}
                    </div>  
                    <div class="col-4" v-else>
                        Actioned: N/A 
                    </div>  
                </div>
                <div class="row">
                    <div class="col-4">
                        Billing Address:
                        <span v-if="order.serverInfo.buyerDepotName == '' || order.serverInfo.buyerDepotName == null"> 
                            N/A
                        </span>
                        <span v-else>
                            <u v-on:click="showBillingAddressModal = true" class="clickable-text" title="Show billing address">{{order.serverInfo.buyerBillingPostcode}}</u>
                        </span>
                         
                    </div>
                     <div class="col-4">
                        Marketplace Total: {{(marketplaceTotal).toFixed(2)}}
                    </div>
                    <!--<div class="col-4">
                        Unit Price: {{(order.serverInfo.price).toFixed(2)}}
                    </div>-->
                    <div class="col-4">
                         Marketplace ref #: {{shortenGuid(basket.basketId)}} 
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4">
                    </div>
                    <div class="col-4 ">
                        Advice Note #: {{order.clientInfo.adviceNumber}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                    </div>
                    <div class="col-4">
                    </div>
                    <!--<div class="col-4">
                        Ordered: {{order.serverInfo.quantity}}
                    </div>-->
                    <div class="col-4 ">
                        Customer account #: {{order.clientInfo.customerNumber}} 
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <!-- Sellers Buttons -->
            <div v-if="order.serverInfo.status===20 && !audienceIsBuyer" class="text-right">
                <button type="button" @click="getQuantityEnough" class="btn btn-success">Accept</button>&nbsp;
                <button type="button" @click="rejectOrder" class="btn btn-danger">Reject</button>
            </div>
            <div v-if="order.serverInfo.status===40 && !audienceIsBuyer" class="text-right">
                <button type="button" @click="showAdviceNoteGenerator" class="btn btn-success">Generate Advice Note</button>
            </div>

            <!-- Buyers Buttons -->
            <div class="text-right">
                <span v-if="order.serverInfo.status===30 && audienceIsBuyer">
                    <button type="button" class="btn btn-success" @click="showPurchaseOrderGenerator">Generate Purchase Order</button>
                 </span>
                &nbsp;
                <span v-if="(order.serverInfo.status===20 || order.serverInfo.status===10 || order.serverInfo.status==30) && audienceIsBuyer">
                    <button type="button" @click="buyerCancel" class="btn btn-danger">Cancel</button> 
                </span>
            </div>
        </template>
    </order-card>

    <ib-modal v-model="showDeliveryAddressModal"
                    modalClass="col-6 p-2"
                    :dismissable="true">
        <div v-if="audienceIsBuyer">
            <h5 class="card-title">{{order.serverInfo.sellerCompanyName}} - {{order.serverInfo.sellerDepotName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">Delivery Address Details</h6>
            <div class="card-body">
            <div class="row">
                <div class= "col-2">
                    Contact name:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactName}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Email:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactEmail}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Telephone:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactPhone}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 1:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine1}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 2:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine2}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 3:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine3}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 4:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine4}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Postcode:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryPostcode}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    <h5> &nbsp; </h5>
                </div>
            </div>
            <div class="row">
                <div class= "col-8">
                    <h6> 
                    Delivery Terms:
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pre-formatted">
                    <textarea disabled style="height: 330px; width: 100%;" v-model="order.serverInfo.deliveryTerms">
                    </textarea>
                </div>
            </div>
            
            </div>
        </div>
        <div v-else>
            <h5 class="card-title">{{order.serverInfo.buyerCompanyName}} - {{order.serverInfo.buyerDepotName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">Delivery Address Details</h6>
        <div class="card-body">
            <div class="row">
                <div class= "col-2">
                    Contact name:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactName}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Email:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactEmail}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Telephone:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactPhone}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 1:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDeliveryAddressLine1}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 2:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDeliveryAddressLine2}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 3:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDeliveryAddressLine3}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 4:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDeliveryAddressLine4}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Postcode:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDeliveryPostcode}}
                </div>
            </div>
            
        </div>
        </div>

    </ib-modal>

    <ib-modal v-model="showBillingAddressModal"
                    modalClass="col-6 p-2"
                    :dismissable="true">
        <div>
            <h5 class="card-title">{{order.serverInfo.buyerCompanyName}} - {{order.serverInfo.buyerDepotName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">Billing Address Details</h6>
        <div class="card-body">
            <div class="row">
                <div class= "col-2">
                    Contact name:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactName}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Email:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactEmail}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Telephone:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerContactPhone}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 1:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerBillingAddressLine1}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 2:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerBillingAddressLine2}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 3:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerBillingAddressLine3}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 4:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerBillingAddressLine4}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Postcode:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerBillingPostcode}}
                </div>
            </div>
            
        </div>
        </div>

    </ib-modal>
    
</div>
</template>

<script>

import OrderCard                 from '@MP/components/v2/OrderCard';
import DateMixin, { DateFormat } from '@/mixins/DateMixin';
import IbModal                   from '@/components/utilities/IbModal';
import ClientApi                 from '@MP/api/client';
import OrderNumberMixin          from '@/mixins/OrderNumberMixin';


import { 
    mapGetters,
    mapActions,
    mapState
}       from 'vuex';

export default {
    name: 'OrderCardRendererV2',
    
    components: {
        OrderCard,
        IbModal
    },

    mixins: [
        DateMixin,
        OrderNumberMixin
    ],

    data() {
        return {
            showDeliveryAddressModal: false,
            showBillingAddressModal : false,
        }
    },

    props: {
       basket: {
            required : true,
            type     : Object,
            default: function()
            {
                return { listing: {}, buyer: {}, seller: {} }
            }
        },
        audienceIsBuyer: {
            required : true,
            type     : Boolean
        }
    },
    computed:{
        order(){
            return this.basket.items[0];
        },
        marketplaceTotal(){
            let total = 0;
            this.basket.items.forEach(item => {
               total+= item.serverInfo.price*item.serverInfo.quantity; 
            });
            return total;
        }
    },
    methods: {
        ...mapActions('marketplaceorders', {
          buyerCancelOrder   : 'buyerCancelOrder',
          sellerAcceptOrder  : 'sellerAcceptOrder'
        }),

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Filters Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

        async buyerCancel()
        {
            this.$emit('cancelBulkOrder', this.order);
        },

        getStatusName(statusCode) {
            let statusName = "";
            switch(statusCode) {
                case 20:
                    if(this.audienceIsBuyer) {
                        statusName = "Awaiting Seller Acceptance";
                    }
                    else {                    
                        statusName = "Ready to Accept/Reject";
                    }
                    break;
                case 30:
                    if(this.audienceIsBuyer) {
                        statusName = "Ready for Purchase Order";
                    }
                    else {
                        statusName = "Awaiting Buyer's Purchase Order";
                    }
                    break;
                case 40:
                    statusName = "Order Number Available";
                    break;
                case 50:
                    statusName = "Advice Generated";
                    break;
                case 90:
                    statusName = "Buyer Cancelled";
                    break;
                case 99:
                    statusName = "Seller Rejected";
                    break;
                default:
            }
            return statusName;
        },

        async getQuantityEnough()
        {
            let quantityEnough = false;
            try
            {
                quantityEnough = await ClientApi.checkBasketQuantityEnough(this.basket.items,this.order.serverInfo.sellerDepotNumber);                
                if (quantityEnough.data.length == 0)
                {
                    this.$emit('acceptWithoutVerification', this.order.serverInfo);
                }
                else
                {
                    let value = {order: this.order.serverInfo, quantityEnough: quantityEnough.data}
                    this.$emit('verifyQuantity',value);
                }
            }
            catch(err)
            {
                this.toast('b-toaster-top-right', 'danger', err.response.data, true);
            }
        },
                
        rejectOrder() {
            this.$emit('rejectOrder', this.order);
        },

        showAdviceNoteGenerator() {
            let value = {order: this.basket.items, item: this.order}
            this.$emit('showAdviceNoteModal', value);
        },

        showPurchaseOrderGenerator() {
            let value = {orders: this.basket.items, item: this.order}
            this.$emit('showPurchaseOrderModal', value);
        }
    },

    watch: 
    {
        orderProp: function(newVal)
        {
            this.order = newVal;
        }
    }
}
</script>
<style scoped>
	.clickable-text {
		cursor: pointer;
	}
</style>