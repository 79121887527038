<template>
<div class="ib-lookup">
    <div class="input-group">
        <input 
            :id="id"
            ref="input"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': !valid }"
            :maxlength="maxLength"
            v-model="searchFilter"
            @blur="performQuickSearch"
            @keydown.enter="performQuickSearch"
            @focus="$event.target.select()"
        >
        <div class="input-group-append">
            <button 
                type="button"
                class="btn ib-append-button form-control"
                :class="{ 'invalid': false === valid }"
                @click="openModal"
                tabindex="-1"
            >
                <i class="fas fa-search"></i>
            </button>
        </div>
        <div 
            class="visible error-feedback"
            :class="{ 'invisible' : valid }"
        >
            Please select a job
        </div>
    </div>

    <!-- Modal -->
    <b-modal v-model="showModal"
        class="ib-lookup-modal"
        size="lg"
        title="Job Lookup"
        :ok-disabled="null === table.selectedJob"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        @shown="focusInput"
        @hidden="clear"
        @ok="handleOk">
        <!-- Search Box -->
        <div class="modal-search">
            <div class="input-group">
                <input ref="search"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': 0 < searchFilter.length && !isSearchFilterValid }"
                    placeholder="Enter search text"
                    v-model="searchFilter">
                <div class="input-group-append">
                    <button type="button"
                        class="btn btn-secondary rounded-right-borders"
                        :disabled="!searchFilter"
                        @click="clear">Clear</button>
                </div>
                <div class="invalid-feedback">
                    {{ messages.filterTooShort }}
                </div>
            </div>
        </div>
        <!-- Results -->
        <div class="modal-table">
            <ib-table v-if="isSearchFilterValid"
                :items="table.jobs"
                :columns="table.columns"
                :loading="searching"
                :loadingMessage="messages.searching"
                :noItemsMessage="messages.noResults"
                :itemsPerPage="10"
                :small="true"
                v-model="table.selectedJob">
            </ib-table>
        </div>

        <hr>

        <!-- Selected Job -->
        <div class="modal-job">
            <div v-if="table.selectedJob"
                class="row">
                <div class="col-sm-3">
                    <b class="mr-2">Job:</b>{{ table.selectedJob.Id }}
                </div>
                <div class="col-sm-3">
                    <b class="mr-2">Invoice Type:</b>{{ table.selectedJob.Type }}
                </div>
                <div class="col-sm-3">
                    <b class="mr-2">Status:</b>{{ table.selectedJob.Status }}
                </div>
                <div class="col-sm-3">
                    <b class="mr-2">Created:</b>{{ table.selectedJob.CreatedDate | formatDate(DateFormat.DATE_ONLY) }}
                </div>
                <div class="col-sm-3">
                    <b class="mr-2">Account:</b>{{ table.selectedJob.AccountNo }}
                </div>
                <div class="col-sm-9">
                    <b class="mr-2">Description:</b>{{ table.selectedJob.Description }}
                </div>
            </div>
        </div>
    </b-modal>
</div>
</template>
    
<script>
import {
    has as _has,
    get as _get
} from 'lodash-es';

import IbLookupBase                 from '@/components/form/IbLookupBase';
import JobsApi                      from '@WS/api/job';
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import IbTable                      from '@/components/table/IbTable';

const TableColumns = [
    {
        heading:        'ID',
        property:       'Id',
        sortable:       true,
        class:          'idCol'
    },
    {
        heading:        'Account No',
        property:       'AccountNo',
        sortable:       true,
        class:          'accountNoCol'
    },
    {
        heading:        'Account Name',
        property:       'AccountName',
        sortable:       true,
        class:          'accountNameCol',
        hideOverflow:   true
    },
    {
        heading:        'Delivery Address',
        property:       'DeliveryAddress',
        sortable:       false,
        class:          'deliveryAdressCol',
        hideOverflow:   true
    },
    {
        heading:        'Nick Name',
        property:       'Customer.NickName',
        sortable:       false,
        class:          'nickNameCol',
        hideOverflow:   true
    }
];

export default {
    name: 'IbJobLookup',

    extends: IbLookupBase,
    
    mixins: [
        DateMixin
    ],
    
    components: {
        IbTable
    },
    
    props: {
        /**
         * Override base definition.
         * The parent source of the v-model binding.
         */
        value: {
            type:       Object
        }
        
    },
    
    data() {
        return {
            job:                this.value,
            searchFilter:       '',
            minFilterLength:    3,
            searching:          false,
            error:              null,

            table: {
                columns:        TableColumns,
                jobs:           [],
                selectedJob:    null
            },

            messages: {
                searching:      'Searching...',
                filterTooShort: 'Search term much be at least 3 characters long.',
                noResults:      'No jobs found that match the search.'
            }
        }
    },

    created() {
        this.id = `ib-job-lookup-${Date.now()}`;
    },
    
    computed: {
        /**
         * Returns the ID of the job, if there is one.
         */
        jobId() {
            let jobId = '';
            if (null !== this.job) {
                jobId = this.job.Id;
            }
            return jobId;
        },

        /**
         * Validate that the search filter equals or exceeds,
         * the minimum search filter length.
         */
        isSearchFilterValid() {
            let isValid = false;
            if ('string' === typeof this.searchFilter) {
                isValid = this.minFilterLength <= this.searchFilter.length;
            }
            return isValid;
        },

        /**
         * Delay updating the search filter and cancel any
         * pending updates if a newer update arrives before the
         * delay has expired.
         * N.B. Returns a debounced function to enable use of
         * the prop filterDelay which is not available for use
         * when referenced from a method (not initiated at
         * creation time).
         */
        debouncedFilter() {
            const me = this;
            return me.$_.debounce(() => me.performJobSearch(me.searchFilter), me.filterDelay);
        }
    },
    
    watch: {
        /**
         * Update this component side of the v-model binding
         * with changes from the parent.
         */
        value(newValue, oldValue) {
            this.job = newValue;
        },

        /**
         * Update v-model binding on parent.
         */
        job(newValue, oldValue) {
            this.$emit('input', newValue);
        },

        /**
         * Watch for changes to the search filter.
         * @param {string} newValue - The new filter value.
         * @param {string} oldValue - The old filter value.
         */
        searchFilter(newValue, oldValue) {
            this.debouncedFilter();
        }
    },
    
    methods: {

        /**
         * Focuses the input element of the control.
         */
        focus() {
            this.$refs.input.select();
        },

        /**
		 * Perform job search on the API server.
         * @param {string} filter - The search term.
		 */
		async performJobSearch(filter) {
            if (!this.isSearchFilterValid) {
                return;
            }

            this.table.jobs        = [];
            this.table.selectedJob = null;
            this.error             = null;
            this.searching         = true;

            try {
                const response = await JobsApi.searchJobs(filter);

                this.table.jobs = response.data.data;
            }
            catch (error) {
                this.error = `Failed to search for jobs`;

                if (_has(error, 'response.data')) {
                    console.error(`${this.error}: ${error.response.data.message}`);
                }
            }
            finally {
                this.searching = false;
            }
        },

        /**
         * Perform search using the normal search endpoint, but take
         * the first element of the returned results.
         */
        async performQuickSearch() {
            const currentJobId = _get(this.job, 'Id', '');

            if (this.searchFilter && this.searchFilter != currentJobId) {
                this.job = null;
                
                await this.performJobSearch(this.searchFilter);

                if (this.table.jobs.length) {
                    this.table.selectedJob = this.table.jobs[0];
                    this.handleOk();
                }
            }
        },

        /**
         * Set focus to search input when the modal is
         * opened.
         */
        focusInput() {
            this.$refs.search.focus();
        },

        /**
         * Clear all state within the lookup.
         */
        clear() {
            this.table.jobs        = [];
            this.table.selectedJob = null;
        },

        /**
         * Set the selected job from the table to the
         * job property.
         */
        handleOk() {
            if (null !== this.table.selectedJob) {
                this.job = this.table.selectedJob;
                this.searchFilter = this.job.Id;
            }
        }
    }
}
</script>
    
<style lang="less" scoped>
@import '../../../../assets/styles/ib-common';

.form-control[readonly] {
    background-color: white;
}
.ib-lookup {
    min-width: 125px;
}
.ib-lookup-modal {
    .modal-search {
        min-height: 60px;
    }
    .modal-table {
        min-height: 260px;
        height: 300px;
        overflow-y: auto;
    }
    .modal-job {
        min-height: 100px;
    }
}
.rounded-right-borders {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

// Table column classes.
/deep/ .idCol {
    min-width: 70px;
}
/deep/ .accountNoCol {
    min-width: 115px;
}
/deep/ .accountNameCol {
    width: 50%;
}
/deep/ .deliveryAdressCol {
    width: 50%;
}
/deep/ .nickNameCol {
    min-width: 120px;
}
</style>