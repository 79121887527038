const ACTION_COLLECT = 10;
const ACTION_USE = 20;
const ACTION_RETURN = 30;
const ACTION_TRANSFER = 40;
const ACTION_ALLOCATE = 50;

const PartTransactionAction = {
    ACTION_COLLECT,
    ACTION_USE,
    ACTION_RETURN,
    ACTION_TRANSFER,
    ACTION_ALLOCATE
};

const PartTransactionActionStrings = {
    10 : 'Collect',
    20 : 'Use',
    30 : 'Return',
    40 : 'Transfer',
    50 : 'Allocate'
};

const STATUS_PENDING = 10;
const STATUS_PROCESSED = 20;
const STATUS_CONFLICT = 30;
const STATUS_SKIPPED = 40;

const PartTransactionStatus = {
    STATUS_PENDING,
    STATUS_PROCESSED,
    STATUS_CONFLICT,
    STATUS_SKIPPED
};

const PartTransactionStatusStrings = {
    10 : 'Pending',
    20 : 'Processed',
    30 : 'Conflict',
    40 : 'Skipped'
};

export { PartTransactionAction,
         PartTransactionActionStrings,
         PartTransactionStatus,
         PartTransactionStatusStrings };