<template>
<div>
    <select class="custom-select"
        :class="{ 'is-invalid': !valid }"
        v-model="selectedItem"
        @change="change">
        <option v-if="false === hasItems"
            disabled>
            {{ noItemsMessage }}
        </option>
        <option v-for="(item, index) in items"
            :key="`${id}-item-${index}`"
            :value="item">
            <slot :item="item">
                {{ getDisplayValue(item) }}
            </slot>
        </option>
    </select>
    <div class="invalid-feedback">
        {{ validationError }}
    </div>
</div>
</template>
    
<script>
export default {
    name: 'IbSelectEntry',
    
    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            type:       [String, Object],
            default:    null
        },
        /**
         * The items to generate option elements
         * from.
         */
        items: {
            type:       Array,
            default() {
                return [];
            }
        },
        /**
         * The property on each item object to display in the option
         * elements of the select control.
         */
        displayProperty: {
            type:       String,
            default:    null
        },
        /**
         * Set to false to display validation error.
         */
        valid: {
            type:       Boolean,
            default:    true
        },
        /**
         * The message to display when there are no
         * items to render in the drop down.
         */
        noItemsMessage: {
            type:       String,
            default:    'No items available'
        },
        /**
         * The message to display when the valid prop
         * is set to false.
         */
        validationError: {
            type:       String,
            default:    'Please select an item'
        }
    },
    
    data() {
        return {
            id:             `ib-select-entry-${Date.now()}`,
            selectedItem:   this.value
        }
    },
    
    computed: {
        /**
         * Returns true if the items array has at least
         * one item, otherwise false.
         */
        hasItems() {
            const hasItems = 0 < this.items.length;
            return hasItems;
        }
    },
    
    watch: {
        /**
         * Update this component side of the v-model binding
         * with changes from the parent.
         */
        value(newValue, oldValue) {
            this.selectedItem = newValue;
        },

        /**
         * Update the parent side of the v-model binding with
         * changes from this component.
         */
        selectedItem(newValue, oldValue) {
            this.$emit('input', newValue);
        }
    },

    methods: {
        /**
         * Returns the value of the specified property on item, or if
         * no property is specified returns the item.
         */
        getDisplayValue(item) {
            let value = item;
            if (null !== this.displayProperty) {
                value = this.getObjectProperty(item, this.displayProperty)
            }
            return value;
        },

        /**
         * Gets the value at the path of the specified object,
         * or returns null if the value cannot be resolved.
         * @param {Object} item - The object to query.
         * @param {string} path - The path of the property to get.
         */
        getObjectProperty(item, path) {
            const value = this.$_.get(item, path, null);
            return value;
        },
        change(){
            this.$emit('change');
        }
    }
}
</script>
    
<style lang="less" scoped>

</style>