<template>
    <span>
        <!-- Trigger -->
        <span data-toggle="modal" :data-target="modalId">
            <i :class="[iconColourClass, iconClass]" />
        </span>

        <!-- Modal -->
        <div class="modal fade" :id="id" tabindex="-1" role="dialog" :aria-labelledby="`${id}-label`"  aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header" :class="[headerBackgroundClass]">
                        <h5 class="modal-title" :id="`${id}-label`">
                            <i :class="iconClass"></i>&nbsp;
                            {{ title }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body text-dark">
                        <slot></slot>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>  
                </div>              
            </div>
        </div>
    </span>
</template>

<script>
export default {
    name: "IbInfoModal",

    props: {
        /**
         * HTML Id of the modal without a leading #
         * defaults to 'info-modal'
         */
        id: {
            type: String,
            default: "info-modal"
        },

        /**
         * Title of the modal
         */
        title: {
            type: String,
            default: "Information"
        },

        iconColour: {
            type: String,
            validator: function (value) {
                // The value must match one of these strings
                return ['primary', 'secondary', 'warning', 'success', 'info', 'danger', 'light', 'dark', 'white', 'secondary'].indexOf(value) !== -1
            },
            default: 'info'
        },

        headerColour: {
            type: String,
            validator: function (value) {
                // The value must match one of these strings
                return ['primary', 'secondary', 'warning', 'success', 'info', 'danger', 'light', 'dark', 'white', 'secondary'].indexOf(value) !== -1
            },
            default: 'secondary'
        },
    },        

    data() {
        return {
            iconClass : "fas fa-lg fa-info-circle iconClickable"
        }
    },

    computed: {
        modalId() {
            return `#${this.id}`;
        },

        iconColourClass() {
            return `text-${this.iconColour}`
        },
        headerBackgroundClass(){
            return `bg-${this.headerColour}`
        }
    }
}
</script>

<style lang="less" scoped>
.iconClickable {
    cursor: pointer;
}
</style>