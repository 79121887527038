const DEALER_GENERATED          = 0;
const JOHN_DEEERE               = 1;
const JCB                       = 2;
const MANITOU                   = 3;

const ManufacturerCodes = {
    DEALER_GENERATED,
    JOHN_DEEERE,
    JCB,
    MANITOU
};

const ManufacturerStrings = {
    0 : 'Dealer Generated',
    1 : 'John Deere',
    2 : 'JCB',
    3 : 'Manitou'
};

const NotifyManufacturerOnCancel = {
    0 : true,
    1 : true,
    2 : false,
    3 : true
}
       
const JobRequestManufacturers = {
    methods: {
        getManufacturerForId(value) {
            let manufacturerName = ManufacturerStrings[value];
            if(undefined === manufacturerName){
                manufacturerName = "Unknown";
            }
            return manufacturerName;
        },
        getNotifyManufacturerOnCancelRequest(value) {
            let preference = NotifyManufacturerOnCancel[value];
            if(undefined === preference) {
                preference = false;
            }
            return preference;
        }
    }
}
export default JobRequestManufacturers;