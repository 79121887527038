<template>
<div class="form-row job-activity-edit align-items-center">
	<div class="col-auto">
		<b-form-select class="activity-type"
			v-model="activityType"
			:options="activityTypes">
		</b-form-select>
	</div>
	<div class="col-auto">
		<ib-time-input v-model="startTime"
					   class="form-control form-control-sm rounded-0 time-input"/>
	</div>
	<div class="col-auto">
		<ib-time-input v-model="endTime"
					   class="form-control form-control-sm rounded-0 time-input"/>
	</div>
	<div class="col-auto duration"
		:class="{ 'invalid-duration': !durationValid }">
		{{ duration | formatDuration }}
	</div>
	<div class="col">
		<vue-select v-model="selectedJobResult"
			:options="jobResults"
			:onSearch="onSearch"
		></vue-select>
	</div>
	<div class="col-2">
		{{ customer }}
	</div>
	<div class="col-2 d-flex justify-content-end">
		<button class="btn btn-secondary mr-2"
			:disabled="uploading"
			@click="cancel">
			Cancel
		</button>
		<button class="btn btn-primary"
			:disabled="!isValid || uploading"
			@click="save">
			<span v-show="!uploading">Save</span>
			<span v-show="uploading">Saving...</span>
		</button>
	</div>
	<ib-warning-modal :value="showWarning" @hideWarning="hideWarning" :message="warningMessage"/>
</div>
</template>

<script>
import { mapGetters }				from 'vuex';

//APIs
import JobsApi						from '@WS/api/job';
import JobActivityApi				from '@WS/api/job-activity';

//Utilities
import DateMixin, { DateFormat }	from '@/mixins/DateMixin';

import Moment						from 'moment';
import MomentDurationFormat			from 'moment-duration-format';
import VueSelect					from 'vue-select';
import IbWarningModal           	from '@/components/IbWarningModal';
import IbTimeInput					from '@/components/form/IbTimeInput'
import { JobActivityType }			from '@WS/common/job-activity-type';

const PLATFORM = 'web';

export default {
	name: 'JobActivityAdd',

	mixins: [
        DateMixin
    ],

	components: {
		VueSelect,
        IbWarningModal,
		IbTimeInput
	},

	props: {
		date: {
			type:     String,
			required: true
		},

		engineerId: {
			type:     Number,
			required: true
		},

		lastEndTime: {
			type:     String,
			required: true
		}
	},

	data() {
		return {
			activityTypes:		[
				{ value: JobActivityType.LABOUR, text: 'Labour' },
				{ value: JobActivityType.TRAVEL, text: 'Travel' },
				{ value: JobActivityType.ATTENDANCE, text: 'Time and Attendance' }
			],
			jobSearchTerm:		null,
			jobResults:			[],

			activityType:		0,
			startTime:			null,
			endTime:			null,
			selectedJobResult:	null,

			startTimeValid:		true,
			endTimeValid:		true,
			durationValid:		true,

            showWarning: 		false,
            warningMessage: 	"Invalid Job type",

			uploading:			false,
			searching:			false
		}
	},

	created() {
		MomentDurationFormat(Moment);

		// Intialise start time to end time of last activity.
		// The time in the UI should always be the local time
		const moment      = Moment.utc(this.lastEndTime);
		this.startTime    = moment.local().format("HH:mm");
		this.activityType = JobActivityType.LABOUR;
	},

	computed: {
		...mapGetters('auth', [
            'userInitials',
        ]),

		/**
		 * Return start time as a MomentJS object, or null if invalid.
		 */
		start() {
			let start = null;
			if (this.startTimeValid) {
				start = this.getDateFromTime(this.startTime);
			}
			return start;
		},

		/**
		 * Return end time as a MomentJS object, or null if invalid.
		 */
		end() {
			let end = null;
			if (this.endTimeValid) {
				end = this.getDateFromTime(this.endTime);
			}
			return end;
		},

		/**
		 * Return duration as a MomentJS duration object, or null if invalid.
		 */
		duration() {
			let duration = null;
			if ((this.startTimeValid && this.endTimeValid) === true) {
				const difference   = this.end - this.start;
				const momentDuration = Moment.duration(difference);
				if (momentDuration.isValid() === true) {
					duration = momentDuration;
				}
			}
			return duration;
		},

		/**
		 * Return the selected job.
		 */
		job() {
			let job = null;
			if (this.selectedJobResult) {
				job = this.selectedJobResult.value;
			}
			return job;
		},

		/**
		 * Return the customer of the selected job
		 */
		customer() {
			let customer = null;
			if (this.job) {
				customer = this.job.AccountName;
			}
			return customer;
		},

		/**
		 * Returns true if all properties have a value and those values are
		 * valid, otherwise false.
		 */
		isValid() {
			const arePresent = this.activityType !== null &&
							   this.startTime 	 !== null &&
							   this.endTime 	 !== null &&
							   this.job 		 !== null;
			const areValid   = this.startTimeValid &&
							   this.endTimeValid   &&
							   this.durationValid  &&
							   (this.activityType === JobActivityType.ATTENDANCE) === this.isTimeAttendanceJob();
			return arePresent && areValid;
		}
	},

	watch: {
		startTime(value) {
			this.validateStartTime();
		},

		endTime(value) {
			this.validateEndTime();
		},

		duration(value) {
			this.validateDuration();
		},

        job() {
			if(this.job != null){
				if (this.isTimeAttendanceJob()) {
					this.activityType = JobActivityType.ATTENDANCE;
				}
				else if(this.activityType == JobActivityType.ATTENDANCE){
					this.showWarning = true;
				}
			}
        },

		activityType(value) {
			if(this.job != null){
				if((value === JobActivityType.ATTENDANCE) != this.isTimeAttendanceJob()){
					this.showWarning = true;
				}
			}
		}
	},

	filters: {
		/**
		 * Return a duration string (Xh Ym) from a MomentJS duration object.
		 */
		formatDuration(value) {
			let duration = '';
			if (value !== null) {
				duration = value.format('h[h] m[m]', {
								trim: "none"
							});
			}
			return duration;
		}
	},

	methods: {
		/**
		 * Event Handler: Emit event to parent component to cancel the
		 * add operation.
		 */
		cancel() {
			this.$emit('cancel');
		},

		hideWarning(){
			this.showWarning = false;
		},

		isTimeAttendanceJob: function() {
			const jobId      = this.job ? this.job.Id : '';
			// Check if second character is a 'z'.
			const attendance = ('z' === jobId.charAt(1).toLowerCase());
			return attendance;
		},

		/**
		 * Event Handler: Create and upload a new job activity based on
		 * the data provided.
		 */
		save() {
			if (this.isValid === true) {
				const jobActivity = this.createJobActivity();
				this.uploadJobActivity(jobActivity);
			}
		},

		/**
		 * Event Handler: Search all jobs for matches with the specified
		 * search term.
		 */
		onSearch(term, loading) {
			if (term !== null && term.length >= 3) {
				this.performJobSearch(term);
			}
			else {
				this.clearJobResults();
			}
		},

		/**
		 * Return a new job activity constructed from the data entered
		 * into this control.
		 */
		createJobActivity() {
			const start       = this.getDateTime(`${this.date} ${this.startTime}`, 'DD/MM/YYYY HH:mm');
			const end         = this.getDateTime(`${this.date} ${this.endTime}`, 'DD/MM/YYYY HH:mm');
			const duration    = this.getDurationString(this.duration);

			const jobActivity = {
				JobId:      		this.job.Id,
				EngineerId: 		this.engineerId,
				Status:     		3,
				Type:       		this.activityType,
				Start:      		start,
				End:        		end,
				Duration:   		duration,
				FlatRate:   		-1, //Need to address flatrates.
				Comment:    		null,
				CreatedOn: 			this.getDateTime(Date.now()),
				CreatedBy:			this.userInitials,
				CreatedPlatform:	PLATFORM
			};

			return jobActivity;
		},

		/**
		 * Perform job search on the API server.
		 * @param {string} term - Search term.
		 */
		async performJobSearch(term) {
			this.searching = true;

            try {
				const searchResponse = await JobsApi.searchJobs(term);
				const results		 = searchResponse.data.data;
				this.jobResults		 = this.formatJobsForVueSelect(results);
            }
            catch(failure) {
                const error = `Failed to search for jobs. ${this.getResponseError(failure)}`;
                console.error(error);
                this.$emit('error', {
					id:      Date.now(),
					message: error
				});
            }
            finally {
                this.searching = false;
            }
		},

		/**
		 * Upload job activity to the API server.
		 * @param {object} jobActivity - JobActivity model to upload.
		 */
		async uploadJobActivity(jobActivity) {
			this.uploading = true;

            try {
                const insertResponse   = await JobActivityApi.insert(jobActivity);
                const insertedActivity = {
					...insertResponse.data.data,
					Description: this.job.Description,
					Customer:	 this.job.AccountName
				}
				// Notify parent that job activity has been created.
				this.$emit('save', insertedActivity); 
            }
            catch(failure) {
                const error = `Failed to add time. ${this.getResponseError(failure)}`;
                console.error(error);
                this.$emit('error', {
					id:          Date.now(),
					message:     error,
					jobActivity: jobActivity
				});
            }
            finally {
                this.uploading = false;
            }
		},

		/** 
         * Get message from response, if there is one.
		 * @param {object} responseError - Error response object.
         */
        getResponseError(responseError) {
            let error = '';
            if (responseError.response && responseError.response.data) {
                error += responseError.response.data.message;
            }
            else {
                error += responseError.message;
            }
            return error;
        },

		/**
		 * Format job objects for use with the vue-select component.
		 */
		formatJobsForVueSelect(jobs) {
			let options = [];
			if (jobs) {
				options = jobs.map(
					job => {
						const label = `${job.Id} - ${job.Description.trim()}`;
						return {
							value: job,
							label: label
						};
					}
				);
			}
			return options;
		},

		/**
		 * Create a MomentJS object from a time string.
		 */
		getDateFromTime(time) {
			const date = Moment(time, "HH:mm")
			return date;
		},

		/**
		 * Convert a duration to a string of the format HH:mm:ss.
		 */
		getDurationString(duration) {
			const formatted = duration.format('hh:mm:ss', {
				trim: false
			});
			return formatted;
		},

		/**
		 * Apply validation rules to start time.
		 */
		validateStartTime() {
			this.startTimeValid = this.validateTimeFormat(this.startTime);
		},

		/**
		 * Apply validation rules to end time.
		 */
		validateEndTime() {
			this.endTimeValid = this.validateTimeFormat(this.endTime);
		},

		/**
		 * Apply validation rules to duration.
		 */
		validateDuration() {
			this.durationValid = this.validateDurationIsPositive(this.duration);
		},

		validateTimeFormat(time) {
			return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
		},

		validateDurationIsPositive(duration) {
			return (this.duration !== null) && (this.duration.asMilliseconds() >= 0);
		},

		clearJobResults() {
			this.jobResults.splice(0, this.jobResults.length);
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../../../assets/colours';

.activity-type {
	font-size: 15px;
}

.form-row {
	padding: 10px 0px;
}

.time-input {
	font-size: 15px;
	height: 35px;
	width: 52px;
	padding: 3px 5px 5px 5px;
	margin: auto 0;
}

.duration {
	width: 90px;
}

.invalid-time {
	border-color: @danger !important;
	border-width: 2px;
	padding: 2px 4px 4px 4px !important;
}
.invalid-time:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.39);
}

.invalid-duration {
	color: @danger;
	font-weight: bolder;
}
</style>
