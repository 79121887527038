<template>
<div>
    <h2>Feature not supported in IE</h2>
    <p v-if="noIESupportMessage">{{ noIESupportMessage }}</p>
    <p v-else>Unfortunately this feature is not supported in Internet Explorer. Please consider using one of the modern browsers available at <a href="https://browsehappy.com">https://browsehappy.com</a>.</p>
</div>
</template>

<script>
export default {
    props: {
        noIESupportMessage: {
            type:       String,
            default:    null
        },
    },
};
</script>
