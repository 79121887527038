import ApiService from '@/common/api-service';

const CONFIG_ROOT = "api/v1/marketplace/configuration";

const ConfigApi = {
	get() {
		const url = `${CONFIG_ROOT}/get-config`
		return ApiService.get(url);
	},
	post(data) {
		const url = `${CONFIG_ROOT}/create-config`
		return ApiService.post(url, data);
	},
	postPassword(currentPass, newPass) {
		const url = `${CONFIG_ROOT}/set-password`
		return ApiService.post(url, {"current": currentPass, "new": newPass});
	},
	checkPassword(pass) {
		const url = `${CONFIG_ROOT}/check-password`
		return ApiService.post(url, {"current": pass}) 
	},
	setOrderType(orderType) {
		const url = `${CONFIG_ROOT}/set-order-type/${orderType}`
		return ApiService.post(url)
	}
};

export default ConfigApi;
