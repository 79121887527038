import Vue      from 'vue';
import axios    from 'axios';
import VueAxios from 'vue-axios';

import StorageService from './storage-service';

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
		Vue.axios.defaults.headers.common['Cache-Control'] = 'no-cache';
	},

	setAuthHeader() {
		let token = StorageService.get(process.env.VUE_APP_TOKEN_KEY);
		Vue.axios.defaults.headers.common['Authorization'] = token;
	},

	get(url, data) {
		return Vue.axios.get(url, data);
	},

	post(url, data) {
		return Vue.axios.post(url, data);
	},

	put(url, data) {
		return Vue.axios.put(url, data);
	},

	patch(url, data) {
		return Vue.axios.patch(url, data);
	},

	delete(url, data) {
		return Vue.axios.delete(url, data);
	}
};

export default ApiService;
