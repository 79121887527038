import Marketplace from '@MP/Marketplace';
import ListingsManager from '@MP/views/ListingsManager';
import MarketplaceProfile from '@MP/views/MarketplaceProfile';
import PrefixMappings from '@MP/views/PrefixMappings';
import MarketplaceSales from '@MP/views/MarketplaceSales';
import MarketplacePurchases from '@MP/views/MarketplacePurchases';
import MarketplacePurchasesV2 from '@MP/views/v2/MarketplacePurchases';
import MarketplaceSalesV2 from '@MP/views/v2/MarketplaceSales';
import CancelPassword from '@MP/views/CancelPassword';
import OrderParts from '@MP/views/OrderParts';


export default [
    {
        path: '/marketplace',
        name: 'Marketplace',
        component: Marketplace,
        meta: {
            module: true,
            menu: {
                label: 'Marketplace',
                icon: ['fas', 'shopping-basket']
            }
        },
        children: [
           
            {
                path: 'sales',
                name: 'Sales',
                component: MarketplaceSales,
                meta: {
                    module: false,
                    menu: {
                        label: 'Sales',
                        icon: ['fas', 'sign-out-alt']
                    }
                }
            },
            {
                path: 'purchases',
                name: 'Purchases',
                component: MarketplacePurchases,
                meta: {
                    module: false,
                    menu: {
                        label: 'Purchases',
                        icon: ['fas', 'sign-in-alt']
                    }
                }
            },
            {
                path: 'orderparts',
                name: 'OrderParts',
                component: OrderParts,
                meta: {
                    module: false,
                    menu: {
                        label: 'Order Parts',
                        icon: ['fas', 'shopping-cart']
                    }
                }
            },
            {
                path: 'v2/purchases',
                name: 'Purchases(beta)',
                component: MarketplacePurchasesV2,
                meta: {
                    module: false,
                    menu: {
                        label: 'Purchases',
                        icon: ['fas', 'sign-in-alt']
                    }
                }
            },
            {
                path: 'v2/sales',
                name: 'Sales(beta)',
                component: MarketplaceSalesV2,
                meta: {
                    module: false,
                    menu: {
                        label: 'Sales',
                        icon: ['fas', 'sign-in-alt']
                    }
                }
            },
            {
                path: 'listingsmanager',
                name: 'Listings Manager',
                component: ListingsManager,
                meta: {
                    module: false,
                    menu: {
                        label: 'Listings Manager',
                        icon: ['fas', 'edit']
                    }
                }
            },
            {
                path: 'marketplaceprofile',
                name: 'Marketplace Profile',
                component: MarketplaceProfile,
                meta: {
                    module: false,
                    menu: {
                        label: 'Marketplace Profile',
                        icon: ['fas', 'user']
                    }
                }
            },
            {
                path: 'prefixmappings',
                name: 'Prefix Mappings',
                component: PrefixMappings,
                meta: {
                    module: false,
                    menu: {
                        label: 'Prefix Mappings',
                        icon: ['fas', 'random']
                    }
                }
            },
            {
                path: 'cancelpassword',
                name: 'CancelPassword',
                component: CancelPassword,
            }

        ]
    }
];