<script>
export default {
    /**
     * This component serves as a basis for all lookup components and
     * as such should NOT be instantiated directly. Common functionality
     * shared by all lookup components will be defined here, such as
     * props for supporting v-model binding, validation, and component
     * disabling as well as methods and filters.
     * Unfortunately template inheritance is not supported by VueJS
     * so templates must be defined on a component basis.
     * @see {@link https://vuejs.org/v2/api/#extends}
     */
    name: 'IbLookupBase',
    
    props: {
        /**
         * The parent source of the v-model binding.
         */
        value: {
            required:   false,
            default:    null
        },
        /**
         * Determines whether the component is
         * disabled or not.
         */
        disabled: {
            required:   false,
            default:    false
        },
        /**
         * The maximum number of characters to allow
         * the user to enter into the input control.
         */
        maxLength: {
            type:       Number,
            required:   false,
            default:    -1
        },
        /**
         * Set to false to display validation error.
         */
        valid: {
            type:       Boolean,
            default:    null
        }
    },
    
    data() {
        return {

            table: {
                columns:          [],
                filterProperties: [],
                items:            [],
                selectedItem:     null,
                error:            null,
                searching:        false
            },

            item: {
                display:   null,
                value:     null,
                loading:   false,
                valid:     null,
                searching: false
            },

            messages: {
                noResults: 'No results match the search',
                searching: 'Searching...'
            },

            id:          null,
            showModal:   false,
            filter:      null,
            filterDelay: 500
        }
    },

    methods: {
        /**
         * Open the modal popup.
         */
        openModal(event) {
            event.target.blur();
            this.showModal = true;
        },

        /**
         * Close the modal popup.
         */
        closeModal() {
            this.showModal = false;
        },

        /**
         * Open the modal if it's closed, otherwise close it.
         */
        toggleModal() {
            this.showModal = !this.showModal;
        },

        /**
         * Clear the filter and the filter results.
         */
        clear() {
            this.filter      = null;
            this.table.items = [];
        },

        /**
         * Focus the search box.
         */
        focusInput() {
            this.$refs.search.focus();
        },

        /**
         * Event handler for the OK button.
         */
        handleOk() {
            if (this.table.selectedItem) {
                this.item.value = this.table.selectedItem;
                this.item.valid = null;
            }
        }
    },

    computed: {

        debouncedFilter() {
            const me = this;
            return me.$_.debounce(() => me.search(me.filter), me.filterDelay);
        }

    },

    watch: {
        
        filter(newValue) {
            if (newValue) {
                this.debouncedFilter();
            }
        },

        "item.valid"(newValue) {
            this.$emit('valid', newValue);
        },

        "item.display"(newValue) {
            if (!newValue) {
                this.item.value = {};
            }
        },

        valid(newValue) {
            this.item.valid = newValue;
        }

    }
}
</script>
