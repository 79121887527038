<template>
    <ib-modal
        v-model="show"
        modalClass="col-11"
        :dismissable="true"
        >
        <div>
            
            <div>
                <h5>Preview Parts</h5>
                {{rulesToPreview}}
            </div>
            <div class="row pl-3 w-100 pt-2 pb-2">
                <span>
                    <input class="form-control" type="text" placeholder="Search" v-model="searchPhrase">
                </span>
                <span class="pl-2">
                    <button type="button" class="btn btn-secondary" @click="clearSearch">Clear</button>
                </span>
                <span class="pl-2">
                    <button type="button" class="btn btn-primary" @click="exportToCSV">Export</button>
                </span>
            </div>
        </div>
        <div style="overflow-y: auto; height:650px">
            <ib-table
                :items="prelistings"
                :columns="resultsColumns"
                noItemsMessage="No Records Found"
                :itemsPerPage="16"
                :small="true"
                :value="selectedResult"
                :filterValue="searchPhrase"
                :filterProperties="searchColumns"
                :filterDelay="50"
                :loading="prelistingsLoading"
                @input="selectResult">
                <template
                    slot       ="excludeSelected"
                    slot-scope ="data"
                    >    
                    <input type="checkbox" name="exclude" :id="data.item.partNumber" v-model="data.item.excludeSelected"/>
                </template>
                <template 
                    slot="listingPrice"
                    slot-scope="data">
                    <div class="text-right pr-5">
                        {{ data.item.currency }}{{ data.item.listingPrice }}
                    </div>
                </template>
                <template 
                    slot="totalQuantity"
                    slot-scope="data">
                    <div class="text-right pr-5">
                        {{ data.item.totalQuantity }}
                    </div>
                </template>                
            </ib-table>
        </div>
        <div>
            <button type="button" class="btn btn-secondary" @click="excludeParts"><i class="fas fa-ban"></i> Exclude Selected</button>
        </div>
    </ib-modal>
</template>
<script>
import IbTable      from '@/components/table/IbTable';
import IbModal      from '@/components/utilities/IbModal';

const ResultsColumns = [
    {
        heading: 'Exclude',
        property: 'excludeSelected',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Depot',
        property: 'depot',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Part Number',
        property: 'partNumber',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Product Group',
        property: 'productGroup',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Class Code',
        property: 'classCode',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Quantity',
        property: 'totalQuantity',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Listing Price',
        property: 'listingPrice',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Listing Rule',
        property: 'matchingListingRule',
        sortable: true,
        class: 'large-column'
    },
];

const SearchColumns = [
    'partNumber','description'
];

export default {
    name: 'PreviewParts',
    
    components: {
        IbModal,
        IbTable,
    },

    created() {
    },

    data() {
        return {
            show            : false,
            resultsColumns  : ResultsColumns,
            searchColumns   : SearchColumns,
            selectedResult  : null,
            searchPhrase    : "",
        }
    },

    computed: {
        rulesToPreview() {
            let previewRules = this.rules.filter(function(p) {
                return p.showInResults === true;
            });

            
            if (previewRules.length > 1) 
            {
                let rulesFormatted = previewRules.map(e => {return "'" + e.description + "'";});
                var result = rulesFormatted.slice(0,-1).join(" , ") + " and " + rulesFormatted.slice(-1);
                return "Previewing parts for " + result;
            }
            else if (previewRules.length == 1)
            {
                let rulesFormatted = previewRules.map(e => {return "'" + e.description + "'";});
                return "Previewing parts for " + rulesFormatted[0]; 
            }
            else
            {
                return "No rules selected.";
            }
        },
    },

    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            required : true,
            type     : Boolean
        },

        rules: {
            required    : true,
            type        : Array
        },

        prelistings: {
            required    : true,
            type        : Array
        },

        prelistingsLoading: {
            required    : true,
            type        : Boolean
        }

    },

    methods: {
        excludeParts() {
            console.debug("Preview Parts - Excluding Parts, emmiting");
            //emit the exclude
            let partsToExclude = this.prelistings.filter(function(p) {
                    return p.excludeSelected === true;
                });
            this.$emit('excludeParts', partsToExclude);
        },
        
        selectResult() {

        },

        clearSearch() {

            this.searchPhrase = "";
        },

        exportToCSV() {
            this.$emit('exportToCSV', 'preListings', this.searchPhrase);
        },

    },

    watch: {

        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
  

    }
    
}
</script>
<style lang="less" scoped>
    hr { margin: 5px 0 10px 0; }

/deep/ .medium-column {
    min-width: 100px;
    width: 100px;
}

/deep/ .large-column {
    min-width: 200px;
    width: 200px; 
}

</style>