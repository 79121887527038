import ApiService from '@/common/api-service';

const SALES_ROOT = "api/v1/sales";

const SalesApi = {
    ///
    /// GET Requests
    ///
    /**
     * Get all operators.
     * 
     */
    getAllOperators() {
            
            const url = `${SALES_ROOT}/operators`;
            return ApiService.get(url);
        },

    ///
    /// POST Requests
    ///
    
    /**
     * check an operators credentials.
     * @param {Object} operator - The operator model populated with adepot, storeman and password.
     */
     async checkOperatorCredentials(operatorToCheck) {
        const url = `${SALES_ROOT}/operator/checkcredentials`;
        return await ApiService.post(url, operatorToCheck);
    },
    
    /**
     * check an operators permssion.
     * @param {Object} operator - The operator model populated with the adepot, storeman and permissions you want them to have.
     * 
     *  1 Raise Parts Invoices           
     *  2 Raise Credits                  
     *  4 Create IDTs                    
     *  8 Allow Price & Discount Changes 
     * 16 Raise Serial Number Item Invoi 
     * 32 Raise Serial Number Item Self- 
     * 64 All of above  
     */
     async checkOperatorPermissions(operatorToCheck) {
        const url = `${SALES_ROOT}/operator/checkpermissions`;
        return await ApiService.post(url, operatorToCheck);
    }
};

export default SalesApi;
