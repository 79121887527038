const ToastTarget = {
    TOP_RIGHT      : 'b-toaster-top-right',
    TOP_LEFT       : 'b-toaster-top-left',
    TOP_CENTER     : 'b-toaster-top-center',
    TOP_FULL       : 'b-toaster-top-full',
    BOTTOM_RIGHT   : 'b-toaster-bottom-right',
    BOTTOM_LEFT    : 'b-toaster-bottom-left',
    BOTTOM_CENTER  : 'b-toaster-bottom-center',
    BOTTOM_FULL    : 'b-toaster-bottom-full'
};

const ToastVariant = {
    PRIMARY   : 'primary',
    SECONDARY : 'secondary',
    DANGER    : 'danger',
    WARNING   : 'warning',
    SUCCESS   : 'success',
    INFO      : 'info'
};

/**
 * Mixin for functionality to create toast notifications.
 */
const ToastMixin = {

    methods: {
        toast(title, target, variant, message) {
            const options = {
                title       : title,
                toaster     : target,
                variant     : variant,
                solid       : true,
                appendToast : true
            };

            if (!message) {
                message = ' ';
            }

            this.$root.$bvToast.toast(message, options);
        }
    }

};

export default ToastMixin;
export { ToastTarget, ToastVariant };