const NUMERIC = /\d/;
const ALPHABETICAL = /[a-zA-Z]/;
const ALPHANUMERIC = /[0-9a-zA-Z]/;

const RegexTokens = {
    '#': { pattern: NUMERIC },
    '@': { pattern: ALPHABETICAL },
    '~': { pattern: ALPHANUMERIC }
};

export { RegexTokens };
