<template>
<div
    v-if="isOpen"
    class="account-menu dropdown-menu"
    @click.stop
>
    <h6 class="dropdown-header">{{ userFullName }}</h6>
    <a
        class="dropdown-item"
        @click="doLogout"
    >
        Log out
    </a>
</div>
</template>

<script>
import {
    mapState,
    mapGetters,
    mapMutations,
    mapActions,
} from 'vuex';
import {
    CLOSE_ACCOUNT_MENU
} from '@/store/mutation-types';

export default {
    beforeDestroy() {
        this.unregisterClickHandler();
    },

    computed: {
        ...mapState('core', {
            isOpen: state => state.accountMenu.open,
        }),

        ...mapGetters('auth', [
            'userFullName',
        ]),
    },

    watch: {
        isOpen(newValue) {
            if (true === newValue) {
                this.registerClickHandler();
            }
            else {
                this.unregisterClickHandler();
            }
        },
    },

    methods: {
        ...mapMutations('core', {
            close   : CLOSE_ACCOUNT_MENU,
        }),

        ...mapActions('auth', [
			'logout',
        ]),

        registerClickHandler() {
            window.addEventListener('click', this.close);
        },

        unregisterClickHandler() {
            window.removeEventListener('click', this.close);
        },

        doLogout() {
            this.logout();
            this.close();
        },
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/colours.less';
@import '~@/assets/css/imports.less';

.account-menu {
    position        : absolute;
    left            : @quickbar-width;
    bottom          : 10px;
    width           : 120px;
    background-color: @light-grey;
    z-index         : @account-menu-z-index;
    top             : auto;
    display         : block;
    float           : unset;

    .dropdown-item {
        cursor  : pointer;
    }
}
</style>
