<template>
<div>
	<label v-show="text.length > 0 && textAlign==='right'">
			{{text}}
	</label>
	<button class="btn btn-sm"
		:disabled="disabled"
		@click="checked"
		:style="{ 'background-color' : backgroundColour }"
		tabindex="-1">
		<span v-show="isChecked">
			<i class="far fa-fw fa-check-square fa-lg"></i>
		</span>
		<span v-show="!isChecked">
			<i class="far fa-fw fa-square fa-lg"></i>
		</span>
	</button>
	<label v-show="text.length > 0 && textAlign==='left'">
			{{text}}
	</label>
</div>
</template>
<script>
// https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
export default {
	name: 'IbCheckbox',

	props: {
		value: {
			type:     Boolean,
			required: true
		},

		disabled: {
			type:     Boolean,
			required: false
		},
		
		backgroundColour: {
			type: String,
			default: 'white'
		},
		//If you want to show text with check box add text
		text:{
			type:String,
			default:''
		},
		//This property to show text on right or left of checkbox
		textAlign:{
			type:String,
			default:'left'
		}
	},

	computed: {
		isChecked: {
			get() {
				// Get latest value from parent.
				return this.value;
			},
			set(newValue) {
				// Emit an input event to allow binding via v-model.
				this.$emit('input', newValue);
			}
		}
	},

	methods: {
		checked() {
			this.isChecked = !this.isChecked;
			this.$emit('change');
		}
	}
}
</script>

<style scoped>
button {
	border: none;
    background: white;
    padding: 0px;
}
button:focus {
	outline: none;
	box-shadow: none;
}
</style>
