import ClientApi from '@MP/api/client';
import { set, reset, commit } from '../mutators';
import * as mutation from '@/store/mutation-types';

const initialState = () => ({
    orders: {
        value: [],
        loading: false,
        error: null,
    },
});

const getters = {


    //     ,ad8888ba,                                                             
    //     d8"'    `"8b              ,d      ,d                                    
    //    d8'                        88      88                                    
    //    88             ,adPPYba, MM88MMM MM88MMM ,adPPYba, 8b,dPPYba, ,adPPYba,  
    //    88      88888 a8P_____88   88      88   a8P_____88 88P'   "Y8 I8[    ""  
    //    Y8,        88 8PP"""""""   88      88   8PP""""""" 88          `"Y8ba,   
    //     Y8a.    .a88 "8b,   ,aa   88,     88,  "8b,   ,aa 88         aa    ]8I  
    //      `"Y88888P"   `"Ybbd8"'   "Y888   "Y888 `"Ybbd8"' 88         `"YbbdP"'  

    // https://ascii.co.uk/text

    /**
     * Get all orders.
     */
    orders: state => {
        return state.orders.value;
    },
    /**
    * Get orders grouped by basket Id.
    */
    baskets: state => {
        const basketIds = [...new Set(state.orders.value.map(order => order.serverInfo.basketId))]
        let baskets = [];
        basketIds.forEach((basketId) => {
            //Added orderCreated and seller for sorting
            let basket = {
                basketId: basketId,
                orderCreated: "",
                sellerCompanyName: "",
                buyerCompanyName: "",
                items: []
            }
            state.orders.value.forEach((order) => {
                if (order.serverInfo.basketId === basketId) {
                    basket.orderCreated = order.serverInfo.orderCreated;
                    basket.sellerCompanyName = order.serverInfo.sellerCompanyName;
                    basket.buyerCompanyName = order.serverInfo.buyerCompanyName;
                    basket.items.push(order);
                }
            })
            baskets.push(basket)
        })
        return baskets;
    },
    /**
     * Get an order by id
     */
    order: ({ orders }) => id => {
        return _.find(orders.value, c => c.id === id);
    },
    /**
     * the product rules loading state
     */
    ordersLoading: ({ orders }) => orders.loading,

    /**
    * the product rules error state
    */
    ordersError: ({ orders }) => orders.error,


};



// 88b           d88                                        88                                    
// 888b         d888               ,d                 ,d    ""                                    
// 88`8b       d8'88               88                 88                                          
// 88 `8b     d8' 88 88       88 MM88MMM ,adPPYYba, MM88MMM 88  ,adPPYba,  8b,dPPYba,  ,adPPYba,  
// 88  `8b   d8'  88 88       88   88    ""     `Y8   88    88 a8"     "8a 88P'   `"8a I8[    ""  
// 88   `8b d8'   88 88       88   88    ,adPPPPP88   88    88 8b       d8 88       88  `"Y8ba,   
// 88    `888'    88 "8a,   ,a88   88,   88,    ,88   88,   88 "8a,   ,a8" 88       88 aa    ]8I  
// 88     `8'     88  `"YbbdP'Y8   "Y888 `"8bbdP"Y8   "Y888 88  `"YbbdP"'  88       88 `"YbbdP"'  

// https://ascii.co.uk/text        

const mutations = {
    // Set state product rules.
    [mutation.SET_ORDERS]: set('orders.value', 'orders'),

    [mutation.ORDERS_ERROR]: set('orders.error', 'error'),

    [mutation.ORDERS_LOADING]({ orders }, { loading }) {
        orders.loading = loading;
    },

    [mutation.UPDATE_ORDER]({ orders }, order) {
        orders.value = orders.value.map(o => {
            if (o.id == order.id) {
                return order;
            }
            else {
                return o;
            }
        });
    },

    // Clear state product filters.
    [mutation.CLEAR_ORDERS]: (state) => state.orders.value = [],
    [mutation.CLEAR_ORDERS_ERROR]: (state) => state.orders.error = null,

    // Reset state.
    [mutation.RESET]: (state) => reset(state, initialState()),

};



//        db                          88                                    
//       d88b                   ,d    ""                                    
//      d8'`8b                  88                                          
//     d8'  `8b     ,adPPYba, MM88MMM 88  ,adPPYba,  8b,dPPYba,  ,adPPYba,  
//    d8YaaaaY8b   a8"     ""   88    88 a8"     "8a 88P'   `"8a I8[    ""  
//   d8""""""""8b  8b           88    88 8b       d8 88       88  `"Y8ba,   
//  d8'        `8b "8a,   ,aa   88,   88 "8a,   ,a8" 88       88 aa    ]8I  
// d8'          `8b `"Ybbd8"'   "Y888 88  `"YbbdP"'  88       88 `"YbbdP"'  

// https://ascii.co.uk/text          

const actions = {
    /**
     * Fetch all orders from
     * the API and store in vuex.
     */
    async fetchOrders({ commit }, parameters) {
        commit(mutation.CLEAR_ORDERS);
        commit(mutation.ORDERS_ERROR, { error: null });
        commit(mutation.ORDERS_LOADING, { loading: true });

        try {
            const response = await ClientApi.getOrders(parameters);
            var orders = response.data;
            commit(mutation.SET_ORDERS, { orders });
        }
        catch (error) {
            commit(mutation.RESET);
            console.log(error);
            commit(mutation.ORDERS_ERROR, { error: error });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async buyerCancelOrder({ commit }, order) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            const response = await ClientApi.buyerCancelOrder(order.serverInfo.id);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async bulkCancelOrder({ commit }, basket) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            const response = await ClientApi.bulkCancelOrder(basket.basketId);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async sellerAcceptOrder({ commit }, order) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            console.log(order);
            const response = await ClientApi.sellerAcceptOrder(order.serverInfo.id);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async sellerBulkAcceptOrder({ commit }, basket) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            console.log(basket);
            const response = await ClientApi.sellerBulkAcceptOrder(basket.basketId);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },
    async sellerBulkRejectOrder({ commit }, basket) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            const response = await ClientApi.sellerBulkRejectOrder(basket.basketId);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async sellerRejectOrder({ commit }, order) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            console.log(order);
            const response = await ClientApi.sellerRejectOrder(order.serverInfo.id);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },

    async sellerGenerateAdvice({ commit }, adviceNoteDetails) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            console.debug(adviceNoteDetails);
            const response = await ClientApi.sellerGenerateAdvice(adviceNoteDetails);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    },
    async sellerBulkGenerateAdvice({ commit }, adviceNoteDetails) {
        commit(mutation.ORDERS_LOADING, { loading: true });
        commit(mutation.ORDERS_ERROR, { error: null });

        try {
            console.debug(adviceNoteDetails);
            const response = await ClientApi.sellerBulkGenerateAdvice(adviceNoteDetails);
            commit(mutation.UPDATE_ORDER, response)
        }
        catch (error) {
            commit(mutation.ORDERS_ERROR, { error: error.response.data });
        }
        finally {
            commit(mutation.ORDERS_LOADING, { loading: false });
        }
    }

};

export default {
    namespaced: true,
    state: initialState(),
    getters,
    mutations,
    actions
}