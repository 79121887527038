<template>
	<div
		v-if="hasDocStoreOptions(...requiredLicenses)"
		class="container-fluid h-100"
	>
		<b-toast
			id="job-created-toast"
			title="Workshop Job Request"
			variant="success"
			toaster="b-toaster-top-center"
			no-auto-hide
		>
			{{ toastText }}
		</b-toast>
		<div class="btn-toolbar justify-content-start mb-3" role="toolbar">
			<!-- Spacer - Expand to use all available space -->
			<div class="flex-grow-1"><h3>Job Requests</h3></div>
			<!-- Refresh job requests -->
			<button
				class="btn btn-primary mr-2"
				@click="refresh"
				:disabled="requestSummariesLoading === true"
			>
				<transition name="fade" mode="out-in">
					<span v-if="true === requestSummariesLoading" key="spinner">
						<i class="fas fa-sync-alt fa-spin"></i>
					</span>
					<span v-else key="fixed">
						<i class="fas fa-sync-alt"></i>
					</span>
				</transition>
				<span class="ml-2">Refresh</span>
			</button>

			<router-link
				tag="button"
				class="btn btn-primary"
				:to="{ name: 'JobRequestValidation' }"
			>
				Validation Issues
			</router-link>
		</div>

		<div v-if="!pageLoading" class="container-fluid h-100">
			<!-- Error -->
			<b-alert
				variant="danger"
				dismissible
				:show="null !== error"
				@dismissed="error = null"
			>
				<h5 class="alert-heading">Error:</h5>
				<p class="mb-0">{{ error }}</p>
			</b-alert>

			<br />

			<div class="row request-row">
				<div id="requestList" class="col-lg-3 col-2 mh-100">
					<ib-select-entry
						:items="depotList"
						displayProperty="text"
						noItemsMessage="No depots available"
						v-model="selectedDepot"
					/>
					<br />
					<div class="multi-select-wrapper">
						<multiselect
							v-model="selectedLabels"
							placeholder="Select labels"
							:options="labels"
							:multiple="true"
							:showLabels="false"
							@input="onLabelSelect"
							group-values="Labels"
							group-label="GroupName"
							track-by="Name"
							label="Name"
							:close-on-select="true"
							:clear-on-select="true"
							:preserve-search="true"
							:loading="labelLoading"
							:limit="2"
						/>
					</div>

					<br />
					<div v-if="filteredSummaries && filteredSummaries.length != 0">
						<ib-loading-spinner
							message="Loading..."
							class="align-middle"
							v-if="true === requestSummariesLoading"
						/>
						<div
							v-else
							class="card mb-1 bg-light"
							v-for="(summary, index) in filteredSummaries"
							:key="index"
						>
							<div
								class="card-header"
								:class="{
									'bg-light text-dark': summary.Customer && isUnselected(
										summary.Customer.AccountNo
									),
									'bg-secondary text-white': summary.Customer && isHighlighted(
										summary.Customer.AccountNo
									),
									'bg-primary text-white': summary.Customer && isSelected(
										summary.Customer.AccountNo
									),
								}"
								:id="`customer-${index}`"
							>
								<div
									data-toggle="collapse"
									class="clickable-text"
									:data-target="`#wholegoods-${index}`"
									:aria-expanded="summary.Customer ? isSelected(summary.Customer.AccountNo): false"
									:aria-controls="`wholegoods-${index}`"
									@click="summary.Customer && highlightCustomer(summary.Customer.AccountNo)"
								>
									{{ summary.Customer ? (`${summary.Customer.AccountNo} - ${summary.Customer.Name}`) : ("Wholegood(s) with No Customer")}} 
									<span v-if="summary.HasCriticalRequest" style="line-height:1.25" 
										class="badge badge-pill badge-danger float-right">
										{{summary.WholegoodRequestSummaries.length}} 
									</span>
									<span v-else-if="summary.HasHighRequest" style="line-height:1.25" 
										class="badge badge-pill badge-warning float-right">
										{{summary.WholegoodRequestSummaries.length}} 
									</span>
									<span v-else
										style="line-height:1.25"
										class="badge badge-pill badge-primary float-right"
										:class="{ 'badge-light': summary.Customer && isSelected(summary.Customer.AccountNo) }">
                                            {{summary.WholegoodRequestSummaries.length}} 
									</span>
								</div>
							</div>

							<div
								:id="`wholegoods-${index}`"
								class="collapse p0"
								:class="{ show: summary.Customer ? isSelected(summary.Customer.AccountNo) : false }"
								:aria-labelledby="`customer-${index}`"
								data-parent="#requestList"
							>
								<div class="card-body">
									<div
										v-for="wholegoodSummary in summary.WholegoodRequestSummaries"
										:id="`wholegood-${wholegoodSummary.Wholegood.Id}`"
										:key="wholegoodSummary.Wholegood.Id"
										class="mb-1 wholegood-list-item clickable-text"
										:class="{
											'bg-primary text-white':
												wholegoodSummary.Wholegood.Id === selected.wholegood,
												'text-warning': wholegoodSummary.HasHighRequest && wholegoodSummary.Wholegood.Id !== selected.wholegood,
												'text-danger': wholegoodSummary.HasCriticalRequest && wholegoodSummary.Wholegood.Id !== selected.wholegood,
										}"
										@click="
											selectWholegood(
												wholegoodSummary.Wholegood,
												summary.Customer
											)
										"
									>
										<b-tooltip
											placement="righttop"
											boundary="window"
											:delay="{ show: 500, hide: 100 }"
											custom-class="wholegood-tooltip"
											:target="`wholegood-${wholegoodSummary.Wholegood.Id}`"
										>
											<b>RegistrationNumber:</b>
											{{ wholegoodSummary.Wholegood.RegistrationNumber }}
											<br />
											<b>SerialNumber:</b>&nbsp;{{
												wholegoodSummary.Wholegood.SerialNumber
											}}
											<br />
											<b>Make:</b> {{ wholegoodSummary.Wholegood.Make }}
											<br />
											<b>Model:</b> {{ wholegoodSummary.Wholegood.ModelCode }}
											<br />
											<b>Year:</b> {{ wholegoodSummary.Wholegood.Year }}
											<br />
											<b>ClockHours:</b>
											{{ wholegoodSummary.ClockHours }} (Latest)
											{{ goldClockHours(wholegoodSummary.Wholegood) }} (Gold)
											<br />
											<b>WarrantyEndDate:</b>
											{{
												wholegoodSummary.Wholegood.WarrantyEndDate
													| standardDateFormat
											}}
											<br />
											<b>WarrantyExtendedDate:</b>
											{{
												wholegoodSummary.Wholegood.WarrantyExtendedDate
													| standardDateFormat
											}}
											<br />
											<b>Specifications:</b>
											<br />{{
												wholegoodSummary.Wholegood.Specification
													| removeExtraCommasFromString
											}}
										</b-tooltip>
										{{ wholegoodSummary.Wholegood.ModelCode }} -
										{{ wholegoodSummary.Wholegood.Model }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="filteredSummaries && filteredSummaries.length != 0"
					class="col-lg-9 col-10 mh-100"
				>
					<!-- <request-details v-on:refreshRequested="refresh" /> -->
					<transition name="fade">
						<div
							key="list"
							class="container-fluid h-100"
							v-if="false == creating && !requestsLoading">
							<keep-alive>
								<request-tab
									v-on:refreshFilteredRequestList="refreshFilteredSummaries"
									@create="creating = true"
								/>
							</keep-alive>
						</div>
						<div class="container-fluid h-100"
							v-if="requestsLoading && creating==false">
							<ib-loading-spinner
								message="Loading..."
								class="align-middle h-75"
							/>
						</div>
						<div key="create" class="container-fluid h-100" v-if="creating">
							<create-requested-job
								@cancel="creating = false"
								@created="jobCreated"
							/>
						</div>
					</transition>
				</div>
				<div v-else class="col-lg-9 col-10 mh-100">
					<div class="alert alert-warning col-lg-12 text-center" role="alert">
						<h5>No Pending Requests for selected Depot(s)</h5>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="my-auto h-100">
			<ib-loading-spinner
				message="Loading..."
				class="align-middle h-75"
				v-if="true === requestSummariesLoading"
			/>
		</div>
	</div>
	<div v-else class="alert alert-warning">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Job Requests licencing
		</h4>
		<p>
			Job Requests Module requires licencing, please contact your Account
			Manager for further details.
		</p>
	</div>
</template>

<script>
	/** VueJS */
	import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

	/** Components */
	import RequestTab from '@/components/tabs/RequestTab';
	import CreateRequestedJob from '@WS/components/job-requests/CreateRequestedJob';
	import DepotApi from '@WS/api/depot';
	import JobApi from '@WS/api/job';
	import LicenceMixin from '@/mixins/LicenceMixin';
	import DateMixin, { DateFormat } from '@/mixins/DateMixin';
	import IbSelectEntry from '@/components/form/IbSelectEntry';
	import IbTable from '@/components/table/IbTable';
	import IbLoadingSpinner from '@/components/IbLoadingSpinner';
	import Multiselect from 'vue-multiselect';
	import { DocStoreOptions } from '@/common/docstore-options';
	import {
		SET_SELECTED_WHOLEGOOD,
		SET_SELECTED_WHOLEGOOD_OBJ,
		SET_SELECTED_CUSTOMER,
		SET_SELECTED_REQUEST,
	} from '@/store/mutation-types';

	const johnDeereLicenses = [
		DocStoreOptions.GOLD_SVAP_SINGLE_DEPOT,
		DocStoreOptions.GOLD_SVAP_MULTI_DEPOT,
	];

	const jcbLicenses = [
		DocStoreOptions.GOLD_JCB_LIVELINK_SINGLE_DEPOT,
		DocStoreOptions.GOLD_JCB_LIVELINK_MULTI_DEPOT,
	];

	const requiredLicenses = [DocStoreOptions.GOLD_PORTAL_JOB_REQUEST];

	const ALL_DEPOTS = {
		value: '*',
		text: 'All Depots',
	};

	export default {
		name: 'JobRequests',

		mixins: [DateMixin, LicenceMixin],

		components: {
			RequestTab,
			CreateRequestedJob,
			IbTable,
			IbLoadingSpinner,
			IbSelectEntry,
			Multiselect,
		},

		data() {
			return {
				loading: false,
				error: null,
				creating: false,
				highlightedCustomer: null,
				docStoreOptions: DocStoreOptions,
				requiredLicenses: requiredLicenses,
				depots: [],
				selectedDepot: null,
				filteredSummaries: [],
				pageLoading: true,
				requestSummariesLoading: true,
				labels: [],
				labelLoading: true,
				selectedLabels: [],
				toastText: '',
				requestsLoading: false
			};
		},

		async created() {
			this.pageLoading = true;
			await this.clearSelection();
			await this.refresh();
		},

		computed: {
			...mapState('jobrequest', {
				summaries: 'requestSummaries',
				selected: 'selection',
				priorityCodes: 'priorities',
			}),

			...mapState('system', {
				company: 'company',
				depot: (state) => state.depot.selected,
			}),
			/**
			 * Creates a list of depots for display. Name is reformatted to include the
			 * depot short name.
			 */
			depotList() {
				const depots = this.depots.map((depot) => {
					let text = `${depot.Id} - ${depot.Name}`;
					if (depot.ShortName && depot.Name != depot.ShortName) {
						text += ` (${depot.ShortName})`;
					}

					return {
						value: depot.Id,
						text: text,
					};
				});
				return this.buildSelectList(depots, ALL_DEPOTS);
			},

			depotAndSummariesReady() {
				return this.depot && !this.summaries.loading;
			},
		},

		watch: {
			async 'company.selected'(newCompany) {
				this.pageLoading = true;
				await this.clearSelection();
				await this.refresh();
			},

			selectedDepot(newDepot) {
				this.updateFilteredSummaries(newDepot);
			},

			depotAndSummariesReady(newValue) {
				if (true == newValue) {
					this.fetchDepots();
				}
			},
		},

		methods: {
			/**
			 *  Map in state load functions.
			 */

			...mapActions('jobrequest', {
				fetchJobRequestSummaries: 'fetchJobRequestSummaries',
				fetchJobRequests: 'fetchJobRequests',
				fetchJobPriorities: 'fetchJobPriorities',
				removeRequest: 'removeRequest',
				clearSelection: 'clearSelection',
			}),

			/**
			 * Map in 'setters' for state items.
			 */
			...mapMutations('jobrequest', {
				setCustomerId: SET_SELECTED_CUSTOMER,
				setWholegood: SET_SELECTED_WHOLEGOOD_OBJ,
				setRequest: SET_SELECTED_REQUEST,
			}),

			showJobCreate() {
				this.creating = true;
			},

			async jobCreated(request) {
				this.creating = false;
				this.toastText = `Workshop Job ${request.JobId} Created.`;
				this.$bvToast.show('job-created-toast');
				this.removeRequest(request);
				await this.fetchJobRequestSummaries([]);
				await this.refreshFilteredSummaries(this.selectedDepot);
			},

			highlightCustomer(customerId) {
				this.highlightedCustomer = customerId;
			},

			isUnselected(customerId) {
				return !this.isSelected(customerId) && !this.isHighlighted(customerId);
			},

			isHighlighted(customerId) {
				return customerId === this.highlightedCustomer;
			},

			isSelected(customerId) {
				return customerId === this.selected.customer;
			},

			async selectWholegood(wholegood, customer) {
				this.requestsLoading=true;
				let current = _.get(this.selected, 'wholegoodObj');

				this.setWholegood(wholegood);
				this.setCustomerId(customer);

				this.highlightedCustomer = null;
				this.creating = false;

				// Clear the selected request if we are changing wholegood.
				if (current && wholegood && current.Id !== wholegood.Id) {
					this.setRequest({ request: {} });
				}

				await this.fetchJobRequests();
				this.requestsLoading=false;
			},

			refreshFilteredSummaries() {
				this.updateFilteredSummaries(this.selectedDepot);
			},

			//using a method, rather than a watcher here, as company change may result in identical 'all depots' depot being selecetd
			updateFilteredSummaries(newDepot) {
				let me = this;
				let summariesClone = _.cloneDeep(this.summaries.value);
				try {
					if (newDepot.value == '*') {
						this.filteredSummaries = summariesClone;
					} else {
						this.filteredSummaries = summariesClone.filter(function(summary) {
							let listOfWholegoodRequestSummaries = summary.WholegoodRequestSummaries.filter(
								function(wholegoodSummary) {
									return wholegoodSummary.Wholegood.Depot === newDepot.value;
								}
							);
							summary.WholegoodRequestSummaries = listOfWholegoodRequestSummaries;
							return listOfWholegoodRequestSummaries.length > 0;
						});
					}
				} catch (error) {
					this.filteredSummaries = summariesClone;
				} finally {
					me.pageLoading = false;
				}
			},

			async refresh() {
				try {
					this.requestSummariesLoading = true;
					await this.fetchNewRequests();
					await this.fetchJobRequestSummaries([]);
					await this.fetchJobPriorities();
					await this.refreshFilteredSummaries(this.selectedDepot);
					await this.fetchJobRequestLabels();
				} finally {
					this.pageLoading = false;
					this.requestSummariesLoading = false;
				}
			},

			/**
			 * Currently this method invokes John Deere SvAP only functionality.
			 */
			async fetchNewRequests() {
				const me = this;
				// Only run for JD.
				if (false === me.hasDocStoreOptions(...johnDeereLicenses)) {
					return;
				}

				await JobApi.fetchNewRequests().then(
					(success) => {},
					(failure) => {
						let message = null;
						if (failure.response) {
							message = `Failed to Fetch New Job Requests. ${failure.response.data.message}`;
						} else {
							console.error(
								'JobApi.fetchNewRequests(): No response recieved from REST server. Possibly offline'
							);
						}
						me.error = message;
					}
				);
			},

			/**
			 * Returns a new array with defaultItem at index 0 and all items specified
			 * in array appended afterwards.
			 */
			buildSelectList(array, defaultItem) {
				let items = [];
				if (Array.isArray(array) && 0 < array.length) {
					// Create new array with defaultItem as first item.
					items = [defaultItem, ...array];
				}
				return items;
			},

			/**
			 * Ask the server for a list of depots.
			 * TODO: Should pull this out of state somewhere.
			 */
			async fetchDepots() {
				const me = this;
				try {
					let depots = await DepotApi.getAllDepotsForCompany(
						this.currentCompanyId
					);
					me.depots = depots.data.data;
					me.selectedDepot = _.find(me.depotList, function(d) {
						return me.depot.Id == d.value;
					});

					this.updateFilteredSummaries(me.selectedDepot);
				} catch (error) {
					me.error = `Failed to get list of depots. ${error}.`;
				}
			},

			async fetchJobRequestLabels() {
				try {
					this.labelLoading = true;
					var jobRequestLabels = await JobApi.getJobRequestLabels();
					this.labels = jobRequestLabels.data.data;
					this.labelLoading = false;
				} catch (error) {
					this.error = `Failed to get list of job request labels. ${error}.`;
				}
			},
			async onLabelSelect() {
				await this.fetchJobRequestSummaries(this.selectedLabels);
				this.updateFilteredSummaries(this.selectedDepot);
			},
			goldClockHours(wholegood) {
				let result = '0';
				if (false === _.isNil(wholegood)) {
					result = Math.max(
						wholegood.ClockHoursAtLastService,
						wholegood.ClockHoursAtLastService
					);
				}

				return result;
			},
		}, //END methods
	};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
	body,
	html {
		height: 100% !important;
	}

	#requestList {
		overflow-y: scroll;
	}

	.request-row {
		height: 90% !important;
	}

	.clickable-text {
		cursor: pointer;
	}

	.wholegood-list-item {
		border-radius: 0.5em;
		padding: 2px 6px;
	}
</style>

<style>
	/******************* vue-multiselect theme adjustment ************************/
	.multi-select-wrapper .multiselect__spinner:after,
	.multiselect__spinner:before {
		border-top-color: #007bff !important;
	}

	.multi-select-wrapper .multiselect__tag-icon:focus,
	.multiselect__tag-icon:hover {
		background: #007bff !important;
	}
	.multi-select-wrapper .multiselect__tag {
		background: #007bff !important;
	}
	.multi-select-wrapper .multiselect__option--highlight {
		background: #007bff !important;
	}
	.multi-select-wrapper .multiselect__option--highlight:after {
		background: #007bff !important;
	}
	.multi-select-wrapper
		.multiselect__option--selected.multiselect__option--highlight {
		background: #e4c27a !important;
		color: #fff;
	}
	.multi-select-wrapper
		.multiselect__option--selected.multiselect__option--highlight:after {
		background: #e4c27a !important;
		color: #fff;
	}
	.multi-select-wrapper
		.multiselect__option--group-selected.multiselect__option--highlight {
		background: #e4c27a !important;
		color: #fff;
	}
	.multi-select-wrapper
		.multiselect__option--group-selected.multiselect__option--highlight:after {
		background: #e4c27a !important;
		color: #fff;
	}
	/******************* vue-multiselect theme adjustment ************************/

	/************************************ bootstrap tooltip custom css **************************************/
	.wholegood-tooltip {
		opacity: 1 !important;
	}
	.wholegood-tooltip .tooltip-inner {
		/* Removes the default max-width */
		max-width: 300px !important;
		text-align: justify;
		background: rgb(255, 255, 255);
		color: black;
		box-shadow: 1px 1px 5px 0px black;
	}
	/************************************ bootstrap tooltip custom css **************************************/
</style>
