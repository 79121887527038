<template>
<div class="header form-row align-items-center">
	<div class="col-md-1">Start</div>
	<div class="col-md-1">Stop</div>
	<div class="col-md-1">Hours</div>
	<div class="col-md-1">Job No.</div>
	<div class="col-md-2">Customer</div>
	<div class="col-md-3">Comment</div>
	<div class="col-md-2">Rate</div>
	<div class="col-md-1">
		<ib-checkbox 
			class="checkbox float-right"
			v-model="isSelectAllChecked"
		></ib-checkbox>
	</div>
</div>
</template>

<script>
import IbCheckbox from '@/components/form/IbCheckbox';

export default {
	name: 'DayHeader',

	components: {
		IbCheckbox
	},

	props: {
		allSelected: {
			type:     Boolean,
			required: true
		}
	},

	computed: {
		isSelectAllChecked: {
			get() {
				// Get latest value from parent.
				return this.allSelected;
			},
			set(newValue) {
				// Emit an event to update parent.
				this.$emit('select-all-checked', newValue);
			}
		}
	}
}
</script>

<style scoped>
:not(.checkbox) {
	font-size: 1rem;
}

div {
	font-style: italic;
	font-weight: bold;
}

.header {
	min-height: 30px;
	margin-bottom: 5px;
}

.header > div {
	text-align: left;
}
</style>
