<template>
        <div @drop.stop="onDrop"
            @dragover.prevent
            @dragenter.prevent
            v-if="!disabled">
            <slot/>
        </div>
        <div v-else>
            <slot/>
        </div>
</template>

<script>
/**
 * Component for handling drop events in a uniform way.
 */
export default {
    name: 'IbDrop',


    props: {

        /**
         * Flag determines if the element can be dropped.
         */
        disabled: {
            type: Boolean,
            default: false
        },
        /**
         * Object of the drop target
         */
        name: {
            type: String,
            default: ""
        }
    },

    methods: {

        /**
         * Event handler for the drop event.
         * 
         * Emits a 'drop' event to the parent component, containing
         * the payload from the drop event.
         */
        onDrop(e) { 
            console.debug("doing onDrop");
            const transferData = JSON.parse(e.dataTransfer.getData('payload'));
            transferData.dropTarget = this.name;
            console.debug("emitting drop");;
            this.$emit('drop', transferData);
        }
    }
}
</script>