<template>
<div>
	<h3>Engineers Time Entries</h3>

	<div class="d-flex flex-row mb-2"
		role="toolbar">
		<depot-list class="col-3" />

		<div
			class="dropdown col-3"
			v-if="$_.get(schedulerDepartments.value, 'length')"
		>
			<select
				class="form-control"
				v-model="schedulerDepartments.selected"
			>
				<option
					:value="null"
					selected
				>
					Select Department
				</option>

				<option
					v-for="(department, index) in schedulerDepartments.value"
					:key="index"
					:value="department.Code"
				>
					{{ department.Code }} - {{ department.Description }}
				</option>
			</select>
		</div>

		<!-- Spacer - Expand to use all available space -->
		<div class="flex-grow-1"></div>

		<!-- Refresh job activities -->
		<button class="btn btn-primary mr-2"
			@click="fetchActivities"
			:disabled="loading === true"
		>
			<transition name="fade" mode="out-in">
				<span v-if="true === loading" key="spinner">
					<i class="fas fa-sync-alt fa-spin"></i>
				</span>
				<span v-else key="fixed">
					<i class="fas fa-sync-alt"></i>
				</span>
			</transition>
			<span class="ml-2">Refresh</span>
		</button>

		<router-link tag="button"
			class="btn btn-primary"
			:to="{ name: 'AddTime' }">
			Add Hours
		</router-link>
	</div>

	<div class="d-flex justify-content-end">
		<help-panel class="mt-0 mb-2">
			Here, you can manage time logged by your <em>Gold Service</em> Engineers.  

			<ul>
				<li><strong>To amend an activity;</strong> Edit the start or stop fields.  You can not modify an activity after it has been approved.</li>
						
				<li>
					<strong>To approve an activity;</strong> Click the <i class="far fa-fw fa-square fa-lg"></i> box for any activities you wish to approve. 
				Then click the <em>Approve</em> button to add these lines to the invoice and payroll report.
					<ul>
						<li>To allow you to see the full day's work; activities that have already been approved will continue to show on the list until all activity for a day is approved or deleted.</li>
						<li>If there are errors in the activity, you will not be able to approve the activity.</li>
					</ul>
				</li>
			
				<li><strong>To remove an activity;</strong> Click the <i class="far fa-fw fa-trash-alt fa-lg"></i> next to a job activity for this engineer. 
				Once deleted, you can not undelete an activity.</li>
			</ul>

			To add a new activity for an engineer, click the <em>"Add Hours"</em> button.  This will allow you to insert a new activity for a given day. Set
			the activity type, start and stop times. Click the search bar in the middle to find the job based on job number, description, account name or contact name.	
			<br/><br/>
			To set the rate of the labour created from an activity, select the rate from the drop down. <strong>AUTOMATIC</strong> will let the usual overtime 
			calculations determine the rate.	
		</help-panel>
	</div>

	<!-- Progress bar -->
	<transition name="progress-fade">
		<div v-show="loading" class="progress mb-2">
			<div class="progress-bar bg-success"
				role="progressbar"
				:style="`width: ${loadingProgress}%`"
				:aria-valuenow="loadingProgress"
				aria-valuemin="0"
				aria-valuemax="100"></div>
			<span class="progress-bar-label text-center">{{ loadingProgressLabel }}</span>
		</div>
	</transition>
    	
	<!-- Errors -->
	<b-alert
		v-for="error in displayErrors"
		:key="error.id"
		variant="danger"
	 	show
		dismissible
		@dismissed="clearError(error.id)">
		{{ error.message }}
	</b-alert>

	<engineer-activities
		v-for="(engineer, index) in engineersForApproval"
		:key="engineer.id"
		:id="engineer.id"
		:name="engineer.name"
		:activities="engineer.activities.list"
		:labourCategories="labourCategories.value"
		:visible="0 === index"
	></engineer-activities>

	<div
		v-if="(false === hasTimeToApprove) && (false === loading)"
		class="no-entries">
		<h4>No time entries to approve.</h4>
		<i class="far fa-10x fa-calendar-check"></i>
	</div>

</div>
</template>

<script>
import {
	mapState,
	mapGetters,
  mapActions
}							from 'vuex';
import {
	get as _get
} 							from 'lodash-es';
import StorageService		from '@/common/storage-service';

//Utilities
import moment				from 'moment';
import JobActivityApi		from './../api/job-activity';
import EngineerApi		 	from '@WS/api/engineer';
import SchedulerApi 		from '@WS/api/scheduler';

//Components
import IbLoadingSpinner		from '@/components/IbLoadingSpinner';
import HelpPanel			from '@/components/HelpPanel';
import EngineerActivities	from '@WS/components/time-approval/EngineerActivities';
import DepotList			from '@WS/components/DepotList';

const FETCH_DELAY = 600;

export default {
	name: 'ApproveTimeEngineer',

	components: {
		IbLoadingSpinner,
		HelpPanel,
		EngineerActivities,
		DepotList
	},

	data() {
		return {
			engineersLocal		: [],
			engineersDataLoaded	: 0,
			loading				: false,

            labourCategories	: {
                value	: [],
                loading	: false,
                error	: null
			},
			
			schedulerDepartments : {
				loading  : false,
				error 	 : null,
				value 	 : [],
				selected : null
			},
		}
	},

	created() {
		this.fetchActivities();
		this.fetchLabourCategories();
		this.fetchSchedulerDepartments();
	},

	computed: {
		...mapState('engineer', {
			engineers	: state => state.engineers.value,
		}),

		...mapGetters('engineer', {
			schedulerDepartment : 'schedulerDepartment'
		}),

		...mapGetters('system', {
			depotId		: 'currentDepotId',
		}),
		
		/**
		 * Returns a sub set of engineers that are assigned to the
		 * selected depot.
		 * If all depots is selected all engineers will be returned.
		 * @returns {object[]} - Array of engineers at depot.
		 */
		engineersAtDepot() {
			const engineersAtDepot = _.filter(this.engineersLocal, (engineer) => {
				const atDepot = this.engineerAtDepot(engineer);
				return atDepot;
			});
			return engineersAtDepot;
		},

		/**
		 * Returns a sub set of engineers which have activities.
		 * @returns {object[]} - Array of engineers with activities.
		 */
		engineersForApproval() {
			const schedulerDepartment = this.schedulerDepartments.selected;
			const engineersForApproval = _.filter(this.engineersAtDepot, (engineer) => {
				const hasActivities = 0 < engineer.activities.list.length;
				const atDepartment  = !schedulerDepartment || schedulerDepartment == engineer.department;
				
				return hasActivities && atDepartment;
			});
			return engineersForApproval;
		},

		/**
		 * Returns the total number of engineers for the selected
		 * depot.
		 * @returns {number} - The total number of engineers.
		 */
		totalEngineersAtDepot() {
			const engineerCount = this.engineersAtDepot.length;
			return engineerCount;
		},

		/**
		 * Returns the current percentage progress of the data
		 * load.
		 * @returns {number} Percentage complete as an integer.
		 */
		loadingProgress() {
			const rawPercent = (this.engineersDataLoaded / this.totalEngineersAtDepot) * 100;
			const percent	 = Math.round(rawPercent);
			return percent;
		},

		/**
		 * Returns the progress message to inform the user of
		 * progress with loading engineer data.
		 * @returns {string} The message to display within the
		 * progress bar.
		 */
		loadingProgressLabel() {
			let label = `Fetching data for ${this.engineersDataLoaded}/${this.totalEngineersAtDepot} engineers.`;
			if (this.totalEngineersAtDepot === this.engineersDataLoaded) {
				label = `Loaded data for ${this.engineersDataLoaded} engineers.`;
			}
			return label;
		},

		/**
		 * Returns all errors returned from the server when
		 * requesting job activities.
		 * @returns {object[]} Array of error objects.
		 */
		errors() {
			const errors = [];
			_.forEach(this.engineersAtDepot, (engineer) => {
				if (engineer.activities.error) {
					errors.push({
						id:		 engineer.id,
						message: engineer.activities.error
					});
				}
			});
			return errors;
		},

		/**
		 * Limits the returned errors to three.
		 */
		displayErrors() {
			const MAX_ERRORS = 3;
			const errorCount = MAX_ERRORS < this.errors.length ? MAX_ERRORS : this.errors.length;
			const errors 	 = this.errors.splice(0, errorCount);
			return errors;
		},

		/**
		 * Indicates whether there are any job activities to
		 * approve.
		 * @returns {boolean} True if one or more engineers have
		 * activities, otherwise false.
		 */
		hasTimeToApprove() {
			const result = 0 < this.engineersForApproval.length;
			return result;
		},

		/**
		 * A debounced function wrapping the fetch job activities
		 * method.
		 * @returns {function} Debounced fetch function.
		 */
		debouncedFetchActivities() {
			const me 		= this;
			const debounced = _.debounce(me.fetchJobActivities, FETCH_DELAY);
			return debounced;
		}
	},

	methods: {
		...mapActions('engineer', {
			setSchedulerDepartment : 'setSchedulerDepartment'
		}),

		/**
		 * Builds a local array of engineer objects for use
		 * with the time approval view.
		 */
		buildEngineers() {
			const engineers = this.engineers.map(e => {
				const engineer = {
					id			: e.Id,
					name		: e.Name,
					depotId		: e.DepotId,
					department  : e.SchedulerDepartment,
					activities	: {
						list	: [],
						loading	: false,
						error	: null
					}
				}
				return engineer;
			});
			this.engineersLocal = engineers;
		},

		/**
		 * Perform an immediate fetch for job activities clearing
		 * any debounced fetches that have been queued up.
		 */
		fetchActivities() {
			// Clear any pending debounced fetches.
            const debounced = this.debouncedFetchActivities;
            debounced.cancel();
            this.fetchJobActivities();
		},

		/**
		 * Perform a debounced fetch for job activities to prevent
		 * multiple calls being made in a sort space of time.
		 */
		debounceFetchActivities() {
			this.debouncedFetchActivities();
		},

		/**
		 * Fetches all unapproved, and supporting approved,
		 * job activities for engineers at selected depot.
		 */
		async fetchJobActivities() {
			this.loading			 = true;
			this.engineersDataLoaded = 0;

			// Clear activities.
			this.engineersAtDepot.forEach(engineer => {
				engineer.activities.list.splice(0);
			});

			try {
				for (let i = 0; i < this.engineersAtDepot.length; i++) {
					const engineer = this.engineersAtDepot[i];
					await this.fetchJobActivitiesForEngineer(engineer);
				}
			}
			finally {
				this.loading = false;
			}
		},

		/**
		 * Fetches all unapproved, and supporting approved,
		 * job activities for an engineer.
		 */
		async fetchJobActivitiesForEngineer(engineer) {
			const me 					= this;
			engineer.activities.error 	= null;
			engineer.activities.loading	= true;

			try {
				const response 		= await JobActivityApi.getForEngineerForApproval(engineer.id);
				const activities	= _.orderBy(response.data.data, 'Start', 'asc');

				engineer.activities.list = activities;
			}
			catch (error) {
				engineer.activities.error = `${engineer.name} (${engineer.id}): Failed to get time entries. ${error.message}.`;
			}
			finally {
				this.engineersDataLoaded++;
				engineer.activities.loading = false;
			}
		},

		/**
		 * Fetches all labour categories for all 
		 * labour rates.
		 */
        async fetchLabourCategories() {
            const me                    = this;
            me.labourCategories.error   = null;
            me.labourCategories.loading = true;

            try {
                const response = await EngineerApi.getLabourCategories();
                me.labourCategories.value = response.data.data;
            }
            catch (failure) {
                const error = `Failed to get labour categories: ${failure.message}`;
                console.error(error);
                me.labourCategories.value = [];
                me.labourCategories.error = error;
            }
            finally {
                me.labourCategories.loading = false;
            }
		},
		
		/**
		 * Fetch all the defined scheduler departments from the 
		 * API.
		 */
		async fetchSchedulerDepartments() {
			this.schedulerDepartments.loading = true;
			this.schedulerDepartments.error   = null;
			this.schedulerDepartments.value   = [];

			try {
				const response = await SchedulerApi.getDepartments();
				this.schedulerDepartments.value = response.data.data;
			}
			catch (error) {
				this.schedulerDepartments.error = error.response.data.message;
			}
			finally {
				this.schedulerDepartments.loading = false;
			}
		},

		/**
		 * Returns true if the engineer is assigned to the currently
		 * selected depot or if all depots is selected, otherwise false.
		 */
		engineerAtDepot(engineer) {
			const ALL_DEPOTS = '*';
			const atDepot    = (ALL_DEPOTS 		 === this.depotId) ||
							   (engineer.depotId === this.depotId)
			return atDepot;
		},

		/**
		 * Clears the error for a specific engineer.
		 */
		clearError(errorId) {
			const engineer = _.find(this.engineersAtDepot, e => {
				return errorId === e.id
			});
			if (engineer) {
				engineer.activities.error = null;
			}
		}
	},

	watch: {
		/**
		 * Get labour categories when core engineer list
		 * changes.
		 */
		engineers: {
			immediate: true,
			handler() {
				this.buildEngineers();
				this.fetchLabourCategories();
			}
		},

		"schedulerDepartments.selected"(newValue) {
			this.setSchedulerDepartment(newValue);
		},

		schedulerDepartment: {
			immediate: true,
			handler() {
				this.schedulerDepartments.selected = this.schedulerDepartment;
			}
		},

		depotId() {
			this.buildEngineers();
		},

		/**
		 * Load activities for all engineers at currently selected
		 * depot.
		 */
		engineersAtDepot() {
			this.debounceFetchActivities();
		}
	}
}
</script>

<style lang="less" scoped>
@import '../../../assets/colours';

h3 {
	margin-bottom: 20px;
}

.btn-toolbar {
	margin-bottom: 15px;
}

.no-entries {
	color: #999;
	text-align: center;
}
.no-entries > svg {
	color: rgba(173, 230, 173, 0.6);
	margin-top: 20px;
}

.progress {
	position: relative;
	height: 20px;
	.progress-bar-label {
		position: absolute;
		font-size: 14px;
		line-height: 20px;
		width: 100%;
	}
}

// Fade Animations
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.progress-fade-enter-active, .progress-fade-leave-active {
  transition: opacity 0.5s;
}

.progress-fade-enter, .progress-fade-leave-to {
  opacity: 0;
}
</style>
