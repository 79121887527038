<template>
    <div class="card mb-1">
        <div class="card-body">
            <div class="parts-accordian card">
                 <div class="card-header" v-b-toggle="id">
                    <h5>
                        <i class="when-opened fas fa-caret-down fa-1x"></i>
	            	    <i class="when-closed fas fa-caret-right fa-1x"></i>
                        {{itemsCount}}     
                    </h5>
	            </div>   
                <b-collapse :id="id" role="tabpanel">
                    <div class="card-body">
                        <div class="table-responsive-sm table-borderless">
                            <table class="table table-sm">
                            <thead>
                                <tr>
                                    <th>Part Number</th>
                                    <th>Decription</th>
                                    <th class="text-center">Quantity</th>
                                    <th class="text-center">Unit Price</th>
                                    <th class="text-center">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,orderIndex) in items" :key=orderIndex>
                                    <td>{{item.partNumberForDisplay}}</td>
                                    <td>{{item.serverInfo.sellerDescription}}</td>
                                    <td class="text-right pr-5">{{item.serverInfo.quantity}}</td>
                                    <td class="text-right pr-5">{{item.serverInfo.currency | getCurrencySymbolForCode()}} {{item.serverInfo.price | getCurrencyValue()}}</td>
                                    <td class="text-right pr-5">{{item.serverInfo.currency | getCurrencySymbolForCode()}} {{(item.serverInfo.quantity * item.serverInfo.price) | getCurrencyValue() }}</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </b-collapse>
            </div>
            <slot name="content"/>
            <div class="text-right">
                <slot name="buttons"/>
            </div>
        </div>
    </div>
</template>


<script>
import CurrencyMixin from '@/mixins/CurrencyMixin';
export default {
    name: 'OrderCardV2',
    
    components: {
    },
    computed:{
        itemsCount(){
            return this.items.length+"  Items";
        },
        id(){
            return this.basketId;;
        }
    },
    mixins: [
        CurrencyMixin
    ],
    created() {
    },

    data() {
        return {
            stage: 0,
            show: false
        }
    },

    props: {
        items:
        {
            required: true,
            type: Array
        },
        basketId:{
            require:true,
            type: String
        }
    },

    methods: {

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Filters Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

    },
}
</script>
<style lang="less" scoped>
    .box2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
    }
    .box>* {
        flex: 1 1 160px;
    }

    .box {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
    }
    .boxwide {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
    .description-text {
        font-size: 12px;
    }
    .parts-accordian {     
        margin-bottom   : 20px;
        padding         : 0; 
        .card-header {
            h5{
                margin-bottom: 0px;
            }
            background: none;
            padding: 6px 12px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            cursor: pointer;
        }
        .card-header.collapsed {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    .parts-accordian .card-body {
        max-height: 400px;
        overflow-y: auto;   
    }

    .collapsed > h5 > .when-opened,
    :not(.collapsed) > h5 > .when-closed {
        display: none;
    }
    .table-borderless tbody+tbody, 
    .table-borderless td, 
    .table-borderless th, 
    .table-borderless thead th {
        border: 0;
        border-bottom: solid 2px #dddddd;
    }
</style>