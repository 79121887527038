import InvoiceTypeApi      from '@WS/api/invoice-type';
import { set, reset, commit }   from '../mutators';
import * as mutation    from '@/store/mutation-types';

const initialState = () => ({	
    invoiceTypes: {
        value   : [],
        loading : false,
        error   : null,
    },
});

const getters = {
    /**
     * Get all invoiceTypes.
     */
    invoiceTypes: state => state.invoiceTypes.value,
    /**
     * Get the name of an invoicetype
     * @param {string} id of the invoice type
     */
    invoiceType: state => type => {
        return _.find(state.invoiceTypes.value, inv => inv.Type === type);
    }
};

const mutations = {
    // Set state.
    [mutation.SET_INVOICETYPES         ] : set('invoiceTypes.value'   , 'invoiceTypes'),
    [mutation.SET_INVOICETYPES_LOADING ] : set('invoiceTypes.loading' , 'loading'  ),
    [mutation.SET_INVOICETYPES_ERROR   ] : set('invoiceTypes.error'   , 'error'    ),
    // Clear state.
    [mutation.CLEAR_INVOICETYPES       ] : (state) => state.invoiceTypes.value = [],
    [mutation.CLEAR_INVOICETYPES_ERROR ] : (state) => state.invoiceTypes.error = null,

    // Reset state.
    [mutation.RESET                 ] : (state) => reset(state, initialState()),
};

const actions = {
    /**
     * Fetch all invoiceTypes for the current company from
     * the API and store in vuex.
     */
    async fetchInvoiceTypes({ commit }) {
        commit(mutation.CLEAR_INVOICETYPES);
        commit(mutation.SET_INVOICETYPES_LOADING, { loading: true });
        
        try {
            const response      = await InvoiceTypeApi.getInvoiceTypeNames();
            const invoiceTypes  = response.data.data;
            
            commit(mutation.SET_INVOICETYPES, { invoiceTypes });
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.SET_INVOICETYPES_ERROR, { error: error });
        }

        commit(mutation.SET_INVOICETYPES_LOADING, { loading: false });    
    },
};

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
}
