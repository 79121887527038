<template>
<div class="help-panel card shadow-sm"> 
    <div class="card-header border border-primary border-1 bg-primary text-white" v-b-toggle="id">
        <i class="when-opened fas fa-caret-down fa-1x"></i>
		<i class="when-closed fas fa-caret-right fa-1x"></i>        
        {{title}}
	</div>   
    <b-collapse :id="id">
        <div class="card-body">
            <slot></slot>
        </div>
    </b-collapse>
</div> 
</template>

<script>
export default {
    name: "HelpPanel",
    props: {
        title: {
            type: String,
            default: "Help"
        }
    },
   
    computed: {
        id() {
            return "help-panel-" + Date.now();
        }
    }
}
</script>

<style lang="less" scoped>
.help-panel {     
    margin-bottom   : 20px;
    margin-top      : 10px;
    padding         : 0; 
    .card-header {
        font-size: 16px;
        height: 38px;
        padding: 6px 12px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        cursor: pointer;
    }
    .card-header.collapsed {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.help-panel .card-body {
    max-height: 400px;
    overflow-y: auto;   
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>