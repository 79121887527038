export const SET_FILTERS_LOADING          = 'SET_FILTERS_LOADING';

export const CLEAR_PRODUCT_FILTERS        = 'CLEAR_PRODUCT_FILTERS';
export const CLEAR_PRODUCT_FILTERS_ERROR  = 'CLEAR_PRODUCT_FILTERS_ERROR';
export const DELETE_PRODUCT_FILTER        = 'DELETE_PRODUCT_FILTER';
export const SET_PRODUCT_FILTER           = 'SET_PRODUCT_FILTER';
export const SET_PRODUCT_FILTERS          = 'SET_PRODUCT_FILTERS';
export const PRODUCT_FILTERS_ERROR        = 'PRODUCT_FILTERS_ERROR';
export const PRODUCT_FILTERS_LOADING      = 'PRODUCT_FILTERS_LOADING';
export const UPDATE_PRODUCT_FILTER        = 'UPDATE_PRODUCT_FILTER';

export const CLEAR_TIME_FILTERS           = 'CLEAR_TIME_FILTERS';
export const CLEAR_TIME_FILTERS_ERROR     = 'CLEAR_TIME_FILTERS_ERROR';
export const DELETE_TIME_FILTER           = 'DELETE_TIME_FILTER';
export const SET_TIME_FILTER              = 'SET_TIME_FILTER';
export const SET_TIME_FILTERS             = 'SET_TIME_FILTERS';
export const TIME_FILTERS_ERROR           = 'TIME_FILTERS_ERROR';
export const TIME_FILTERS_LOADING         = 'TIME_FILTERS_LOADING';
export const UPDATE_TIME_FILTER           = 'UPDATE_TIME_FILTER';

export const CLEAR_PRICE_MODIFIERS           = 'CLEAR_PRICE_MODIFIERS';
export const CLEAR_PRICE_MODIFIERS_ERROR     = 'CLEAR_PRICE_MODIFIERS_ERROR';
export const DELETE_PRICE_MODIFIER           = 'DELETE_PRICE_MODIFIER';
export const SET_PRICE_MODIFIER              = 'SET_PRICE_MODIFIER';
export const SET_PRICE_MODIFIERS             = 'SET_PRICE_MODIFIERS';
export const PRICE_MODIFIERS_ERROR           = 'PRICE_MODIFIERS_ERROR';
export const PRICE_MODIFIERS_LOADING         = 'PRICE_MODIFIERS_LOADING';
export const UPDATE_PRICE_MODIFIER           = 'UPDATE_PRICE_MODIFIER';

export const CLEAR_LISTINGRULES           = 'CLEAR_LISTINGRULES';
export const CLEAR_LISTINGRULES_ERROR     = 'CLEAR_LISTINGRULES_ERROR';
export const DELETE_LISTINGRULE           = 'DELETE_LISTINGRULE';
export const SET_LISTINGRULE              = 'SET_LISTINGRULE';
export const SET_LISTINGRULES             = 'SET_LISTINGRULES';
export const LISTINGRULES_ERROR           = 'LISTINGRULES_ERROR';
export const LISTINGRULES_LOADING         = 'LISTINGRULES_LOADING';
export const UPDATE_LISTINGRULE           = 'UPDATE_LISTINGRULE';
export const REMOVE_FILTER_FROM_LISTINGRULE  = 'REMOVE_FILTER_FROM_LISTINGRULE';
export const ADD_FILTER_TO_LISTINGRULE       = 'ADD_FILTER_TO_LISTINGRULE';
export const RENAME_LISTINGRULE            = 'RENAME_LISTINGRULE';
export const CHANGE_LISTINGRULE_PRIORITY   = 'CHANGE_LISTINGRULE_PRIORITY';
export const TOGGLE_LISTINGRULE_SHOW_IN_RESULTS   = 'TOGGLE_LISTINGRULE_SHOW_IN_RESULTS';
export const TOGGLE_LISTINGRULE_IS_PUBLISHED   = 'TOGGLE_LISTINGRULE_IS_PUBLISHED';
export const LISTINGRULES_UI_DISABLED     = 'LISTINGRULES_UI_DISABLED';

export const CLEAR_PRELISTINGS         = 'CLEAR_PRELISTINGS';
export const CLEAR_PRELISTINGS_ERROR   = 'CLEAR_PRELISTINGS_ERROR';
export const DELETE_PRELISTING         = 'DELETE_PRELISTING';
export const SET_PRELISTING            = 'SET_PRELISTING';
export const SET_PRELISTINGS           = 'SET_PRELISTINGS';
export const PRELISTINGS_ERROR         = 'PRELISTINGS_ERROR';
export const PRELISTINGS_LOADING       = 'PRELISTINGS_LOADING';
export const UPDATE_PRELISTING         = 'UPDATE_PRELISTING';

export const ADD_EXCLUSIONS           = 'ADD_EXCLUSIONS';
export const DELETE_EXCLUSIONS        = 'DELETE_EXCLUSIONS';
export const SET_EXCLUSIONS           = 'SET_EXCLUSIONS';
export const CLEAR_EXCLUSIONS         = 'CLEAR_EXCLUSIONS';
export const CLEAR_EXCLUSIONS_ERROR   = 'CLEAR_EXCLUSIONS_ERROR';
export const EXCLUSIONS_ERROR         = 'EXCLUSIONS_ERROR';
export const EXCLUSIONS_LOADING       = 'EXCLUSIONS_LOADING';

export const SET_LISTING_DIFF           = 'SET_LISTING_DIFF';
export const CLEAR_LISTING_DIFF         = 'CLEAR_LISTING_DIFF';
export const CLEAR_LISTING_DIFF_ERROR   = 'CLEAR_LISTING_DIFF_ERROR';
export const LISTING_DIFF_ERROR         = 'LISTING_DIFF_ERROR';
export const LISTING_DIFF_LOADING       = 'LISTING_DIFF_LOADING';
export const RESET_DIFF_STATE           = 'RESET_DIFF_STATE';

export const SET_CURRENT_LISTINGS           = 'SET_CURRENT_LISTINGS';
export const CLEAR_CURRENT_LISTINGS         = 'CLEAR_CURRENT_LISTINGS';
export const CLEAR_CURRENT_LISTINGS_ERROR   = 'CLEAR_CURRENT_LISTINGS_ERROR';
export const CURRENT_LISTINGS_ERROR         = 'CURRENT_LISTINGS_ERROR';
export const CURRENT_LISTINGS_LOADING       = 'CURRENT_LISTINGS_LOADING';
export const RESET_CURRENT_LISTINGS_STATE   = 'RESET_CURRENT_LISTINGS_STATE';