<template>
    <div>
        <div class="pt-2">
            <!-- Start of Header -->
            <div class="row">
                <div class="col-12">
                    <h5>
                        Suggested Stock Orders{{ depotName }}
                    </h5>
                </div>
            </div>

            <div class="row">
                <div class="col-12 button-separator">
                    <button
                        class="btn btn-secondary float-right"
                        v-show="partDetails"
                        @click="partDetails = false"
                    >
                        Back to reports
                    </button>
                     <button
                        class="btn btn-primary float-right"
                        v-show="partDetails"
                        @click="showModal = true"
                        :disabled="loading"
                    >
                        <i class="fa fa-cart-plus" /> Add All
                    </button>
                </div>
                <div class="col-12">
                    <!-- Start of search sso reports table -->
                    <ib-table
                        v-show="!partDetails"
                        :items="ssoReportItems"
                        :columns="reportColumns"
                        :small="true"
                        :loading="loading"
                        loadingMessage="Loading Search..."
                        noItemsMessage="No Records Found"
                        :itemsPerPage="15"
                    >
                        <template slot="createDate" slot-scope="result">
                            {{
                                result.item.createDate
                                    | formatDate(DateFormat.DATE_TIME)
                            }}
                        </template>
                        <template slot="action" slot-scope="result">
                            <button class="btn btn-primary btn-sm" @click="loadSSOPartDetails(result.item)">
                                Review
                            </button>
                        </template>
                    </ib-table>
                    <!-- End of search sso reports table -->
                    <!-- Start of parts detail table -->
                    <ib-table
                        v-show="partDetails"
                        :items="formattedParts"
                        :columns="partsColumns"
                        :small="true"
                        :loading="loading"
                        loadingMessage="Loading Search..."
                        noItemsMessage="No Records Found"
                        :itemsPerPage="10"
                    >
                        <template slot="number" slot-scope="result">
                            <input
                                style="width:100px; padding-top:0px; padding-bottom:0px;"
                                v-model="result.item.number"
                                type="number"
                                min="0"
                                :max="result.item.quantity"
                                step="1"
                                value="0"
                            />
                            &nbsp;
                            <button
                                title="Add To Basket"
                                @click="addToBasket(result.item)"
                                class="btn p-0 m-0 tn-primary"
                            >
                                <i class="fa fa-plus-circle" />
                            </button>
                        </template>
                        <template slot="displayPartNumber" slot-scope="data">
                            <div
                                v-if="
                                    data.item.partError != '' &&
                                        data.item.partError != null
                                "
                                :title="data.item.partError"
                            >
                                <small>
                                    <i
                                        style="color:orange;"
                                        class="fas fa-fw fa-lg fa-exclamation-triangle"
                                    >
                                    </i>
                                </small>
                                {{ data.item.displayPartNumber }}
                            </div>
                            <div v-else>
                                {{ data.item.displayPartNumber }}
                            </div>
                        </template>
                        <template slot="priceRange" slot-scope="data">
                            <div v-if="data.item.priceLow != 0" class="text-right ">
                                £{{ data.item.priceLow | getCurrencyValue() }} - £{{
                                    data.item.priceHigh | getCurrencyValue()
                                }}
                            </div>
                            <div v-if="data.item.priceLowEur != 0" class="text-right ">
                                €{{ data.item.priceLowEur | getCurrencyValue() }} - €{{
                                    data.item.priceHighEur | getCurrencyValue()
                                }}
                            </div>
                        </template>
                    </ib-table>
              <!-- End of parts detail table -->
                </div>
            </div>
           
        </div>

        <!-- Add all to basket warning model -->
        <ib-modal v-model="showModal" :dismissable="false">
            <div class="container-fluid">
                <div class="row p-2">
                    <h3>Suggested Stock Order Warnings</h3>
                </div>
                <hr>
                <div class="row">
                    <div class="col-4"></div>
                    <div class="col-4 font-weight-bold">
                        Lines
                    </div>
                    <div class="col-4 font-weight-bold">
                        Total Quantity
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Parts:
                    </div>
                    <div class="col-4">
                        {{ quantities.parts }}
                    </div>
                    <div class="col-4">
                        {{ quantities.partsQuantity }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Prefix not mapped:
                    </div>
                    <div class="col-4">
                        <span
                            :class="
                                quantities.prefixNotMapped > 0
                                    ? 'badge badge-danger'
                                    : ''
                            "
                        >
                            {{ quantities.prefixNotMapped }}
                        </span>
                    </div>
                    <div class="col-4">
                        <span
                            :class="
                                quantities.prefixNotMappedQty > 0
                                    ? 'badge badge-danger'
                                    : ''
                            "
                        >
                            {{ quantities.prefixNotMappedQty }}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Not found on Marketplace:
                    </div>
                    <div class="col-4">
                        <span
                            :class="
                                quantities.notFoundInMarketplace > 0
                                    ? 'badge badge-danger'
                                    : ''
                            "
                        >
                            {{ quantities.notFoundInMarketplace }}
                        </span>
                    </div>
                    <div class="col-4">
                        <span
                            :class="
                                quantities.notFoundInMarketplaceQty > 0
                                    ? 'badge badge-danger'
                                    : ''
                            "
                        >
                            {{ quantities.notFoundInMarketplaceQty }}
                        </span>
                    </div>
                </div>
                <br />
                <p>
                    <b>Note:</b> Parts without prefix mappings or Gold part records will not be added to the basket.  
                    <br />Quantities will be capped at the quantity available on marketplace with your chosen order configuration.
                     <br />Do you wish to continue?
                </p>
                <br />
                <div class="text-right button-separator">
                    <button class="btn btn-danger" @click="showModal = false">
                        Cancel
                    </button>
                    <button
                        class="btn btn-success"
                        @click="addAllToBasket()"
                        :disabled="cantContinue || processingItems"
                    >
                       <transition name="fade" mode="out-in">
						<span v-if="true === processingItems" key="spinner">
							<i class="fas fa-sync-alt fa-spin"></i>
						</span>
						<span v-else key="fixed">
							<i class="fas fa-sync-alt"></i>
						</span>
					</transition>
					<span class="ml-2">Continue</span>
                    </button>
                    
                    &nbsp; &nbsp;
                </div>
            </div>
        </ib-modal>
        <!-- Add all to basket warning model -->
    </div>
</template>

<script>
import IbTable from "@/components/table/IbTable";
import ClientApi from "@MP/api/client";
import DateMixin from "@/mixins/DateMixin";
import CurrencyMixin from "@/mixins/CurrencyMixin";
import IbModal from "@/components/utilities/IbModal";
import IbLoadingSpinner from "@/components/IbLoadingSpinner";
const ReportColumns = [
    {
        heading: "User",
        property: "user",
        sortable: true
    },
    {
        heading: "Depot",
        property: "depot",
        sortable: true
    },
    { heading: "Order Type", property: "orderType", sortable: true },
    {
        heading: "Sequence",
        property: "sequence",
        sortable: true
    },
    {
        heading: "Created Date",
        property: "createdDate",
        sortable: false
    },
    {
        heading: "Parts Count",
        property: "partsCount",
        sortable: false,
        cellClass:"text-right pr-5"
    },
    {
        heading: "Action",
        property: "action",
        sortable: false
    }
];

const PartsColumns = [
    {
        heading: "Basket Quantity",
        property: "number",
        sortable: false
    },
    {
        heading: "Suggested Quantity",
        property: "suggestedOrder",
        sortable: false,
        cellClass:"text-right pr-5"
    },
    { heading: "Part No.", property: "displayPartNumber", sortable: false },
    {
        heading: "Description",
        property: "description",
        sortable: false
    },
    {
        heading: "Qty on Marketplace",
        property: "quantity",
        sortable: false,
        cellClass:"text-right pr-5"
    },
    {
        heading: "Price Range*",
        property: "priceRange",
        sortable: false
    }
];
export default {
    name: "SuggestedStockOrder",
    components: {
        IbTable,
        IbModal,
        IbLoadingSpinner
    },
    mixins: [DateMixin, CurrencyMixin],
    props: {
        from: {
            type: String,
            required: false
        },
        to: {
            type: String,
            required: false
        },
        loadReports: {
            type: Boolean,
            required: true
        },
        selectedDepot: {
            type: String,
            require: true
        },
        selectedDepotName: {
            type: String,
            require: true
        },
        basket: {
            type: Array,
            require: true
        }
    },
    watch: {
        loadReports: {
            immediate: true,
            handler: function(value) {
                if (value) {
                    this.partDetails = false;
                    this.loadSSOReports();
                }
            }
        }
    },
    data() {
        return {
            reportColumns: ReportColumns,
            partsColumns: PartsColumns,
            partDetails: false,
            ssoReportItems: [],
            loading: false,
            processingItems:false,
            ssoPartsItems: [],
            showModal: false
        };
    },
    computed: {
        formattedParts() {
            return this.ssoPartsItems.map(e => {
                e.displayPartNumber = e.localPrefix + "-" + e.partNumber;
                e.number = 0;
                this.basket.forEach(b => {
                    if (
                        e.partNumber == b.partNumber &&
                        e.manufacturer == b.manufacturer
                    ) {
                        e.number += b.number;
                    }
                });
                return e;
            });
        },
        quantities() {
            let counts = {
                parts: 0,
                prefixNotMapped: 0,
                notFoundInMarketplace: 0,
                partsQuantity: 0,
                prefixNotMappedQty: 0,
                notFoundInMarketplaceQty: 0
            };
            this.ssoPartsItems.map(e => {
                counts.parts++;
                counts.partsQuantity += e.suggestedOrder;
                if (e.errorType === 1) {
                    counts.prefixNotMapped++;
                    counts.prefixNotMappedQty += e.suggestedOrder;
                } else if (e.errorType === 2) {
                    counts.notFoundInMarketplace++;
                    counts.notFoundInMarketplaceQty += e.suggestedOrder;
                }
            });
            return counts;
        },
        depotName() {
            return " ("+this.selectedDepot+" - "+this.selectedDepotName+")";
        },
        cantContinue(){
           return this.quantities.parts == this.quantities.notFoundInMarketplace + this.quantities.prefixNotMapped;
        }
    },
    methods: {
        reduceMarketplaceQuantity(item){
            this.ssoPartsItems = this.ssoPartsItems.map(e => {
                if (e.displayPartNumber == item.partNumber)
                {
                    e.quantity -= item.totalQuantity;
                }
                return e;
            });
        },
        loadSSOPartDetails(item) {
            this.loading = true;
            this.partDetails = true;
            this.loadParts(item, response => {
                if (response) {
                    this.ssoPartsItems = response;
                }

                this.loading = false;
            });
        },
        loadParts(item, callback) {
            ClientApi.getSSORepotParts(item.id, item.depot)
                .then(response => {
                    var result = response.data;
                    callback(result);
                })
                .catch(error => {
                    console.log(error);
                    callback(null);
                });
        },
        loadSSOReports() {
            this.loading = true;
            ClientApi.getSSOReports(this.from, this.to, this.selectedDepot)
                .then(response => {
                    var result = response.data;
                    this.ssoReportItems = result;
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("report-load-complete")
                });
        },
        addToBasket(item) {
            this.$emit("add-to-basket",item);
        },
        async addAllToBasket() {
            this.processingItems = true;
            await Promise.all(this.formattedParts.map(e => {
                if (e.partError == null || e.partError == "") {
                    e.number += e.suggestedOrder;
                    if (e.number > e.quantity) {
                        e.number = e.quantity;
                    }
                    this.addToBasket(e);
                }       
            })).then(()=>{
                this.showModal = false;
                this.processingItems = false;
            })
            
        }
    }
};
</script>
<style>
.table-sm td{
    vertical-align: middle !important;
}
</style>
