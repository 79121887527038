<template>
<div
    v-if="showActionbar"
    ref="actionbar"
    class="action-bar d-flex flex-row justify-content-between"
>
    <button
        v-for="(button, index) in buttons"
        :key="index"
        class="btn"
        :class="button.classes"
        @click="button.handler"
    >
        {{ button.label }}
    </button>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('core', {
            buttons: state => state.actionbar.buttons,
        }),

        showActionbar() {
            let show = false;
            if (this.buttons) {
                show = this.buttons.length;
            }
            return show;
        },
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/colours.less';
@import '~@/assets/css/imports.less';

.action-bar {
    background-color: @light-grey;
    border-top      : darken(@light-grey, 15%) 1px solid;
    min-height      : @actionbar-height;
    max-height      : @actionbar-height;
    padding         : 0.75rem 2rem;

    h3 {
        overflow        : hidden;
        white-space     : nowrap;
        text-overflow   : ellipsis;
    }
}
</style>
