<template>
    <div class="card mb-1">
        <div class="card-body">
            <h5 class="card-title">{{order.serverInfo.quantity}} x {{order.partNumberForDisplay}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">{{order.serverInfo.sellerDescription}}</h6>
                <slot name="content"/>
            <div class="text-right">
                <slot name="buttons"/>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'OrderCard',
    
    components: {
    },

    created() {
        
    },

    data() {
        return {
            stage: 0,
            show: false
        }
    },

    props: {
        order:
        {
            required: true,
            type: Object,
            default: function()
            {
                return { listing: {}, buyer: {}, seller: {} }
            }
        }
    },

    methods: {

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Filters Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

    },
}
</script>
<style scoped>
      .box2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
    }
    .box>* {
        flex: 1 1 160px;
    }

    .box {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
    }
    .boxwide {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
    .description-text {
        font-size: 12px;
    }

</style>