<template>
<div>
    <transition name="fade-in">
        <div
            v-if="isOpen"
            class="overlay"
            @click.self="close"
        ></div>
    </transition>
    <transition name="slide-in-left">
        <div
            v-if="isOpen"
            class="settings-menu d-flex flex-column p-3"
        >
            <div class="settings-header d-flex align-items-center mb-5">
                <font-awesome-icon
                    :icon="['fas', 'cog']"
                    size="2x"
                    fixed-width
                />
                <h1 class="heading flex-grow-1 pl-1">
                    Settings
                </h1>
                <div
                    class="close-icon"
                    @click="close"
                >
                    <font-awesome-icon
                        :icon="['fas', 'times']"
                        size="lg"
                        fixed-width
                    />
                </div>
            </div>

            <div class="settings-content">
                <form>
                    <div class="form-group">
                        <label for="companySelect">Company</label>
                        <company-list id="companySelect"></company-list>
                    </div>
                    <div class="form-group">
                        <label for="depotSelect">Depot</label>
                        <depot-list id="depotSelect"></depot-list>
                    </div>
                    <div class="form-group">
                        <label for="versionText">Version</label>
                        <input
                            id="versionText"
                            type="text"
                            readonly
                            class="form-control-plaintext"
                            :value="version">
                    </div>
                </form>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import { get as _get }  from 'lodash-es';
import {
    mapState,
    mapMutations,
    mapActions,
}                       from 'vuex';
import {
    CLOSE_SETTINGS_MENU
}                       from '@/store/mutation-types';
import CompanyList      from '@/components/system/CompanyList';
import DepotList        from '@WS/components/DepotList';

export default {
    components: {
        CompanyList,
        DepotList,
    },

	created() {
		this.fetchVersion();
	},

    computed: {
        ...mapState('core', {
            isOpen  : state => state.settingsMenu.open,
        }),

        ...mapState('system', {
            version : state => state.version.value,
        }),
    },

    methods: {
        ...mapMutations('core', {
            close   : CLOSE_SETTINGS_MENU,
        }),

        ...mapActions('system', [
            'fetchVersion'
        ]),
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/imports.less';

.overlay {
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index         : @settings-overlay-z-index;
}

.settings-menu {
    position        : absolute;
    top             : 0;
    bottom          : 0;
    left            : 0;
    width           : 500px;
    background-color: rgb(247, 247, 247);
    z-index         : @settings-panel-z-index;

    .settings-header {
        .heading {
            font-size       : 1.75rem;
            margin-bottom   : 0;
        }

        .close-icon {
            display         : flex;
            justify-content : center;
            align-items     : center;
            width           : 35px;
            height          : 35px;
            border-radius   : 50%;
            cursor          : pointer;

            &:hover {
                background-color: rgb(220, 220, 220);
            }
        }
    }

    .settings-content {
        padding-left: 44px;
    }
}


.slide-in-left-enter-active {
	-webkit-animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-in-left-leave-active {
	-webkit-animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
	        animation: slide-in-left 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both reverse;
}

.fade-in-enter-active {
	-webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.fade-in-leave-active {
	-webkit-animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
	        animation: fade-in 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both reverse;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-1-23 17:0:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
