import { get as _get }  from 'lodash-es';
import { set, reset, }  from '../mutators';
import * as mutation    from '../mutation-types';
import ApiService     	from '@/common/api-service';
import StorageService 	from '@/common/storage-service';
import AccountApi		from '@/common/accounts';

const initialState = () => ({
	token	    : null,
	user	    : null,
    userData    : null,
    rememberMe  : false,
	error	    : null,
});

const getters = {
    hasToken    : (state) => {
        const hasToken = (null != state.token);
        return hasToken;
    },

    userFullName: (state) => {
        let name = _get(state.userData, 'Name', '');
        if (!name) {
            const firstName = _get(state.user, 'FirstName', '');
            const lastName 	= _get(state.user, 'LastName' , '');
            name = `${firstName} ${lastName}`.trim();
        }
		return name;
    },

    userInitials: (state) => {
        const initials = _get(state.userData, 'Initials', '');
        return initials;
    },

    hasBetaAccess: (state) => {
        const betaAccess = _get(state.user, 'BetaAccess', false);
        return betaAccess;
    },
	
	getUserRoles: (state)=>{
		const roles = _get(state.user, 'Roles', []);
		return roles;
	}
};

const mutations = {
    // Set state.
	[mutation.SET_TOKEN         ] : (state, payload) => {
		state.token	= payload.token;
  
		StorageService.set(
            process.env.VUE_APP_TOKEN_KEY,
            payload.token,
            state.rememberMe
        );

		ApiService.setAuthHeader();
    },
    [mutation.SET_USER          ] : (state, payload) => {
        state.user = payload.user;
        
		StorageService.set(
            process.env.VUE_APP_USER_KEY,
            JSON.stringify(payload.user),
            state.rememberMe
        );
    },
    [mutation.SET_REMEMBER_ME   ] : (state, payload) => {
		state.rememberMe = payload.rememberMe;
  
		StorageService.set(
            process.env.VUE_APP_REMEMBER_ME_KEY,
            payload.rememberMe,
            true
        );
    },
    [mutation.SET_USER_DATA     ] : set('userData'  , 'userData'),
    [mutation.SET_LOADING       ] : set('loading'   , 'loading' ),
    [mutation.SET_ERROR         ] : (state, { error }) => {
        // Initialise with status code error.
        let message = error.message;
        if (!error.response) {
            message = 'Network Error: Unable to reach server.'
        }
        else {
            // Check all expected properties for an error message.
            const message1  = _get(error, 'response.data.error', null);
            const message2  = _get(error, 'response.data.Message', null);
            const message3  = _get(error, 'response.data.message', null);

            message = message1 || message2 || message3;
        }
        state.error = message;
    },
    // Clear state.
    [mutation.CLEAR_TOKEN       ] : (state) => {
        StorageService.delete(process.env.VUE_APP_TOKEN_KEY);
		state.token = null;
    },
    [mutation.CLEAR_USER        ] : (state) => {
        StorageService.delete(process.env.VUE_APP_USER_KEY);
		state.user = null;
    },
    [mutation.CLEAR_REMEMBER_ME ] : (state) => {
        StorageService.delete(process.env.VUE_APP_REMEMBER_ME_KEY);
		state.rememberMe = false;
    },
    [mutation.CLEAR_ERROR       ] : (state) => state.error = null,
    // Reset state.
    [mutation.RESET             ] : (state) => {
        // Purge persisted data.
        const rememberMe = StorageService.get(process.env.VUE_APP_REMEMBER_ME_KEY);
        StorageService.delete(process.env.VUE_APP_TOKEN_KEY);
        if ("true" !== rememberMe) {
            StorageService.delete(process.env.VUE_APP_USER_KEY);
        }

        let newState        = initialState();
        newState.rememberMe = state.rememberMe;
        newState.user       = state.user;
        reset(state, newState);
    },
};

const actions = {
    async login({ dispatch, commit }, credentials) {
        commit(mutation.CLEAR_ERROR);

        const requestBody = {
			'username'     : credentials.username,
			'password'     : credentials.password,
			'applicationId': process.env.VUE_APP_ID,
			'ismobile'     : false
        };
        
        try {
            const response  = await AccountApi.login(requestBody);
            const user      = { ...response.data };
            const token     = user.Token;
            delete user.Token;

            commit(mutation.SET_REMEMBER_ME, { rememberMe: credentials.rememberMe });
            commit(mutation.SET_TOKEN,       { token });
            commit(mutation.SET_USER,        { user });

            dispatch('loadUserData');
		}
		catch (error) {
			commit(mutation.SET_ERROR, { error });
		}
    },

    logout({ dispatch, commit, state }) {
        const rememberMe = StorageService.get(process.env.VUE_APP_REMEMBER_ME_KEY);
        AccountApi.logout();
        
		if ("true" === rememberMe) {
            commit(mutation.RESET);
            // Clear company and depot selection from vuex.
            dispatch('system/clearCurrentCompany', { rememberMe }, { root: true });
            dispatch('system/clearCurrentDepot', { rememberMe }, { root: true });
        }
        else {
            // Clear all application state.
            dispatch('resetAppState', null, { root: true });
        }
	},

    async checkAuth({ dispatch, commit }) {
        const rememberMe    = StorageService.get(process.env.VUE_APP_REMEMBER_ME_KEY);
		const token         = StorageService.get(process.env.VUE_APP_TOKEN_KEY);
		const user          = StorageService.get(process.env.VUE_APP_USER_KEY);
		
		if ((null !== token) && (null !== user)) {
            ApiService.setAuthHeader();
            
            commit(mutation.SET_REMEMBER_ME, { rememberMe: JSON.parse(rememberMe) });
            commit(mutation.SET_TOKEN,       { token });
            commit(mutation.SET_USER,        { user: JSON.parse(user) });

            dispatch('loadUserData');
		}
		else {
			dispatch('logout');
		}
    },
    
    async loadUserData({ dispatch, state }) {
        const defaultCompanyId  = state.user.CompanyId;
        const rememberMe        = state.rememberMe;

        await dispatch('getUserInfo');
        await dispatch('system/getLicenceInfo', null, { root: true });
        await dispatch('system/fetchCompanies', null, { root: true });
        await dispatch(
            'system/initialiseCurrentCompany',
            { defaultCompanyId, rememberMe },
            { root: true }
        );
    },

    async getUserInfo({ commit }) {
		try {
            const response 	= await AccountApi.getUserInfo();
            const userData  = response.data.data;
            commit(mutation.SET_USER_DATA, { userData });
		}
		catch (error) {
			commit(mutation.SET_ERROR, { error });
		}
    },

    loadStoredState({ commit }) {
        const rememberMe = StorageService.get(process.env.VUE_APP_REMEMBER_ME_KEY);
        const user       = StorageService.get(process.env.VUE_APP_USER_KEY);

        commit(mutation.SET_REMEMBER_ME, { rememberMe: JSON.parse(rememberMe) });
        commit(mutation.SET_USER,        { user: JSON.parse(user) });
    },
};

// SET_ERROR(state, failure) {
//     // Initialise with status code error.
//     let error = failure.message;

//     if ((null === failure.response) || (undefined === failure.response)) {
//         error = 'Network Error: Unable to reach server.'
//     }
//     else if (true === failure.response.data.hasOwnProperty('error')) {
//         error = failure.response.data.error;
//     }
//     else if (true === failure.response.data.hasOwnProperty('Message')) {
//         error = failure.response.data.Message;
//     }

//     state.error = error;
// },

export default {
    namespaced  : true,
	state		: initialState(),
	getters,
	mutations,
	actions,
};
