import EngineerApi      from '@WS/api/engineer';
import { set, reset }   from '../mutators';
import * as mutation    from '@/store/mutation-types';

const initialState = () => ({	
    engineers: {
        value   : [],
        loading : false,
        error   : null,
    },

    /**
     * The selected scheduler department.
     */
    schedulerDepartment : null,
});

const getters = {
    /**
     * Get an engineer by ID.
     */
    getEngineerById: (state) => (id) => {
        const engineer = state.engineers.value.find(eng => id === eng.Id);
        return engineer;
    },

    /**
     * Get all engineers.
     */
    engineers: (state) => {
        return state.engineers.value;
    },

    /**
     * Get the selected scheduler department.
     */
    schedulerDepartment: (state) => {
        return state.schedulerDepartment;
    },
};

const mutations = {
    // Set state.
    [mutation.SET_ENGINEERS         ]     : set('engineers.value'     , 'engineers'),
    [mutation.SET_ENGINEERS_LOADING ]     : set('engineers.loading'   , 'loading'  ),
    [mutation.SET_ENGINEERS_ERROR   ]     : set('engineers.error'     , 'error'    ),
    [mutation.SET_SCHEDULER_DEPARTMENT]   : set('schedulerDepartment' , 'department'),
    // Clear state.
    [mutation.CLEAR_ENGINEERS       ]     : (state) => state.engineers.value = [],
    [mutation.CLEAR_ENGINEERS_ERROR ]     : (state) => state.engineers.error = null,
    [mutation.CLEAR_SCHEDULER_DEPARTMENT] : (state) => state.schedulerDepartment = null,
    // Reset state.
    [mutation.RESET                 ]     : (state) => reset(state, initialState()),
};

const actions = {
    /**
     * Fetch all engineers for the current company from
     * the API and store in vuex.
     */
    async fetchEngineers({ commit }) {
        commit(mutation.CLEAR_ENGINEERS);
        commit(mutation.SET_ENGINEERS_LOADING, { loading: true });
        
        try {
            const response  = await EngineerApi.getAll();
            const engineers = response.data.data;
            
            commit(mutation.SET_ENGINEERS, { engineers });
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.SET_ENGINEERS_ERROR, { error: error });
        }

        commit(mutation.SET_ENGINEERS_LOADING, { loading: false });
    },

    /**
     * Set the currently selected scheduler department.
     * @param {*} department - The scheduler department.
     */
    setSchedulerDepartment({ commit }, department) {
        commit(mutation.SET_SCHEDULER_DEPARTMENT, { department });
    },
};

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
}
