<template>
<div class="container-fluid">
    <h3>Engineers</h3>

    <depot-list />

    <ib-table :items="filteredEngineers"
              :columns="headings">

        <template
            slot="Name"
            slot-scope="row">
            <b-link :to="{ name: 'Engineer', params: { id: row.item.Id } }">
                {{ row.item.Name }}
            </b-link>
        </template>
    </ib-table>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import IbTable      from '@/components/table/IbTable';
import DepotList    from '@WS/components/DepotList';

const ALL_DEPOTS = '*';

//	Unit Id	Weekly Hours	Scheduler Department	Last Time Logged	Last Logged Hours	Is Active
const headings = [
    {
        heading: 'Id',
        property: 'Id',
        sortable: true
    },
    {
        heading: 'Name',
        property: 'Name',
        sortable: true
    },
    {
        heading: 'Depot',
        property: 'DepotId',
        sortable: true
    },
    {
        heading: 'Skill Level',
        property: 'SkillLevel',
        sortable: true
    }
];

export default {
    name: 'EngineerList',

    data() {
		return {
            headings:      headings,
            selectedDepot: null,
			loading:       false,
			error:         null
		}
    },

    components: {
        DepotList,
        IbTable
	},

    computed: {
        ...mapState('engineer', {
            engineers: 'engineers',
        }),

        ...mapGetters('system', {
            currentDepotId : 'currentDepotId'
        }),

        filteredEngineers() {
            const me                = this;
            let   filteredEngineers = [];

            if (this.engineers.value) {
                filteredEngineers = this.engineers.value.filter(function (engineer) {
                    let selected = true;
                    if (me.currentDepotId && ALL_DEPOTS !== me.currentDepotId){
                        selected = engineer.DepotId == me.currentDepotId;
                    }
                    return selected;
                });
            }

            return filteredEngineers;
        }
    }
}
</script>