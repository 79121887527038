<template>
<div>
    <datetime type="date"
        ref="picker"
        class="input-group"
        :input-class="inputClasses"
        :format="format"
        :min-datetime="minValue"
        :max-datetime="maxValue"
        :placeholder="placeholder"
        v-model="internalValue">
        <div slot="after"
            class="input-group-append">
            <button type="button"
                class="btn ib-append-button"
                :class="{ 'invalid': !valid }"
                @click="openPopup">
                <i class="far fa-calendar-alt"></i>
            </button>
        </div>
        <div slot="after"
            class="invalid-feedback">
            {{ validationError }}
        </div>
    </datetime>
</div>
</template>
    
<script>
import { Datetime } from 'vue-datetime';
import IbDateTimePicker from '@/components/form/IbDateTimePicker';

export default {
    name: 'IbDatePicker',

    components: {
        Datetime
    },

    extends: IbDateTimePicker,
    
    props: {
        /**
         * Format to display dates in input control.
         */
        format: {
            type:       String,
            default:    'dd/LL/yyyy'
        },
        /**
         * The message to display when the valid prop
         * is set to false.
         */
        validationError: {
            type:       String,
            default:    'Please select a date'
        }
    },

    methods: {
        openPopup() {
            this.$refs.picker.isOpen = true;
        }
    }
}
</script>
    
<style lang="less" scoped>
@import '../../assets/styles/ib-date-time-picker';
</style>