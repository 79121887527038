
import MPClientApiService from '@/common/mp-client-api-service';
import { param } from 'jquery';

const CLIENT_ROOT = "marketplace";

const ClientApi = {
    /**
    * Get the rules model
    */
    getManufacturers() {
        const url = `${CLIENT_ROOT}/manufacturer/`;
        return MPClientApiService.get(url);
    },

    getLastUploaded() {
        const url = `${CLIENT_ROOT}/listings/last-upload-record`;
        return MPClientApiService.get(url);
    },

    uploadListings() {
        const url = `${CLIENT_ROOT}/listings/`;
        return MPClientApiService.post(url);
    },

    getListingsCsv() {
        const url = `${CLIENT_ROOT}/listings/csv`;
        return MPClientApiService.post(url);
    },

    getProfile() {
        const url = `${CLIENT_ROOT}/profile/`;
        return MPClientApiService.get(url);
    },

    getIsAuthed() {
        const url = `${CLIENT_ROOT}/ping/isAuthed`;
        return MPClientApiService.get(url);
    },

    getDepots() {
        const url = `${CLIENT_ROOT}/profile/depots`;
        return MPClientApiService.get(url);
    },

    postDepot(depot) {
        const url = `${CLIENT_ROOT}/profile/depot`;
        return MPClientApiService.post(url, depot);
    },

    putDepot(depot) {
        const url = `${CLIENT_ROOT}/profile/depot/${depot.id}`;
        return MPClientApiService.put(url, depot);
    },

    uploadProfile(profile) {
        const url = `${CLIENT_ROOT}/profile`;
        return MPClientApiService.put(url, profile);
    },

    getPrefixMappings() {
        const url = `${CLIENT_ROOT}/manufacturer`;
        return MPClientApiService.get(url);
    },

    postPrefixMappings(mappings) {
        const url = `${CLIENT_ROOT}/manufacturer`;
        return MPClientApiService.post(url, mappings);
    },

    getOrders(parameters) {
        const url = `${CLIENT_ROOT}/transaction/details?fromDate=${parameters.fromDate}&toDate=${parameters.toDate}&type=${parameters.type}`;
        return MPClientApiService.get(url);
    },

    buyerCancelOrder(orderId) {
        const url = `${CLIENT_ROOT}/order/${orderId}/buyer/cancel`;
        return MPClientApiService.post(url);
    },

    bulkCancelOrder(basketId) {
        const url = `${CLIENT_ROOT}/order/${basketId}/bulk/buyer/cancel`;
        return MPClientApiService.post(url);
    },

    sellerAcceptOrder(orderId) {
        const url = `${CLIENT_ROOT}/order/${orderId}/seller/accept`;
        return MPClientApiService.post(url);
    },

    sellerBulkAcceptOrder(basketId) {
        const url = `${CLIENT_ROOT}/order/${basketId}/bulk/seller/accept`;
        return MPClientApiService.post(url);
    },
    sellerBulkRejectOrder(basketId) {
        const url = `${CLIENT_ROOT}/order/${basketId}/bulk/seller/reject`;
        return MPClientApiService.post(url);
    },

    sellerRejectOrder(orderId) {
        const url = `${CLIENT_ROOT}/order/${orderId}/seller/reject`;
        return MPClientApiService.post(url);
    },

    checkQuantityEnough(partNumber, depot, quantity) {
        const url = `${CLIENT_ROOT}/order/quantity-enough/${partNumber}/${depot}/${quantity}`;
        return MPClientApiService.get(url);
    },

    checkBasketQuantityEnough(order, depotId) {
        order = order.map(o => {
            o.Quantity = o.serverInfo.quantity;
            o.BuyerPartNumber = o.partNumberForDisplay;
            o.DepotId = depotId;
            return o;
        })
        const url = `${CLIENT_ROOT}/order/quantity-enough-basket/${depotId}`;
        return MPClientApiService.put(url, order);
    },

    doAggregatedSearch(partNumber, manufacturerId, depotId) {
        partNumber = encodeURIComponent(partNumber);
        const url = `${CLIENT_ROOT}/listings/search-aggregated/${partNumber}/${depotId}/${manufacturerId}`;
        return MPClientApiService.get(url);
    },

    doAggregatedCheck(partsRequest) {
        const url = `${CLIENT_ROOT}/listings/check-aggregated`;
        return MPClientApiService.post(url, partsRequest);
    },

    doGetSuggestedOrderLowPricePartial(basket, depotId, currency) {
        const url = `${CLIENT_ROOT}/listings/get-suggested-order/low-price-partial/${currency}`;
        return MPClientApiService.put(url, basket.map(b => { return { BuyerDepotId: depotId, PartNumber: b.partNumber, ManufacturerId: b.manufacturer, Quantity: b.number } }));
    },

    doGetSuggestedOrderLowPriceNonPartial(basket, depotId, currency) {
        const url = `${CLIENT_ROOT}/listings/get-suggested-order/low-price-nonpartial/${currency}`;
        return MPClientApiService.put(url, basket.map(b => { return { BuyerDepotId: depotId, PartNumber: b.partNumber, ManufacturerId: b.manufacturer, Quantity: b.number } }));
    },

    doGetSuggestedOrderSingleSellerNonPartial(basket, depotId, currency) {
        const url = `${CLIENT_ROOT}/listings/get-suggested-order/single-seller-nonpartial/${currency}`;
        return MPClientApiService.put(url, basket.map(b => { return { BuyerDepotId: depotId, PartNumber: b.partNumber, ManufacturerId: b.manufacturer, Quantity: b.number } }));
    },

    doGetSuggestedOrderSingleSellerPartial(basket, depotId, currency) {
        const url = `${CLIENT_ROOT}/listings/get-suggested-order/single-seller-partial/${currency}`;
        return MPClientApiService.put(url, basket.map(b => { return { BuyerDepotId: depotId, PartNumber: b.partNumber, ManufacturerId: b.manufacturer, Quantity: b.number } }));
    },
    sellerGenerateAdvice(adviceNoteDetails) {
        let adviceNoteDetail = {
            Part           : adviceNoteDetails.part,
            Price          : adviceNoteDetails.price,
            Quantity       : adviceNoteDetails.quantity,
            Depot          : adviceNoteDetails.depot,
            CarriagePart   : adviceNoteDetails.carriagePart, 
            CarriageCharge : adviceNoteDetails.carriageCharge,
            Customer       : adviceNoteDetails.customer,
            Operator       : adviceNoteDetails.operator,
            DeliveryAddress1 : adviceNoteDetails.deliveryAddress1,
            DeliveryAddress2 : adviceNoteDetails.deliveryAddress2,
            DeliveryAddress3 : adviceNoteDetails.deliveryAddress3,
            DeliveryAddress4 : adviceNoteDetails.deliveryAddress4,
            DeliveryPostcode : adviceNoteDetails.deliveryPostcode,
            BillingAddress1 : adviceNoteDetails.billingAddress1,
            BillingAddress2 : adviceNoteDetails.billingAddress2,
            BillingAddress3 : adviceNoteDetails.billingAddress3,
            BillingAddress4 : adviceNoteDetails.billingAddress4,
            BillingPostcode : adviceNoteDetails.billingPostcode,
            ContactName     : adviceNoteDetails.contactName,
            PurchaseOrderNumber : adviceNoteDetails.purchaseOrderNumber,
            CompanyName     : adviceNoteDetails.companyName,
            DepotName       : adviceNoteDetails.depotName
        };
        const url = `${CLIENT_ROOT}/order/${adviceNoteDetails.orderId}/seller/create-advice`;
        return MPClientApiService.post(url, adviceNoteDetail);

    },
    sellerBulkGenerateAdvice(adviceNoteDetails) {
        const url = `${CLIENT_ROOT}/order/${adviceNoteDetails.Lines[0].BasketId}/bulk/seller/create-advice`;
        return MPClientApiService.post(url, adviceNoteDetails);
    },

    buyerGeneratePurchaseOrder(order, supplier) {
        const url = `${CLIENT_ROOT}/order/${order.serverInfo.id}/buyer/create-purchase-order/${supplier}`;
        return MPClientApiService.post(url);
    },

    buyerGeneratePurchaseOrderBulk(orders, supplier) {
        orders = orders.map(o => {
            o.Quantity = o.serverInfo.quantity;
            o.SalesPrice = o.serverInfo.price;
            o.PartNumber = o.partNumberForDisplay;
            o.DepotId = o.serverInfo.buyerDepotNumber;
            o.OrderId = o.serverInfo.id;
            return o;
        })
        const url = `${CLIENT_ROOT}/order/${orders[0].serverInfo.basketId}/bulk/buyer/create-purchase-order/${supplier}`;
        return MPClientApiService.post(url, orders);
    },

    bulkOrderParts(order, depotId) {
        order = order.map(o => {
            o.Quantity = o.totalQuantity;
            o.Price = o.listingPrice;
            o.BuyerPartNumber = o.partNumber;
            o.DepotId = depotId;
            return o;
        })
        const url = `${CLIENT_ROOT}/order/bulk-order`;
        return MPClientApiService.post(url, order);
    },

    getSSOReports(from, to, depot) {
        let url = `${CLIENT_ROOT}/sso/reports/${depot}`;
        if (from && to) {
            let fromDate = new Date(from);
            let toDate = new Date(to);
            url +=
                "?from=" +
                fromDate.toLocaleDateString() +
                "&to=" +
                toDate.toLocaleDateString();
        }
        return MPClientApiService.get(url);
    },

    getSSORepotParts(reportId, depot) {
        const url = `${CLIENT_ROOT}/sso/aggregated-listing/${reportId}/${depot}`;
        return MPClientApiService.get(url);
    }
}
export default ClientApi;
