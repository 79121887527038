import { get as _get }  from 'lodash-es';
import { set, reset }   from '../mutators';
import * as mutation    from '../mutation-types';
import StorageService   from '@/common/storage-service';
import SystemApi        from '@/common/system';
import AccountApi       from '@/common/accounts';
import CompanyApi       from '@/common/company';
import DepotApi         from '@WS/api/depot';

const ALL_DEPOTS_ID = '*';
const ALL_DEPOTS	= {
	Id  : ALL_DEPOTS_ID,
	Name: 'All Depots'
};

const initialState = () => ({
    licenceInfo     : {
        DocStoreOptions: []
    },
    licenceInfoError: null,
    company: {
        selected: null,
        list    : [],
        loading : false,
        error   : null,
    },
    depot: {
        selected: null,
        list    : [],
        loading : false,
        error   : null,
    },
    version: {
        value   : null,
        loading : false,
        error   : null,
    },
    isRoleRequired: false
});

const getters = {
    currentCompanyId: (state) => {
        const companyId = _get(state.company.selected, 'Id', null);
        return companyId;
    },

    currentDepotId  : (state) => {
        const depotId = _get(state.depot.selected, 'Id', null);
        return depotId;
    },
    getCompanyById  : (state) => (id) => {
        const company = state.company.list.find(c => id === c.Id) || null;
        return company;
    },

    getDepotById    : (state) => (id) => {
        const depot = state.depot.list.find(d => id === d.Id) || null;
        return depot;
    },
    isRoleRequired: (state)=> {
        return state.isRoleRequired;
    }
};

const mutations = {
    /**
     * Update local state with the licence information.
     * @param {*} state
     * @param {*} info
     * @param {*} rememberMe
     */
    [mutation.SET_LICENCE_INFO      ] : (state, info, rememberMe) => {
		state.licenceInfo = info;

		StorageService.set(process.env.VUE_APP_LICENCE_INFO_KEY	,
						   JSON.stringify(info),
						   rememberMe);
    },

    [mutation.SET_LICENCE_INFO_ERROR] : (state, failure) => state.licenceInfoError = failure,

    // Set state.
    [mutation.SET_SELECTED_COMPANY  ] : (state, { company, rememberMe }) => {
        state.company.selected = company;
        
        const companyId = _get(company, 'Id', null);

        StorageService.set(
            process.env.VUE_APP_COMPANY_ID_KEY,
            companyId,
            rememberMe
        );
    },
    [mutation.SET_COMPANIES             ] : set('company.list'      , 'companies'   ),
    [mutation.SET_COMPANIES_LOADING     ] : set('company.loading'   , 'loading'     ),
    [mutation.SET_COMPANIES_ERROR       ] : set('company.error'     , 'error'       ),
    [mutation.SET_SELECTED_DEPOT        ] : (state, { depot, rememberMe }) => {
        state.depot.selected = depot;
        
        const depotId = _get(depot, 'Id', null);

        StorageService.set(
            process.env.VUE_APP_DEPOT_ID_KEY,
            depotId,
            rememberMe
        );
    },
    [mutation.SET_DEPOTS                ] : set('depot.list'        , 'depots'      ),
    [mutation.SET_DEPOTS_LOADING        ] : set('depot.loading'     , 'loading'     ),
    [mutation.SET_DEPOTS_ERROR          ] : set('depot.error'       , 'error'       ),
    [mutation.SET_GOLD_VERSION          ] : set('version.value'     , 'version'     ),
    [mutation.SET_GOLD_VERSION_LOADING  ] : set('version.loading'   , 'loading'     ),
    [mutation.SET_GOLD_VERSION_ERROR    ] : set('version.error'     , 'error'       ),
    // Clear state.
    [mutation.CLEAR_SELECTED_COMPANY] : (state, { rememberMe }) => {
        if (false === rememberMe) {
            StorageService.delete(process.env.VUE_APP_COMPANY_ID_KEY);
        }
        
        state.company.selected = null
    },
    [mutation.CLEAR_COMPANIES           ] : (state) => state.company.list       = [],
    [mutation.CLEAR_COMPANIES_ERROR     ] : (state) => state.company.error      = null,
    [mutation.CLEAR_SELECTED_DEPOT      ] : (state, { rememberMe }) => {
        if (false === rememberMe) {
            StorageService.delete(process.env.VUE_APP_DEPOT_ID_KEY);
        }
        
        state.depot.selected = null;
    },
    [mutation.CLEAR_DEPOTS              ] : (state) => state.depot.list         = [],
    [mutation.CLEAR_DEPOTS_ERROR        ] : (state) => state.depot.error        = null,
    [mutation.CLEAR_GOLD_VERSION        ] : (state) => state.version.value      = null,
    [mutation.CLEAR_GOLD_VERSION_ERROR  ] : (state) => state.version.error      = null,
    [mutation.SET_IS_ROLE_REQUIRED]: (state, {isRoleRequired}) => {
        state.isRoleRequired = isRoleRequired
    },
    // Reset state.
    [mutation.RESET                     ] : (state) => {
        // Purge persisted data.
        StorageService.delete(process.env.VUE_APP_COMPANY_ID_KEY);
        StorageService.delete(process.env.VUE_APP_DEPOT_ID_KEY);

        reset(state, initialState());
    },
};

const actions = {
    /**
     * Call to the API to retrieve the licence info.
     */
    async getLicenceInfo({ commit }, rememberMe) {
        try {
            const response      = await SystemApi.getLicenceInfo();
            const licenceInfo   = response.data.data;
	        commit(mutation.SET_LICENCE_INFO, licenceInfo, rememberMe);
        }
        catch (failure) {
            commit(mutation.SET_LICENCE_INFO_ERROR, failure);
        }
    },

    /**
     * Check for licence info in the session storage, and re-apply
     * it if found.
     * @param {*} context
     * @param {*} rememberMe
     */
    restoreLicenceInfo({ commit }, rememberMe) {
        const licenceInfo = StorageService.get(process.env.VUE_APP_LICENCE_INFO_KEY);

        if (licenceInfo !== null) {
            commit(mutation.SET_LICENCE_INFO, JSON.parse(licenceInfo), rememberMe);
        }
    },

    /**
     * Fetch all companies from the API.
     */
    async fetchCompanies({ commit }) {
        commit(mutation.CLEAR_COMPANIES_ERROR);
        commit(mutation.SET_COMPANIES_LOADING, { loading: true });

        try {
            const response  = await CompanyApi.getSyncedCompanies();
            const companies = response.data.data;
            
            commit(mutation.SET_COMPANIES, { companies });
        }
        catch (error) {
            const message = _getErrorMessage(error);
            commit(mutation.CLEAR_COMPANIES);
            commit(mutation.SET_COMPANIES_ERROR, { error: message });
        }
        finally {
            commit(mutation.SET_COMPANIES_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all depots from the API for the currently selected
     * company and inserts a placeholder 'All Depots' depot.
     */
    async fetchDepots({ commit, state }) {
        commit(mutation.CLEAR_DEPOTS_ERROR);
        commit(mutation.SET_DEPOTS_LOADING, { loading: true });

        try {
            if (!state.company.selected) {
                throw new Error('Unable to get depots. No company selected.');
            }

            const response  = await DepotApi.getAllDepotsForCompany(state.company.selected.Id);
            const depots    = response.data.data;
            // Insert fake 'All Depots' depot.
            depots.splice(0, 0, ALL_DEPOTS);
            
            commit(mutation.SET_DEPOTS, { depots });
        }
        catch (error) {
            const message = _getErrorMessage(error);
            commit(mutation.CLEAR_DEPOTS);
            commit(mutation.SET_DEPOTS_ERROR, { error: message });
        }
        finally {
            commit(mutation.SET_DEPOTS_LOADING, { loading: false });
        }
    },

    /**
     * Fetch the current Gold version from the API.
     */
    async fetchVersion({ commit }) {
        commit(mutation.CLEAR_GOLD_VERSION_ERROR);
        commit(mutation.SET_GOLD_VERSION_LOADING, { loading: true });

        try {
            const response  = await CompanyApi.getInfo();
            const version   = _get(response, 'data.GOLD_Version', null);

            commit(mutation.SET_GOLD_VERSION, { version });
        }
        catch (error) {
            const message = _getErrorMessage(error);
            commit(mutation.CLEAR_GOLD_VERSION);
            commit(mutation.SET_GOLD_VERSION_ERROR, { error: message });
        }
        finally {
            commit(mutation.SET_GOLD_VERSION_LOADING, { loading: false });
        }
    },

    /**
     * Initialise the current company upon login or a refresh.
     * Uses company selection from session/local storage or
     * falls back to company ID from login model.
     */
    async initialiseCurrentCompany({ dispatch, getters }, { defaultCompanyId, rememberMe }) {
        const rawStoredCompanyId    = StorageService.get(process.env.VUE_APP_COMPANY_ID_KEY);
        const storedCompanyId       = parseInt(rawStoredCompanyId);
        const storedCompany         = getters.getCompanyById(storedCompanyId);
        // Fallback to default company ID if no stored company is found.
        const companyId             = null === storedCompany ? defaultCompanyId : storedCompanyId;
        await dispatch('setCurrentCompany', { companyId, rememberMe });
    },

    /**
     * Store company selection in vuex and persist in user
     * session in the API.
     */
    async setCurrentCompany({ dispatch, commit }, { companyId, rememberMe }) {
        commit(mutation.SET_COMPANIES_ERROR, {error: ''});
        try {
            const response  = await AccountApi.updateCompany(companyId);
            const company   = response.data.data;
            if (response.data.success)
            {
                commit(mutation.SET_SELECTED_COMPANY, { company, rememberMe });
                await dispatch('fetchDepots');
                await dispatch('initialiseCurrentDepot', { rememberMe });
            }
            else
            {
                const message = `Failed to change comapny. ${response.data.message}}`;
                
                commit(mutation.SET_COMPANIES_ERROR, {error: message});
            }
        }
        catch (error) {
            const message = _getErrorMessage(error);
            commit(mutation.SET_COMPANIES_ERROR, {error: `Failed to change company. ${message}`});
        }
    },

    /**
     * Clear company selection from vuex and optionally
     * from storage service.
     */
    clearCurrentCompany({ commit }, { rememberMe = false }) {
        commit(mutation.CLEAR_SELECTED_COMPANY, { rememberMe });
    },

    /**
     * Initialise the current depot upon login or a refresh.
     * Uses depot selection from session/local storage or
     * falls back to all depots.
     */
    async initialiseCurrentDepot({ dispatch, getters }, { rememberMe }) {
        const storedDepotId = StorageService.get(process.env.VUE_APP_DEPOT_ID_KEY);
        const storedDepot   = getters.getDepotById(storedDepotId);
        // Fallback to all depots if no stored depot is found.
        const depotId       = null === storedDepot ? ALL_DEPOTS_ID : storedDepotId;
        dispatch('setCurrentDepot', { depotId, rememberMe });
    },

    /**
     * Store depot selection in vuex.
     */
    setCurrentDepot({ commit, getters }, { depotId, rememberMe }) {
        const depot = getters.getDepotById(depotId);
        commit(mutation.SET_SELECTED_DEPOT, { depot, rememberMe });
    },

    /**
     * Clear depot selection from vuex and optionally
     * from storage service.
     */
    clearCurrentDepot({ commit }, { rememberMe = false }) {
        commit(mutation.CLEAR_SELECTED_DEPOT, { rememberMe });
    },
};

/**
 * Helper function to extract message from Error object.
 * @param {Error} error
 */
function _getErrorMessage(error) {
    // Initialise with status code error.
    let message = error.message;
    if (!error.response) {
        message = 'Network Error: Unable to reach server.'
    }
    else {
        // Check all expected properties for an error message.
        const message1  = _get(error, 'response.data.error', null);
        const message2  = _get(error, 'response.data.Message', null);
        const message3  = _get(error, 'response.data.message', null);

        message = message1 || message2 || message3;
    }
    return message;
};

export default {
    namespaced  : true,
    state       : initialState(),
    getters,
    mutations,
    actions
};
