<template>
    <ib-modal
        v-model="show"
        modalClass="col-11"
        :dismissable="true"
        >
        <div >
            <div v-if="!currentListingsLoading && !listingDiffLoading && !isUploading && !isUploadSuccessful">
                
                <div>
                    <h5>Review and Upload Listings</h5>
                </div>
                <div style="overflow-y: auto; height:700px">
                <b-tabs>
                    <!-- Current Listings Tab -->
                    <b-tab :title="'Current Listings ('+currentListings.length+')'" active>
                        <br/>
                        These are the listings you currently have on marketplace. <i> (showing {{filteredCurrentListingsCount}} of {{currentListings.length}}) </i>
                        <br/>
                        <br/>
                        <div class="row pl-3 w-100">
                            <span>
                                <input class="form-control" type="text" placeholder="Search" v-model="searchCurrent">
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-secondary" @click="clearCurrent">Clear</button>
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-primary" @click="exportCurrentToCSV">Export</button>
                            </span>
                        </div>
                        <br/>
                            <ib-table 
                                :items="currentListings"
                                :columns="resultsColumns"
                                :filterValue="searchCurrent"
                                :filterProperties="searchColumns"
                                :filterDelay="50"
                                noItemsMessage="No Records Found"
                                :itemsPerPage="13"
                                :small="true"
                                >
                                <template
                                    slot="listingPrice"
                                    slot-scope="data">
                                    <div class="text-right pr-5">
                                        {{ data.item.currency | getCurrencySymbolForCode()}}{{ data.item.listingPrice | getCurrencyValue()}}
                                    </div>
                                </template>

                                <template
                                    slot="totalQuantity"
                                    slot-scope="data">
                                    <div class="">
                                        {{ data.item.totalQuantity }}
                                    </div>
                                </template>
                            </ib-table>
                    </b-tab>


                    <!-- Added Listings Tab -->
                    <b-tab :title="'Added Listings ('+listingsDiff.addedListings.length+')'">
                        <br/>
                        These Listings will be added after upload. <i> (showing {{filteredAddedListingsCount}} of {{listingsDiff.addedListings.length}}) </i>
                        <br/>
                        <br/>
                        <div class="row pl-3 w-100">
                            <span>
                                <input class="form-control" type="text" placeholder="Search" v-model="searchAdded">
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-secondary" @click="clearAdded">Clear</button>
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-primary" @click="exportAddedToCSV" >Export</button>
                            </span>
                        </div>
                        <br/>
                        <ib-table
                            :items="listingsDiff.addedListings"
                            :columns="resultsColumns"
                            noItemsMessage="No Records Found"
                            :filterValue="searchAdded"
                            :filterProperties="searchColumns"
                            :filterDelay="50"
                            :itemsPerPage="13"
                            :small="true"
                            :value="selectedResult"
                            @input="selectResult">
                            <template
                                slot="listingPrice"
                                slot-scope="data">
                                <div class="text-right pr-5">
                                    {{ data.item.currency | getCurrencySymbolForCode()}}{{ data.item.listingPrice | getCurrencyValue()}}
                                </div>
                            </template>

                            <template
                                slot="totalQuantity"
                                slot-scope="data">
                                <div class="">
                                    {{ data.item.totalQuantity }}
                                </div>
                            </template>
                        </ib-table>
                    </b-tab>

                    <!-- Removed Listings Tab -->
                    <b-tab :title="'Removed Listings (' + listingsDiff.removedListings.length + ')'">
                        <br/> 
                        These Listings will be removed after upload. <i> (showing {{filteredRemovedListingsCount}} of {{listingsDiff.removedListings.length}}) </i>
                        <br/> 
                        <br/> 
                        <div class="row pl-3 w-100">
                            <span>
                                <input class="form-control" type="text" placeholder="Search" v-model="searchRemoved">
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-secondary" @click="clearRemoved">Clear</button>
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-primary" @click="exportRemovedToCSV">Export</button>
                            </span>
                        </div>
                        <br/> 
                        <ib-table
                            :items="listingsDiff.removedListings"
                            :columns="resultsColumns"
                            noItemsMessage="No Records Found"
                            :filterValue="searchRemoved"
                            :filterProperties="searchColumns"
                            :filterDelay="50"
                            :itemsPerPage="13"
                            :small="true"
                            :value="selectedResult"
                            @input="selectResult">
                            <template
                                slot="listingPrice"
                                slot-scope="data">
                                <div class="text-right pr-4">
                                    {{ data.item.currency | getCurrencySymbolForCode()}}{{ data.item.listingPrice | getCurrencyValue()}}
                                </div>
                            </template>

                            <template
                                slot="totalQuantity"
                                slot-scope="data">
                                <div class="text-right pr-4">
                                    {{ data.item.totalQuantity }}
                                </div>
                            </template>
                        </ib-table>
                    </b-tab>

                    <!-- Changed Listings Tab -->
                    <b-tab :title="'Changed Listings (' + listingsDiff.changedListings.length + ')'">
                        <br/>
                        These listings will be changed after upload. <i> (showing {{filteredChangedListingsCount}} of {{listingsDiff.changedListings.length}}) </i>
                        <br/>
                        <br/>
                        <div class="row pl-3 w-100">
                            <span>
                                <input class="form-control" type="text" placeholder="Search" v-model="searchChanged">
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-secondary" @click="clearChanged">Clear</button>
                            </span>
                            <span class="pl-2">
                                <button type="button" class="btn btn-primary" @click="exportChangedToCSV">Export</button>
                            </span>
                        </div>
                        <br/>

                        <ib-table
                            :items="listingsDiff.changedListings"
                            :columns="changesColumns"
                            noItemsMessage="No Records Found"
                            :filterValue="searchChanged"
                            :filterProperties="searchColumns"
                            :filterDelay="50"
                            :itemsPerPage="13"
                            :small="true"
                            :value="selectedResult"
                            @input="selectResult">
                            <template
                                slot="listingPrice"
                                slot-scope="data">
                                <div class="text-right pr-4">
                                    {{ data.item.currency | getCurrencySymbolForCode()}}{{ data.item.listingPrice | getCurrencyValue()}}
                                </div>
                            </template>

                            <template
                                slot="totalQuantity"
                                slot-scope="data">
                                <div class="text-center">
                                    {{ data.item.totalQuantity }}
                                </div>
                            </template>
                            <template
                                slot="reasonForChange"
                                slot-scope="data">
                                <div class="text-left" :title="reasonTextVerbose[data.item.reasonForChange]">
                                    {{ reasonText[data.item.reasonForChange] }}
                                </div>
                            </template>                            
                        </ib-table>
                    </b-tab>

                </b-tabs>
                </div>
                <hr>
                <div class="text-right">
                    <button type="button" class="btn btn-success" @click="upload">Upload</button>
                </div>
            </div>
            <div v-if="currentListingsLoading || listingDiffLoading">
                <ib-loading-spinner 
                    message="Getting Listings..."
                    class="flex-grow-1"
                />
            </div>
            <div v-if="isUploading">
                <ib-loading-spinner 
                message=""
                class="flex-grow-1"
                />
                <center> {{uploadStatusMessage}} </center>
            </div>
            <div v-if="isUploadSuccessful && !isUploading">
                <ib-process-successful 
                :message='"Upload succeeded with " + uploadFailures.length + " issues."'
                class="flex-grow-1"
                />
                <ib-table
                            :items="uploadFailures"
                            :columns="uploadFailureColumns"
                            noItemsMessage="No Issues Found"
                            :itemsPerPage="13"
                            :small="true"
                            :value="selectedResult"
                            @input="selectResult">
                        </ib-table>
                <div class="text-right">
                    <button type="button" class="btn btn-primary" @click="exportFailuresToCSV">Export</button> &nbsp;
                    <button type="button" class="btn btn-success" @click="finishUpload">Finish Upload</button>
                </div>
            </div>
        </div>
    </ib-modal>
</template>
<script>
import IbTable              from '@/components/table/IbTable';
import IbModal              from '@/components/utilities/IbModal';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';
import ClientApi            from '@MP/api/client';
import IbTabs               from '@/components/tabs/IbTabs';
import CurrencyMixin        from '@/mixins/CurrencyMixin';
import IbProcessSuccessful  from '@/components/IbProcessSuccessful.vue';

const ReasonText = [
    "None",
    "Description",
    "Price",
    "Currency",
    "Listing Rule"
];

const ReasonTextVerbose = [
    "None",
    "The description of this part has changed from when it was last uploaded",
    "The listing price of this part has changed from when it was last uploaded",
    "The currency of this part has changed from when it was last uploaded",
    "The Listing Rule which caused this part to be upload has changed"
];


const ResultsColumns = [
    {
        heading: 'Depot',
        property: 'depotId',
        sortable: true,
        class: 'small-column'
    },
    {
        heading: 'Part Number',
        property: 'originalPartNumber',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Product Group',
        property: 'productGroup',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Class Code',
        property: 'classCode',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Quantity',
        property: 'totalQuantity',
        sortable: true,
        class: 'number-column'
    },
    {
        heading: 'Listing Price',
        property: 'listingPrice',
        sortable: true,
        class: 'number-column'
    },
    {
        heading: 'Listing Rule',
        property: 'listingRule',
        sortable: true,
        class: 'large-column'
    },
];

const ChangesColumns = [
    {
        heading: 'Depot',
        property: 'depotId',
        sortable: true,
        class: 'small-column'
    },
    {
        heading: 'Part Number',
        property: 'originalPartNumber',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Product Group',
        property: 'productGroup',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Class Code',
        property: 'classCode',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Quantity',
        property: 'totalQuantity',
        sortable: true,
        class: 'number-column'
    },
    {
        heading: 'Listing Price',
        property: 'listingPrice',
        sortable: true,
        class: 'number-column'
    },
    {
        heading: 'Listing Rule',
        property: 'listingRule',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Reason',
        property: 'reasonForChange',
        sortable: true,
        class: 'small-column'
    },
];


const UploadFailureColumns = [
    {
        heading: 'Depot',
        property: 'depot',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Part Number',
        property: 'partNumber',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Reason',
        property: 'reason',
        sortable: true,
        class: 'large-column'
    }
];

const SearchColumns = [
    'originalPartNumber','description'
];

export default {
    name: 'ReviewAndUpload',
    mixins: [
        CurrencyMixin
    ], 
    components: {
        IbModal,
        IbTable,
        IbLoadingSpinner,
        IbTabs,
        IbProcessSuccessful
    },

    created() {
    },

    data() {
        return {
            show            : false,
            resultsColumns  : ResultsColumns,
            changesColumns  : ChangesColumns,
            uploadFailureColumns   : UploadFailureColumns,
            selectedResult  : null,
            searchCurrent   : "",
            searchColumns   : SearchColumns,
            searchAdded     : "",
            searchRemoved   : "",
            searchChanged   : "",
            isUploading     : false,
            isUploadSuccessful : false,
            uploadFailures : [],
            lastUploadRecordOnProcessStart: 0,
            uploadStatusMessage: "",
            reasonText : ReasonText,
            reasonTextVerbose : ReasonTextVerbose
        }
    },

    computed: {
        rulesToPreview() {
                    let previewRules = this.rules.filter(function(p) {
                    return p.showInResults === true;
                });
            let suggestedName = "";
            previewRules.forEach(item => {
                suggestedName += "'" + item.description + "', ";
            });
            return suggestedName.substring(0, suggestedName.length - 2);
        },
        filteredCurrentListingsCount()
        {
            return this.currentListings.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchCurrent.toLowerCase()) || e.description.toLowerCase().includes(this.searchCurrent.toLowerCase())).length;
        },
        filteredAddedListingsCount()
        {
            return this.listingsDiff.addedListings.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchAdded.toLowerCase()) || e.description.toLowerCase().includes(this.searchAdded.toLowerCase())).length;
        },
        filteredRemovedListingsCount()
        {
            return this.listingsDiff.removedListings.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchRemoved.toLowerCase()) || e.description.toLowerCase().includes(this.searchRemoved.toLowerCase())).length;
        },
        filteredChangedListingsCount()
        {
            return this.listingsDiff.changedListings.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchChanged.toLowerCase()) || e.description.toLowerCase().includes(this.searchChanged.toLowerCase())).length;
        }
    },

    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            required : true,
            type     : Boolean
        },
        listingsDiff: {
            required : true,
            type     : Object 
        },
        currentListingsLoading: {
            required : true,
            type     : Boolean 
        },
        listingDiffLoading: {
            required : true,
            type     : Boolean 
        },
        currentListings: {
            required : true,
            type: Array
        },
    },

    methods: {
        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Rules Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },
        async upload() {
            this.isUploading = true;
            try { 
                await this.checkUploadStatus(true);
                await ClientApi.uploadListings();
                this.t = setInterval(() => {
                    this.checkUploadStatus(false);
                }, 1000);
                this.toast('b-toaster-top-center', 'success', `Upload started.`, true);
            }
            catch (err)
            {
                console.log(err.response.data);
                this.toast('b-toaster-top-center', 'danger', `Error uploading listings: ${err.response.data}.`, true);
            }
            
        },
        async checkUploadStatus(firstCheck)
        {
            try{
                let lastUploaded = await ClientApi.getLastUploaded();
                if (firstCheck)
                {
                    this.lastUploadRecordOnProcessStart = lastUploaded.data.id;
                    this.uploadStatusMessage = "Initialising Upload...";
                }
                else if (this.lastUploadRecordOnProcessStart != lastUploaded.data.id && lastUploaded.data.state != 6 && lastUploaded.data.dateCompleted != "0001-01-01T00:00:00")
                {
                    this.isUploadSuccessful = false;
                    this.uploadFailures = lastUploaded.data.failures;
                    this.isUploading = false;
                    this.toast('b-toaster-top-center', 'danger', "Upload failed: " + lastUploaded.data.errorMessage, true)
                    clearInterval(this.t);
                }
                //if this is the new record
                else if (this.lastUploadRecordOnProcessStart != lastUploaded.data.id)
                {
                    if (lastUploaded.data.state == 0)
                    {
                        this.uploadStatusMessage = "Syncing with server, this may take some time..."
                    }
                    else if (lastUploaded.data.state == 1)
                    {
                        this.uploadStatusMessage = "Adding Listing " + lastUploaded.data.numberAdded + " of " + lastUploaded.data.numberToAdd;
                    }
                    else if (lastUploaded.data.state == 2)
                    {
                        this.uploadStatusMessage = "Removing Listing " + lastUploaded.data.numberRemoved + " of " + lastUploaded.data.numberToRemove;
                    }
                    else if (lastUploaded.data.state == 3)
                    {
                        this.uploadStatusMessage = "Updating Listing " + lastUploaded.data.numberUpdated + " of " + lastUploaded.data.numberToUpdate;
                    }

                    else if (lastUploaded.data.state == 6) 
                    {
                        this.isUploadSuccessful = true;
                        this.uploadFailures = lastUploaded.data.failures;
                        this.isUploading = false;
                        clearInterval(this.t);
                    }
                }
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
        },
        selectResult() {

        },

        finishUpload() {
            console.log("finish upload");
            this.isUploadSuccessful = false;
            this.isUploading = false;
            this.show = false;
            this.$emit('uploadFinished', true);
        },

        clearCurrent() {
            this.searchCurrent = "";
        },
        clearAdded() {
            this.searchAdded = "";
        },
        clearRemoved() {
            this.searchRemoved = "";
        },
        clearChanged() {
            this.searchChanged = "";
        },
        exportCurrentToCSV() {
            let csv = 'Depot,Part Number,Description,Product Group,Class Code,Total Quantity,Listing Price\n';

            let listingsToExport = this.currentListings.map(({depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice}) => ({ depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice}));
            console.log(listingsToExport);
            if(this.searchPhrase != "") {
                listingsToExport = listingsToExport.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchCurrent.toLowerCase()) || e.description.toLowerCase().includes(this.searchCurrent.toLowerCase()) );
            }

            listingsToExport.forEach((e) => {
                e.description = '"' + e.description.replaceAll('"','""') + '"';
                e.originalPartNumber = '"' + e.originalPartNumber.replaceAll('"','""') + '"';
            });

            listingsToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `currentListings-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        },

        exportAddedToCSV() {
            let csv = 'Depot,Part Number,Description,Product Group,Class Code,Total Quantity,Listing Price,Listing Rule\n';

            let listingsToExport = this.listingsDiff.addedListings.map(({depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}) => ({ depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}));
            console.log(listingsToExport) ;
            if(this.searchPhrase != "") {
                listingsToExport = listingsToExport.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchAdded.toLowerCase()) || e.description.toLowerCase().includes(this.searchAdded.toLowerCase()) );
            }

            listingsToExport.forEach(function(e) {
                e.description = '"' + e.description.replaceAll('"','""') + '"';
                e.originalPartNumber = '"' + e.originalPartNumber.replaceAll('"','""') + '"';
                e.listingRule = '"' + e.listingRule.replaceAll('"','""') + '"';
            });

            listingsToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `addedListings-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        }, 
        exportRemovedToCSV() {
            let csv = 'Depot,Part Number,Description,Product Group,Class Code,Total Quantity,Listing Price\n';

            let listingsToExport = this.listingsDiff.removedListings.map(({depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}) => ({ depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}));
            console.log(listingsToExport) ;
            if(this.searchPhrase != "") {
                listingsToExport = listingsToExport.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchRemoved.toLowerCase()) || e.description.toLowerCase().includes(this.searchRemoved.toLowerCase()) );
            }

            listingsToExport.forEach((e) => {
                e.description = '"' + e.description.replaceAll('"','""') + '"';
                e.originalPartNumber = '"' + e.originalPartNumber.replaceAll('"','""') + '"';
                e.listingRule = '"' + e.listingRule.replaceAll('"','""') + '"';
            });

            listingsToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `removedListings-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        },
        exportChangedToCSV() {
            let csv = 'Depot,Part Number,Description,Product Group,Class Code,Total Quantity,Listing Price\n';

            let listingsToExport = this.listingsDiff.changedListings.map(({depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}) => ({ depotId, originalPartNumber, description, productGroup, classCode, totalQuantity, listingPrice, listingRule}));
            console.log(listingsToExport) ;
            if(this.searchPhrase != "") {
                listingsToExport = listingsToExport.filter(e => e.originalPartNumber.toLowerCase().includes(this.searchRemoved.toLowerCase()) || e.description.toLowerCase().includes(this.searchRemoved.toLowerCase()) );
            }

            listingsToExport.forEach((e) => {
                e.description = '"' + e.description.replaceAll('"','""') + '"';
                e.originalPartNumber = '"' + e.originalPartNumber.replaceAll('"','""') + '"';
                e.listingRule = '"' + e.listingRule.replaceAll('"','""') + '"';
            });

            listingsToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `changedListings-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        },
        exportFailuresToCSV() {
            let csv = 'Depot,Part Number,Reason\n';

            let failuresToExport = this.uploadFailures.map(({depot, partNumber, reason}) => ({ depot, partNumber, reason}));

            failuresToExport.forEach((e) => {
                e.partNumber = '"' + e.partNumber.replaceAll('"','""') + '"';
                e.reason = '"' + e.reason.replaceAll('"','""') + '"';
            });

            failuresToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `uploadFailures-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        }, 
    },

    watch: {
        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
    }
    
}
</script>
<style lang="less" scoped>
    hr { margin: 5px 0 10px 0; }

/deep/ .small-column {
    min-width: 50px;
    width: 50px;
}

/deep/ .medium-column {
    min-width: 100px;
    width: 100px;
}

/deep/ .number-column {
    min-width: 40px;
    width: 40px; 
    text-align: right !important;
}

/deep/ .large-column {
    min-width: 250px;
    width: 250px;
}

</style>