<template>
    <ib-modal
        v-model="show"
        modalClass="col-5"
        :dismissable="true"
        >
            
        <div>
            <h5>Generate Advice Note</h5>
        </div>
        <br/>
        <div style="overflow-y: auto; height:600px">
        <div style="width:90%">
           
                    <div class="tableFixHead">
                        <table>
                        <thead>
                            <tr>
                                <th>Part</th>
                                <th class="text-right">Quantity</th>
                                <th class="text-right">Unit Price</th>
                                <th class="text-right">Sub Total Price</th>
                                <th>Depot</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in order" :key="item.id">
                                <td>{{item.partNumberForDisplay}}</td>
                                <td class="text-right">{{item.serverInfo.quantity}} </td>
                                <td class="text-right">{{(item.serverInfo.price) | getCurrencyValue()}} </td>
                                <td class="text-right">{{(item.serverInfo.quantity * item.serverInfo.price) | getCurrencyValue()}} </td>
                                <td>{{item.serverInfo.sellerDepotName}}</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
           
               
            <div class="row pt-3">
               <div class="col-4">
                   Carriage Part:
               </div>
               <div class="col-8">
                    <rs-dropdown
                        :ref="'carriagePartDropdown'"
                        :options="filteredMemoParts"
                        v-on:selected="memoPartSelected"
                        :disabled="memoPartsLoading"
                        :maxItem="100000"
                        placeholder="Select a Carriage Part">
                    </rs-dropdown>
               </div>
           </div>   
            <div class="row">
               <div class="col-4">
                   Carriage Charge:
               </div>
               <div class="col-8">
                   <input type="number" step="0.01" v-model="selectedCarriageCharge" style="width:100px" autocomplete="off"  min="0"/>
               </div>
           </div>
           <div class="row">
               <div class="col-4">
                   Customer Account:
               </div>
               <div class="col-8">
                   <rs-dropdown
                        :ref="'customerDropdown'"
                        :options="customers"
                        v-on:selected="customerSelected"
                        :disabled="customersLoading"
                        :maxItem="100000"
                        placeholder="Select a Customer Account">
                    </rs-dropdown>
               </div>
           </div>
           <div class="row">
               <div class="col-4">
               </div>
               <div v-if="selectedCustomerOnStop" class="col-8 alert alert-danger" role="alert">
                   Customer is on 'Total Stop'.
               </div>
            </div>
           <div class="row">
               <div class="col-4">
                   Operator:
               </div>
               <div class="col-8">
                   <rs-dropdown
                        :ref="'operatorDropdown'"
                        :options="filteredOperators"
                        v-on:selected="operatorSelected"
                        :disabled="operatorsLoading"
                        :maxItem="1000"
                        placeholder="Select an Operator"
                        >
                    </rs-dropdown>
               </div>
           </div>
            <div v-if="operatorPasswordRequired" class="row">
               <div class="col-4">
                   Operator Password:
               </div>
               <div class="col-8">
                   <form autocomplete="off">
                        <input type="password" v-model="operatorPassword" style="width:100px" autocomplete="off"/>
                   </form>
               </div>
           </div>
            <div class="row">
                <div class="col-4">
                </div>
                <div v-if="operatorPasswordError != ''" class="col-8 alert alert-danger" role="alert">
                    {{operatorPasswordError}}
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                </div>
                <div v-if="operatorPermissionError != ''" class="col-8 alert alert-danger" role="alert">
                    {{operatorPermissionError}}
                </div>
            </div>

            <div class="row pt-3">
               <div class="col-4">
                   Delivery Address:
               </div>
               <div class="col-8">
                   {{buyerDeliveryAddress}}
               </div>
           </div>
           <div class="row pt-3">
               <div class="col-4">
                   Billing Address:
               </div>
               <div class="col-8">
                   {{buyerBillingAddress}}
               </div>
           </div>
           <div class="text-muted pt-3"><i>Delivery &amp; Billing Address will be taken from the Buyer's profile</i></div>
                       
        </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-success" @click="generateAdviceNote" :disabled="!formValid">Generate Advice</button>
        </div>
    </ib-modal>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';
import RsDropdown       from '@/components/form/RomainSimonDropdown';
import SalesApi         from '@/common/sales';
import CurrencyMixin        from '@/mixins/CurrencyMixin';

export default {
    name: 'AdviceNoteModalV2',
    
    components: {
        RsDropdown,
        IbModal
    },

    mixins: [
        CurrencyMixin
    ],

    created() {
    },

    data() {
        return {
            show                    : false,
            selectedCarriageCharge  : 0,
            selectedCarriagePart    : "",

            selectedCustomer        : "",
            selectedCustomerOnStop  : false,

            selectedOperator        : "",
            operatorPasswordRequired: false,
            operatorPassword        : "",
            operatorPasswordError   : "",
            operatorPermissionError : "",
        }
    },

    computed: {
        buyerBillingAddress() {
            let address = "";
            if (this.item.serverInfo.buyerBillingAddressLine1 !== "") {
                address = this.item.serverInfo.buyerBillingAddressLine1
            }
            if (this.item.serverInfo.buyerBillingAddressLine2 !== "") {
                address += ", " + this.item.serverInfo.buyerBillingAddressLine2
            }
            if (this.item.serverInfo.buyerBillingAddressLine3 !== "") {
                address += ", " + this.item.serverInfo.buyerBillingAddressLine3
            }
            if (this.item.serverInfo.buyerBillingAddressLine4 !== "") {
                address += ", " + this.item.serverInfo.buyerBillingAddressLine4
            }
            if (this.item.serverInfo.buyerBillingPostcode !== "") {
                address += ", " + this.item.serverInfo.buyerBillingPostcode
            }
            return address;
        },

        buyerDeliveryAddress() {
            let address = "";
            if (this.item.serverInfo.buyerDeliveryAddressLine1 !== "") {
                address = this.item.serverInfo.buyerDeliveryAddressLine1
            }
            if (this.item.serverInfo.buyerDeliveryAddressLine2 !== "") {
                address += ", " + this.item.serverInfo.buyerDeliveryAddressLine2
            }
            if (this.item.serverInfo.buyerDeliveryAddressLine3 !== "") {
                address += ", " + this.item.serverInfo.buyerDeliveryAddressLine3
            }
            if (this.item.serverInfo.buyerDeliveryAddressLine4 !== "") {
                address += ", " + this.item.serverInfo.buyerDeliveryAddressLine4
            }
            if (this.item.serverInfo.buyerDeliveryPostcode !== "") {
                address += ", " + this.item.serverInfo.buyerDeliveryPostcode
            }
            return address;
        },

        formValid() {
            // check valid customer and operator
            if(this.selectedCustomerOnStop || this.selectedCustomer == "" || this.selectedOperator == "" || this.selectedCarriagePart == "") {
                return false;
            }
            else
            {
                return true;
            }            

        },

        filteredOperators() {
            //only show operators for the selling depot who haven't left (can be null if they've never had a leaving date or "0001-01-01T00:00:00" if the date is later removed)
            return this.operators.filter(o => o.depot === this.item.serverInfo.sellerDepotNumber && (o.leavingDate == null || o.leavingDate == "0001-01-01T00:00:00"));
        },

        filteredMemoParts() {
            //only show memo parts for the selling depot
            return this.memoParts.filter(p => p.depot === this.item.serverInfo.sellerDepotNumber);
        },
    },

    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            required : true,
            type     : Boolean
        },
        order: {
            required : true,
            type     : Array
        },
        item: {
            required : true,
            type     : Object
        },
        customers: {
            required : true,
            type     : Array
        },
        customersLoading: {
            required : true,
            type     : Boolean
        },
        operators: {
            required : true,
            type     : Array
        },
        operatorsLoading: {
            required : true,
            type     : Boolean
        },
        memoParts: {
            required : true,
            type     : Array
        },
        memoPartsLoading: {
            required : true,
            type     : Boolean
        }

    },

    methods: {
        async generateAdviceNote() {

            // todo: check operator and actually create the advice note
            let credentialCheck = await this.checkOperatorCredentials();
            let permissionCheck = await this.checkOperatorPermission();
            if(credentialCheck && permissionCheck) {
                let basket = this.order.map(o => {
                    o.BasketId = o.serverInfo.basketId;
                    o.OrderNumber = o.serverInfo.id;
                    o.PartNumber = o.partNumberForDisplay;
                    o.Price = Number(o.serverInfo.price)
                    o.Quantity = Number(o.serverInfo.quantity);
                    o.Depot = o.serverInfo.sellerDepotNumber;
                    return o;
                })
                let adviceNoteDetails = {
                    Lines         : basket,

                    //same for entire basket
                    CarriagePart   : this.selectedCarriagePart, 
                    CarriageCharge : Number(this.selectedCarriageCharge), 
                    Customer       : this.selectedCustomer, 
                    Operator       : this.selectedOperator,
                    ContactName    : this.item.serverInfo.buyerContactName,
                    DeliveryAddress1 : this.item.serverInfo.buyerDeliveryAddressLine1,
                    DeliveryAddress2 : this.item.serverInfo.buyerDeliveryAddressLine2,
                    DeliveryAddress3 : this.item.serverInfo.buyerDeliveryAddressLine3,
                    DeliveryAddress4 : this.item.serverInfo.buyerDeliveryAddressLine4,
                    DeliveryPostcode : this.item.serverInfo.buyerDeliveryPostcode,                
                    BillingAddress1 : this.item.serverInfo.buyerBillingAddressLine1,
                    BillingAddress2 : this.item.serverInfo.buyerBillingAddressLine2,
                    BillingAddress3 : this.item.serverInfo.buyerBillingAddressLine3,
                    BillingAddress4 : this.item.serverInfo.buyerBillingAddressLine4,
                    BillingPostcode : this.item.serverInfo.buyerBillingPostcode,
                    PurchaseOrderNumber : this.item.serverInfo.purchaseOrderNumber,
                    
                    companyName         : this.item.serverInfo.buyerCompanyName,
                    depotName           : this.item.serverInfo.buyerDepotName
                }
                this.$emit('generateAdviceNote', adviceNoteDetails);
            }
        },
        
        customerSelected(selection, name)
        {
            if(selection.option != undefined) {
                this.selectedCustomer = selection.option.id;
            }
            // grab customer object and check for total stop
            let customerObject =_.find(this.customers, c => c.id === this.selectedCustomer);
            if(customerObject !== undefined && customerObject.onStop == "Total Stop") {
                this.selectedCustomerOnStop = true;
            }
            else {
                this.selectedCustomerOnStop = false;
            }
        },
        
        memoPartSelected(selection, name)
        {
            if(selection.option != undefined) {
                this.selectedCarriagePart = selection.option.id;
            }
        },
                
        operatorSelected(selection, name)
        {
            if(selection.option != undefined) {
                this.selectedOperator = selection.option.id;
                // grab operator object and check for password
                let operatorObject =_.find(this.filteredOperators, o => o.id === this.selectedOperator);
                if(operatorObject !== undefined && operatorObject.password != undefined) {
                    this.operatorPasswordRequired = true;
                }
                else {
                    this.operatorPasswordRequired = false;
                }
                this.operatorPermissionError = "";
                this.operatorPasswordError = "";
            }
        },

            
        async checkOperatorCredentials() {
            console.debug("checking operator credentials");
            let operatorObject =_.find(this.filteredOperators, o => o.id === this.selectedOperator);
            let operatorToCheck = {storeman: operatorObject.id, aDepot : operatorObject.depot, password : this.operatorPassword.toUpperCase()};
            try {
                let credentialCheck = await SalesApi.checkOperatorCredentials(operatorToCheck);
                if(credentialCheck.data.success || this.operatorPasswordRequired === false) {
                    this.operatorPasswordError = "";
                    this.operatorPassword = "";
                    return true;
                }
                else
                {
                    this.operatorPasswordError = "Invalid Operator Password.";
                    return false;
                }
            }
            catch(error) {
                this.operatorPasswordError = error;
                return false;
            }
        },

        async checkOperatorPermission() {
            console.debug("checking operator permissions");
            let operatorObject =_.find(this.filteredOperators, o => o.id === this.selectedOperator);
            let operatorToCheck = {storeman: operatorObject.id, aDepot : operatorObject.depot, level : 1};
            try {
                let permissionCheck = await SalesApi.checkOperatorPermissions(operatorToCheck);
                if(permissionCheck.data.success) {
                    this.operatorPermissionError = "";
                    return true;
                }
                else
                {

                    this.operatorPermissionError = "'Raise Parts Invoices' Permission required.";
                    return false;
                }
            }
            catch(error) {
                this.operatorPermissionError = error;
                return false;
            }
        },
    },

    watch: {

        show(newValue) {
            this.$emit('input', this.show);
            this.selectedCarriageCharge = 0;
            this.selectedCustomer = "";
            this.selectedOperator = "";
            this.selectedCarriagePart = "";
            this.operatorPassword = "";
            this.operatorPasswordError = "";
            this.operatorPasswordRequired = false;
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
  
    }
    
}
</script>

<style scoped>
    .tableFixHead          { overflow: auto; height: 200px; margin-left:50px;}
    .tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
    table  { border-collapse: collapse; width: 100%; }
    th, td { padding: 8px 16px; }
    th     { background:#eee; }
</style>