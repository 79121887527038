import Vue from 'vue';
import moment from 'moment' //Formating things

Vue.filter('replaceCommaWithNewLine', function (commaSeparatedString) {
    if (!_.isNil(commaSeparatedString) && commaSeparatedString != '') {
        var splitArrayWithoutEmptySpaces = commaSeparatedString.split(',').filter(x => x !== null && x.trim() !=='');
        return splitArrayWithoutEmptySpaces.map(s => s.trim()).join("\n");
    }
    else{
        return '';
    }
})

Vue.filter('standardDateFormat', function(value) {
    if (value) {
      return moment(String(value)).format('DD/MM/YYYY')
    }
})


Vue.filter('removeExtraCommasFromString', function(value) {
    if (!_.isNil(value) && value != '') {
        var splitArrayWithoutEmptySpaces = value.split(',').filter(x => x !== null && x.trim() !=='');
        return splitArrayWithoutEmptySpaces.map(s => s.trim()).join(",");
    }
    else{
        return '';
    }
})