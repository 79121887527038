import store from '@/store';

/**
 * @Beta Component
 */
const BetaMixin = {

    beforeRouteEnter (to, from, next) {
        const hasBetaAccess = store.getters['auth/hasBetaAccess'];

        next(hasBetaAccess);
    }

};

export default BetaMixin;