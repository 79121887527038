<template>
<div class="container-fluid">
    
<div class="container-fluid h-75">
	<div class="btn-toolbar justify-content-start mb-2"
		role="toolbar">
        <h3>Job Requests - Validation Issues</h3>
    
		<!-- Spacer - Expand to use all available space -->
		<div class="flex-grow-1"></div>

		<!-- Refresh job requests -->
		<button class="btn btn-primary mr-2"
			@click="refresh"
			:disabled="true === this.jobReceivedRequests.loading"
		>
			<transition name="fade" mode="out-in">
				<span v-if="true === this.jobReceivedRequests.loading" key="spinner">
					<i class="fas fa-sync-alt fa-spin"></i>
				</span>
				<span v-else key="fixed">
					<i class="fas fa-sync-alt"></i>
				</span>
			</transition>
			<span class="ml-2">Refresh</span>
		</button>
	</div>

    <!-- Error -->
    <b-alert variant="danger"
        dismissible
        :show="null !== error"
        @dismissed="error = null">
        <h5 class="alert-heading">Error:</h5>
        <p class="mb-0">{{ error }}</p>
    </b-alert>

 
        <div class="row h-75">
            <div class="col-12 h-100">
                <div class="card h-100 bg-light shadow-sm">
                    <div class="card-header bg-secondary text-light">
                        <i class="fas fa-fw fa-lg fa-wrench"></i> Validation Issues
                    </div>

                    <div class="card-body pt-0 pb-0 pl-1 pr-1 overflow-auto" id="requestTableContainer">
                        
                        <div v-if="!jobReceivedRequests || 0 == jobReceivedRequests.length"
                            class="d-flex h-100 justify-content-center align-items-center">
                            <div role="alert" 
                                class="alert alert-secondary text-muted shadow-sm">
                                No invalid Job Requests remaining
                            </div>
                        </div>

                        <ib-table v-else
                            id="requestTable"
                            class="overflowHiddenTable"
                            :columns="headings"
                            :items="jobReceivedRequests"
                            :loadingMessage="loadingMessage"
                            :itemsPerPage="9999"
                            v-model="request"
                        >

                            <template slot="ManufacturerId"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ getManufacturerForId(data.item.ManufacturerId)  }}
                                </span>
                            </template>

                            <template slot="RequestReceivedOn"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ data.item.RequestReceivedOn | formatDate() }}
                                </span>
                            </template>

                            <template slot="SerialNumber"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ data.item.SerialNumber }}
                                </span>
                            </template>

                            <template slot="Priority"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ getPriorityForId(data.item.Priority) }}
                                </span>
                            </template>

                            <template slot="RequestTitle"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ data.item.RequestTitle  }}
                                </span>
                            </template>

                            <template slot="ValidationStatusId"
                                slot-scope="data">
                                <span class="overflowHidden">
                                    {{ getStatusForId(data.item.ValidationStatusId) }}
                                </span>
                            </template>
                        </ib-table> 
                    </div>
                </div>
            </div>           
        </div> 

        
<div class="row h-25 mt-3" v-if="!$_.isEmpty(request)">
    <div class="col-12">
        <div class="card bg-light shadow-sm h-100">
                    <div class="card-header bg-secondary text-light">
                        <i class="fas fa-fw fa-lg fa-info-circle"></i> {{request.RequestTitle}}
                    </div>

                    <div class="card-body pt-0 pb-0 pl-1 pr-1" id="requestTableContainer">
                        <form>
                            <div class="col-6">

                                <div class="row">
                                    <div class="col-12">
                                        {{ getStatusForId(request.ValidationStatusId) }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        {{ getStatusSuggestionForId(request) }}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="card-footer text-right">
                        <button class="btn" 
                                :class="{
                                    'btn-outline-danger ib-cursor-denied' : !haveRequest,
                                    'btn-danger'              : haveRequest
                                }"
                                :disabled="false == haveRequest || true == processingRequest"
                                @click="deleteJobRequest">
                            <i class="fas fa-fw fa-trash"></i>Reject Job
                        </button>                    
                    </div>
                </div>
        </div>
    </div>


<br>
<ib-back-button size="lg"></ib-back-button>
   
</div>

</div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import JobApi                       from '@WS/api/job';
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import LicenceMixin                 from '@/mixins/LicenceMixin';
import JobRequestValidationStatus   from '@WS/common/job-request-validation-status';
import JobRequestPriorities         from '@WS/common/job-request-priorities';
import JobRequestManufacturers      from '@WS/common/job-request-manufacturers';
import IbTable                      from '@/components/table/IbTable';
import IbDatePicker                 from '@/components/form/IbDatePicker';
import IbSelectEntry                from '@/components/form/IbSelectEntry';
import IbCheckbox                   from '@/components/form/IbCheckbox';
import ContactApi                   from '@WS/api/contact';
import IbLoadingSpinner             from '@/components/IbLoadingSpinner';
import IbBackButton                 from '@/components/IbBackButton';
import { DocStoreOptions }          from '@/common/docstore-options';

const ALL_STATUSES  = 'All Statuses';

const johnDeereLicenses = [
    DocStoreOptions.GOLD_SVAP_SINGLE_DEPOT, 
    DocStoreOptions.GOLD_SVAP_MULTI_DEPOT
];

const headings = [
    {
        heading     : 'Manufacturer',
        property    : 'ManufacturerId',
        class       : 'manufacturerCol',
        sortable    : true
    },
    {
        heading     : 'Received',
        property    : 'RequestReceivedOn',
        class       : 'recievedCol',
        sortable    : true
    },
    {
        heading     : 'Serial Number',
        property    : 'SerialNumber',
        class       : 'serialCol',
        sortable    : true
    },
    {
        heading     : 'Priority',
        property    : 'Priority',
        class       : 'priorityCol',
        sortable    : true
    },
    {
        heading     : 'Request Title',
        property    : 'RequestTitle',
        class       : 'descriptionCol',
        sortable    : true
    },
    {
        heading     : 'Validation Status',
        property    : 'ValidationStatusId',
        class       : 'statusCol',
        sortable    : true
    }
];

export default {
    name: 'JobRequestList',

    mixins: [
        DateMixin, 
        JobRequestManufacturers,
        JobRequestPriorities,
        JobRequestValidationStatus,
        LicenceMixin
    ],

    components: {
    },

    data() {
		return {
            headings:      headings,
            loading:       false,
			error:         null,
            jobReceivedRequests:   [],
            loadingMessage:     "Loading requests...",
            request:           {},
            processingRequest: false
        }
    },

    components: {
        IbTable,
        IbSelectEntry,
        IbDatePicker,
        IbCheckbox,
        IbLoadingSpinner,
        IbBackButton
	},
    
    created() {
        this.fetchNewRequests();
        this.fetchReceived();
    },
    

    computed: {
       /**
         * Returns an array of job request status starting with an 'All Statuses' option.
         */
        requestStatusList() {
            const statuses = this.buildSelectList(JobRequestStatusList, ALL_STATUSES);
            return statuses;
        },
        ...mapState('engineer', {
            engineers: 'engineers',
        }),
        ...mapState('jobrequest', {
            selected : 'selection',
            priorityCodes: 'priorities'
        }),
        requestPopulated() {
            return this.request !== {};
        },
        haveRequest(){
            let selected = false;
            if (this.request && !_.isEmpty(this.request)){
                selected = true;
            }
            return selected;  
        }
    },

    methods: {

        /**
        *  Map in state load functions.
        */ 
        ...mapActions('jobrequest', {
            fetchJobRequests:         'fetchJobRequests',
            fetchJobRequestSummaries: 'fetchJobRequestSummaries',
            removeRequest:            'removeRequest'
        }),


        toast(toaster, variant, message, append = false) {
        this.$bvToast.toast(message, {
          title: `Workshop Job Request`,
          variant: variant,
          toaster: toaster,
          solid: true,
          appendToast: append
        })
        },
        refresh(){
            this.jobReceivedRequests.loading = true;
            console.debug("Refreshing Page Content");
            this.fetchNewRequests();
            this.fetchReceived();
            this.jobReceivedRequests.loading = false;
            this.request = {};
        },
		fetchReceived() {
            const me = this;
            me.jobReceivedRequests.loading = true;
			JobApi
				.getJobRequestsReceived()
				.then(
					success => {
                        me.jobReceivedRequests = success.data.data;
                        me.jobReceivedRequests.loading = false;     
					},
					failure => {
                        me.error = `Failed to get Job Requests. ${failure.response.data.message}.`;
                        me.jobReceivedRequests.loading = false;
					}
                );
                
        },
        async fetchNewRequests() {
            const me = this;
            if (false === me.hasDocStoreOptions(...johnDeereLicenses)) {
                return;
            }
            me.jobReceivedRequests.loading = true;
            
            console.debug("Fetching New Job Requests");
			await JobApi
				.fetchNewRequests()
				.then(
					success => {
                        me.error = null;
					},
					failure => {
                        me.error = `Failed to Fetch New Job Requests. ${failure.response.data.message}`;
					}
                );
                
            me.jobReceivedRequests.loading = false;
        },
        async deleteJobRequest() {
            const me = this;
            me.processingRequest = true;
            try {
                await JobApi
                    .deleteJob(me.request);
                
                me.toast('b-toaster-top-center', 'warning', `Workshop Job Request rejected.`, true);
                //this.$emit('refreshRequested', true);
                me.removeRequest(me.request);
                
                // TODO: this shouldn't be needed but the changes to the store don't seem to be triggering changes here
                me.fetchJobRequests();
                await me.refresh();
            }
            catch(error) {
                me.toast('b-toaster-top-center', 'danger', failure.response.data.message, true);
                me.error = `Failed to reject Job Request  ${me.request.Id} - ${failure.response.data.message}. `;
            }
            finally {
                me.processingRequest = false;
            }
                    
        },
    } //END methods
}
</script>

<style lang="less" scoped>

    /deep/ .priorityCol {
        width: 8%;;
    }

    /deep/ .manufacturerCol {
        width: 10%;
    }

    /deep/ .recievedCol {
        width: 12%;
    }

    /deep/ .descriptionCol {
         width: 15%;
    }

    /deep/ .statusCol {
        width: 15%;
    }

    /deep/ .serialCol {
         width: 15%;
    }

    /deep/ .overflowHidden {
        float: left;
        width:100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .overflowHiddenTable {
        height: 75% !important;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        table-layout: fixed;
    }
</style>
