<template>
    <div
        class="tabs d-flex flex-grow-1"
        :class="{ 'flex-column' : !vertical }"
    >

        <div :class="{ 'card-header' : card }">
            <ul
                class="nav"
                :class="tabHeaderClasses"
                role="tablist"
                :aria-orientation="vertical ? 'vertical' : 'horizontal'"
            >
                <li
                    v-for="(tab, index) in tabs"
                    :key="index"
                    class="nav-item"
                >
                    <a
                        :id="getTabId(tab)"
                        class="nav-link"
                        :class="{ 'active' : activeTab === tab }"
                        data-toggle="tab"
                        :href="`#${getTabPaneId(tab)}`"
                        @click.prevent="activeTab = tab"
                        role="tab"
                        :aria-controls="getTabPaneId(tab)"
                        :aria-selected="activeTab === tab"
                    >
                        {{ tab.label }}
                        <span
                            v-if="tab.pill"
                            class="badge badge-pill"
                            :class="getPillClass(tab)"
                        >{{ tab.pill }}</span>
                    </a>
                </li>
            </ul>
        </div>

        <div
            class="tab-content flex-grow-1"
            role="tab-content"
        >
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                :id="getTabPaneId(tab)"
                class="tab-pane h-100"
                :class="{ 'active' : activeTab === tab, 'card-body' : card }"
                role="tabpanel"
                :aria-labelledby="getTabId(tab)"
            >
                <component
                    :is="tab.content"
                    v-bind="tab.props"
                    v-on="$listeners"
                ></component>
            </div>
        </div>

    </div>
</template>

<script>
import {
    get      as _get,
    find     as _find,
    head     as _head,
    includes as _includes,
    isObject as _isObject,
} from 'lodash';

export default {

    props: {
        tabs: {
            type    : Array,
            default : () => [],
        },
        pills: {
            type    : Boolean,
            default : false,
        },
        vertical: {
            type    : Boolean,
            default : false,
        },
        card: {
            type    : Boolean,
            default : false,
        },
    },
    
    data() {
        return {
            activeTab  : null,
        };
    },

    computed: {
        tabHeaderClasses() {
            const classes = {
                'nav-tabs'          : !this.pills,
                'nav-pills'         : this.pills,
                'card-header-tabs'  : this.card,
                'flex-column'       : this.vertical,
            };
            return classes;
        },
    },

    watch: {
        tabs: {
            immediate: true,
            handler(newValue, oldValue) {
                if (false === this.isActiveTabValid(newValue))
                {
                    this.initialiseActiveTab();
                }
            },
        },
    },

    methods: {
        /**
         * Initialise the active tab:
         * 1) Sets the first tab with active flag.
         * 2) Sets the first tab.
         * 
         * Sets to null if there are no tabs.
         */
        initialiseActiveTab() {
            let activeTab = null;
            if (this.tabs.length) {
                // Find first tab with active flag set.
                activeTab = _find(this.tabs, ['active', true]);
                if (!activeTab) {
                    // Fallback to first tab.
                    activeTab = _head(this.tabs);
                }
            }
            this.activeTab = activeTab;
        },

        /**
         * Returns true if an active tab is set and the tab exists
         * in the tabs array, otherwise false.
         */
        isActiveTabValid(tabs) {
            const valid = (true === _isObject(this.activeTab)) && 
                          (true === _includes(tabs, this.activeTab));
            return valid;
        },

        /**
         * Get CSS class to apply to tab header pill badge.
         * Defaults to 'badge-primary' if no class is provided.
         */
        getPillClass(tab) {
            const pillClass = _get(tab, 'pillClass', 'badge-primary');
            return pillClass;
        },

        /**
         * Generate a tab ID by lower-casing the tab label, replacing
         * white space characters with '-' and appending '-tab'.
         */
        getTabId(tab) {
            const tabId = `${this.getTabPaneId(tab)}-tab`;
            return tabId;
        },

        /**
         * Generate a tab pane ID by lower-casing the tab label and
         * replacing white space characters with '-'.
         */
        getTabPaneId(tab) {
            const tabPaneId = _get(tab, 'label', '')
                                .toLowerCase()
                                .replace(/\s/g, '-');
            return tabPaneId;
        }
    },
}
</script>

<style lang="less" scoped>
// Animations.
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .05s ease-in-out;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}
</style>