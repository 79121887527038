<template>
    <div>
        <div 
            class="engineer-list"
            tabindex="-1"
            @focus="showOptions"
            @blur="hideOptions"
        >
            <div class="d-flex input-group">
                <input 
                    class="form-control"
                    :class="[{ 'open-entry' : show },
                            { 'is-invalid' : false === valid }]"
                    v-model="display"
                    :state="valid"
                    @focus="$event.target.select()"
                    @blur="validateEngineer"
                    @keydown.enter="validateEngineer"
                    :tabindex="tabIndex"
                />

                <div class="input-group-append">
                    <button 
                        class="btn ib-append-button"
                        :class="[{ 'open-button' : show },
                                { 'invalid' : false === valid }]"
                        type="button"
                        @click="toggleOptions"
                        @blur="hideOptions"
                        tabindex="-1"
                    >
                        <font-awesome-icon
                            class="caret-icon"
                            :class="{ 'caret-icon-active' : show }"
                            :icon="['fas', 'caret-down']"
                            fixed-width
                        />
                    </button>
                </div>
            </div>

            <div 
                class="options rounded-bottom"
                :class="{ 'options-active' : show }"
                @click="hideOptions"
            >
                <div 
                    v-for="engineer in engineers"
                    :key="engineer.Id"
                    class="item"
                    :class="{ 'selected-item' : engineer.Id == $_.get(selectedEngineer, 'Id') }"
                    @click="selectedEngineer = engineer"
                >
                    <span class="ml-3">
                        {{ engineer.Id }} - {{ engineer.Name }}
                    </span>
                </div>
            </div>
        </div>

        <div
            class="visible error-feedback"
            :class="{ invisible : valid }"
        >
            {{ validationError }}
        </div>
    </div>
</template>

<script>
import {
    get     as _get,
    isEmpty as _isEmpty
} from 'lodash-es';
import { mapGetters } from 'vuex';

export default {
    name: 'EngineerList',

    props: {

        /**
         * The output value.
         */
        value: {
            required: true,
        },

        /**
         * Flag to indicate if the value is valid.
         */
        valid: {
            required: false,
            default:  false,
            type:     Boolean
        },

        /**
         * The tab index of the input control.
         */
        tabIndex: {
            required: false,
            default:  0,
            type:     Number
        },

        /**
         * The validation error. Shown when valid is false.
         */
        validationError: {
            required: false,
            default:  null,
            type:     String
        }

    },

    data() {
        return {
            show             : false,
            display          : null,
            selectedEngineer : null,
        }
    },

    computed: {
        ...mapGetters('engineer', {
            engineers : 'engineers'
        }),
    },

    methods: {
        
        /**
         * Hide the dropdown options.
         */
        hideOptions() {
            this.show = false;
        },

        /**
         * Show the dropdown options.
         */
        showOptions() {
            this.show = true;
        },

        /**
         * Toggle whether the dropdown options are shown.
         */
        toggleOptions() {
            this.show = !this.show;
        },

        /**
         * Validate the input and attempt to find the engineer
         * with a matching ID.
         */
        validateEngineer() {
            let engineerId = this.display;

            if (!engineerId) {
                return;
            }

            // If input contains a space, take all the input up to the space.
            if (-1 != this.display.indexOf(' ')) {
                engineerId = this.display.substr(0, this.display.indexOf(' '));
            }

            this.selectedEngineer = this.engineers.find(e => engineerId == e.Id);
        },
    },

    watch: {

        selectedEngineer() {
            if (this.selectedEngineer) {
                this.display = `${this.selectedEngineer.Id} - ${this.selectedEngineer.Name}`;
            }

            this.$emit('input', this.selectedEngineer);
        },

        value: {
            immediate: true,

            handler(newValue) {
                this.selectedEngineer = newValue;
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../../assets/styles/ib-common';

.btn:focus {
    outline: none;
    box-shadow: none;
}

.caret-icon {
    transition: transform 0.2s ease;
}

.caret-icon-active {
    transform: rotateZ(180deg);
}

.engineer-list {
    position: relative;

    :focus {
        outline: none;
    }
}

.item {
    cursor: pointer;
    background-color: white;
}

.item:hover {
    background-color: lightgrey;
}

.open-button {
    border-bottom-right-radius: 0;
}

.open-entry { 
    border-bottom-left-radius: 0;
}

.options {
    overflow-x:         hidden;
    overflow-y:         auto;
    height:             auto;
    max-height:         0px;
    width:              100%;
    transition:         max-height 0.1s ease;
    -webkit-transition: max-height 0.1s ease;
    position:           absolute;
    box-shadow:         1px 1px 5px;
    padding:            0px;
    z-index:            99;
}

.options-active {
    max-height: 150px;
}

.selected-item {
    background-color: grey !important;
    color:            white !important;
}

</style>