import ApiService from '@/common/api-service';

const CURRENCY_ROOT = "api/v1/currency";

const CurrencyApi = {
	get() {
		const url = `${CURRENCY_ROOT}`
		return ApiService.get(url);
	},
};

export default CurrencyApi;
