
const CurrencyMixin = {
    filters: {
        getCurrencySymbolForCode(
            code
        ) 
        {
             switch(code) {
                case 'GBP':
                    return '\u00A3';
                case 'EUR':
                    return '€';
                default:
                    return ''; 
            }
        },
        getCurrencyFromCodeAndValue(code, price)
        {
            return this.getCurrencySymbolForCode(code) + price.toFixed(2).padEnd(4,0);
        },
        getCurrencyValue(price) {
            return price.toFixed(2).padEnd(4,0);
        }
    }

};

export default CurrencyMixin;
