import ApiService from '@/common/api-service';

const ACCOUNT_ROOT_V1 = "api/v1/account";
const ACCOUNT_ROOT_V2 = "api/v2/account";

const AccountApi = {
    /**
     * Log the user into the API.
     * 
     * @param {object} credentials - Username and password.
     */
    login(credentials) {
        const url = `${ACCOUNT_ROOT_V1}/login`;
        return ApiService.post(url, credentials);
    },

    /**
     * Log the user out of the API.
     */
    logout() {
        const url = `${ACCOUNT_ROOT_V1}/logout`;
        return ApiService.post(url);
    },

    /**
     * Update the stored company ID within the session claims.
     * 
     * @param {string} id - The ID of the company.
     */
    updateCompany(id) {
        const url = `${ACCOUNT_ROOT_V2}/session/company`;
        let out = ApiService.put(url, id);
        return out;
    },

    /**
     * Get extra information concerning the logged in user.
     */
    getUserInfo() {
        const url = `${ACCOUNT_ROOT_V1}/user/info`;
        return ApiService.get(url);
    }
};

export default AccountApi;