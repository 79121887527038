import { set, reset }   from '../mutators';
import * as mutation    from '../mutation-types';

const initialState = () => ({
    quickbar    : {
        module  : null,
    },
    sidebar     : {
        open    : true,
    },
    actionbar   : {
        buttons : [],
    },
    settingsMenu: {
        open    : false,
    },
    accountMenu : {
        open    : false,
    },
});

const getters = { };

const mutations = {
    // Set state.
    [mutation.SET_ACTIONBAR_BUTTONS]    : set('actionbar.buttons' , 'buttons'),
    [mutation.SET_ACTIVE_MODULE]        : set('quickbar.module'   , 'module' ),
    // Clear state.
    [mutation.CLEAR_ACTIONBAR_BUTTONS]  : (state) => state.actionbar.buttons  = [],
    [mutation.CLEAR_ACTIVE_MODULE]      : (state) => state.quickbar.module    = null,
    [mutation.TOGGLE_SIDEBAR_MENU]      : (state) => state.sidebar.open       = !state.sidebar.open,
    [mutation.OPEN_SETTINGS_MENU]       : (state) => state.settingsMenu.open  = true,
    [mutation.CLOSE_SETTINGS_MENU]      : (state) => state.settingsMenu.open  = false,
    [mutation.OPEN_ACCOUNT_MENU]        : (state) => state.accountMenu.open   = true,
    [mutation.CLOSE_ACCOUNT_MENU]       : (state) => state.accountMenu.open   = false,
    // Reset state.
    [mutation.RESET]                    : (state) => reset(state, initialState()),
};

const actions = {
    setActionBarButtons({ commit }, buttons) {
        let buttonConfigs = buttons;
        if (false === Array.isArray(buttonConfigs)) {
            buttonConfigs = [ buttons ];
        }
        commit(mutation.SET_ACTIONBAR_BUTTONS, { buttons: buttonConfigs });
    },

    clearActionBarButtons({ commit }) {
        commit(mutation.CLEAR_ACTIONBAR_BUTTONS);
    },
};

export default {
    namespaced  : true,
	state       : initialState(),
    getters,
    mutations,
    actions,
};
