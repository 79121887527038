import ApiService from '@/common/api-service';

const WHOLEGOOD_ROOT = "api/v1/wholegood";

const WholegoodApi = {
    ///
    /// GET Requests
    ///
    /**
     * Get all wholegoods for a customer.
     * @param {String} customerId - The customers account number.
     */
    getWholegoodsByCustomer(customerId) {
        const url = `${WHOLEGOOD_ROOT}/customer/${customerId}`;
        return ApiService.get(url);
    },
    /**
     * Get a wholegood.
     * @param {String} wholegoodId - The Wholegood's ID.
     */
    getWholegoodById(wholegoodId) {
        const url = `${WHOLEGOOD_ROOT}/${wholegoodId}`;
        return ApiService.get(url);
    },
    /**
     * Get wholegood makes.
     */
    getWholegoodMakes() {
        const url = `${WHOLEGOOD_ROOT}/makes`;
        return ApiService.get(url);
    }

    ///
    /// POST Requests
    ///

    ///
    /// PUT Requests
    ///
};

export default WholegoodApi;
