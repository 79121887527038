<template>
<div>
    <ib-modal
                        v-model="show"
                        modalClass="p-1"
                        :dismissable="false"
                        :isSecondOrder="true"
                        :isUnescapable="true">
        <div>
            <h5 class="card-title">Enter Marketplace Password</h5>
            <div class="card-body">
                <div class="row">
                    <div class= "col-8">
                        <input type="password" v-model="passwordString" v-on:keyup.enter="CheckPassword"/>
                    </div>
                </div>
                <div class="row">
                    <div class= "col-8 pt-2 font-italic">
                    </div>
                </div>
                <div class="float-right button-separator">
                    <button type="button" class="btn btn-success" @click="CheckPassword">Continue</button>
                    <button type="button" class="btn btn-danger" @click="CancelScreen" >Cancel</button>
                </div>
            </div>
        </div>
    </ib-modal>
</div>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';
import ConfigApi        from '@MP/api/config';

export default {
    name: 'MarketplacePasswordModal',
    
    components: {
        IbModal
    },

    created: function() {
        this.CheckIfPasswordNeeded();
    },

    data() {
        return {
            show: false,
            passwordString: "",
            initialCheck: true
        }
    },

    computed: {
      
    },

    props: {
        value: {
            required : true,
            type     : Boolean
        },
    },

    methods: {
        async CheckIfPasswordNeeded()
        {
            this.show = false;
            this.passwordString = "";
            this.CheckPassword();
        },
        async CheckPassword()
        {
            try
            {
                let data = await ConfigApi.checkPassword(this.passwordString);
                if (data)
                {
                    this.$emit('passwordValidated');
                }
                else
                {
                    if (!this.initialCheck)
                        this.toast('b-toaster-top-right', 'danger', 'Password Invalid', true);
                    else
                        this.show = true;
                }
            }
            catch(err)
            {
                if (!this.initialCheck)
                    this.toast('b-toaster-top-right', 'danger', 'Password Invalid', true);
                else 
                    this.show = true;
            }
            finally
            {
                this.initialCheck = false;
            }
        },
        CancelScreen()
        {
            this.$router.push({name: 'CancelPassword'})
        },
        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Marketplace Purchases`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },
    },

    watch: {
        show(newValue) {
            this.$emit('input', this.show);
        },
        value(newValue) {
            this.show = newValue;
        }
    }
}
</script>
<style>
    .button-separator > .btn{
        margin: 5px;
    }
</style>