const TRAVEL        = 0;
const LABOUR        = 1;
const ATTENDANCE    = 2;

const JobActivityType = {
    TRAVEL,
    LABOUR,
    ATTENDANCE
};

export { JobActivityType };
