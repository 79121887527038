<template>
    <div class="row h-100">
        <div class="col-md-12 h-100">
            <router-view class="h-100"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Internal',
}

</script>