<template>
    <ib-info-modal :id="id" :title="title" :headerColour="headerColour" :iconColour="iconColour">
        <button type="button" class="btn btn-link" @click="openInNewWindow">Open in new Window</button>
        <vue-markdown :id="title" :anchorAttributes="linkOptions">{{markdown}}</vue-markdown>
    </ib-info-modal>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import IbInfoModal from '@/components/utilities/IbInfoModal';

export default {
    name: "IbInfoModalMarkdown",

    components: {
        VueMarkdown,
        IbInfoModal
    },

    props: {
        markdown: {
            type: String,
            required : true
        },
        id: {
            type: String
        },

        title: {
            type: String
        },

        iconColour: {
            type: String
        },
        headerColour: {
            type: String
        }
    },        

    methods: {
        openInNewWindow() {
            var divText = "<title>"+ this.title +"</title><div style=\"font-family:'Ubuntu', 'Avenir', Helvetica, Arial, sans-serif\">" + document.getElementById(this.title).outerHTML + "</div>";
            var myWindow = window.open('','','width=400,height=600');
            myWindow.document.write(divText);
        }
    },

    data() {
        return {
            linkOptions:{
                target: "_blank",
                rel: "noreferrer noopener"
            }
        }
    }
}
</script>