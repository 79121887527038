<template>
    <div
        class="bg-dark text-white tool-tip"
        :style="style"
        v-show="show"
        ref="tooltip"
    >
        <slot>
            {{ text }}
        </slot>
    </div>
</template>

<script>
import { get as _get } from 'lodash-es';

export default {
    name: 'IbToolTip',

    props: {

        /**
         * The text to be displayed in the default
         * slot.
         */
        text: {
            default: null
        }

    },

    data() {
        return {
            x    : null,
            y    : null,
            show : false
        }
    },

    mounted() {
        if (this.$el.parentElement) {
            this.$el.parentElement.addEventListener('mouseenter', this.onMouseEnter);
            this.$el.parentElement.addEventListener('mouseleave', this.onMouseLeave);
        }
    },

    beforeDestroy() {
        if (this.$el.parentElement) {
            this.$el.parentElement.removeEventListener('mouseenter', this.onMouseEnter);
            this.$el.parentElement.removeEventListener('mouseleave', this.onMouseLeave);
        }
    },

    methods: {

        /**
         * Check if the tooltip goes out of view, offsetting the position
         * to ensure all of the content is in view.
         */
        checkOffset(event) {
            const viewWidth     = _get(event, 'view.innerWidth', 0);
            const viewHeight    = _get(event, 'view.innerHeight', 0);
            const tooltipWidth  = _get(this.$refs, 'tooltip.clientWidth', 0);
            const tooltipHeight = _get(this.$refs, 'tooltip.clientHeight', 0);

            // Calculate the offset.
            let offsetX = viewWidth - (event.clientX + tooltipWidth);
            let offsetY = viewHeight - (event.clientY + tooltipHeight);

            if (0 > offsetX) {
                this.x += offsetX;
            }

            if (0 > offsetY) {
                this.y += offsetY;
            }
        },

        /**
         * Event handler for the mouse enter event.
         */
        onMouseEnter(event) {
            this.x    = event.clientX;
            this.y    = event.clientY;
            this.show = true;

            this.$nextTick(() => this.checkOffset(event));
        },

        /**
         * Event handler for the mouse leave event.
         */
        onMouseLeave(event) {
            this.x    = null;
            this.y    = null;
            this.show = false;
        },

    },

    computed: {

        /**
         * Computed style of the tooltip content to set it's position.
         */
        style() {
            let style = {};

            if (true === this.show) {
                style = {
                    top  : this.y - document.body.scrollTop + 'px',
                    left : this.x + 'px'
                }
            }

            return style;
        }

    },
}
</script>

<style lang="less" scoped>

.tool-tip {
    position:      fixed;
    padding:       0px 5px;
    z-index:       999;
    overflow:      hidden;
    cursor:        default;
    border-radius: 2.5px;
    box-shadow:    0px 5px 10px 1px #ACACAC;
    white-space:   nowrap;
}

</style>