<template>
    <div :id="filterType" class="collapse" role="tabpanel" :aria-labelledby="filterType">
        <ib-drag :transferData="{
            id              : item.id,
            name            : item.description,
            filterType        : item.filterType
            }"
            :enabled="true"
            @dragEnd="dragEnd">
            <div :test-data="item.description" :test-data-id="'filter-'+item.filterType+'-'+item.id"  :class="{'card-body' : true, 'time-filter-item' : item.filterType == 5, 'filter-item' : item.filterType <= 4 || item.filterType == 8, 'depot-item' : item.filterType == 6, 'part-item' : item.filterType == 4}" v-on:mousedown="dragged(item.filterType)" v-on:mouseup="dragEnd">
                <div class="row" :title="item.values">
                    <div class="col-lg-10">
                        {{item.description}}
                    </div>
                    <div class="col-lg-1" @click="editFilter(item)">
                        <span :test-data="item.description+'edit'"  title="Edit Filter"><i class="fas fa-pencil-alt clickable-text transparent"></i></span>
                    </div>
                </div>
            </div>
        </ib-drag>
    </div>
</template>

<script>
import IbDrag from '@/components/utilities/IbDrag';
import IbDrop from '@/components/utilities/IbDrop';

export default {
    name: 'FilterItem',
        
    components: {
        IbDrag,
        IbDrop
    },

    props: {

        /**
         * The item to display.
         */
        item: {
            type:     Object,
            required: true
        },
        filterType: {
            type:       String,
            required:   true
        }

    },

    methods: {

        /**
         * Event handler for the drop event.
         * 
         * Emits a 'move' event attaching information about this item.
         */
        move(data) {
            if (!this.readonly) {
                this.$emit('move', { 
                    toItemIndex   : this.index,
                    ...data
                });
            }
        },
        dragged(type){
            this.$emit('dragged',type);
        },
        dragEnd() {
            this.$emit('dragEnd');
        },
        editFilter(filter) {
            this.$emit('editFilter',filter);
        },
        deleteFilter(filter) {
            this.$emit('deleteFilter',filter);
        },

    }
}
</script>

<style lang="less" scoped>

.filter-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #397CFC;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.time-filter-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #7D52A0;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.part-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #DBB25D;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.depot-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #008080;
    margin-bottom:    .1rem;
    padding:          .5rem;
}


.transparent {
    opacity:0.3;
}

.clickable-text {
    cursor: pointer;
}


</style>