import ApiService from '@/common/api-service';

const CUSTOMER_ROOT = "api/v1/customer";

const CustomerApi = {
    ///
    /// GET Requests
    ///
    /**
     * Get a customer.
     * @param {String} customerId - The Customer ID.
     */
    getCustomerById(customerId) {
            if(null !== customerId){
            const url = `${CUSTOMER_ROOT}/${customerId}`;
            return ApiService.get(url);
        }
    },

    /**
     * Search customers by the term specified.
     * @param {string} term - The search term.
     */
    searchCustomers(term) {
        const url = `${CUSTOMER_ROOT}/search/${term}`;
        return ApiService.get(url);
    },
     /**
     * Search customers by the term specified.
     * @param {string} term - The search term.
     * @param {string} minAccountNo - The minimum account number.
     * @param {string} maxAccountNo - The maximum account number.
     */
    searchCustomersInRange(term, minAccountNo, maxAccountNo) {
        const url = `${CUSTOMER_ROOT}/search/${term}`;
        const config = {
			params: {
				minAccountNo:	minAccountNo,
				maxAccountNo:	maxAccountNo
			}
		};
        return ApiService.get(url, config);
    },
    /**
     * Search customer nicknames by the term specified.
     * @param {string} term - The search term.
     */
    searchCustomerByNickname(term) {
        const url = `${CUSTOMER_ROOT}/search/nickname/${term}`;
        return ApiService.get(url);
    },

    getAllCustomers() {
        const url = `${CUSTOMER_ROOT}`;
        return ApiService.get(url);
    },
    ///
    /// POST Requests
    ///

    ///
    /// PUT Requests
    ///
};

export default CustomerApi;
