import ApiService from '@/common/api-service';

const CONTACT_ROOT = "api/v1/contact";

const contactApi = {
    /**
    * Get customer contacts using following class (Contac Logic)
    * @param {String} customerId - The Customer ID.
    */
   getContactsByCustomer(customerId) {
    const url = `${CONTACT_ROOT}/customer/${customerId}`;
    return ApiService.get(url);
}
}   

export default contactApi;