<template>
<div>
   <ib-modal v-model="showDeliveryAddressModal"
                        modalClass="col-6 p-2"
                        :dismissable="true"
                        :isSecondOrder="true">
        <div>
            <h5 class="card-title">{{item.serverInfo.sellerCompanyName}} - {{item.serverInfo.sellerDepotName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">Delivery Address Details</h6>
            <div class="card-body">
            <div class="row">
                <div class= "col-2">
                    Contact name:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerContactName}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Email:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerContactEmail}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Telephone:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerContactPhone}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 1:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerDeliveryAddressLine1}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 2:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerDeliveryAddressLine2}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 3:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerDeliveryAddressLine3}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 4:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerDeliveryAddressLine4}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Postcode:
                </div>
                <div class="col-8">
                    {{item.serverInfo.sellerDeliveryPostcode}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    <h5> &nbsp; </h5>
                </div>
            </div>
            <div class="row">
                <div class= "col-8">
                    <h6> 
                    Delivery Terms:
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pre-formatted">
                    <textarea disabled style="height: 330px; width: 100%;" v-model="item.serverInfo.deliveryTerms">
                    </textarea>
                </div>
            </div>
            
            </div>
        </div>
    </ib-modal>
            
    <ib-modal
        v-model="show"
        modalClass="col-5"
        :dismissable="true"
        >
            
        <div>
            <h5>Generate Purchase Order</h5>
        </div>
        <div style="overflow-y: fixed; height:25em">
        <div style="width:90%">
        
                <div class="card-body">
                    <div class="tableFixHead">
                        <table>
                        <thead>
                            <tr>
                                <th>Part</th>
                                <th>Quantity</th>
                                <th>Buying Depot</th>
                                <th>Total Price</th>
                                <th>Seller</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item) in order" :key="item.id">
                                <td>{{item.partNumberForDisplay}}</td>
                                <td class="text-right">{{item.serverInfo.quantity}} </td>
                                <td>{{item.serverInfo.buyerDepotName}}</td>
                                <td class="text-right">{{(item.serverInfo.quantity * item.serverInfo.price)  | getCurrencyValue()}} </td>
                                <td>{{item.serverInfo.sellerCompanyName}} </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
        <div class="row pt-4">
            <div class="col-4 font-weight-bold">
                Supplier Account:
            </div>
            <div class="col-8">
                <rs-dropdown
                        :ref="'supplierDropdown'"
                        :options="supplierAccounts"
                        v-on:selected="supplierAccountSelected"
                        :maxItem="100000"
                        placeholder="Select a Supplier Account">
                    </rs-dropdown>
            </div>
        </div>
        <div class="row pt-4">
                <div class="col-4 ">
                    <span class="font-weight-bold" > Delivery Address: </span> 
                </div>
                <div class="col-8">
                {{order.sellerDeliveryPostcode}} <u v-on:click="showDeliveryAddressModal = true" class="clickable-text"> <i> show more </i> </u>
                </div>
        </div>
        <div class="text-muted pt-3">
            <div class="col-12 font-italic">
                Delivery &amp; billing address will be taken from your marketplace profile.
            </div>
        </div>
                    
        </div>
        </div>
        <div class="text-right">
            <button type="button" class="btn btn-success" :disabled="!formValid" v-on:click="generate">Generate Purchase Order</button>
        </div>
    </ib-modal>
    </div>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';
import RsDropdown       from '@/components/form/RomainSimonDropdown';
import ClientApi        from '@MP/api/client';
import CurrencyMixin        from '@/mixins/CurrencyMixin';

export default {
    name: 'PurchaseOrderModalV2',
    
    components: {
        RsDropdown,
        IbModal
    },

    mixins: [
        CurrencyMixin
    ],

    created() {
    },

    data() {
        return {
            show: false,
            showDeliveryAddressModal: false,
            selectedSupplier: {},
            formValid: false
        }
    },

    computed: {
      
    },

    props: {
        order: {
            required : true,
            type     : Array
        },
        item: {
            required : true,
            type     : Object
        },
        value: {
            required : true,
            type     : Boolean
        },
        supplierAccounts: {
            required : true,
            type     : Array
        }
    },

    methods: {
       async generate()
       {
           this.$emit('generatePurchaseOrder', {order: this.order, supplier: this.selectedSupplier});
       },
       supplierAccountSelected(selection, name)
        {
            if(selection.option != undefined) {
                this.selectedSupplier = selection.option.id;
                this.formValid = true;
            }
        },
    },

    watch: {

        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
  
    }
    
}
</script>

<style scoped>
    .tableFixHead          { overflow: auto; height: 200px; }
    .tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
    table  { border-collapse: collapse; width: 100%; }
    th, td { padding: 8px 16px; }
    th     { background:#eee; }
</style>