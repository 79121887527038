import Vue from 'vue';
function GetUrl(url) {
    const fullyQualifiedUrl = `${process.env.VUE_APP_REST_API_BASE_URL}${url}`;
    return fullyQualifiedUrl;
}
const RestApiService = {
    get(url, data) {
        return Vue.axios({
            method: 'get',
            url: GetUrl(url),
            data
        });
    },
    post(url, data) {
        return Vue.axios({
            method: 'post',
            url: GetUrl(url),
            data
        });
    },
    put(url, data) {
        return Vue.axios({
            method: 'put',
            url: GetUrl(url),
            data
        });
    },
    patch(url, data) {
        return Vue.axios({
            method: 'patch',
            url: GetUrl(url),
            data
        });
    },
    delete(url, data) {
        return Vue.axios({
            method: 'delete',
            url: GetUrl(url),
            data
        });
    }
};
export default RestApiService;