const Utility = {
    /**
     * Returns false if value is a string with a length greater than zero,
     * otherwise true.
     */
    isNullOrEmpty(value) {
        const result =
            (undefined === value) ||
            (null      === value) ||
            (('string' === typeof(value)) && (0 === value.length));
        return result;
    }
};

export default Utility;
