/**
 * Automatically loads all vuex modules from the current
 * directory and all sub-directories, where the file name
 * matches the pattern '[name].store.js'.
 */
const modules = {};

let requireModule;
// Test environment doesn't utilise webpack so use require-context package directly. 
if (process.env.NODE_ENV === 'test') {
    const requireContext = require('require-context');
    requireModule = requireContext('.', true, /\.store\.js$/);
}
else {
    requireModule = require.context('.', true, /\.store\.js$/);
}

requireModule.keys().forEach((filename) => {
    // Remove 'store.js' extension.
    const moduleName = filename.replace(/(.*\/|\.store\.js)/g, '');
    
    modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default modules;
