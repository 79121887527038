<template>
    <div class="d-flex flex-column p-2 h-100 parts-audit">
        <h1 class="m-2">
            Parts Audit: Job {{ id }}
        </h1>

        <b-alert v-if="error"
                 show
                 variant="danger">
            {{ error }}
        </b-alert>

        <b-row class="m-2 mt-3 mb-3">
            <ib-table :items="table.transactions"
                      :columns="table.columns"
                      :loading="table.loading"
                      :itemsPerPage="25"
                      :noItemsMessage=noItemsMessage
                      :striped=true
                      :rowHover=true
                      v-model="selectedTransaction"
                      loadingMessage="Fetching audit..">

                <template slot="ProcessedOn"
                          slot-scope="data">
                    <div class="d-flex justify-content-center">
                        <i v-if="!isMinDate(data.item.ProcessedOn)"
                            class="far fa-check-square fa-lg"/>
                        <i v-else
                           class="far fa-square fa-lg"/>
                    </div>
                </template>

                <template slot="CreatedOn"
                          slot-scope="data">
                    {{ data.item.CreatedOn | formatDate(DateFormat.DATE_TIME )}}
                </template>

                <template slot="UploadedOn"
                          slot-scope="data">
                    {{ data.item.UploadedOn | formatDate(DateFormat.DATE_TIME )}}
                </template>
            </ib-table>
        </b-row>

        <b-row class="m-2 mt-auto">
            <ib-back-button size="lg"/>
        </b-row>

        <transaction-issue-info :issue="selectedIssue"
                                :loading="selectedTransaction.issueLoading"
                                :show="showTransactionInfo"
                                @hidden="onInfoClosed"
                                @acknowledging="onAcknowledging"/>
    </div>
</template>

<script>
import { mapState }                     from 'vuex';
import BetaMixin                        from '@/mixins/BetaMixin';
import LicenceMixin                     from '@/mixins/LicenceMixin';
import DateMixin, { DateFormat }        from '@/mixins/DateMixin';
import IbBackButton                     from '@/components/IbBackButton';
import IbTable                          from '@/components/table/IbTable';
import IbLoadingSpinner                 from '@/components/IbLoadingSpinner';
import JobsApi                          from '@WS/api/job';
import TransactionIssueInfo             from '@WS/components/parts-audit/TransactionIssueInfo';
import {
    PartTransactionAction,
    PartTransactionStatus,
    PartTransactionActionStrings,
    PartTransactionStatusStrings
}                                       from '@WS/common/part-transaction-constants';

const TABLE_COLUMNS = [
    {
        heading: 'Part No.',
        property: 'PartId',
        sortable: true,
        class: 'col-part'
    },
    {
        heading:  'Action',
        property: 'action',
        sortable: true,
        class: 'col-action'
    },
    {
        heading: 'Quantity',
        property: 'Quantity',
        sortable: true,
        class: 'col-quantity'
    },
    {
        heading: 'From',
        property: 'From',
        sortable: true,
        class: 'col-fromto'
    },
    {
        heading: 'To',
        property: 'To',
        sortable: true,
        class: 'col-fromto'
    },
    {
        heading: 'User',
        property: 'engineer',
        sortable: true,
        class: 'col-user'
    },
    {
        heading: 'Date',
        property: 'CreatedOn',
        sortable: true,
        class: 'col-date'
    },
    { 
        heading: 'Uploaded',
        property: 'UploadedOn',
        sortable: true,
        class: 'col-date'
    },
    { 
        heading: 'Processed',
        property: 'ProcessedOn',
        sortable: true,
        class: 'col-processed'
    }
]

/**
 * Page for displaying the parts audit for a job.
 */
export default {
    name: 'PartsAudit',

    mixins: [
        DateMixin,
        LicenceMixin
    ],

    components: {
        IbBackButton,
        IbTable,
        IbLoadingSpinner,
        TransactionIssueInfo
    },

    props: {
        id: {
            type:     String,
            required: true
        },

        depots: {
            type: Object,
            default() {
                return {
                    value:   [],
                    error:   null,
                    loading: false
                }
            }
        }
    },

    data() {
        return {
            error: null,
            isSkipped: false,

            table: {
                columns: TABLE_COLUMNS,
                transactions: [],
                loading: false
            },

            selectedTransaction: {},
            selectedIssue: {},
            showTransactionInfo: false
        }
    },

    created() {
        this.fetchTransactions();
    },

    computed: {
        ...mapState('engineer', {
            engineers: 'engineers',
        }),

        noItemsMessage() {
            return `No audit found for job ${this.id}.`;
        },
    },

    methods: {

        /**
         * Fetch all part transactions for the job.
         */
        async fetchTransactions() {
            const me              = this;
            me.error              = null;
            me.table.transactions = [];
            me.table.loading      = true;

            try {
                const response = await JobsApi.getPartTransactions(me.id);
                me.table.transactions = response.data.data;
                me.$_.forEach(me.table.transactions, async (transaction, key) => {
                    await this.setUpTransactionDisplay(transaction);
                });
            }
            catch (failure) {
                const message = `Failed to get parts for job ${me.id}: ${failure.response.data.message}.`
                console.error(message);
                me.error = message;
            }
            finally {
                me.table.loading = false;
            }
              
        },
        
        /**
         * Fetch the transaction issue for the given transaction.
         */
        async fetchTransactionIssue(transaction) {            
            if (!transaction) {
                // Invalid transaction; fail fast.
                return;
            }

            const me                 = this;
            let issue                = null;
            me.error                 = null;
            transaction.issueLoading = true;

            try {
                const response = await JobsApi.getTransactionIssue(transaction.Id);
                if (true === response.data.success) {
                    issue = response.data.data;
                }
            }
            catch (failure) {
                const message = `Failed to get issue for transaction '${transaction.Id}': ${failure.response.data.message}`;
                console.error(message);
                me.error = message;
            }
            finally {
                transaction.issueLoading = false;
            }

            return issue;
        },
        
        /**
         * Initialise display values for transaction based
         * on properties of the transaction.
         */
        async setUpTransactionDisplay(transaction) {
            transaction.action = PartTransactionActionStrings[transaction.Action];
            transaction.status = PartTransactionStatusStrings[transaction.Status];                            
            
            transaction.engineer = transaction.UserId;
            const engineer = this.$_.find(this.engineers.value, (t) => { return t.Id == transaction.UserId });

            if (engineer) {
                transaction.engineer = engineer.Name;
            }

            // If this is a collection transaction, the from field is the
            // depot the part has come from. So get the depot ShortName
            // from the global depots list.
            if (PartTransactionAction.ACTION_COLLECT === transaction.Action) {
                const depot = this.$_.find(this.depots.value, (d) => { return d.Id == transaction.From });

                if (depot) {
                    transaction.From = depot.ShortName;
                }
            }

            if(transaction.status === PartTransactionStatusStrings[PartTransactionStatus.STATUS_SKIPPED]){
                transaction.rowClass = 'skipped';
                transaction.issue = await this.fetchTransactionIssue(transaction);
                
                // If the transaction has already been selected while the issue is being loaded
                // assign the issue to the selectedTransaction.
                if (this.selectedTransaction &&
                    this.selectedTransaction.Id === transaction.Id &&
                    transaction.issue) {
                    this.selectedIssue = transaction.issue;
                }
            }
        },

        /**
         * Send the request to acknowledge the given issue.
         */
        async acknowledgeIssue(issueId) {
            if (!issueId) {
                return;
            }

            const me              = this;
            let acknowledgedIssue = null;
            me.error              = null;

            try {
                const response = await JobsApi.acknowledgeTransactionIssue(issueId);
                if (true === response.data.success) {
                    acknowledgedIssue = response.data.data;
                }
            }
            catch (failure) {
                const message = `Failed to acknowledge issue '${issueId}': ${failure.response.data.message}`;
                console.error(message);
                me.error = message;
            }

            return acknowledgedIssue;
        },

        /**
         * Event handler for when the transaction issue information
         * modal is closed.
         */
        onInfoClosed() {
            this.selectedTransaction = {};
            this.selectedIssue = {};
            this.showTransactionInfo = false;
        },

        /**
         * Event handler for when an issue is being acknowledged.
         */
        async onAcknowledging(id) {
            if (!id) {
                return;
            }

            let transaction = _.find(this.table.transactions, function(transaction) {
                                                                    return transaction.issue && id === transaction.issue.Id;
                                                                });
            
            if (!transaction.issue) {
                console.error(`Couldn't find transaction issue '${id}'`);
            }

            transaction.issue.acknowledging = true;
            transaction.issue = await this.acknowledgeIssue(transaction.issue.Id);

            if (transaction.issue.Id === this.selectedIssue.Id) {
                this.selectedIssue = transaction.issue;
            }
        },
    },

    watch: {

        selectedTransaction(newValue) {
            this.showTransactionInfo = false;

            if (newValue && 
                PartTransactionStatusStrings[PartTransactionStatus.STATUS_SKIPPED] === newValue.status) {
                this.showTransactionInfo = true;

                if (newValue.issue) {
                    this.selectedIssue = newValue.issue;
                }
            }
        }

    }
}
</script>

<style lang="less" scoped>

/deep/ .col-status {
    min-width: 100px;
}

/deep/ .col-action {
    min-width: 100px;
}

/deep/ .col-quantity {
    width: 120px;
    min-width: 110px;
}

/deep/ .col-fromto {
    min-width: 90px;
}

/deep/ .col-user {
    min-width: 120px;
}

/deep/ .col-date {
    min-width: 120px;
}

/deep/ .col-processed {
    min-width: 120px;
}

/deep/ .col-part {
    min-width: 150px;
}

/deep/ .skipped{
    color: white;
    background-color: #ff4d4d !important;           
}

/deep/ .skipped:hover {
    cursor: pointer;
    background-color: #ff1010 !important;
}

</style>
