import Internal from '@DEV/Internal';
import Audit from '@DEV/views/Audit';

export default [
    {
        path: '/internal',
        name: 'Internal',
        component: Internal,
        meta: {
            module: true,
            menu: {
                label: 'Internal',
                icon: [ 'fas', 'code' ]
            }
        },
        children: [
            {
                path: 'audit',
                name: 'Audit',
                component: Audit,
                meta: {
                    module: false,
                    menu: {
                        label: 'Audit',
                        icon: [ 'fas', 'align-left' ]
                    }
                }
            }
        ]
    }
];