<template>
    <datetime-popup
        class="popup"
        ref="popup" 
        v-if="show"
        :datetime="internalDateTime"
        type="datetime"
        tabindex="-1"
        :min-datetime="minValue"
        :max-datetime="maxValue"
        @keydown.esc="$emit('cancel')"
        @confirm="confirm"
        @cancel="$emit('cancel')"
        auto
    />
</template>

<script>
import 'vue-datetime/dist/vue-datetime.css';
import { DateTime } from 'luxon';
import { DatetimePopup } from 'vue-datetime';

/**
 * Component wrapping the date time popup component from the
 * 'vue-datetime' package.
 */
export default {
    name: 'IbDateTimePopup',

    components: {
        DatetimePopup
    },

    props: {

        /**
         * The resulting value.
         */
        value: {
            required: true
        },

        /**
         * Flag to indicate if the popup should be shown.
         */
        show: {
            type:     Boolean,
            required: false,
            default:  false
        },

        /**
         * The minimum value the popup can output.
         */
        minValue: {
            type:    String,
            default: null
        },
        
        /**
         * The maximum value the popup can output.
         */
        maxValue: {
            type:    String,
            default: null
        }

    },

    data() {
        return {
            dateTime         : null,
            internalDateTime : null
        }
    },

    created() {
        this.internalDateTime = DateTime.local();
    },

    methods: {

        /**
         * Handler for when the value from the popup is confirmed.
         */
        confirm(dateTime) {
            this.dateTime = dateTime.toUTC();
        },

        /**
         * Handler for when the the popup element loses focus.
         */
        onBlur() {
            this.$refs.popup.$el.removeEventListener('blur', this.onBlur);
            this.$emit('cancel');
        }

    },

    watch: {

        show(newValue) {
            if (newValue) {
                this.$nextTick(() => {
                    this.$refs.popup.$el.focus()
                    this.$refs.popup.$el.addEventListener('blur', this.onBlur);
                });
            }
        },
        
        dateTime() {
            this.$emit('input', this.dateTime ? this.dateTime.toISO() : '');
        }

    }
}
</script>

<style lang="less" scoped>

.popup:focus {
    outline: none;
}

</style>