<template>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">
        <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>
        <div v-if="!loading && !uploadInProgress">
            <ib-yes-no-cancel-modal v-model="showSaveDepotConfirmation" @yesPressed="enableDepotAndSave" @noPressed="keepDepotDisabledAndSave" @cancelPressed="cancelDepotSave" :title="`Enable Depot?`" 
            :message="`The depot you are saving is not currently enabled. You won't be able to use the marketplace at this depot until it is. Would you like to enable it?`"/>
            <ib-warning-modal :value="showCentralDepotWarning" @hideWarning="hideWarning" 
            :message="`Updating your central billing address will not change any depot billing addresses. Please ensure your depot billing addresses are correct.`"/>

            <div class="container-fluid h-100 w-100">
                <div class="alert alert-info" role="alert">
                This information is for Marketplace use only, this will not change the depot or company configuration in Gold
            </div>
                <div class="container-fluid" style="overflow-y: auto; height:calc(100vh - 95px);">
                    <h5 class="pt-1">Marketplace Password</h5>
                    <div class="p-2 mt-4 mb-4 card" style="width: 900px;">
                        <div class="card-body">
                            <div class="row w-100">
                                <div class="col-lg-3 col-md-2 col-sm-2">
                                    Current Password 
                                </div>
                                <div class="col-lg-7 col-md-5 col-sm-5">
                                    <input type="password" v-model="currentPassword" style="width: 100%;">
                                </div>
                                </div>
                            <div class="row w-100">
                                <div class="col-lg-3 col-md-2 col-sm-2">
                                    New Password 
                                </div>
                                <div class="col-lg-7 col-md-5 col-sm-5">
                                    <input type="password" v-model="newPassword" style="width: 100%;">
                                </div>
                                <div class="pl-2 mt-4 font-italic">
                                    If set, this password will be required to access any marketplace portal page
                                </div>
                                </div>
                            <div class="float-right pt-2">
                                <button type="button" class="btn btn-success" :disabled="doingUpload || inputsDisabled" @click="savePassword">Save</button>
                            </div>
                        </div>
                    </div>

                    <h5 class="pt-1">Marketplace Order Type</h5>
                     <div class="p-2 mt-4 mb-4 card" style="width: 900px;">
                        <div class="card-body">
                            <div class="row w-100">
                                <div class="col-lg-7 col-md-5 col-sm-5">
                                    <select
                                        class="custom-select"
                                        v-model="selectedOrderType">
                                        <option
                                            v-for="(option, index) in orderTypes.filter(e => e.Description != null)"
                                            :key="index+1"
                                            :value="option.Id">
                                                {{ option.Letter + " - " + option.Description }}
                                        </option>
                                    </select>
                                </div>
                                <div class="font-italic pt-4">
                                    Select the order type to apply to your marketplace generated purchase orders.
                                </div>
                            </div>
                            <div class="float-right pt-2">
                                <button type="button" class="btn btn-success" :disabled="doingUpload || inputsDisabled" @click="saveOrderType">Save</button>
                            </div>
                        </div>
                    </div>

                    <h5 class="pt-1">Company Information</h5>
                    <div class="p-2 card" style="width: 900px;">
                        <div class="card-body">
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Company Name
                                </div>
                                <div class="col-lg-7 col-md-5 col-sm-5">
                                    <input :disabled="true" type="text" v-model="profile.name" style="width: 100%;">
                                </div>
                            </div>
                            <br/>
                            <b> Central Billing Address </b>
                            <div class="font-italic pt-4">
                                Complete only if your head office pays supplier invoices on behalf of other depots.
                            </div>
                                <div class=" pt-4 row w-100">
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        Address Line 1
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <input v-model="marketplaceConfig.SharedBillingAddressLine1" type="text" style="width: 100%;" >
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        Address Line 2
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <input v-model="marketplaceConfig.SharedBillingAddressLine2" type="text" style="width: 100%;" >
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        Address Line 3
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <input v-model="marketplaceConfig.SharedBillingAddressLine3" type="text" style="width: 100%;" >
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        Address Line 4
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <input v-model="marketplaceConfig.SharedBillingAddressLine4" type="text" style="width: 100%;" >
                                    </div>
                                </div>
                                <div class="row w-100">
                                    <div class="col-lg-2 col-md-2 col-sm-2">
                                        Post Code
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5">
                                        <input v-model="marketplaceConfig.SharedBillingPostCode" type="text" style="width: 100%;" >
                                    </div>
                                </div>
                            <div class="float-right pt-2">
                                <button type="button" class="btn btn-secondary" :disabled="doingUpload || inputsDisabled" @click="refreshConfig">Cancel</button>&nbsp;
                                <button type="button" class="btn btn-success" :disabled="doingUpload || inputsDisabled" @click="saveConfiguration">Save</button>
                            </div>

                        </div>

                    </div>
                    <br>
                    <h5>Depot Information</h5>
                
                    <div v-for="(depot, depotIndex) in serverDepots" class="p-2 card" style="width: 900px; " :key="depotIndex">
                        <div class="card-title" style="margin-bottom: 0.2rem;">
                            Depot {{localDepots[depotIndex].Id}} - {{localDepots[depotIndex].ShortName}}
                        </div>

                        <div class="card-body" style="padding-top: 0.1rem;">
                            <div class="custom-control custom-switch" >
                                <div class="float-left">
                                    <input :disabled="inputsDisabled" @change="toggleEnabled(depot)" v-model="depot.marketplaceEnabled" type="checkbox" class="custom-control-input" :id="`enable` + depot.depotId">
                                    <label class="custom-control-label" :for="`enable` + depot.depotId">Marketplace Enabled</label>
                                </div> <br/> <br/>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <h5> Marketplace </h5>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">

                                </div>

                            <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferAll(depotIndex)" > <i class="fas fa-fw fa-angle-double-left"></i> </button>
                                </div> 
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <h5> Gold Record </h5> 
                                </div>
                            </div>
                            
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Display name
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input :disabled="inputsDisabled" type="text" :id="'depot'+depot.id+'servername'" v-model="depot.displayName" style="width: 100%;">
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferDisplayName(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localname'" v-model="localDepots[depotIndex].DisplayName" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Contact name
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'servercontact'" v-model="depot.contactName" style="width: 100%;">
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferContactName(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localcontact'" v-model="localDepots[depotIndex].ContactName" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Phone number
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serverphone'" v-model="depot.contactPhone" style="width: 100%;">
                                </div>
                            <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferPhoneNumber(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localphone'" v-model="localDepots[depotIndex].ContactPhone" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Email address
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveremail'" v-model="depot.contactEmail" style="width: 100%;">
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferEmailAddress(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localemail'" v-model="localDepots[depotIndex].ContactEmail" style="width: 100%;">
                                </div>
                            </div>
                            <br/>
                            <b> Delivery Address </b>
                            <br/>
                            <br/>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 1
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr1'" v-model="depot.deliveryAddress1" style="width: 100%;" >
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferAddress1(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localaddr1'" v-model="localDepots[depotIndex].Address1" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 2
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr2'" v-model="depot.deliveryAddress2" style="width: 100%;" >
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferAddress2(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localaddr2'" v-model="localDepots[depotIndex].Address2" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 3
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr3'" v-model="depot.deliveryAddress3" style="width: 100%;" >
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferAddress3(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localaddr3'" v-model="localDepots[depotIndex].Address3" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 4
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr4'" v-model="depot.deliveryAddress4" style="width: 100%;" >
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferAddress4(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localaddr1'" v-model="localDepots[depotIndex].Address4" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Post Code
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr1'" v-model="depot.deliveryPostCode" style="width: 100%;" >
                                </div>
                                <div class="col-lg-1 col-md-1 col-sm-1">
                                    <button class="bg-transparent border-0" type="text" :id="'depot'+depot.id+'transfername'" v-on:click="transferPostCode(depotIndex)" > <i class="fas fa-fw fa-angle-left"></i> </button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                    <input type="text" disabled="true" :id="'depot'+depot.id+'localaddr1'" v-model="localDepots[depotIndex].PostCode" style="width: 100%;">
                                </div>
                            </div>
                            <br/>
                            <b> Billing Address </b>
                            <br/><br/> 
                            <button v-on:click="transferDeliveryAddressToDepotBillingAddress(depot)" style="background:none; text-decoration: underline; border:none; color:grey;">Copy Delivery Address</button>|
                            <button v-on:click="transferCentralAddressToDepotBillingAddress(depot)" style="background:none; text-decoration: underline; border:none; color:grey;">Copy Central Address</button>|
                            <button v-on:click="clearDepotBillingAddress(depot)" style="background:none; border:none; text-decoration: underline; color:grey;" >Clear</button> <br/> <br/>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 1
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr1'" v-model="depot.billingAddress1" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 2
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr2'" v-model="depot.billingAddress2" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 3
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr3'" v-model="depot.billingAddress3" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Address Line 4
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr4'" v-model="depot.billingAddress4" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Post Code
                                </div>
                                <div class="col-lg-5 col-md-5 col-sm-5">
                                    <input type="text" :disabled="inputsDisabled" :id="'depot'+depot.id+'serveraddr1'" v-model="depot.billingPostCode" style="width: 100%;">
                                </div>
                            </div>
                            <br/>
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    Delivery Terms
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-10">
                                    <textarea :disabled="inputsDisabled" type="text" :id="'depot'+depot.id+'delterm'" v-model="depot.deliveryTerms" style="width: 100%;"/>
                                </div>
                            </div>
                            <div class="float-right pt-2">
                                <button type="button" class="btn btn-secondary" :disabled="doingUpload || inputsDisabled" @click="refreshDepot(depot)">Cancel</button>&nbsp;
                                <button type="button" class="btn btn-success" :disabled="doingUpload || inputsDisabled" @click="saveDepot(depot)">Save</button>
                            </div>

                        </div>
                    </div>
                    <br>
        
                </div>
            </div>
        </div>
        <div v-show="loading">
            <ib-loading-spinner 
                message="Loading Profile.."
                class="flex-grow-1"
            />
        </div>
        <div v-show="!loading && uploadInProgress">
            <ib-loading-spinner 
                    message="Listings Upload in progress."
                    class="flex-grow-1"
                />
                <center> You can't use this page until its finished. </center>
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p>
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</template>
<script>
import DepotApi             from '@WS/api/depot';
import OrderTypeApi         from '@WS/api/order-type'
import ConfigApi            from '@MP/api/config';
import ClientApi            from '@MP/api/client';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';
import IbYesNoCancelModal   from '@/components/utilities/IbYesNoCancelModal';
import IbWarningModal       from '@/components/IbWarningModal';
import LicenceMixin         from '@/mixins/LicenceMixin';
import MarketplacePasswordModal from '@MP/components/MarketplacePasswordModal';
import { DocStoreOptions }  from '@/common/docstore-options';

const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE
];

export default {
    name: 'MarketplaceProfile',

mixins: [
        LicenceMixin,
    ],

components: {
        IbLoadingSpinner,
        IbYesNoCancelModal,
        IbWarningModal,
        MarketplacePasswordModal
    },

    data() {
        return {
            showPasswordModal : true,
            localDepots      : [],
            serverDepots      : [],
            doingUpload     : false, 
            prefixes    : [],
            profile     : null,
            loading     : true,
            inputsDisabled : true,
            marketplaceConfig : null,
            orderTypes : [],
            selectedOrderType : null,
            centralBillingAddress: true,
            showSaveDepotConfirmation : false,
            showCentralDepotWarning : false,
            depotToSave : null,
            uploadInProgress : true,
            requiredLicenses : requiredLicenses,
            timer            : 0,
            licensedTimer    : 1,
            licenseChecks    : 0,
            currentPassword : "",

            newPassword: ""
        }
    },

    async beforeMount() {
        this.checkLicensed();
            this.licensedTimer = window.setInterval(() => {
                this.checkLicensed();
            }, 200);
    },

    created: function() {
        this.checkIsUploading();
        this.timer = window.setInterval(() => {
            this.checkIsUploading();
        }, 10000);
    },
    beforeDestroy(){
        window.clearInterval(this.licensedTimer);
        window.clearInterval(this.timer);
    },

    methods: {
        async passwordValidated(){
            await this.doCreated();
            this.showPasswordModal = false;
        },
        async doCreated() {
            await this.refresh();
        },
        async checkLicensed()
        {
            this.licenseChecks += 1;
            if (this.hasDocStoreOptions(...this.requiredLicenses))
            {
                window.clearInterval(this.licensedTimer);
            }
            if (this.licenseChecks > 5)
            {
                window.clearInterval(this.licensedTimer);
            }
        },
        async checkIsUploading() {
            try{
                let lastUploaded = await ClientApi.getLastUploaded();
                this.uploadInProgress = lastUploaded.data.dateCompleted == "0001-01-01T00:00:00";
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true)
            }
        },
        hideWarning()
        {
            this.showCentralDepotWarning = false;
        },

        enableDepotAndSave()
        {
            this.depotToSave.marketplaceEnabled = true; 
            this.serverDepots = this.serverDepots.map(e =>
                {
                    if (e.depotId == this.depotToSave.depotId)
                    {
                        e.marketplaceEnabled = true;
                    }
                    return e;
                });
            this.saveDepotWithoutValidating(this.depotToSave);
            this.showSaveDepotConfirmation = false;
        },
        keepDepotDisabledAndSave()
        {
            this.saveDepotWithoutValidating(this.depotToSave);
            this.showSaveDepotConfirmation = false;
            return; 
        },
        cancelDepotSave()
        {
            this.showSaveDepotConfirmation = false;
            this.doingUpload = false;
            return;
        },
        transferCentralAddressToDepotBillingAddress(depot)
        { 
            this.serverDepots.map(e =>
                {
                    if (e.depotId == depot.depotId)
                    {
                        e.billingAddress1 = this.marketplaceConfig.SharedBillingAddressLine1;
                        e.billingAddress2 = this.marketplaceConfig.SharedBillingAddressLine2;
                        e.billingAddress3 = this.marketplaceConfig.SharedBillingAddressLine3;
                        e.billingAddress4 = this.marketplaceConfig.SharedBillingAddressLine4;
                        e.billingPostCode = this.marketplaceConfig.SharedBillingPostCode;
                    }
                    return e;
                });
        },
        transferDeliveryAddressToDepotBillingAddress(depot)
        {
            this.serverDepots.map(e =>
                {
                    if (e.depotId == depot.depotId)
                    {
                        e.billingAddress1 = e.deliveryAddress1;
                        e.billingAddress2 = e.deliveryAddress2;
                        e.billingAddress3 = e.deliveryAddress3;
                        e.billingAddress4 = e.deliveryAddress4;
                        e.billingPostCode = e.deliveryPostCode;
                    }
                    return e;
                });
        },
        clearDepotBillingAddress(depot)
        {
            this.serverDepots.map(e =>
                {
                    if (e.depotId == depot.depotId)
                    {
                        e.billingAddress1 = ""; 
                        e.billingAddress2 = ""; 
                        e.billingAddress3 = ""; 
                        e.billingAddress4 = ""; 
                        e.billingPostCode = ""; 
                    }
                    return e;
                });
        },
        toggleEnabled(depot)
        {
            setTimeout(() => {
                console.log(depot);
                let validationIssues = this.validateDepot(depot);
                if(depot.marketplaceEnabled && validationIssues.length != 0){
                this.toast('b-toaster-top-right', 'danger', "Could not enable depot: " + validationIssues.join(', '), true);
                this.serverDepots = this.serverDepots.map(d => 
                    {
                        if (d.depotId == depot.depotId)
                        {
                            d.marketplaceEnabled = false;
                        }
                        return d;
                    });
                return;
                }
            }, 100);
        },

        validateDepot(depot)
        {
            let validationIssues = [];

            if(!depot.displayName){
                validationIssues.push("Display name cannot be empty");
            }
            else if (depot.displayName.length > 28)
            {
                validationIssues.push("Display name must not be more than 28 characters long");
            }
            if(!depot.contactName){
                validationIssues.push("Contact name cannot be empty");
            }
            else if (depot.contactName.length > 40)
            {
                validationIssues.push("Contact name must not be more than 40 characters long");
            }
            if(!depot.contactEmail){
                validationIssues.push("An email address must be supplied");
            }
            else if(depot.contactEmail.length > 55)

            {
                validationIssues.push("Email address must not be more than 55 characters long");
            }
            else if(!this.validateEmail(depot.contactEmail)){
                validationIssues.push("Email address must be valid");
            }
            if (!depot.contactPhone){
                validationIssues.push("A contact phone number must be supplied");
            }
            else if(depot.contactPhone.length > 27)
            {
                validationIssues.push("Phone number must not be more than 27 characters long");
            }
            else if(!this.validatePhone(depot.contactPhone))
            {
                validationIssues.push("Phone number must be valid");
            }
            if (depot.deliveryAddress1.length > 32){
                validationIssues.push("Address line 1 must not be more than 32 characters long");
            }
            else if (depot.deliveryAddress1.length == 0){
                validationIssues.push("Address line 1 is required"); 
            }
            if (depot.deliveryAddress2.length > 32){
                validationIssues.push("Address line 2 must not be more than 32 characters long");
            }
            else if (depot.deliveryAddress2.length == 0){
                validationIssues.push("Address line 2 is required"); 
            }
            if (depot.deliveryAddress3.length > 32){
                validationIssues.push("Address line 3 must not be more than 32 characters long");
            }
            if (depot.deliveryAddress4.length > 32){
                validationIssues.push("Address line 4 must not be more than 32 characters long");
            }
            if (!(depot.deliveryPostCode.length <= 10 && depot.deliveryPostCode.trim().length > 0 && /^[a-zA-Z0-9 ]+$/.test(depot.deliveryPostCode)))
            {
                validationIssues.push("Postcode must be valid");
            }

            if (depot.billingAddress1.length > 32){
                validationIssues.push("Billing Address line 1 must not be more than 32 characters long.");
            }
            else if (depot.billingAddress1.length == 0){
                validationIssues.push("Billing Address line 1 is required"); 
            }
            if (depot.billingAddress2.length > 32){
                validationIssues.push("Billing Address line 2 must not be more than 32 characters long.");
            }
            else if (depot.billingAddress2.length == 0){
                validationIssues.push("Billing Address line 2 is required"); 
            }
            if (depot.billingAddress3.length > 32){
                validationIssues.push("Billing Address line 3 must not be more than 32 characters long.");
            }
            if (depot.billingAddress4.length > 32){
                validationIssues.push("Billing Address line 4 must not be more than 32 characters long.");
            }
            if (!(depot.billingPostCode.length <= 10 && depot.billingPostCode.trim().length > 0 && /^[a-zA-Z0-9 ]+$/.test(depot.billingPostCode)))
            {
                validationIssues.push("Billing Postcode must be valid.");
            }
            
            return validationIssues;

        },

        //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
        validateEmail(email){
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },

        //https://stackoverflow.com/questions/29970244/how-to-validate-a-country-specific-phone-number
        validatePhone(phone){
            const re = /^[+]*[-\s\./0-9]*$/;
            return re.test(String(phone).toLowerCase());
        },

        async getDepots() {
            let me = this;
            let localDepots = await DepotApi.getAllDepots();
            this.localDepots = localDepots.data.data.map(depot => ({...depot, 
                                                                    DisplayName  : depot.ShortName,
                                                                    Id           : depot.Id,
                                                                    ContactName  : depot.ContactName, 
                                                                    ContactPhone : depot.Telephone, 
                                                                    ContactEmail : depot.Email,
                                                                    Address1     : depot.Address1,
                                                                    Address2     : depot.Address2,
                                                                    Address3     : depot.Address3,
                                                                    Address4     : depot.Address4,
                                                                    PostCode     : depot.PostCode}));

             this.localDepots.forEach(depot => {
                        if (me.serverDepots == null || me.serverDepots.Count == 0 || me.serverDepots.filter(e => e.depotId == depot.Id).length == 0)
                        {
                            me.serverDepots.push({
                                depotId: depot.Id,
                                companyId    : this.profile.id,
                                id: "",
                                displayName : "",
                                contactName: "",
                                contactPhone: "",
                                contactEmail: "",
                                deliveryAddress1: "",
                                deliveryAddress2: "",
                                deliveryAddress3: "",
                                deliveryAddress4: "",
                                deliveryPostCode: "", 
                                deliveryTerms: "",
                                billingAddress1: "",
                                billingAddress2: "",
                                billingAddress3: "",
                                billingAddress4: "",
                                billingPostCode: "",
                                marketplaceEnabled: false});
                        }
                    });

            this.localDepots = this.localDepots.sort(function(a,b){
                    if (a.Id < b.Id) {
                        return -1;
                    }
                    if (a.Id > b.Id) {
                        return 1;
                    }
                    return 0;
                });
            this.serverDepots = this.serverDepots.sort(function(a,b){
                    if (a.depotId < b.depotId) {
                        return -1;
                    }
                    if (a.depotId > b.depotId) {
                        return 1;
                    }
                    return 0;
            });
        },

        async refreshDepot(depot){
            let depots = await ClientApi.getDepots();
            depots = depots.data;
            let myDepot = depots.filter(d => {return d.depotId == depot.depotId})[0];
            this.serverDepots = this.serverDepots.map(d => {
                if (d.depotId == depot.depotId)
                {
                  return myDepot;
                }
                else
                    return d;
            });
        },

        async loadProfile(){
            let profile = await ClientApi.getProfile();
            this.profile = profile.data;
            this.inputsDisabled = false;
        },

        async loadOrderTypes(){
            try 
            {
                let types = await OrderTypeApi.getAllOrderTypes();
                this.orderTypes = types.data;
            }
            catch(err)
            {
                 this.toast('b-toaster-top-right', 'danger', err.response.data, true)
            } 
        },

        async loadConfig(){
            try 
            {
                let config = await ConfigApi.get();
                this.marketplaceConfig = config.data.data;
                this.selectedOrderType = this.marketplaceConfig.OrderType;

                if (this.marketplaceConfig.SharedBillingAddressLine1 == null)
                {
                    this.marketplaceConfig.SharedBillingAddressLine1 = "";
                }
                if (this.marketplaceConfig.SharedBillingAddressLine2 == null)
                {
                    this.marketplaceConfig.SharedBillingAddressLine2 = "";
                }
                if (this.marketplaceConfig.SharedBillingAddressLine3 == null)
                {
                    this.marketplaceConfig.SharedBillingAddressLine3 = "";
                }
                if (this.marketplaceConfig.SharedBillingAddressLine4 == null)
                {
                    this.marketplaceConfig.SharedBillingAddressLine4 = "";
                }
                if (this.marketplaceConfig.SharedBillingPostCode == null)
                {
                    this.marketplaceConfig.SharedBillingPostCode = "";
                }
            }
            catch(err)
            {
                this.marketplaceConfig = {
                    SharedBillingAddressLine1: "",
                    SharedBillingAddressLine2: "",
                    SharedBillingAddressLine3: "",
                    SharedBillingAddressLine4: "",
                    SharedBillingPostCode: ""
                }
            } 
        },

        async loadDepots(){
            let serverDepots = await ClientApi.getDepots();
            serverDepots = serverDepots.data;
            this.serverDepots = serverDepots;
        },


        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Marketplace Profile`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

        transferAll(id){
            this.transferDisplayName(id);
            this.transferContactName(id);
            this.transferPhoneNumber(id);
            this.transferEmailAddress(id);
            this.transferAddress1(id);
            this.transferAddress2(id);
            this.transferAddress3(id);
            this.transferAddress4(id);
            this.transferPostCode(id);

        },

        transferDisplayName(id){
            this.serverDepots[id].displayName = this.localDepots[id].DisplayName;
        },

        transferContactName(id){
            this.serverDepots[id].contactName = this.localDepots[id].ContactName;
        },

        transferPhoneNumber(id){
            this.serverDepots[id].contactPhone = this.localDepots[id].ContactPhone;
        },

        transferEmailAddress(id){
            this.serverDepots[id].contactEmail = this.localDepots[id].ContactEmail;
        },

        transferAddress1(id){
            this.serverDepots[id].deliveryAddress1 = this.localDepots[id].Address1;
        },
        transferAddress2(id){
            this.serverDepots[id].deliveryAddress2 = this.localDepots[id].Address2;
        },
        transferAddress3(id){
            this.serverDepots[id].deliveryAddress3 = this.localDepots[id].Address3;
        },
        transferAddress4(id){
            this.serverDepots[id].deliveryAddress4 = this.localDepots[id].Address4;
        },
        transferPostCode(id){
            this.serverDepots[id].deliveryPostCode = this.localDepots[id].PostCode;
        },
        async savePassword()
        {
            try
            {
                this.doingUpload = true;
                await ConfigApi.postPassword(this.currentPassword, this.newPassword);
                this.toast('b-toaster-top-right', 'success', "Marketplace password successfully updated.", true);
            }
            catch(error) 
            {
                console.log(error);
                this.toast('b-toaster-top-right', 'danger', 'Unable to set new marketplace password', true)
            }
            finally
            {
                this.doingUpload = false;
            }

        },
        async saveOrderType()
        {
            try
            {
                this.doingUpload = true;
                await ConfigApi.setOrderType(this.selectedOrderType);
                this.toast('b-toaster-top-right', 'success', "Marketplace Order Type successfully updated.", true);
            }
            catch(error) 
            {
                console.log(error);
                this.toast('b-toaster-top-right', 'danger', 'Unable to set Order Type', true)
            }
            finally
            {
                this.doingUpload = false;
            }

        },

        async saveConfiguration()
        {
            try
            {
                this.doingUpload = true;
                
                if (this.marketplaceConfig.SharedBillingAddressLine1.length > 32 ||
                    this.marketplaceConfig.SharedBillingAddressLine2.length > 32 || 
                    this.marketplaceConfig.SharedBillingAddressLine3.length > 32 || 
                    this.marketplaceConfig.SharedBillingAddressLine4.length > 32 )
                {
                    this.toast('b-toaster-top-right', 'danger', "Central billing address lines cannot be longer than 32 characters.", true);
                    return;
                }

                if (this.marketplaceConfig.SharedBillingAddressLine1.length < 1 ||
                this.marketplaceConfig.SharedBillingAddressLine2.length < 1  
                ) 
                {
                    this.toast('b-toaster-top-right', 'danger', "Central billing address lines 1 and 2 must be filled in.", true);
                    return;
                }

                if (!(this.marketplaceConfig.SharedBillingPostCode.length <= 10 && 
                        this.marketplaceConfig.SharedBillingPostCode.trim().length > 0 &&
                         /^[a-zA-Z0-9 ]+$/.test(this.marketplaceConfig.SharedBillingPostCode)))
                {
                    this.toast('b-toaster-top-right', 'danger', "Central billing address postcode must be valid.", true);
                    return;
                }

                await ConfigApi.post(this.marketplaceConfig);
                this.showCentralDepotWarning = true;
                this.toast('b-toaster-top-right', 'success', "Central billing address successfully updated.", true);
            }
            catch(error) 
            {
                 this.toast('b-toaster-top-right', 'danger', error.response.data, true)
            }
            finally
            {
                this.doingUpload = false;
            }

        },

        async saveDepotWithoutValidating(depot)
        {
            try
            {
                for (var key in depot) {
                    depot[key] = depot[key] ?? "";
                }  

                if (depot.id == "" || depot.id == undefined || depot.id == null) 
                {
                   let depotReturned = await ClientApi.postDepot(depot);
                   depotReturned = depotReturned.data;
                   this.serverDepots = this.serverDepots.map(e => {
                       if (e.depotId == depotReturned.depotId)
                       {
                           return depotReturned;
                       }
                       else return e;
                   });
                   
                }
                else
                {
                    await ClientApi.putDepot(depot);
                }

                this.toast('b-toaster-top-right', 'success', 'Marketplace Profile successfully updated', true)
            }
            catch(error) 
            {
                 this.toast('b-toaster-top-right', 'danger', error.response.data, true)
            }
            finally
            {
                this.doingUpload = false;
            }

        },

        async saveDepot(depot){
            try 
            {
                this.doingUpload = true;
                
                let invalid = false;
                let validationIssues = this.validateDepot(depot);

                if (validationIssues.length > 0)
                {
                    this.toast('b-toaster-top-right', 'danger', "Could not save depot " + depot.depotId + " - " + depot.displayName + ": " + validationIssues.join(', '), true);
                    this.doingUpload = false;
                    return;
                }
                if (depot.marketplaceEnabled == false)
                {
                    this.showSaveDepotConfirmation = true;
                    this.depotToSave = depot;
                    return;
                }

                for (var key in depot) {
                    depot[key] = depot[key] ?? "";
                }  
               
                if (depot.id == "" || depot.id == undefined || depot.id == null) 
                {
                   let depotReturned = await ClientApi.postDepot(depot);
                   depotReturned = depotReturned.data;
                   this.serverDepots = this.serverDepots.map(e => {
                       if (e.depotId == depotReturned.depotId)
                       {
                           return depotReturned;
                       }
                       else return e;
                   });
                   
                }
                else
                {
                    await ClientApi.putDepot(depot);
                }

                this.toast('b-toaster-top-right', 'success', 'Marketplace Profile successfully updated', true)
            }
            catch(error) 
            {
                 this.toast('b-toaster-top-right', 'danger', error.response.data, true)
            }
            finally
            {
                this.doingUpload = false;
            }
        },

        async updateProfileUrl(){
            this.profile.portalUrl = window.location.origin;
            await ClientApi.uploadProfile(this.profile);
        },

        async refreshConfig()
        {
            await this.loadConfig();
        },

        async refresh() {
            this.loading = true;
            await this.loadConfig();
            try {
                await this.loadOrderTypes();
                await this.loadProfile();
                await this.loadDepots();
                await this.updateProfileUrl();
            }
            catch(err) 
            {
                this.toast('b-toaster-top-right', 'danger', err.response.data, true);
                if (err.response.data.includes("Your marketplace profile is not yet set up"))
                {
                    this.inputsDisabled = false;
                }
                else
                {
                    this.inputsDisabled = true;
                }
                this.profile = {name: ""};
            }
            await this.getDepots();
            this.loading = false;
        }
    }
}
</script>
