<template>
    <b-modal v-model="showModal"
             size="lg"
             title="Failed Part Movement"
             :hide-footer="true"
             @hidden="onHide">

        <div class="pl-3 pr-3 d-flex flex-column"
             v-if="hasValue(issue)">
            
            <!-- ID -->
            <label class="font-weight-bold mb-0">
                ID:
            </label>
            <label class="mb-3">
                {{ issue.TransactionId }}
            </label>

            <!-- Issue -->
            <label class="font-weight-bold mb-0">
                Issue:
            </label>
            <label class="mb-3">
                {{ issue.Issue }}
            </label>

            <!-- Description -->
            <div v-if="issue.Description"
                 class="d-flex flex-column">
                <label class="font-weight-bold mb-0">
                    Description:
                </label>
                <label class="mb-3">
                    {{ issue.Description }}
                </label>
            </div>

            <!-- Resolution -->
            <div v-if="issue.Resolution"
                 class="d-flex flex-column">
                <label class="font-weight-bold mb-0">
                    Resolution:
                </label>
                <label class="mb-3">
                    {{ issue.Resolution }}
                </label>
            </div>
        </div>

        <!-- Loading display -->
        <div class="d-flex align-items-center justify-content-center"
             v-else-if="loading">

            <ib-loading-spinner message="Loading Issue..."/>
        </div>

        <div class="d-flex align-items-center justify-content-center"
             v-else>
            No details of issue found.
        </div>

        <template v-slot:modal-footer>
            <div class="row"
                 v-if="hasValue(issue)">

                <!-- Acknowledge button -->
                <b-button type="button"
                          class="btn btn-primary ml-auto mr-3"
                          @click="onAcknowledge"
                          v-if="!issue.Acknowledged && !acknowledging">
                    Acknowledge
                </b-button>

                <div v-else-if="acknowledging"
                     class="ml-auto mr-3">
                    <i class="fas fa-sync-alt fa-spin"/>
                    Acknowledging...
                </div>

                <!-- Acknowledged information -->
                <label v-else
                        class="ml-auto mr-3">
                    Acknowledged by {{ issue.AcknowledgedBy }} on {{ issue.AcknowledgedOn | formatDate(DateFormat.DATE_TIME) }}
                </label>
            </div>
        </template>
    </b-modal>
</template>

<script>
import DateMixin, { DateFormat } from '@/mixins/DateMixin';
import IbLoadingSpinner          from '@/components/IbLoadingSpinner';

/**
 * Component to display the information from a part transaction
 * issue. Allowing the user to acknowledge the issue, displaying
 * who and when acknowledged it.
 */
export default {
    name: 'TransactionIssueInfo',

    mixins: [
        DateMixin
    ],

    components: {
        IbLoadingSpinner
    },

    props: {
        issue: {
            required: false,
            type:     Object,
            default:  () => { return {}; }
        },

        loading: {
            required: false,
            type:     Boolean,
            default:  false
        },

        show: {
            required: false,
            type:     Boolean,
            default:  false
        }
    },

    data() {
        return {
            showModal: false,
            acknowledging: false
        }
    },

    methods: {
        
        /**
         * Event handler for when the modal is closed. Emits
         * message to tell parent component that the modal has
         * closed.
         */
        onHide() {
            this.$emit('hidden');
        },

        /**
         * Event handler for when the 'Acknowledged' button is
         * pressed. Sets local state to show the issue is being 
         * acknowledged and emits message to tell the parent 
         * component to acknowledge the given issue.
         */
        onAcknowledge() {
            this.acknowledging = true;
            this.$emit('acknowledging', this.issue.Id);
        },

        /**
         * Helper function to determine if the given object has
         * value.
         */
        hasValue(value) {
            return !_.isEmpty(value);
        }

    },

    watch: {

        show(newValue) {
            this.showModal = newValue;
        },

        issue(newValue) {
            if (false === _.isEmpty(newValue)) {
                this.acknowledging = newValue.acknowledging;
            }
        }

    }
}
</script>