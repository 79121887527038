import ApiService	 	from '@/common/api-service';
import RestApiService 	from '@/common/rest-api-service';
import Utility 			from '@/common/utility';

const JOB_ROOT 			= "api/v1/job";
const JOB_V2_ROOT       = "api/v2/workshop/job";
const REST_JOB_ROOT     = "rest/v1/job";
const DOCSTORE_ROOT 	= "/api/v1/docstore";
const PUBLIC_ROOT 		= `${process.env.VUE_APP_API_BASE_URL}public`;
const ALL_DEPOTS 		= '*';

const JobsApi = {

	///
    /// GET Requests
	///
	
	/**
	 * Get a job by identifier.
	 * @param {string} id 
	 */
	getJobById(id) {
		const url = `${JOB_ROOT}/${id}`;
		return ApiService.get(url);
	},

	/**
	 * Get all jobs with a status of open, live or initiated.
	 */
	getActiveJobs(depotId) {
		let url = `${JOB_ROOT}/summary/active`;
		if (depotId && (ALL_DEPOTS !== depotId)) {
			url += "/depot/" + depotId;
		}
		return ApiService.get(url);
	},

	/**
	 * Get all jobs with a status of logged for checking.
	 */
	getLoggedJobs(depotId) {
		let url = `${JOB_ROOT}/summary/logged`;
		if (depotId && (ALL_DEPOTS !== depotId)) {
			url += "/depot/" + depotId;
		}
		return ApiService.get(url);
	},

	/**
	 * Search for jobs that match the specified search term.
	 * @param {string} term 
	 */
	searchJobs(term) {
        const url = `${JOB_ROOT}/quicksearch/${term}`;

		return ApiService.get(url);
	},

	/**
	 * Get a transaction issue by ID.
	 * @param {number} id 
	 */
	getTransactionIssue(id){
		const url = `${JOB_ROOT}/part/transaction/${id}/issue/`;
		return ApiService.get(url);
	},

	/**
	 * Search for job summaries that match the specified search term.
	 * @param {string} term -  Search term to find.
	 * @param {string} depotId - Limit jobs to those belonging to this depot.
	 * @param {string} engineerId - Limit jobs to those assigned to this engineer.
	 * @param {string} status - Limit jobs to those with this status.
	 * @param {string} fromDate - Limit jobs to those with an order date after this.
	 * @param {string} toDate - Limit jobs to those with an order date before this.
	 */
	searchJobSummaries(
		term,
		depotId		= null,
		engineerId	= null,
		status		= null,
		fromDate	= null,
		toDate		= null
	) {
		// Search for all jobs if no term is provided.
		if (true === Utility.isNullOrEmpty(term)) {
			term = '*';
		}
		// Pass filters as query parameters.
		const config = {
			params: {
				depot:		depotId,
				engineer:	engineerId,
				status:		status,
				fromDate:	fromDate,
				toDate:		toDate
			}
		};
		const url = `${JOB_ROOT}/summary/search/${term}`;
		return ApiService.get(url, config);
	},

	/**
	 * Get activity history for a job.
	 * @param {string} jobId 
	 */
	getHistory(jobId) {
		const url = `${JOB_ROOT}/${jobId}/history`;
		return ApiService.get(url);
	},

    /**
     * Get all job activities for a specified job.
     * 
     * @param {string} jobId - The ID of the job.
     */
    getActivities(jobId) {
		const url = `${JOB_ROOT}/${jobId}/activities`;
		return ApiService.get(url);
	},

	/**
	 * Get all completion records for a specified job.
	 * 
	 * @param {string} jobId - The ID of the job.
	 */
	getCompletionRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/tasks`;
		return ApiService.get(url);
	},

	/**
	 * Get all engineers associated to the job ID.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getEngineersForJob(jobId) {
		const url = `${JOB_ROOT}/${jobId}/engineers`;
		return ApiService.get(url);
	},

	/**
	 * Get all mileage records associated to the job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getMileageRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/mileage`;
		return ApiService.get(url);
	},

	/**
	 * Get all notes records associated to the job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getNotesRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/note`;
		return ApiService.get(url);
	},

	/**
	 * Get all the serial number records associated to
	 * the job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getSerialNumberRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/serial_number`;
		return ApiService.get(url);
	},

	/**
	 * Get all signature records associated to the job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getSignatureRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/signature`;
		return ApiService.get(url);
	},

	/**
	 * Get all clock hours records associated to the job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getClockHoursRecords(jobId) {
		const url = `${JOB_ROOT}/${jobId}/wholegood_hours`;
		return ApiService.get(url);
	},

	/**
	 * Get all comments added to a job.
	 * 
	 * @param {string} jobId - the job ID.
	 */
	getComments(jobId) {
		const url = `${JOB_ROOT}/${jobId}/comment`;
		return ApiService.get(url);
	},

	///
    /// PUT Requests
    ///
	/**
	 * Update the story for the specified job.
	 * @param {Job} job - The job to update.
	 */
	updateStory(job) {
		const url = `${JOB_ROOT}/${job.Id}/story`;
		return ApiService.put(url, job);
	},
	/**
	 * Update the parts used for the specified job.
	 * @param {Job} job - The job to update.
	 */
	updateParts(job) {
		const url = `${JOB_ROOT}/${job.Id}/parts`;
		return ApiService.put(url, job);
	},
	/**
	 * Update the mileage for the specified job.
	 * @param {Job} job - The job to update.
	 */
	updateMileage(job) {
		const url = `${JOB_ROOT}/${job.Id}/mileage`;
		return ApiService.put(url, job);
	},
	/**
	 * Update the clock hours for the specified job.
	 * @param {Job} job - The job to update.
	 */
	updateHours(job) {
		const url = `${JOB_ROOT}/${job.Id}/hours`;
		return ApiService.put(url, job);
	},
	/**
	 * Update the wholegood on the specified job.
	 * @param {Job} job - The job to update.
	 */
	updateWholegood(job) {
		const url = `${JOB_ROOT}/${job.Id}/wholegood`;
		return ApiService.put(url, job);
	},
	/**
	 * Acknowledge the given transaction issue.
	 * @param {Number} id 
	 */
	acknowledgeTransactionIssue(id) {
		const url = `${JOB_ROOT}/part/transaction/issue/${id}/acknowledge`;
		return ApiService.put(url, {});
	},
	
	///
    /// POST Requests
    ///
	/**
	 * Set job status to logged for checking.
	 * @param {Job} job - The job to complete.
	 */
	completeJob(job) {
		const url = `${JOB_ROOT}/${job.Id}/complete`;
		return ApiService.post(url, job);
	},

	/**
	 * Upload the work done record for the job specified.
	 * @param {string} jobId - The job ID.
	 * @param {Object} workDone - The Work Done record.
	 */
	setWorkDone(jobId, workDone) {
		const url = `${JOB_ROOT}/${jobId}/work_done`;
		return ApiService.post(url, workDone);
	},

	///
	/// Job Activity Management
	///
	/**
	 * Delete a job activity.
	 * @param {object} jobActivity - Job Activity to delete.
	 */
	deleteJobActivity(jobActivity) {
		const url = `${JOB_ROOT}/activity/${jobActivity.Id}`;
		return ApiService.delete(url, jobActivity);
	},

	///
	/// Document Management
	///
	getUnapprovedDocuments(id) {
		const url = `${JOB_ROOT}/${id}/document/unapproved`;
		return ApiService.get(url);
	},

	getApprovedDocuments(id) {
		const url = `${JOB_ROOT}/${id}/document/list`;
		return ApiService.get(url);
	},

	getImageUriForUnapprovedDoc(docId){
		return `${PUBLIC_ROOT}/docstore/queue/${docId}`;		
	},

	getImageUriForApprovedDoc(docId){		
		return `${PUBLIC_ROOT}/docstore/${docId}`;		
	},

	deleteUnapprovedDocument(jobId, docId){
		const url = `${JOB_ROOT}/${jobId}/document/${docId}`;
		return ApiService.delete(url);
	},

	deleteApprovedDocument(docId){
		const url = `${DOCSTORE_ROOT}/${docId}`;
		return ApiService.delete(url);
	},

	approveDocument(jobId, docId){
		const url = `${JOB_ROOT}/${jobId}/document/${docId}/approve`;
		return ApiService.post(url);
	},

	sendJobDocumentToDocStore(jobId, document) {
		const url = `${DOCSTORE_ROOT}/workshop/${jobId}`;
		return ApiService.post(url, document);
	},

	/**
	 * Get all parts for the job ID specified.
	 * @param {string} jobId 
	 */
	getParts(jobId) {
		const url =`${JOB_ROOT}/${jobId}/part/list`;
		return ApiService.get(url);
	},

	/**
	 * Get all part transactions for the job ID specified.
	 * @param {string} jobId | The job ID.
	 */
	getPartTransactions(jobId) {
		const url = `${JOB_ROOT}/${jobId}/part/transaction/list`;
		return ApiService.get(url);
	},

	/**
	 * Get all labour lines for this job.
	 * @param {string} jobId 
	 */
	getLabour(jobId){
		const url =`${JOB_ROOT}/${jobId}/labour`;
		return ApiService.get(url);
	},

	getJobRequests() {
		const url =`${JOB_ROOT}/requests`;
		return ApiService.get(url);
	},

	fetchNewRequests() {
        const url =`${REST_JOB_ROOT}/requests/fetchnewrequests`;
        return RestApiService.get(url);
	},

	getJobRequestsAwaitingAction() {
		const url =`${JOB_ROOT}/requests/awaitingaction`;
		return ApiService.get(url);
	},
	getJobRequestsReceived() {
		const url =`${JOB_ROOT}/requests/received`;
		return ApiService.get(url);
	},
	createJob(jobRequest) {
		const url = `${JOB_ROOT}/requests/createjob/${jobRequest.Id}`;
		return ApiService.post(url, jobRequest);
	},
	deleteJob(jobRequest, notify) {
		let url = `${JOB_ROOT}/requests/deletejob/${jobRequest.Id}?notify=${notify}`;
		return ApiService.post(url, jobRequest);
	},
	getJobRequestSummaries(selectedLabels) {
		const url =`${JOB_ROOT}/requests/summary`;
		return ApiService.post(url,selectedLabels);
	},
	getJobRequestsForWholegood(jobId) {
		const url = `${JOB_ROOT}/requests/wholegood/${jobId}`;
		return ApiService.get(url);
	},
	getJobPriority() {
		const url = `${JOB_ROOT}/priority`;
		return ApiService.get(url);
	},	
	getJobRequestsConfiguration() {
		const url = `${JOB_ROOT}/requests/configuration`;
		return ApiService.get(url);
	},
	setJobRequestsConfiguration(settings) {
		const url = `${JOB_ROOT}/requests/configuration`;
		return ApiService.post(url, settings);
	},
	///
	/// PATCH Requests
	///

	/**
	 * Make request to the API to update the state of the given job ID.
	 * @param {string} jobId - The job ID.
	 * @param {int} stateId - The ID of the state.
	 */
	updateJobState(jobId, stateId) {
		const url = `${JOB_ROOT}/${jobId}/sub-state`;
		return ApiService.patch(url, { stateId });
	},

	/**
	 * Set the specified job to a status of 'Logged'.
	 * @param {string} jobId - the job ID.
	 */
	markJobComplete(jobId) {
		const url = `${JOB_V2_ROOT}/${jobId}/complete`;
		return ApiService.patch(url);
	},

	/**
	 * Make request to the API to update the story on a job.
	 * 
	 * @param {String} jobId - The job ID.
	 * @param {String} story - The job story.
	 */
	updateJobStory(jobId, story) {
		const url = `${JOB_ROOT}/${jobId}/story`;
		return ApiService.patch(url, { story });
	},

	/**
	 * Make request to the API to update the clock hours on a job.
	 * 
	 * @param {String} jobId - The job ID.
	 * @param {String} clockHours - The job clock hours.
	 */
	updateClockHours(jobId, clockHours) {
		const url = `${JOB_ROOT}/${jobId}/wholegood_hours`;
		return ApiService.patch(url, { clockHours });
	},

	/**
	 * Make request to the API to update the mileage on a job.
	 * @param {String} jobId - The job ID.
	 * @param {String} mileage - The mileage value.
	 */
	updateJobMileage(jobId, mileage) {
		const url = `${JOB_ROOT}/${jobId}/mileage`;
		return ApiService.patch(url, { mileage });
	},
	getJobRequestLabels() {
		const url = `${JOB_ROOT}/requests/jobrequestlabels`;
		return ApiService.get(url);
	}
};

export default JobsApi;
