<template>

    <b-btn class="rounded-0" variant="link" v-b-toggle="collapseId">
		<i class="when-opened fas fa-caret-down fa-2x"></i>
		<i class="when-closed fas fa-caret-right fa-2x"></i>
	</b-btn>    

</template>

<script>
export default {
    props: {
        collapseId: {
            type:     String,
            required: true
        }
    }
}
</script>

<style scoped>

button, button:hover, button:focus {
	background-color: transparent;
	color: black;
	border-style: none;
	border-right-style: solid;
	border-right-width: 4px;
	text-align: left;
	padding: 0px;
	width: 28px;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
	display: none;
}

</style>
