<template>
<div>
    <ib-modal v-model="showDeliveryAddressModal"
                        modalClass="col-6 p-2"
                        :dismissable="true"
                        :isSecondOrder="true">
        <div>
            <h5 class="card-title">{{order.serverInfo.sellerCompanyName}} - {{order.serverInfo.sellerDepotName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted">Delivery Address Details</h6>
            <div class="card-body">
            <div class="row">
                <div class= "col-2">
                    Contact name:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactName}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Email:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactEmail}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Telephone:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerContactPhone}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 1:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine1}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 2:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine2}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 3:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine3}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Address Line 4:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryAddressLine4}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    Postcode:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerDeliveryPostcode}}
                </div>
            </div>
            <div class="row">
                <div class= "col-2">
                    <h5> &nbsp; </h5>
                </div>
            </div>
            <div class="row">
                <div class= "col-8">
                    <h6> 
                    Delivery Terms:
                    </h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pre-formatted">
                    <textarea disabled style="height: 330px; width: 100%;" v-model="order.serverInfo.deliveryTerms">
                    </textarea>
                </div>
            </div>
            
            </div>
        </div>
    </ib-modal>
            
        <ib-modal
            v-model="show"
            modalClass="col-4"
            :dismissable="true"
            >
                
            <div>
                <h5>Generate Purchase Order</h5>
            </div>
            <div style="overflow-y: fixed; height:25em">
            <div style="width:90%">
            <div class="row">
                <div class="col-4 font-weight-bold">
                    Part:
                </div>
                <div class="col-8">
                    {{order.partNumberForDisplay}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 font-weight-bold">
                    Quantity:
                </div>
                <div class="col-8">
                    {{order.serverInfo.quantity}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 font-weight-bold">
                    Buying Depot:
                </div>
                <div class="col-8">
                    {{order.serverInfo.buyerDepotNumber}} - {{order.serverInfo.buyerDepotName}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 font-weight-bold">
                    Total Price:
                </div>
                <div class="col-8">
                    {{order.serverInfo.quantity * order.serverInfo.price}}
                </div>
            </div>
            <div class="row">
                <div class="col-4 font-weight-bold">
                    Seller:
                </div>
                <div class="col-8">
                    {{order.serverInfo.sellerCompanyName}}
                </div>
            </div>
            <div class="row pt-4">
                <div class="col-4 font-weight-bold">
                    Supplier Account:
                </div>
                <div class="col-8">
                    <rs-dropdown
                            :ref="'supplierDropdown'"
                            :options="supplierAccounts"
                            v-on:selected="supplierAccountSelected"
                            :maxItem="100000"
                            placeholder="Select a Supplier Account">
                        </rs-dropdown>
                </div>
            </div>
            <div class="row pt-4">
                    <div class="col-4 ">
                        <span class="font-weight-bold" > Delivery Address: </span> 
                    </div>
                    <div class="col-8">
                    {{order.serverInfo.sellerDeliveryPostcode}} <u v-on:click="showDeliveryAddressModal = true" class="clickable-text"> <i> show more </i> </u>
                    </div>
            </div>
            <div class="row pt-5">
                <div class="col-12 font-italic">
                    Delivery &amp; billing address will be taken from your marketplace profile.
                </div>
            </div>
                        
            </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn btn-success" :disabled="!formValid" v-on:click="generate">Generate Purchase Order</button>
            </div>
        </ib-modal>
    </div>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';
import RsDropdown       from '@/components/form/RomainSimonDropdown';
import ClientApi        from '@MP/api/client';

export default {
    name: 'PurchaseOrderModal',
    
    components: {
        RsDropdown,
        IbModal
    },

    created() {
    },

    data() {
        return {
            show: false,
            showDeliveryAddressModal: false,
            selectedSupplier: {},
            formValid: false
        }
    },

    computed: {
      
    },

    props: {
        order: {
            required : true,
            type     : Object
        },
        value: {
            required : true,
            type     : Boolean
        },
        supplierAccounts: {
            required : true,
            type     : Array
        }
    },

    methods: {
       async generate()
       {
           this.$emit('generatePurchaseOrder', {order: this.order, supplier: this.selectedSupplier});
       },
       supplierAccountSelected(selection, name)
        {
            if(selection.option != undefined) {
                this.selectedSupplier = selection.option.id;
                this.formValid = true;
            }
        },
    },

    watch: {

        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
  
    }
    
}
</script>