<template>
    <div>
        <ib-modal 
            v-model="showRemoveFromOrderModal"
            modalClass="w-25 p-2"
            :dismissable=false
            :isSecondOrder=true    
        >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i class="fas fa-exclamation-triangle ib-modal-info-icon"></i> Remove Part </h3>
                Remove line '{{partForRemovalFromOrder.partNumber}}' from seller {{partForRemovalFromOrderSeller}} from order suggestion and basket?
            </div>
            <hr>
            <div class = "row">
                <div class = "col-12 text-right pr-2">                
                    <button class="btn btn-secondary" @click="showRemoveFromOrderModal = false">Cancel</button> &nbsp;
                    <button class="btn btn-danger" @click="executeRemoveFromOrder()">Remove</button> &nbsp;
                </div>
            </div>
        </div>
        </ib-modal>

        <ib-modal v-model="showDeliveryTermsModal"
                        modalClass="col-6"
                        :dismissable="true"
                        :isSecondOrder="true">
        <div>
            <h5 class="card-title">Seller {{selectedSeller.id}} Delivery Terms</h5>
                <div class="card-body">
                    <div class="row">
                        <textarea disabled style="height: 330px; width: 100%;" v-model="selectedSellerTerms">
                        </textarea>
                    </div>
                </div>
        </div>
        </ib-modal>
        <ib-modal :isUnescapable=true v-model="showModal" :basket="basket" modalClass="col-7" :dismissable=false>
            
            <div v-if="!suggestedOrdersLoading">
            <div v-if="stage==0" class="container-fluid">
                <div class="row p-2">
                    <h3>Configure Marketplace Stock Order</h3>
                </div>
                Step 1 of 2
                <hr>
                <div class="row">
                    <div class="col-4">

                    </div>
                    <div class="col-4 font-weight-bold">
                        Lines
                    </div>
                    <div class="col-4 font-weight-bold">
                        Total Quantity
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Parts:
                    </div>
                    <div class="col-4">
                        {{basket.length}}
                    </div>
                    <div class="col-4">
                        {{basketParts}}
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Missing prefixes:
                    </div>
                    <div class="col-4">
                        <span :class="missingPrefixLine>0?'badge badge-danger':''">
                            {{missingPrefixLine}}
                        </span>
                    </div>
                    <div class="col-4">
                        <span :class="missingPrefixLine>0?'badge badge-danger':''">
                            {{missingPrefix}}
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4 font-weight-bold">
                        Missing part records:
                    </div>
                    <div class="col-4">
                        <span :class="missingPartsLine > 0 ? 'badge badge-danger':''">
                            {{missingPartsLine}}
                        </span>
                    </div>
                    <div class="col-4">
                        <span :class="missingPartsLine > 0 ? 'badge badge-danger':''">
                            {{missingParts}}
                        </span>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-4">
                        Fulfill order by:
                    </div>
                    <div class="col-6 pb-2">
                        <select class="custom-select" v-model="selectedFulfillOrder">
                            <option v-for="(option, index) in fulfillOrderList" :key="index" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        Partially fulfill lines?:
                    </div>
                    <div class="col-6 pb-2">
                        <select class="custom-select" v-model="selectedPartialLines">
                            <option v-for="(option, index) in partialLinesList" :key="index" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-4">
                        Currency:
                    </div>
                    <div class="col-6 pb-2">
                        <select class="custom-select" v-model="selectedCurrency">
                            <option v-for="(option, index) in currencyList" :key="index" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                    <div class="text-right button-separator pr-2 pt-3">
                        <button class="btn btn-danger" @click="showModal = false">Cancel</button>
                        <button class="btn btn-success" @click="doGetOrderSuggestion">Continue</button> &nbsp;
                        &nbsp;
                    </div>
            </div>
            <div v-if="stage==1" class="container-fluid" style="height: 100%;">
                <div class="row p-2 pt-4">
                    <h3>Marketplace Stock Order</h3>
                </div>
                Step 2 of 2
                <div class="row p-2 pt-4 pb-4">
                    <b class="pr-1">Full lines available:</b> {{linesFulfilled}}/{{basket.length}} &nbsp;
                    | <b class="pl-1 pr-1"> &nbsp; Items available:</b> &nbsp; {{suggestedOrder.reduce((p,n) => p+n.totalQuantity, 0)}}/{{basket.reduce((p,n) => p+n.number, 0)}}
                    | <b class="pl-1 pr-1"> &nbsp; Configured by: </b> &nbsp; {{selectedFulfillOrder == 0 ? "Single Seller" : "Lowest Price"}} / {{selectedPartialLines == 0 ? "Partial Lines Allowed" : "Complete Lines Only"}}
                </div>
                <div id="accordion" role="tablist" class="modal-scroll-bar">
                    <div v-for="(seller, index) in groupedOrders" :key="index">
                        <div :id="`sellerParts` + seller.id">
                            <div class="card-header stickyHeader">
                                <div class="row">
                                    <div class="col-lg-1">
                                        <a @click="seller.isExpanded = !seller.isExpanded" class="link"
                                            data-toggle="collapse" :href="'#sellerParts' + seller.id + 'body'"
                                            aria-expanded="false" aria-controls="ruleset">
                                            <div v-show="!seller.isExpanded"><i class="fas fa-chevron-right fa-lg"></i>
                                            </div>
                                            <div v-show="seller.isExpanded"><i class="fas fa-chevron-down fa-lg"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-8">
                                        <span style="font-weight:bold;">
                                            Seller Number : {{seller.id}}
                                        </span>
                                        - {{seller.lines.length}} lines / {{seller.lines.reduce((p, l) =>
                                        p+l.totalQuantity, 0)}} items will be requested
                                    </div>
                                    <div class="col-lg-3 text-right">
                                    <a href="#" v-on:click="showTerms(seller)"> Delivery Terms </a>     
                                    </div>
                                </div>
                            </div>
                        <div class="collapse" :id="'sellerParts' + seller.id + 'body'">
                            <div class="card-body">
                            <ib-table
                                :items="seller.lines"
                                :columns="suggestedOrderColumns"
                                :small=true
                                loadingMessage="Loading Suggested Orders..."
                                noItemsMessage="No Records Found"
                                :itemsPerPage="15">
                                <template
                                    slot="removeFromOrder"
                                    slot-scope="data">
                                    <button style="font-weight:bold; color:red; background:none; border: none; padding: 0;" v-on:click="removeFromOrder(data, seller.id)" title="Remove line from order">X</button> &nbsp;
                                </template>
                                <template
                                    slot="listingPrice"
                                    slot-scope="data">
                                    <div class="text-right pr-5">
                                         {{data.item.currency | getCurrencySymbolForCode()}}{{data.item.listingPrice | getCurrencyValue()}}
                                    </div>
                                    </template>
                                <template
                                    slot="totalPrice"
                                    slot-scope="data">
                                    <div class="text-right pr-5">
                                         {{data.item.currency | getCurrencySymbolForCode()}}{{data.item.totalPrice | getCurrencyValue()}}
                                    </div>
                                    </template>
                                <template
                                    slot="dealerNett"
                                    slot-scope="data">
                                    <div class="text-right pr-5">
                                        <i v-if="data.item.dealerNett < data.item.listingPrice && localCurrency.Currency == data.item.currency" class="fas fa-fw fa-lg fa-info-circle ib-modal-info-icon" title="Listing price is greater than Dealer Nett"></i>
                                         {{localCurrency.Currency | getCurrencySymbolForCode()}}{{data.item.dealerNett | getCurrencyValue()}}
                                    </div>
                                    </template>
                            </ib-table>
                            </div>
                        </div>
                    </div> 
                    </div>
                        <div v-if="unfulfilledLines.length > 0" class="card-header stickyHeader">
                                <div class="row">
                                    <div class="col-lg-1">
                                        <a @click="unfulfilledPartsExpanded = !unfulfilledPartsExpanded" class="link"
                                            data-toggle="collapse" :href="'#unfulfilledPartsBody'"
                                            aria-expanded="false" aria-controls="ruleset">
                                            <div v-show="!unfulfilledPartsExpanded"><i class="fas fa-chevron-right fa-lg"></i>
                                            </div>
                                            <div v-show="unfulfilledPartsExpanded"><i class="fas fa-chevron-down fa-lg"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-11">
                                        <span style="font-weight:bold;">
                                            Unavailable {{selectedFulfillOrder == 0 ? '' : 'from marketplace'}} 
                                        </span>
                                        - {{unfulfilledLines.length}} lines / {{unfulfilledLines.reduce((p, l) =>
                                        p+l.number, 0)}} items will not be requested
                                    </div>
                                    <div class="col-lg-1">
                                    </div>
                                    <div v-if="selectedFulfillOrder == 0 && selectedPartialLines == 1" class="col-lg-11">
                                        <i>Lower quantity may be available with partial line fulfilment</i>
                                    </div>
                                    <div v-if="selectedFulfillOrder == 1 && selectedPartialLines == 1" class="col-lg-11">
                                        <i>Lower quantity may be available, configure order with partial line fulfilment</i>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" :id="'unfulfilledPartsBody'">
                            <div class="card-body">
                            <ib-table
                                :items="unfulfilledLines"
                                :columns="unfulfilledOrderColumns"
                                :small=true
                                loadingMessage="Loading Suggested Orders..."
                                noItemsMessage="No Records Found"
                                :itemsPerPage="15">
                            </ib-table>
                            </div>
                        </div>
                    </div>
                    <div class="text-right button-separator pr-2 pt-3">
                        <button class="btn btn-danger" @click="stage = 0; recalculateFirstPage();">Back</button>
                        <button class="btn btn-success" @click="orderParts">Order Parts</button> &nbsp;
                        &nbsp;
                    </div>
                </div>
            <div v-if="stage==2" class="container-fluid" style="height: 100%;">
                <div class="row p-2 pt-4">
                    <h3>Order Complete</h3>
                </div>
                <div class = "pb-5">
                    Successfully requested <b> {{orderSummary.length}} lines / {{orderSummary.reduce((p,n) => p+n.totalQuantity, 0)}} items </b> 
                    from <b> {{orderSummary.map(item => item.sellerId).filter((value, index, self) => self.indexOf(value) === index).length}} seller locations. </b> 
                    View your orders on your Purchases page.
                </div>
                <div id="accordion" role="tablist" class="modal-scroll-bar"> 
                    <div class="card-header stickyHeader">
                                <div class="row">
                                    <div class="col-lg-1">
                                        <a @click="fulfilledPartsExpanded = !fulfilledPartsExpanded" class="link"
                                            data-toggle="collapse" :href="'#fulfilledPartsBody'"
                                            aria-expanded="true">
                                            <div v-show="!fulfilledPartsExpanded"><i class="fas fa-chevron-right fa-lg"></i>
                                            </div>
                                            <div v-show="fulfilledPartsExpanded"><i class="fas fa-chevron-down fa-lg"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-11">
                                        <span style="font-weight:bold;">
                                            Parts requested 
                                        </span>
                                        ({{orderSummary.length}} lines / {{orderSummary.reduce((p,n) => p+n.totalQuantity, 0)}} items)
                                    </div>
                                </div>
                            </div>
                    
                <div class="collapse" :id="'fulfilledPartsBody'">
                            <div class="card-body">
                            <ib-table
                                :items="orderSummary"
                                :columns="fulfilledOrderColumns"
                                :small=true
                                loadingMessage="Loading fulfilled Orders..."
                                noItemsMessage="No Records Found"
                                :itemsPerPage="15">
                                    <template
                                        slot="listingPrice"
                                        slot-scope="data">
                                        <div class="text-right pr-5">
                                             {{data.item.currency | getCurrencySymbolForCode()}}{{data.item.listingPrice | getCurrencyValue()}}
                                        </div>
                                    </template>
                                    <template
                                        slot="totalPrice"
                                        slot-scope="data">
                                        <div class="text-right pr-5">
                                             {{data.item.currency | getCurrencySymbolForCode()}}{{data.item.totalPrice | getCurrencyValue()}}
                                        </div>
                                    </template>
                            </ib-table>
                            </div>
                        </div>

                            <div v-if="unorderedLines.length > 0" class="card-header stickyHeader">
                                <div class="row">
                                    <div class="col-lg-1">
                                        <a @click="unorderedPartsExpanded = !unorderedPartsExpanded" class="link"
                                            data-toggle="collapse" :href="'#unorderedPartsBody'"
                                            aria-expanded="false" aria-controls="ruleset">
                                            <div v-show="!unorderedPartsExpanded"><i class="fas fa-chevron-right fa-lg"></i>
                                            </div>
                                            <div v-show="unorderedPartsExpanded"><i class="fas fa-chevron-down fa-lg"></i>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-lg-11">
                                        <b> Parts not requested </b>
                                        ({{unorderedLines.length}} lines / {{unorderedLines.reduce((p,n) => p+n.number, 0)}} items)  
                                        <span class="float-right"> <button :disabled="unorderedLines.filter(e=>e.issue=='Unable to fulfil order with selected configuration').length == 0" class="btn btn-success mt-0 mb-0 pt-0 pb-0" @click="stage = 0; recalculateFirstPage();">Configure New Order</button> </span>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse" :id="'unorderedPartsBody'">
                            <div class="card-body">
                            <ib-table
                                :items="unorderedLines"
                                :columns="unfulfilledOrderColumns"
                                :small=true
                                loadingMessage="Loading Orders..."
                                noItemsMessage="No Records Found"
                                :itemsPerPage="15">
                            </ib-table>
                            </div>
                        </div>
                        </div>
                        <div class="text-right pr-2 pt-3">
                            <button class="btn btn-secondary" @click="showModal=false; stage = 0; recalculateFirstPage();">Close</button>
                            &nbsp;
                        </div>
            </div>
            </div>
            <div v-else>
            <ib-loading-spinner 
                message="Loading.."
                class="flex-grow-1"
            />
        </div>
        </ib-modal>
    </div>
</template>
<script>

import IbModal from '@/components/utilities/IbModal';
import IbTable from '@/components/table/IbTable';
import IbSelectEntry from '@/components/form/IbSelectEntry';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';
import ClientApi            from '@MP/api/client';
import CurrencyApi            from '@MP/api/currency';
import CurrencyMixin        from '@/mixins/CurrencyMixin';

const UnfulfilledOrderColumns = [
    {
        heading: 'Part Number',
        property: 'displayPartNumber',
        sortable: false
    },
    {
        heading: 'Quantity',
        property: 'quantity',
        sortable: false,
        class:"ib-table-header-center",
        cellClass:"text-right pr-5"
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: false,
    },
    {
        heading: 'Issue',
        property: 'issue',
        sortable: false,
    },
];

const FulfilledOrderColumns = [
    {
        heading: 'Part Number',
        property: 'partNumber',
        sortable: false
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: false,
    },
    {
        heading: 'Quantity',
        property: 'totalQuantity',
        sortable: false,
        class:"ib-table-header-center",
        cellClass:"text-right pr-5"
    },
    {
        heading: 'Price',
        property: 'listingPrice',
        sortable: false,
        class:"ib-table-header-center"
    },
    
    {
        heading: 'Total Price',
        property: 'totalPrice',
        sortable: false,
        class:"ib-table-header-center"
    },
    {
        heading: 'Seller ID',
        property: 'sellerId',
        sortable: false,
    },
];

const SuggestedOrderColumns = [
    {
        heading: 'Part Number',
        property: 'partNumber',
        sortable: false
    },
    {
        heading: 'Quantity',
        property: 'totalQuantity',
        sortable: false,
        class:"ib-table-header-center",
        cellClass:"text-right pr-5"
    },
    {
        heading: 'Price',
        property: 'listingPrice',
        sortable: false,
        class:"ib-table-header-center"
    },
    {
        heading: 'Dealer Nett',
        property: 'dealerNett',
        sortable: false,
        class:"ib-table-header-center"
    },
    {
        heading: 'Total Price',
        property: 'totalPrice',
        sortable: false,
        class:"ib-table-header-center"
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: false,
    },
    {
        heading: '',
        property: 'removeFromOrder',
        sortable: false,
    }
];
export default {
    name: 'ConfigureOrderModal',

    components: {
        IbModal,
        IbSelectEntry,
        IbLoadingSpinner,
        IbTable
    },

    mixins: [
        CurrencyMixin
    ],

    created() {
    },

    data() {
        return {
            suggestedOrdersLoading: false,
            showModal: false,
            basketParts: 0,
            missingPrefix: 0,
            missingParts: 0,
            missingPrefixLine: 0,
            missingPartsLine: 0,
            currencyValue: 0,
            selectedCurrency: 0,
            currencyList: [{ "value": 0, "text": "Any" },
            { "value": 1, "text": "GBP" },
            { "value": 2, "text": "EUR" }
            ],
            selectedPartialLines: 0,
            partialLinesValue: 0,
            partialLinesList: [{ "value": 0, "text": "Yes" },
            { "value": 1, "text": "No" }],
            selectedFulfillOrder: 0,
            fulfillOrderValue: 0,
            fulfillOrderList: [{ "value": 0, "text": "Single Seller" },
            { "value": 1, "text": "Lowest Price" }],
            stage: 0,
            suggestedOrder: [],
            suggestedOrderColumns: SuggestedOrderColumns,
            unfulfilledOrderColumns: UnfulfilledOrderColumns,
            fulfilledOrderColumns: FulfilledOrderColumns,
            linesFulfilled: 0,
            groupedOrders: [],
            showRemoveFromOrderModal: false,
            partForRemovalFromOrder: {},
            partForRemovalFromOrderSeller: "",
            showDeliveryTermsModal: false,
            selectedSellerTerms: "",
            selectedSeller: {},
            unfulfilledLines: [],
            unorderedLines: [],
            unfulfilledPartsExpanded: false,
            fulfilledPartsExpanded: false,
            unorderedPartsExpanded: false,
            localCurrency: null,
            orderSummary: []
        }
    },

    computed: {
    },
    props: {
        value: {
            required: true,
            type: Boolean
        },
        basket: {
            required: true,
            type: Array
        },
        depotId: {
            required: true,
            type: String
        },
    },

    watch: {
        showModal() {
            this.recalculateFirstPage();
            this.$emit('input', this.showModal);
        },
        value(newValue) {
            this.showModal = newValue;
        },
        selectedCurrency(newCurr) {
            this.currencyValue = newCurr;
        },
        selectedFulfillOrder(newValue) {
            this.fulfillOrderValue = newValue;
        },
        selectedPartialLines(newValue) {
            this.partialLinesValue = newValue;
        }
    },
    methods:
    {
        recalculateFirstPage()
        {
            this.basketParts = 0;
            this.missingParts = 0;
            this.missingPartsLine = 0;
            this.missingPrefix = 0;
            this.missingPrefixLine = 0;
            this.currencyValue = 0;
            this.selectedCurrency = 0;
            this.selectedFulfillOrder = 0;
            this.selectedPartialLines = 0;
            this.unfulfilledPartsExpanded = false;
            this.fulfilledPartsExpanded = false;
            this.unorderedPartsExpanded = false;
            let sum = 0;
            this.basket.forEach(b => {
                sum += b.number;
                if (b.partError != '' && b.partError != null) {
                    if (b.partError.includes('Gold')) {
                        this.missingParts += b.number;
                        this.missingPartsLine += 1;
                    }
                    else {
                        this.missingPrefix += b.number;
                        this.missingPrefixLine += 1;
                    }

                }
            });
            this.basketParts = sum;
        },
        async orderParts()
        {
            this.suggestedOrdersLoading = true;
            try
            {
                let orderResponse = await ClientApi.bulkOrderParts(this.suggestedOrder, this.depotId);
                this.orderSummary = orderResponse.data.map(e => {
                    e.partNumber = e.clientInfo.buyerPrefix+"-"+e.serverInfo.partNumberSuffix;
                    e.description = e.serverInfo.sellerDescription;
                    e.sellerId = e.clientInfo.clientSellerId;
                    e.totalQuantity = e.serverInfo.quantity;
                    e.totalPrice = e.serverInfo.price * e.serverInfo.quantity;
                    e.listingPrice = e.serverInfo.price;
                    return e;
                });
                
                this.unorderedLines = [];
            
                this.basket.forEach(b => {
                    let matching = this.orderSummary.filter(e => e.partNumber == b.displayPartNumber);
                    let issue = b.partError;
                    if (issue == null || issue == "")
                    {
                        if (matching.error != null && matching.error != "")
                            issue = matching.error;
                        else
                            issue = "Unable to fulfil order with selected configuration";
                    }
                    if (matching.length == 0)
                    {
                        this.unorderedLines.push({...b, issue:issue});
                    }
                    else if (matching.reduce((p,n) => p+n.totalQuantity, 0) < b.number)
                    {
                        let newQuantity = b.number - matching.reduce((p,n) => p+n.totalQuantity, 0);
                        this.unorderedLines.push({...b, number:newQuantity, issue:issue});
                    }
                });
                this.orderSummary.forEach(e => this.$emit('removeFromBasketAfterOrder', e))
                this.stage = 2;
            }
            catch (err)
            {
                this.toast('b-toaster-top-center', 'danger', err.data, true);
            }
            finally
            {
                this.suggestedOrdersLoading = false;
            }
        },
        removeFromOrder(item, seller)
        {
            this.partForRemovalFromOrder = item.item;
            this.partForRemovalFromOrderSeller = seller;
            this.showRemoveFromOrderModal = true;
        },
        executeRemoveFromOrder()
        {
            let itemToRemove = this.partForRemovalFromOrder;
            this.suggestedOrder = this.suggestedOrder.filter(e => {
                return !(e.depotId == itemToRemove.depotId && e.partNumber == itemToRemove.partNumber && e.manufacturerId == itemToRemove.manufacturerId)
            });
            this.groupedOrders = this.groupedOrders.map(e => {
                if (e.seller == itemToRemove.depotId)
                {
                    e.lines = e.lines.filter(f => !(f.partNumber == itemToRemove.partNumber && f.manufacturerId == itemToRemove.manufacturerId));
                }
                return e;
            });
            let fulfilledLines = 0;
            this.basket.forEach(b => {
                if (this.suggestedOrder.filter(e => e.partNumber == b.partNumber && b.manufacturer == e.manufacturerId).reduce((p, n) => p + n.totalQuantity, 0) == b.number) {
                    fulfilledLines += 1;
                }
            });

            this.linesFulfilled = fulfilledLines;
            this.$emit('removeFromBasket', itemToRemove);
            this.showRemoveFromOrderModal = false;
        },
        calculateSuggestedOrderGroups()
        {
            let groupedOrders = [];
            this.suggestedOrder.forEach(l => 
            {
                if (groupedOrders.length != 0 && groupedOrders.filter(e => e.seller == l.depotId).length > 0)
                {
                    groupedOrders.map(e => {
                        if(e.seller == l.depotId)
                        {
                            e.lines.push(l);
                        }
                        return e;
                    });
                }
                else
                {
                    groupedOrders.push({seller: l.depotId, lines: [l], isExpanded:false, deliveryTerms: l.deliveryTerms});
                }
            });

            groupedOrders = groupedOrders.sort(function(p,n) { return n.lines.length - p.lines.length });

            let id = 0;
            groupedOrders.forEach(n => {
                id += 1;
                n.id = id;
                this.suggestedOrder.map(e=> {
                    if (n.seller == e.depotId)
                        e.clientSellerId = id;
                    return e;
                })
            });

            this.groupedOrders = groupedOrders;

            this.unfulfilledLines = [];
            
            this.basket.forEach(b => {
                let matching = this.suggestedOrder.filter(e => e.originalPartNumber == b.partNumber && b.manufacturer == e.manufacturerId);
                let issue = b.partError;
                if (issue == null || issue == "")
                {
                    issue = "Unable to fulfill lines with chosen configuration";
                }
                if (matching.length == 0)
                {
                    this.unfulfilledLines.push({...b, issue:issue});
                }
                else if (matching.reduce((p,n) => p+n.totalQuantity, 0) < b.number)
                {
                    let newQuantity = b.number - matching.reduce((p,n) => p+n.totalQuantity, 0);
                    this.unfulfilledLines.push({...b, number:newQuantity, issue:issue});
                }
            });
        },
        showTerms(seller)
        {
            this.showDeliveryTermsModal = true;
            let terms = seller.deliveryTerms;
            if (terms == "" || terms == null)
            {
                terms = "Delivery Terms not provided by Seller";
            }

            this.selectedSellerTerms = terms;
            this.selectedSeller = seller;
        },
        async doGetOrderSuggestion()
        {
            try
                {
                    this.suggestedOrdersLoading=true;
                    let suggestedOrderResponse = [];
                    if (this.fulfillOrderValue == 1 && this.partialLinesValue == 0) {
                        suggestedOrderResponse = await ClientApi.doGetSuggestedOrderLowPricePartial(this.basket.filter(b => b.partError == "" || b.partError == null), this.depotId, this.selectedCurrency);
                    }
                    else if (this.fulfillOrderValue == 1 && this.partialLinesValue == 1) {
                        suggestedOrderResponse = await ClientApi.doGetSuggestedOrderLowPriceNonPartial(this.basket.filter(b => b.partError == "" || b.partError == null), this.depotId, this.selectedCurrency);
                    }
                    else if (this.fulfillOrderValue == 0 && this.partialLinesValue == 1) {
                        suggestedOrderResponse = await ClientApi.doGetSuggestedOrderSingleSellerNonPartial(this.basket.filter(b => b.partError == "" || b.partError == null), this.depotId, this.selectedCurrency);                        
                    }
                    else if (this.fulfillOrderValue == 0 && this.partialLinesValue == 0) {
                        suggestedOrderResponse = await ClientApi.doGetSuggestedOrderSingleSellerPartial(this.basket.filter(b => b.partError == "" || b.partError == null), this.depotId, this.selectedCurrency);                        
                    }
                    else {
                        this.toast('b-toaster-top-center', 'danger', "NOT YET IMPLEMENTED", true);
                        return;
                    }
                    let localCurrency = await CurrencyApi.get();
                    this.localCurrency = localCurrency.data;
                    let suggestedOrderData = suggestedOrderResponse.data;
                    let fulfilledLines = 0;
                    this.basket.forEach(b => {
                        if (suggestedOrderData.filter(e => e.partNumber == b.partNumber && b.manufacturer == e.manufacturerId).reduce((p, n) => p + n.totalQuantity, 0) == b.number) {
                            fulfilledLines += 1;
                        }
                    });

                    this.linesFulfilled = fulfilledLines;
                    
                    this.suggestedOrder = suggestedOrderData.map(l => {
                        let matchingFromBasket = this.basket.filter(b => b.partNumber == l.partNumber && b.manufacturer == l.manufacturerId)[0];
                        l.originalPartNumber = l.partNumber;
                        l.partNumber = matchingFromBasket.localPrefix + "-" + l.partNumber;
                        l.description = matchingFromBasket.description;
                        l.totalPrice = l.listingPrice * l.totalQuantity;
                        l.dealerNett = matchingFromBasket.dealerNett;
                        return l;
                    });

                    this.calculateSuggestedOrderGroups();
                    this.unfulfilledPartsExpanded = false;
                    this.stage = 1;
                    this.suggestedOrdersLoading = false;

            }
            catch (error) {
                this.toast('b-toaster-top-center', 'danger', error.data, true);
            }
        },


        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Order Parts`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        }


    }
}
</script>
<style>
.ib-table-header-center > div > div{
    width: 100%;
    text-align: center;
}
.modal-scroll-bar{
    max-height: calc(100vh - 370px); 
    width: auto;
    overflow-y: auto;
}
</style>
<style lang="less" scoped>
@import '../../../assets/styles/ib-common';
</style>