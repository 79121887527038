<template>
<div class="engineers-time-entries">
	<ib-collapse-toggle
		:collapseId="collapseId"
		:class="allTimeApproved ? 'border-success' : 'border-danger'"
	/>
	<h4 v-b-toggle="collapseId">{{ name }} | {{ id }}</h4>

	<b-collapse
		:id="collapseId"
		accordion="engineerAccordion"
		:visible="visible">
		<engineer-day-times
			v-for="group in dateGroupedActivities"
			:key="group.date"
			:engineerId="id"
			:date="group.date"
			:activities="group.activities"
			:labourCategories="labourCategories"
			class="content"
		></engineer-day-times>
	</b-collapse>
</div>
</template>

<script>
//Utilities
import DateMixin, { DateFormat }	from '@/mixins/DateMixin';

import EngineerDayTimes				from '@WS/components/time-approval/EngineerDayTimes';
import { JobActivityStatus }		from '@WS/common/job-activity-status';
import IbCollapseToggle				from '@/components/IbCollapseToggle';

export default {
	name: 'EngineerTimes',

	mixins: [
        DateMixin
    ],

	components: {
		EngineerDayTimes,
		IbCollapseToggle
	},

	props: {
		id: {
			type:     Number,
			required: true
		},

		name: {
			type:     String,
			required: true
		},

		activities: {
			type:     Array,
			required: true
		},

		labourCategories: {
			type: 	  Array,
			required: true
		},

		visible: {
			type:     Boolean,
			default:  false,
			required: false
		}
	},

	data() {
		return {
			dateGroupedActivities: []
		}
	},

	created() {
		// Initialise activity groups.
		this.buildActivityDateGroups();
	},

	computed: {
		collapseId() {
			const id = `collapse-engineer-${this.id}`;
			return id;
		},

		allTimeApproved() {
			let result = this.activities.every((activity) => {
				const approved = JobActivityStatus.APPROVED <= activity.Status;
				return approved;
			});
			return result;
		}
	},

	methods: {
		/**
		 * Build up groupings of job activities by Start
		 * date.
		 */
		buildActivityDateGroups() {
			const groupsObject   	   = this.groupActivitiesByStart();
			this.dateGroupedActivities = this.buildActivityGroups(groupsObject);
		},

		/**
		 * Group activities by the date component of the
		 * 'Start' property.
		 * @returns {object} Object containing key value pairs
		 * of date and activity collection.
		 */
		groupActivitiesByStart() {
			const grouped = _.groupBy(this.activities, (activity) => {
				const key = this.getDateString(activity.Start, DateFormat.DATE_ONLY);
				return key;
			});
			return grouped;
		},

		/**
		 * Converts a groups object into an array of objects with
		 * date and activities properties.
		 * @returns {object[]} Array of date grouped job activity
		 * objects.
		 */
		buildActivityGroups(groupsObject) {
			// Keys will be the start dates used for grouping.
			const dates 		 = _.keys(groupsObject);
			const activityGroups = _.map(dates, (date) => {
				// Get activities in group.
				const activities = groupsObject[date];
				return {
					date: 		date,
					activities: activities
				};
			});
			return activityGroups;
		}
	},

	watch: {
		/**
		 * Rebuild activity groups when activities prop
		 * changes.
		 */
		activities(newValue, oldValue) {
			this.buildActivityDateGroups();
		}
	}
}
</script>

<style lang="less" scoped>
h4 {
	display: inline;
	padding-left: 8px;
}
h4:hover {
	cursor: pointer;
}

h5 {
	margin-bottom: 0px;
}

button + h4 {
	vertical-align: middle;
}

.engineers-time-entries {
	margin-bottom: 28px;
}

.content {
	margin: 10px 0px 0px 32px;
}
</style>
