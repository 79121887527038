import ApiService from '@/common/api-service';

const ENGINEER_ROOT = "api/v1/engineer";

const engineerApi = {
    /**
     * Get an engineer by identifier.
     * @param {string} id 
     */
    getById(id)  {
        var url = `${ENGINEER_ROOT}/${id}`;
        return ApiService.get(url);
    },
    /**
     * Get all engineers.
     */
    getAll() {
        return ApiService.get(ENGINEER_ROOT);
    },

    /**
     * Get all labour categories.
     */
    getLabourCategories() {
        const endpoint = "api/v1/workshop/timeclock/categories";
        return ApiService.get(endpoint);
    }
}   

export default engineerApi;