<template>
    <div v-if="goldlockedshow">
    <ib-modal 
        v-model="show"
        modalClass="w-50 p-2"
        :dismissable=false
    >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i style="color:#007bff;" class="fas fa-exclamation-triangle"></i> Gold Locked</h3>
            </div>
            <hr>
            <div>
                Requests unavailable as Gold is currently locked.
            </div>
            <div v-if="lockedReason">
                {{ lockedReason }}
            </div>
            <div>
                Your session will resume once Gold has been unlocked.
            </div>
            <div>
                Please contact your System Administrator for further information.
            </div>
        </div>
    </ib-modal>
</div>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';

import { hubConnection } from 'signalr-no-jquery';
import { mapState,mapGetters,mapActions} from 'vuex';
import { computed } from 'vue';

export default {
    name: "IbGoldLocked",

    components: {
        IbModal
    },
    computed: {
        ...mapGetters('apimessage', {
            goldlockedshow   : 'goldlocked',
            lockedReason : 'goldlockedreason'
        })
    },

    data() {
        return {
            show: true
        }
    }
}
</script>