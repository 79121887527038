<template>
    <ib-modal 
        v-model="show"
        modalClass="w-25 p-2"
        :dismissable=false
    >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i style="color:#007bff;" class="fas fa-exclamation-triangle"></i> {{title}}</h3>
                {{message}}
            </div>
            <hr>
            <div class = "row">
                <div class = "col-6 pr-2">
                    <button class="btn btn-secondary" @click="cancelClicked">Cancel</button> &nbsp; 
                </div> 
                <div class = "col-6 text-right pr-2">                
                    <button class="btn btn-success" @click="noClicked">No</button> &nbsp;
                    <button class="btn btn-success" @click="yesClicked">Yes</button> &nbsp;
                </div>
            </div>
        </div>
    </ib-modal>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';

export default {
    name: "IbYesNoCancelModal",

    components: {
        IbModal
    },

    data() {
        return {
            show       : false,
        }
    },

    props: {
          /**
         * Flag to indicate if modal is shown.
         */
        value: {
            required : true,
            type     : Boolean
        },
        message: {
            required : true,
            type     : String
        },
        title: {
            required : true,
            type     : String
        }
    },

    methods: {
        yesClicked() {
            this.$emit('yesPressed');
            this.show = false;
        },
        noClicked() {
            this.$emit('noPressed');
            this.show = false;
        },
        cancelClicked() {
            this.$emit('cancelPressed');
            this.show = false;
        }
    },
    watch: {
        value(newValue) {
            this.show = newValue;
            
        },
        show(newvalue) {
            if(this.show == false) {
                this.$emit('hideWarning');
            }
        }
    }
}
</script>
