import {DocStoreOptions} from '@/common/docstore-options';
import BrowserUtility from '@/utilities/browserUtility';
import Workshop from '@WS/Workshop';
import JobSearch from '@WS/views/JobSearch';
import AddTime from '@WS/views/AddTime';
import ApproveTimeEngineer from '@WS/views/ApproveTimeEngineer';
import Engineers from '@WS/views/Engineers';
import Engineer from '@WS/views/Engineer';
import PartsAudit from '@WS/views/PartsAudit';
import JobRequests from '@WS/views/JobRequests';
import JobRequestValidation from '@WS/views/JobRequestValidation';
import DSMValidation from '@WS/views/DSMValidation'
import JobRequestConfiguration from '@WS/views/JobRequestConfiguration';
import NoIESupport from '@/components/utilities/NoIESupport';

import {CHECKLIST_ROUTES} from '@WS/common/checklist-routes'
import {ROLE} from "@/common/Constants";
import router from "@/router";

export default [
    {
        path: '/workshop',
        name: 'Workshop',
        component: Workshop,
        meta: {
            module: true,
            menu: {
                label: 'Workshop',
                icon: ['fas', 'wrench'],
            },
        },
        children: [
            {
                path: 'job',
                name: 'Jobs',
                component: () => import(/* webpackChunkName: "group-job" */ '../views/Jobs.vue'),
                meta: {
                    module: false,
                    menu: {
                        label: 'Jobs',
                        icon: ['fas', 'clipboard-list'],
                    },
                    roles: [ROLE.WSJJob]
                },
            },
            {
                path: 'job/kanban',
                name: 'JobsKanban',
                // Do not load component on IE.
                component: handleNoIESupport(() => import(/* webpackChunkName: "group-job" */ '../views/JobsKanban.vue')),
                props: {
                    // Custom message to display when viewing in IE.
                    noIESupportMessage: 'There is a new feature that enables you to have more than one department authorising a job. To see this new feature, please access the Portal using a different browser e.g. Google Chrome, Microsoft Edge, Mozilla Firefox, etc.',
                },
                meta: {
                    module: false,
                    menu: {
                        label: 'Jobs (New View)',
                        icon: ['fab', 'trello'],
                    },
                    roles: [ROLE.WSJKanban]
                },
            },
            {
                path: 'job/search',
                name: 'JobSearch',
                component: JobSearch,
                props: true,
                meta: {
                    module: false,
                    menu: {
                        label: 'Job Search',
                        icon: ['fas', 'search'],
                    },
                    roles: [ROLE.WSJSearch]
                },
            },
            {
                path: 'job/requests',
                name: 'JobRequests',
                component: () => import(/* webpackChunkName: "group-job" */ '@WS/views/JobRequests'),
                props: true,
                meta: {
                    module: false,
                    menu: {
                        label: 'Job Requests',
                        icon: ['fas', 'clipboard-check'],
                    },
                    docStoreOptions: [
                        DocStoreOptions.GOLD_PORTAL_JOB_REQUEST,
                    ],
                    roles: [ROLE.WSJReqests]
                },
            },
            // Single Job Routes.
            {
                path: 'job/:id',
                name: 'Job',
                component: () => import(/* webpackChunkName: "group-job" */ '../views/Job.vue'),
                props: true,
                children: [
                    {
                        path: 'edit',
                        name: 'EditJob',
                        component: () => import(/* webpackChunkName: "group-job" */ '../views/EditJob.vue'),
                        props: true,
                    },
                    {
                        path: 'job_card',
                        name: 'JobCard',
                        component: () => import(/* webpackChunkName: "group-job" */ '../views/JobCard.vue'),
                        props: true,
                    },
                    {
                        path: 'checklist/:checklistId',
                        name: 'ChecklistEdit',
                        component: () => import(/* webpackChunkName: "workshop" */ '@WS/components/checklist/ChecklistEditor')
                    },
                    {
                        path: 'complete',
                        name: 'JobCompletion',
                        component: () => import(/* webpackChunkName: "group-job" */ '../views/JobCompletion.vue')
                    }
                ]
            },
            {
                path: 'time/approve/engineer',
                name: 'TimeApproval',
                component: ApproveTimeEngineer,
                meta: {
                    module: false,
                    menu: {
                        label: 'Time Approval',
                        icon: ['fas', 'clock'],
                    },
                    roles: [ROLE.WSJTimeAppro]
                },
            },

            {
                path: 'engineers',
                name: 'Engineers',
                component: Engineers,
                meta: {
                    module: false,
                    menu: {
                        label: 'Engineers',
                        icon: ['fas', 'users'],
                    },
                    roles: [ROLE.WSJEngineer]
                },
            },
            {
                path: 'engineer/:id',
                name: 'Engineer',
                component: Engineer,
            },
            {
                path: 'time/add',
                name: 'AddTime',
                component: AddTime
            },

            {
                path: 'job/:id/part/audit',
                name: 'PartsAudit',
                component: PartsAudit,
                props: true,
                beta: true,
                meta: {
                    docStoreOptions: [
                        DocStoreOptions.GOLD_SERVICE_PLUS
                    ]
                },
            },
            /**
             * Checklist management.
             */
            {
                path: 'checklisttemplates',
                name: CHECKLIST_ROUTES.TEMPLATES,
                component: () => import(/* webpackChunkName: "workshop" */ '@WS/views/ChecklistManager'),
                props: true,
                children: [
                    {
                        path: ':id/edit',
                        name: CHECKLIST_ROUTES.EDIT,
                        component: () => import(/* webpackChunkName: "workshop" */ '@WS/components/checklist/ChecklistTemplateEditor'),
                        meta:{
                            roles: [ROLE.WSJChecklist]
                        }
                    },
                    {
                        path: ':id/duplicate',
                        name: CHECKLIST_ROUTES.DUPLICATE,
                        component: () => import(/* webpackChunkName: "workshop" */ '@WS/components/checklist/ChecklistTemplateEditor'),
                        meta:{
                            roles: [ROLE.WSJChecklist]
                        }
                    },
                    {
                        path: 'create',
                        name: CHECKLIST_ROUTES.CREATE,
                        component: () => import(/* webpackChunkName: "workshop" */ '@WS/components/checklist/ChecklistTemplateEditor'),
                        meta:{
                            roles: [ROLE.WSJChecklist]
                        }
                    },
                    {
                        path: '',
                        name: CHECKLIST_ROUTES.TEMPLATES,
                        component: () => import(/* webpackChunkName: "workshop" */ '@WS/components/checklist/ChecklistTemplateList'),
                        meta:{
                            roles: [ROLE.WSJChecklist]
                        }
                    },
                    {
                        path: '*',
                        redirect: '/'
                    }
                ],
                meta: {
                    menu: {
                        label: 'Checklists',
                        icon: ['fas', 'tasks'],
                    },
                    docStoreOptions: [
                        DocStoreOptions.GOLD_SERVICE_PLUS
                    ],
                    roles: [ROLE.WSJChecklist]
                },
            },
            {
                path: 'job/requests/validation',
                name: 'JobRequestValidation',
                component: JobRequestValidation
            },
            {
                path: 'job/requests/configuration',
                name: 'JobRequestConfiguration',
                component: JobRequestConfiguration
            },
            {
                path: 'scheduler',
                name: 'Scheduler',
                url: `${process.env.VUE_APP_SCHEDULER_URL}/#/workshop/scheduler`,
                meta: {
                    menu: {
                        label: 'Scheduler',
                        icon: ['fas', 'calendar'],
                    },
                    docStoreOptions: [
                        DocStoreOptions.GOLD_SCHEDULER
                    ],
                    roles: [ROLE.WSJScheduler]
                },

                beforeEnter(to, from, next) {
                        if(from.path !== "/") //denotes - we've come from exteral back button
                        {
                            window.location.href =  `${process.env.VUE_APP_SCHEDULER_URL}/#/workshop/scheduler`;
                        }
                        else
                        {
                            //Back detected
                            router.go(-1);   //Because before we left, we left from /workshop/scheduler - We need to force router to go back again...
                        }
                }
          
            },
            {
                path: 'dsm/validation',
                name: 'DSMValidation',
                component: DSMValidation,
                props: true,
                meta: {
                    module: false,
                    menu: {
                        label: 'Claas DSM Validation',
                        icon: ['fas', 'clipboard'],
                    },
                    docStoreOptions: [
                        DocStoreOptions.CLAAS_DSM
                    ],
                    roles: [ROLE.CLAASDSMAdmin]
                },
            },
            {
                path: '*',
                redirect: {name: 'Jobs'}
            },

        ]
    }
];

/**
 * If the user is on IE returns a feature not supported on IE
 * template. Otherwise, the provided component is returned.
 * @param {*} component
 */
function handleNoIESupport(component) {
    const isIE = BrowserUtility.isIE();

    let result = component;

    // Display no IE support template.
    if (isIE) {
        result = NoIESupport;
    }

    return result;
}
