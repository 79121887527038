<template>
    <ib-modal 
        v-model="show"
        modalClass="w-25 p-2"
        :dismissable=false
    >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <button class="btn btn-warning w-100" @click="removePortalOnly">Remove from Job Requests</button>
            </div>
            <div class = "row p-2" v-if="supportsNotify">
                <button class="btn btn-danger w-100" @click="removeAndNotify">Remove from Job Requests and send a cancellation notification to {{manufacturer}}</button>
            </div>
            <div class = "row p-2">
                <button class="btn btn-secondary w-100" @click="cancel">Cancel</button>
            </div>
        </div>
    </ib-modal>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';

export default {
    name: "RequestRejectNotify",

    components: {
        IbModal
    },

    data() {
        return {
            show       : false,
        }
    },

    props: {
          /**
         * Flag to indicate if modal is shown.
         */
        value: {
            required : true,
            type     : Boolean
        },
        manufacturer: null,
        supportsNotify: false
    },

    methods: {
        removePortalOnly() {
            this.$emit('deleteJobRequest',false);
            this.show = false;
        },
        removeAndNotify() {
            this.$emit('deleteJobRequest',true);
            this.show = false;
        },
        cancel() {
            this.$emit('hideReject');
            this.show = false;
        }

    },
    watch: {
        value(newValue) {
            this.show = newValue;
            
        },
        show(newvalue) {
            if(this.show == false) {
                this.$emit('hideReject');
            }
        }
    }
}
</script>