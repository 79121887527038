<template>
<div>
    <h3 class="mb-4">Create Time Entry</h3>

    <!-- Error -->
    <div
        v-for="(dismissableError, index) in dismissableErrors"
        :key="index"
        class="alert alert-danger alert-dismissible show sticky-top"
        role="alert"
    >
        {{ dismissableError }}
        <button 
            type="button" 
            class="close"
            aria-label="Close"
            @click="dismissableErrors.splice(index, 1);"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    
    <form v-on:submit.prevent>
        <!-- Job Number -->
        <div class="row">
            <label for="job"
                class="col-auto label col-form-label">
                <span>Job</span>
            </label>
            <ib-job-lookup id="job"
                ref="jobLookup"
                class="col-sm-6 col-md-5 col-lg-4"
                :maxLength="6"
                :valid="job.valid"
                v-model="job.value"
            ></ib-job-lookup>
            <div v-if="job.value"
                class="col col-form-label text-truncate">
                <span class="mr-5">{{ job.value.AccountName }}</span>
                <span>{{ job.value.Description }} </span>
            </div>
        </div>

        <!-- Engineer -->
        <div class="row">
            <label for="engineer" 
                class="col-auto label col-form-label">
                <span>Engineer</span>
            </label>
            <div class="col-sm-6 col-md-5 col-lg-4">
                <engineer-list
                    v-model="engineer.value"
                    :valid="engineer.valid"
                    validationError="Please select a valid engineer"
                />
            </div>
            <div v-if="engineer.value"
                class="col col-form-label text-truncate">
                <span class="mr-3">Depot:</span>
                <span>{{ engineer.value.DepotId }}</span>
            </div>
        </div>

        <!-- Start Date & Time -->
        <div class="row">
            <label for="start"
                class="col-auto label col-form-label">
                <span>Start</span>
            </label>
            <div class="col-sm-6 col-md-5 col-lg-4">
                <ib-date-time-picker id="start"
                    :valid="start.valid"
                    v-model="start.value"
                ></ib-date-time-picker>
            </div>
        </div>

        <!-- End Date & Time -->
        <div class="row">
            <label for="end"
                class="col-auto label col-form-label">
                <span>End</span>
            </label>
            <div class="col-sm-6 col-md-5 col-lg-4">
                <ib-date-time-picker id="end"
                    :valid="end.valid"
                    :validationError="end.error"
                    v-model="end.value"
                ></ib-date-time-picker>
            </div>
            <!-- Duration -->
            <div v-if="duration.value"
                class="col col-form-label text-truncate">
                <span class="mr-3">Duration:</span>
                <span>{{ duration.value | formatDuration('h[h] m[m]') }}</span>
            </div>
        </div>

        <!-- Activity (Labour, Travel, or Time & Attendance) -->
        <div class="form-group row">
            <div class="col-auto col-form-label label">
                <span>Activity</span>
            </div>
            <div v-if="isTimeAttendanceJob"
                class="col-auto">Time and Attendance</div>
            <div v-else
                class="col-auto btn-group btn-group-toggle"
                role="group">
                <label for="labour"
                    class="btn btn-outline-primary"
                    :class="{ 'active': activity.types.LABOUR === activity.value }">
                    <input id="labour"
                        type="radio"
                        name="activityTypes"
                        :value="activity.types.LABOUR"
                        v-model="activity.value"> Labour
                </label>
                <label for="travel"
                    class="btn btn-outline-primary"
                    :class="{ 'active': activity.types.TRAVEL === activity.value }">
                    <input id="travel"
                        type="radio"
                        name="activityTypes"
                        :value="activity.types.TRAVEL"
                        v-model="activity.value"> Travel
                </label>
            </div>
        </div>

        <!-- Story/Note -->
        <div class="form-group row">
            <label for="comment"
                class="col-auto label col-form-label">
                <span>Comment</span>
            </label>
            <div class="col">
                <textarea id="comment"
                    class="form-control"
                    rows="3"
                    v-model="comment"
                ></textarea>
            </div>
        </div>

        <!-- Fixed Rate -->
        <div v-if="displayFixedRates"
            class="form-group row">
            <label for="fixedRate" 
                class="col-auto label col-form-label">
                <span>Fixed Rate</span>
            </label>
            <div class="col-sm-3">
                <ib-select-entry id="fixedRate"
                    :items="job.value.FlatRateTasks"
                    noItemsMessage="No fixed rates available"
                    v-model="fixedRate">
                    <span slot-scope="data">
                        {{ data.item.Code }}
                    </span>
                </ib-select-entry>
            </div>
            <div v-if="fixedRate"
                class="col col-form-label text-truncate">
                <span class="mr-3">Group:</span>
                <span class="mr-5">{{ fixedRate.Group }}</span>
                <span class="mr-3">Description:</span>
                <span>{{ fixedRate.Description }}</span>
            </div>
        </div>

        <!-- Approve Now -->
        <div class="form-group row">
            <div class="col-auto col-form-label label">
                <span>Approve Now</span>
            </div>
            <div class="col-auto btn-group btn-group-toggle"
                role="group">
                <label for="approveNo"
                    class="btn btn-outline-success"
                    :class="{ 'active': !approve.value }">
                    <input id="approveNo"
                        type="radio"
                        name="approveNoYes"
                        :value="false"
                        v-model="approve.value"> No
                </label>
                <label for="approveYes"
                    class="btn btn-outline-success"
                    :class="{ 'active': true === approve.value }">
                    <input id="approveYes"
                        type="radio"
                        name="approveNoYes"
                        :value="true"
                        v-model="approve.value"> Yes
                </label>
            </div>
        </div>

        <!-- Labour Rate -->
        <div class="form-group row rate-row"
             :class="{ 'fade-in': approve.value, 'fade-out': false === approve.value }">
            <div class="col-auto col-form-label label">
                <span>Rate</span>
            </div>
            <labour-rate-list
                v-if="approve.value"
                class="col-sm-6 col-md-5 col-lg-4"
                :labourCategories="labourCategories.value"
                @rateChanged="_onRateChanged"
            />
        </div>

        <!-- Accept & Back Buttons -->
        <div class="d-flex justify-content-between mt-4">
            <ib-back-button buttonStyle="secondary"
                size="lg">Cancel</ib-back-button>
            <button type="button"
                class="btn btn-primary btn-lg"
                :disabled="isSaving"
                @click="handleSave">
                <span v-show="!isSaving">
                    <i class="fas fa-save"></i>&nbsp;Save
                </span>
                <span v-show="isSaving">
                    <i class="fas fa-spinner fa-spin"></i>&nbsp;Saving
                </span>
            </button>
        </div>
    </form>

    <!-- User Feedback Modal -->
    <b-modal v-model="showModal"
        title="Creation Successful"
        ok-title="Yes"
        cancel-title="No"
        cancel-variant="dark"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        @ok="handleYes"
        @cancel="handleNo">
        <p v-if="null !== jobActivity">Job activity <b>'{{ jobActivity.Id }}'</b> created for job <b>'{{ jobActivity.JobId }}'</b><span v-if="approve.success"> and approved by <b>'{{ jobActivity.ApprovedBy }}'</b></span>.</p>
        <p v-if="null !== approve.error"
            class="text-danger">{{ approve.error }}</p>
        <p class="mb-0">Would you like to create another time entry?</p>
    </b-modal>
</div>
</template>

<script>
import { isObject as _isObject }    from 'lodash-es';
import { mapState, mapGetters }     from 'vuex';
import JobsApi                      from '@WS/api/job';
import JobActivityApi               from '@WS/api/job-activity';
import EngineerApi                  from '@WS/api/engineer';
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import IbSelectEntry                from '@/components/form/IbSelectEntry';
import IbDateTimePicker             from '@/components/form/IbDateTimePicker';
import IbBackButton                 from '@/components/IbBackButton';
import IbJobLookup                  from '@WS/components/lookups/IbJobLookup';
import LabourRateList               from '@WS/components/LabourRateList';
import EngineerList                 from '@WS/components/EngineerList';
import { JobActivityStatus }        from '@WS/common/job-activity-status';
import { JobActivityType }          from '@WS/common/job-activity-type';

const PLATFORM = 'web';

export default {
    name: 'AddTime',

    mixins: [
        DateMixin
    ],

    components: {
        EngineerList,
        IbSelectEntry,
        IbDateTimePicker,
        IbBackButton,
        IbJobLookup,
        LabourRateList
    },
    
    data() {
        return {
            job: {
                value:      null,
                valid:      true
            },
            engineer: {
                value:      null,
                valid:      true
            },
            start: {
                value:      '',
                valid:      true
            },
            end: {
                value:      '',
                valid:      true,
                // Use undefined to ensure default validation error is used
                // in date time component
                error:      undefined 
            },
            duration: {
                value:      null,
                valid:      true
            },
            activity: {
                types:      JobActivityType,
                value:      JobActivityType.LABOUR,
                valid:      true
            },
            comment:        null,
            fixedRate:      null,
            // Creation operation state.
            create: {
                uploading:  false,
                success:    false,
                error:      null
            },
            // Approval operation state.
            approve: {
                value:      null,
                approving:  false,
                success:    false,
                error:      null
            },
            resetting:      false,
            showModal:      false,
            jobActivity:    null,
            labourRate:     null,

            labourCategories: {
                value:   [],
                loading: false,
                error:   null
            },
            dismissableErrors : [],
        }
    },

    created() {
        this.fetchLabourCategories();
    },

    mounted() {
        // Start with the job entry focused to allow the user to type straight away.
        this.$nextTick(() => {
            this.$refs.jobLookup.focus();
        });
    },

    updated() {
        // Re-enable live validation, if a reset has been performed.
        if (true === this.resetting) {
            this.resetting = false;
        }
    },

    /**
     * Called when navigating away from this component. Check for any
     * unsaved changes and notify user.
     */
    beforeRouteLeave (to, from, next) {
        let leave = true;
        if (this.isDirty) {
            leave = window.confirm('You have unsaved changes, do you want to leave?')
        }
        next(leave);
    },
    
    computed: {
        ...mapState('engineer', {
            engineers: 'engineers',
        }),

        ...mapGetters('auth', [
            'userInitials',
        ]),

        /**
         * Returns true if the selected jobs is a time and attendance
         * job, otherwise false.
         */
        isTimeAttendanceJob() {
            const jobId      = this.job.value ? this.job.value.Id : '';
            // Check if second character is a 'z'.
            const attendance = ('z' === jobId.charAt(1).toLowerCase());
            return attendance;
        },

        /**
         * Returns the calculated duration between the specified start
         * and end datetimes.
         */
        calculateDuration() {
            let duration = null;
            if ((true === this.start.valid) && (true === this.end.valid)) {
                duration = this.getDuration(this.start.value, this.end.value);
            }
            return duration;
        },

        /**
         * Returns true if a job with defined flat rates has been
         * selected and the selected activity type is labour, otherwise
         * false.
         */
        displayFixedRates() {
            const job     = this.job.value;
            const display = (null !== job)                                  &&
                            (true === Array.isArray(job.FlatRateTasks))     &&
                            (0 < job.FlatRateTasks.length)                  &&
                            (JobActivityType.LABOUR === this.activity.value);
            return display;
        },

        /**
         * Returns the index of the selected flat rate, if no flat rate
         * is selected -1 is returned.
         */
        fixedRateIndex() {
            let id = -1;
            if (null !== this.fixedRate) {
                // Fixed rates are one indexed in Gold so add one.
                id = this.job.value.FlatRateTasks.indexOf(this.fixedRate) + 1;
            }
            return id;
        },

        /**
         * Returns true if all properties are valid, otherwise false.
         */
        isValid() {
            const isValid = this.job.valid      &&
                            this.engineer.valid &&
                            this.start.valid    &&
                            this.end.valid      &&
                            this.duration.valid;
            return isValid;
        },

        /**
         * Returns true if data has been inputted into the form.
         */
        isDirty() {
            const isDirty = (null !== this.job.value     ) ||
                            (null !== this.engineer.value) ||
                            (''   !== this.start.value   ) ||
                            (''   !== this.end.value     ) ||
                            (null !== this.comment       );
            return isDirty;
        },

        /**
         * Returns true if an activity is being uploaded or
         * approved, otherwise false.
         */
        isSaving() {
            const isSaving = true === this.create.uploading  ||
                             true === this.approve.approving;
            return isSaving;
        }
    },
    
    watch: {
        'job.value'(newValue, oldValue) {
            if (false === this.resetting) {
                this._validateJob();
            }
        },
        'engineer.value'(newValue, oldValue) {
            if (false === this.resetting) {
                this._validateEngineer();
            }
        },
        'start.value'(newValue, oldValue) {
            if (false === this.resetting) {
                this._validateStart();
                // Validate end if present.
                if ('' !== this.end.value) {
                    this._validateEnd();
                }
            }
        },
        'end.value'(newValue, oldValue) {
            if (false === this.resetting) {
                this._validateEnd();
            }
        },
        'calculateDuration'(newValue, oldValue) {
            this.duration.value = newValue;
            if (false === this.resetting) {
                this._validateDuration();
            }
        },
        isTimeAttendanceJob(newValue, oldValue) {
            // For time and attendance job change activity type to ATTENDANCE (3).
            if (true === newValue) {
                this.activity.value = this.activity.types.ATTENDANCE;
            }
        }
    },
    
    methods: {
        /**
         * Validate all input components.
         */
        validateForm() {
            this._validateJob();
            this._validateEngineer();
            this._validateStart();
            this._validateEnd();
        },

        /**
		 * Returns a new job activity model constructed from the data
         * entered into this component.
         * @returns {object} Job activity model.
		 */
		createJobActivity() {
			const jobActivity = {
				JobId: 			    this.job.value.Id,
				EngineerId: 	    this.engineer.value.Id,
				Status: 			JobActivityStatus.LOGGED,
                Type: 			    this.activity.value,
				Start: 			    this.start.value,   // UTC DateTime.
				End: 			    this.end.value,     // UTC DateTime.
				Duration: 			this.duration.value,
				Comment: 			this.comment,
                FlatRate:           this.fixedRateIndex,
                CreatedOn: 			this.getDateTime(Date.now()),
				CreatedBy:			this.userInitials,
				CreatedPlatform:	PLATFORM
            };
			return jobActivity;
        },
        
        /**
		 * Upload job activity to the API server.
         * @param {object} jobActivity - Job activity model.
		 */
		async uploadJobActivity(jobActivity) {
            this.create.uploading = true;

            try {
                const insertResponse = await JobActivityApi.insert(jobActivity);
                this.jobActivity     = insertResponse.data.data;
                this.create.success  = true;
                // Approve activity, if requested.
                if (true === this.approve.value) {
                    this.approveJobActivity(this.jobActivity);
                }
                else {
                    this.showModal = true;
                }
            }
            catch(failure) {
                const error = `Failed to add time. ${this.getResponseError(failure)}`;
                this.dismissableErrors.push(error);
            }
            finally {
                this.create.uploading = false;
            }
        },

        /**
         * Approve job activity on the API server.
         * @param {object} jobActivity - Job activity model.
         */
        async approveJobActivity(jobActivity) {
            this.approve.approving = true;

            // Set approved properties.
            jobActivity.ApprovedStart    = jobActivity.Start;
            jobActivity.ApprovedEnd      = jobActivity.End;
            jobActivity.ApprovedDuration = jobActivity.Duration;

            try {
                await JobActivityApi.update(jobActivity);
                const approveResponse = await JobActivityApi.approve(jobActivity, this.labourRate);
                this.jobActivity      = approveResponse.data.data;
                this.approve.success  = true;
            }
            catch(failure) {
                const error = `Failed to approve time. ${this.getResponseError(failure)}`;
                this.dismissableErrors.push(error);
            }
            finally {
                this.approve.approving = false;
                this.showModal         = true;
            }
        },
        
        /**
		 * Fetches all labour categories for all 
		 * labour rates.
		 */
        async fetchLabourCategories() {
            const me                    = this;
            me.labourCategories.error   = null;
            me.labourCategories.loading = true;

            try {
                const response = await EngineerApi.getLabourCategories();
                me.labourCategories.value = response.data.data;
            }
            catch(failure) {
                const error = `Failed to get labour categories: ${failure.message}`;
                console.error(error);
                me.labourCategories.value = [];
                me.labourCategories.error = error;
            }
            finally {
                me.labourCategories.loading = false;
            }
        },
        
        /**
         * Reset the form.
         */
        reset() {
            // Disable live validation.
            this.resetting          = true;
            // Clear down form fields.
            this.job.value          = null;
            this.engineer.value     = null;
            this.start.value        = '';
            this.end.value          = '';
            this.activity.value     = JobActivityType.LABOUR;
            this.fixedRate          = null;
            this.comment            = null;
            this.approve.value      = false;
            // Clear down state.
            this.create.success     = false;
            this.create.error       = null;
            this.approve.success    = false;
            this.approve.error      = null;
            this.jobActivity        = null;
        },

        /** 
         * Get message from response, if there is one.
         * @param {object} responseError - Error response object.
         */
        getResponseError(responseError) {
            let error = '';
            if (responseError.response && responseError.response.data) {
                error += responseError.response.data.message;
            }
            else {
                error += responseError.message;
            }
            return error;
        },

        /**
         * Handles the click event for the save button.
         */
        handleSave() {
            // Fail fast if a save operation is already in progress.
            if (true === this.isSaving) {
                console.warn("Cannot save changes. A save operation is already in progress.")
                return;
            }

            this.validateForm();
            if (true === this.isValid) {
                const jobActivity = this.createJobActivity();
                this.uploadJobActivity(jobActivity);
            }
            else {
                console.warn('Unable to create job activity. Missing and/or invalid information provided.');
            }
        },

        /**
         * Handles when the user chooses the 'Yes' option on the modal
         * to create another job activity.
         * @param {object} event - Event object returned form modal.
         */
        handleYes(event) {
            this.reset();
        },

        /**
         * Handles when the user chooses the 'No' option on the modal
         * to be returned to the previous view.
         * @param {object} event - Event object returned form modal.
         */
        handleNo(event) {
            this.reset();
            this.$router.go(-1);
        },

        /**
         * Validate a job has been selected.
         */
        _validateJob() {
            this.job.valid = (null !== this.job.value);
        },
        /**
         * Validate an engineer has been selected.
         */
        _validateEngineer() {
            this.engineer.valid = _isObject(this.engineer.value);
        },
        /**
         * Validate start is a valid date/time.
         */
        _validateStart() {
            this.start.valid = this.validateDateTime(this.start.value);
        },
        /**
         * Apply all validation rules for end.
         */
        _validateEnd() {
            let isValid = this._validateEndIsValid();
            if ((true === isValid) && ('' !== this.start.value)) {
                isValid = this._validateEndIsAfterStart();
            }
            this.end.valid = isValid;
        },
        /**
         * Validate end is a valid date/time.
         */
        _validateEndIsValid() {
            const isValid = this.validateDateTime(this.end.value);
            if (false === isValid) {
                this.end.error = undefined;
            }
            return isValid;
        },
        /**
         * Validate the end date/time occurs after the start date/time.
         */
        _validateEndIsAfterStart() {
            const isValid = this.isDateAfter(this.end.value, this.start.value);
            if (false === isValid) {
                this.end.error = "End must occur after start";
            }
            return isValid;
        },
        /**
         * Validate if duration is positive.
         */
        _validateDuration() {
            this.duration.valid = this.validateDuration(this.duration.value);
        },
        _onRateChanged(newRate) {
            this.labourRate = newRate;
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../../assets/styles/ib-transitions';

.fade-in, .fade-out {
    animation-duration: 0.25s;
    -webkit-animation-duration: 0.25s;
    animation-fill-mode: both;
}

.rate-row {
    opacity: 0;
}

.label {
    width: 125px;
}

.btn-group-toggle {
    .btn {
        width: 78px;
    }
    .btn-outline-success:hover {
        color: #28a745;
        background-color: transparent;
    }
    .btn-outline-primary:hover {
        color: #007bff;
        background-color: transparent;
    }
}
</style>
