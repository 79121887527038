
const OrderNumberMixin = {
    methods: {
        shortenGuid(longGuid) {
            return "" + longGuid[0] + longGuid[4] + longGuid[7] + longGuid[10] + longGuid[14] + longGuid[20];
        }
    }
};

export default OrderNumberMixin;
