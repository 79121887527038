<template>

    <input :id="id"
           type='text'
           class="form-control"
           v-model="maskedText"
           placeholder="00:00"
           :class="{ 'invalid': !isValid }"
           @focus="$event.target.select()"/>

</template>

<script>
import IbMaskedInput from '@/components/form/IbMaskedInput';

/**
 * This component is for the entry of time values, such as 12:34.
 * 
 * Extends @see IbMaskedInput masking text with '##:##' and a
 * max length of 4. Using regex to validate that it is a valid
 * time.
 */
export default {
    name: 'IbTimeInput',

    extends: IbMaskedInput,

    props: {
        /**
         * The mask that is applied to the text.
         */
        mask: {
            required: false,
            default: '##:##'
        },
        /**
         * The maximum number of characters to allow
         * the user to enter into the input control.
         */
        maxLength: {
            required: false,
            default: 4
        },
        /**
         * The regex that if supplied is used to validate
         * the masked text.
         */
        regex: {
            required: false,
            default: () =>  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
        },
        /**
         * The default character that if supplied is
         * used in place of token characters where
         * there is no value character to test.
         */
        defaultCharacter: {
            required: false,
            default: '0'
        },
        /**
         * The default value returned if there is no
         * masked text value.
         */
        defaultValue: {
            required: false,
            default: '00:00'
        }
    }
}
</script>

<style lang="less" scoped>
@import './../../assets/colours';

.invalid {
    border: solid 3px @danger !important;
}

</style>
