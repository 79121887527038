<template>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">
        <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>
        <advice-note-modal
            v-model             ="showAdviceNoteModal"
            :order              ="selectedOrder"
            :customers          ="customers"
            :customersLoading   ="customersLoading"
            :operators          ="operators"
            :operatorsLoading   ="operatorsLoading"
            :memoParts          ="memoParts"
            :memoPartsLoading   ="memoPartsLoading"
            @generateAdviceNote ="generateAdviceNote"
        />
        <ib-modal
        v-model="showQuantityWarningModal"
        modalClass="col-4 p-2"
        :dismissable="false"
        >
            <div class="container-fluid h-100">
                <div class = "row p-2">
                    <h3><i class="fas fa-exclamation-triangle"></i> Warning</h3>
                </div>
                    Customer orders exceed stock on hand. Continue?
                <hr>
                <div class = "float-right">
                    <button type="button" class="btn btn-secondary" @click="showQuantityWarningModal=false">No</button>&nbsp; 
                    <button type="button" class="btn btn-success" @click="acceptSelectedOrder">Yes</button>
                </div>
            </div> 
        </ib-modal>

        <ib-modal
        v-model="showConfirmReject"
        modalClass="col-4 p-2"
        :dismissable="false"
        >
            <div class="container-fluid h-100">
                <div class = "row p-2">
                    <h3><i class="fas fa-exclamation-triangle"></i> Warning</h3>
                </div>
                    Are you sure you want to reject this order?
                <hr>
                <div class = "float-right">
                    <button type="button" class="btn btn-secondary" @click="showConfirmReject=false">No</button>&nbsp; 
                    <button type="button" class="btn btn-danger" @click="rejectOrder">Reject Order</button>
                </div>
            </div> 
        </ib-modal>
        
        <div v-if="!ordersLoading">
            <div class="h-100 w-100">
                <div class="row h-100">
                    <div class="col-lg-3 col-md-3 col-sm-3"> <!-- Start of left hand column -->
                        <div class="row p-2"> <!-- Start of Filters Header -->
                        <div> 
                            <h5>Filters</h5>
                        </div>
                    </div> <!-- End of Filters Header -->

                    <!-- Transaction Status -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Status:</div>
                            <div class="col-9">
                                <ib-select-entry id="statuses"
                                    :items="statusList"
                                    noItemsMessage="No statuses available"
                                    displayProperty="text"
                                    v-model="filters.status"
                                ></ib-select-entry>
                            </div>
                        </div>

                        <!-- Transaction Buyer -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Buyer:</div>
                            <div class="col-9">
                            <ib-select-entry id="buyers"
                                :items="buyersFilterList"
                                noItemsMessage="No buyers available"
                                v-model="filters.buyer"
                            ></ib-select-entry>
                            </div>
                        </div>


                        <!-- Depot -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Depot:</div>
                                <div class="col-9">
                                <ib-select-entry id="depots"
                                    :items="depots"
                                    noItemsMessage="No depots available"
                                    v-model="filters.depot"
                                ></ib-select-entry>
                            </div>
                        </div>
                    
                        <!-- From Date -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">From:</div>
                                <div class="col-9">
                                <ib-date-picker id="from"
                                    placeholder="From"
                                    :maxValue="filters.toDate"
                                    v-model="filters.fromDate"
                                ></ib-date-picker>
                            </div>
                        </div>

                        <!-- To Date -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">To:</div>
                                <div class="col-9">
                                <ib-date-picker id="to"
                                    placeholder="To"
                                    :minValue="filters.fromDate"
                                    v-model="filters.toDate"
                                ></ib-date-picker>
                            </div>
                        </div>

                        <!-- Sort by -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Sort By:</div>
                                <div class="col-9">
                                <ib-select-entry id="sortBy"
                                    :items="sortList"
                                    noItemsMessage="No statuses available"
                                    v-model="filters.sortBy"
                                ></ib-select-entry>
                            </div>
                        </div>


                </div> <!-- end of left column -->

                <div class="col-lg-9 col-md-9 col-sm-9"> <!-- Start of right hand column -->

                    <div class="row p-2"> <!-- Start of Sales Header -->
                        <div class="flex-fill">
                            <span><h5>Sales</h5></span>
                        </div>
                        <div class="flex-fill">
                            <span class="pl-1 align-items-center" style="float:right"> 
                                <button class="btn btn-primary mr-2"
                                    @click="refresh"
                                    :disabled="ordersLoading === true"
                                >
                                    <transition name="fade" mode="out-in">
                                        <span v-if="true === ordersLoading" key="spinner">
                                            <i class="fas fa-sync-alt fa-spin"></i>
                                        </span>
                                        <span v-else key="fixed">
                                            <i class="fas fa-sync-alt"></i>
                                        </span>
                                    </transition>
                                    <span class="ml-2">Refresh</span>
                                </button>
                            </span>
                        </div>
                    </div> <!-- End of Sales Header -->

                    <div style="overflow-y: auto; height:calc(100vh - 70px);"> <!-- Start of Sales Area -->
                            <div v-for="(order,orderIndex) in filteredOrders" :key=orderIndex :item="order">
                                <order-card-renderer 
                                    v-bind:order="order"
                                    @acceptWithoutVerification="acceptOrder(order)"
                                    @verifyQuantity="showQuantityWarning(order)"
                                    @rejectOrder="showRejectConfirmation(order)"
                                    @showAdviceNoteModal="showAdviceNote(order)"
                                    :audienceIsBuyer="false"
                                />
                            </div>
                    </div>
                </div>
            </div>
            </div>
            
        </div>
        <div v-else>
            <ib-loading-spinner 
                message="Loading Sales.."
                class="flex-grow-1"
            />
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p>
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</template>
<script>
import TransactionApi               from '@MP/api/transaction';
import ClientApi                    from '@MP/api/client';
import IbLoadingSpinner             from '@/components/IbLoadingSpinner';
import IbDatePicker                 from '@/components/form/IbDatePicker';
import IbSelectEntry                from '@/components/form/IbSelectEntry';
import IbModal                      from '@/components/utilities/IbModal';
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import { DateTime }                 from 'luxon';
import OrderNumberMixin             from '@/mixins/OrderNumberMixin';
import LicenceMixin                 from '@/mixins/LicenceMixin';
import AdviceNoteModal              from '@MP/components/AdviceNoteModal';
import { DocStoreOptions }          from '@/common/docstore-options';
import OrderCardRenderer            from '@MP/components/OrderCardRenderer'
import CustomerApi                  from '@WS/api/customer';
import SalesApi                     from '@/common/sales';
import PartsApi                     from '@WS/api/parts';
import MarketplacePasswordModal     from '@MP/components/MarketplacePasswordModal';

import { 
    mapGetters,
    mapActions,
    mapState
}       from 'vuex';

const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE
];

export default {
    name: 'MarketplaceSales',

    components: {
        IbLoadingSpinner,
        IbDatePicker,
        IbSelectEntry,
        OrderCardRenderer,
        IbModal,
        CustomerApi,
        AdviceNoteModal,
        SalesApi,
        MarketplacePasswordModal
    },

    mixins: [
        DateMixin,
        LicenceMixin,
        OrderNumberMixin
    ],

    data() {
        return { 
            filters: {
                status       : {},
                fromDate     : '',
                toDate       : '',
                buyer        : '- All Buyers -',
                depot        : '- All Depots -',
                sortBy       : 'Oldest Order First',
                type         : 'Sales'
            },

            showPasswordModal : true,

            showAdviceNoteModal :  false,

            statusList: [ {"value": -1, "text": "- All Statuses -"}, 
                          {"value": -2, "text": "- Action Required -"}, 
                          {"value": 20, "text": "Ready to Accept/Reject"}, 
                          {"value": 30, "text": "Awaiting Purchase Order"}, 
                          {"value": 40, "text": "Ready for Advice Note"},
                          {"value": 50, "text": "Advice Note Generated"},
                          {"value": 90, "text": "Buyer Cancelled"},
                          {"value": 99, "text": "Declined"}
                        ],
            sortList: [ "Oldest Order First", "Newest Order First", "Buyer" ],
            filteredOrders: [],
            buyers: [],
            buyersFilterList: [],
            depots: [],
            requiredLicenses : requiredLicenses,
            showQuantityWarningModal : false,

            customers       : [],
            customersLoading: false,
            operators       : [],
            operatorsLoading: false,
            memoParts       : [],
            memoPartsLoading: false,

            showConfirmReject : false,
            selectedOrder : { serverInfo: {price: 0, quantity: 0}, clientInfo: {}} 
       }
    },

    watch: {
        'filters.status.value': {
            immediate: true,
            handler  : function(newStatus){
                this.filterOrders();
            }
        },
        'filters.buyer': {
            immediate: true,
            handler  : function(newBuyer){
                this.filterOrders();
            },
        },
        'filters.depot': {
            immediate: true,
            handler  : function(newDepot){
                this.filterOrders();
            }
        },
        'filters.sortBy': {
            immediate: true,
            handler  : function(newSort){
                this.filterOrders();
            }
        },
        'filters.fromDate': {
            immediate: false,
            handler  : function(newDate, oldDate){
                if(oldDate != "") {
                    console.debug("from date changed from "+ oldDate +" to " + newDate + ", refreshing");
                    this.refresh();
                }
            }
        },
        'filters.toDate': {
            immediate: false,
            handler  : function(newDate, oldDate){
                if(oldDate != "") {
                    console.debug("to date changed from "+ oldDate +" to " + newDate + ", refreshing");
                    this.refresh();
                }
            }
        },

    },

    computed: {
      ...mapGetters('marketplaceorders', {
          orders         : 'orders',
          ordersLoading  : 'ordersLoading',
          ordersError    : 'ordersError',
      })
    },

    methods: {
        ...mapActions('marketplaceorders', {
          fetchOrders   : 'fetchOrders',
          sellerAcceptOrder : 'sellerAcceptOrder',
          sellerRejectOrder : 'sellerRejectOrder',
          sellerGenerateAdvice : 'sellerGenerateAdvice'
        }),

        async passwordValidated()
        {
            await this.initialiseFilters();
            await this.refresh();
            this.showPasswordModal = false; 
        },


        showAdviceNote(order)
        {
            this.selectedOrder = order;
            this.showAdviceNoteModal = true;
        },

        showQuantityWarning(order)
        {
            this.selectedOrder = order;
            this.showQuantityWarningModal = true;
        },
        
        showRejectConfirmation(order)
        {
            this.selectedOrder = order;
            this.showConfirmReject = true;
        },

        async acceptSelectedOrder()
        {
            await this.acceptOrder(this.selectedOrder);
        },

        async generateAdviceNote(adviceNoteDetails)
        {
            try
            {
                await this.sellerGenerateAdvice(adviceNoteDetails);

                if (!this.ordersError)
                {
                    this.toast('b-toaster-top-right', 'success', 'advice generated for order ' + this.shortenGuid(this.selectedOrder.serverInfo.id), true);
                }
                else
                {
                    this.toast('b-toaster-top-right', 'danger', this.ordersError, true);
                }
                this.showAdviceNoteModal = false;
                await this.refresh();
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
        },

        async rejectOrder()
        {
            try
            { 
                this.showConfirmReject = false;
                await this.sellerRejectOrder(this.selectedOrder);
                this.toast('b-toaster-top-right', 'success', 'order ' + this.shortenGuid(this.selectedOrder.serverInfo.id) + ' rejected', true);
                await this.refresh();
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
        },

        async acceptOrder(order)
        {
            try
            { 
                this.showQuantityWarningModal = false;
                await this.sellerAcceptOrder(order);
                this.toast('b-toaster-top-right', 'success', 'order ' + this.shortenGuid(order.serverInfo.id) + ' accepted', true);
                await this.refresh();
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
        },

        async refresh() {
            await this.loadOrders();
            await this.loadCustomers();
            await this.loadOperators();
            await this.loadMemoParts();

            this.filterOrders();
        },
        
        async AcceptTransaction(transaction)
        {
            await TransactionApi.acceptTransaction(transaction);
        },

        async loadOrders() {
            console.log("Loading Orders");
            await this.fetchOrders(this.filters);
            console.log("Orders loaded");

            if(this.orders.length > 0) {
                this.buyers = this.orders.map(item => item.serverInfo.buyerCompanyName)
                .filter((value, index, self) => self.indexOf(value) === index);
                this.buyers = _.sortBy( this.buyers);
                this.buyersFilterList = this.buildSelectList(this.buyers,"- All Buyers -");

                this.depots = this.orders.map(item => item.serverInfo.sellerDepotName)
                .filter((value, index, self) => self.indexOf(value) === index);
                this.depots = _.sortBy( this.depots);
                this.depots = this.buildSelectList(this.depots,"- All Depots -");
            }
            else {
                this.buyersFilterList = ["- All Buyers -"];
                this.filters.buyer = this.buyersFilterList[0];
                this.depots = ["- All Depots -"];
                this.filters.depot = this.depots[0];
            }

            if(this.filters.buyer === undefined)
                this.filters.buyer = this.buyersFilterList[0];
            if(this.filters.depot === undefined)
                this.filters.depot = this.depots[0];
        },

        filterOrders() {
            let actionableStatuses = [20, 40];
            this.filteredOrders = this.orders.filter(
                      order => (order.serverInfo.status           === this.filters.status.value || this.filters.status.value === -1 || this.filters.status.value === -2 && actionableStatuses.includes(order.serverInfo.status))  &&
                               (order.serverInfo.sellerDepotName  === this.filters.depot        || this.filters.depot        === '- All Depots -') &&
                               (order.serverInfo.buyerCompanyName === this.filters.buyer        || this.filters.buyer        === '- All Buyers -')
            );

            if(this.filters.sortBy === "Oldest Order First") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'serverInfo.orderCreated');
            }
            if(this.filters.sortBy === "Newest Order First") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'serverInfo.orderCreated').reverse();
            }
            if(this.filters.sortBy === "Buyer") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'serverInfo.buyerCompanyName' );
            }
        },

        async initialiseFilters() {
            console.debug("initialising filters");
            this.filters.toDate     = this.getDate(new Date());
            this.filters.fromDate   = this.subtractMonths(3, this.filters.toDate);
            this.filters.status = this.statusList[1];
            console.debug("filters initialised");
        },

         buildSelectList(array, defaultItem) {
            let items = []
            if (Array.isArray(array) && (0 < array.length)) {
                // Create new array with defaultItem as first item.
                items.push(defaultItem);
                items.push(...array);
            }
            return items;
        },

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Marketplace Sales`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

        async loadCustomers() {
            console.debug("loading customers");
            this.customers = [];
            this.customersLoading = true;
            let customers = await CustomerApi.getAllCustomers();
            customers.data.data.forEach(c => this.customers.push({id: c.AccountNo, name: c.AccountNo + " - " + c.Name, onStop:c.Stop}))
            this.customers = _.orderBy(this.customers, ['name'],['asc']);

            this.customersLoading = false;
        },
    
        async loadOperators() {
            console.debug("loading operators");
            this.operators = [];
            this.operatorsLoading = true;
            let operators = await SalesApi.getAllOperators();
            operators.data.data.forEach(o => this.operators.push({
                id:       o.Storeman, 
                name:     o.Storeman + " - " + o.StoName, 
                depot:    o.Adepot,
                password: o.Password,
                level:    o.Level,
                leavingDate: o.LeavingDate
            }))
            this.operators = _.orderBy(this.operators, ['name'],['asc']);

            this.operatorsLoading = false;
        },
    
        async loadMemoParts() {
            console.debug("loading memo parts");
            this.memoParts = [];
            this.memoPartsLoading = true;
            let memoParts = await PartsApi.getMemoParts();
            console.debug(memoParts);
            memoParts = memoParts.data.data.filter(p => p.KitPart == "No");
            memoParts.forEach(p => this.memoParts.push({
                 id         : p.PartNumber,
                 name       : p.PartNumber + " - " + p.Description,
                 depot      : p.DepotId
             }))
            this.memoParts = _.orderBy(this.memoParts, ['id'],['asc']);

            this.memoPartsLoading = false;
        }
    }
}
</script>