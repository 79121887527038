<template>
<div>
    <div class="d-flex">
        <ib-date-input 
            v-model="components.date"
            class="mr-1 entry"
            :valid="valid"
            hideError
        />

        <div class="entry d-flex input-group">

            <ib-time-input
                v-model="components.time"
                :class="{ 'is-invalid' : false === valid }"
            />

            <div class="input-group-append">
                <button 
                    type="button"
                    class="btn ib-append-button input-group-append"
                    :class="{ 'invalid': false === valid }"
                    @click="showPopup = true"
                    tabindex="-1"
                >
                    <i class="far fa-calendar-alt"></i>
                </button>
            </div>
        </div>
    </div>

    <div
        class="visible error-feedback"
        :class="{ 'invisible' : valid }"
    >
        {{ validationError }}
    </div>

    <ib-date-time-popup 
        v-model="popupDate"
        :show="showPopup"
        @cancel="showPopup = false"
    />

</div>
</template>
    
<script>
// Setup vue-datetime CSS
import 'vue-datetime/dist/vue-datetime.css';
import DateMixin, { DateFormat } from '@/mixins/DateMixin';

import IbDateTimePopup from '@/components/form/DateTime/IbDateTimePopup';
import IbDateInput     from '@/components/form/IbDateInput';
import IbTimeInput     from '@/components/form/IbTimeInput';

export default {
    name: 'IbDateTimePicker',

    mixins: [
        DateMixin
    ],

    // Wraps the vue-datetime component https://www.npmjs.com/package/vue-datetime.
    components: {
        IbDateInput,
        IbDateTimePopup,
        IbTimeInput,
    },
    
    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            type:       String
        },
        /**
         * Minimum date time value.
         */
        minValue: {
            type:       String,
            default:    null
        },
        /**
         * Maximum date time value.
         */
        maxValue: {
            type:       String,
            default:    null
        },
        /**
         * Maximum date time value.
         */
        placeholder: {
            type:       String
        },
        /**
         * Set to false to display validation error.
         */
        valid: {
            type:       Boolean,
            default:    true
        },
        /**
         * The message to display when the valid prop
         * is set to false.
         */
        validationError: {
            type:       String,
            default:    'Please select a date and time'
        }
    },
    
    data() {
        return {
            internalValue : this.value,
            date          : null,
            time          : null,
            showPopup     : false,
            popupDate     : this.internalValue,
            components: {
                date : null,
                time : null
            }
        }
    },

    computed: {
        // CSS classes to apply to the input control of the
        // datetime component.
        inputClasses() {
            let classes = 'form-control';
            if (false === this.valid) {
                classes += ' is-invalid'
            }
            return classes;
        }
    },
    
    watch: {
        /**
         * Update this component side of the v-model binding
         * with changes from the parent.
         */
        value(newValue, oldValue) {
            this.internalValue = newValue;
        },

        /**
         * Update the parent side of the v-model binding with
         * changes from this component.
         */
        internalValue(newValue, oldValue) {
            this.$emit('input', newValue);
        }
    },

    methods: {
        
        confirm(dateTime) {
            this.date = dateTime.toUTC();
            this.showPopup = false;
        }

    },

    watch: {

        popupDate(newValue) {
            this.showPopup = false;
            if (newValue) {
                this.components.date = this.getDateObject(newValue);
                this.components.time = this.getDateString(newValue, DateFormat.TIME_STAMP) || '';
            }
        },

        components: {
            deep: true,
            handler: function (newValue) {             
                const dateString = this.getDateString(this.components.date.toISOString(), DateFormat.DATE_ONLY);
                const date       = this.getDateObject(`${dateString} ${this.components.time}`, 'DD/MM/YYYY HH:mm')

                this.internalValue = date.toISOString();
            }
        },

        internalValue() {
            this.$emit('input', this.internalValue);
        }

    }
}
</script>
    
<style lang="less" scoped>
@import '../../assets/styles/ib-date-time-picker';
@import '../../assets/styles/ib-common';

.entry {
    flex: 1 1 0;
}

</style>
