const GOLD_SERVICE_PLUS                 = 'SA2';
const GOLD_SVAP_SINGLE_DEPOT            = 'JS1';
const GOLD_SVAP_MULTI_DEPOT             = 'JS2';
const GOLD_JCB_LIVELINK_SINGLE_DEPOT    = 'JC1';
const GOLD_JCB_LIVELINK_MULTI_DEPOT     = 'JC2';
const GOLD_PORTAL_JOB_REQUEST           = 'PRQ';
const GOLD_MARKETPLACE                  = 'MK1';
const GOLD_SCHEDULER                    = 'MWS';
const CLAAS_DSM                         = 'DSM';
const MANITOU_PORTAL                         = 'MN2';

const DocStoreOptionCodes = {
    34  : 'AGR',
    35  : 'AGZ',
    110 : 'AP1',
    111 : 'AP2',
    113 : 'AP3',
    125 : 'AW1',
    126 : 'AW2',
    118 : 'AW3',
    119 : 'AW4',
    43  : 'CB1',
    44  : 'CB2',
    140 : 'CL1',
    141 : 'CL2',
    124 : 'CL3',
    128 : 'CL4',
    122 : 'CNP',
    27  : 'CPA',
    28  : 'CPB',
    29  : 'CPC',
    30  : 'CPD',
    25  : 'DOW',
    109 : 'DPM',
    902 : 'FAG',
    805 : 'FDE',
    904 : 'FJD',
    900 : 'FNH',
    801 : 'FOP',
    808 : 'FSH',
    903 : 'FZE',
    804 : 'IDB',
    130 : 'IP1',
    135 : 'IP2',
    41  : 'ISG',
    42  : 'ISM',
    104 : 'JA1',
    105 : 'JA2',
    31  : 'JCA',
    32  : 'JCB',
    36  : 'JCC',
    37  : 'JCD',
    121 : 'JDQ',
    100 : 'JP1',
    101 : 'JP2',
    120 : 'JUE',
    102 : 'JW1',
    103 : 'JW2',
    106 : 'JWA',
    107 : 'JWB',
    108 : 'JWC',
    116 : 'JWD',
    117 : 'JWE',
    33  : 'KPI',
    806 : 'LOM',
    123 : 'MS1',
    510 : 'MS2',
    132 : 'MS4',
    133 : 'MS5',
    600 : 'NFA',
    112 : 'PCA',
    800 : 'PEF',
    40  : 'PLO',
    802 : 'PPC',
    49  : 'PRQ',
    550 : 'PUN',
    115 : 'RPE',
    511 : 'SA1',
    512 : 'SA2',
    513 : 'SA3',
    39  : 'SMD',
    803 : 'SMS',
    38  : 'SSD',
    26  : 'V65',
    901 : 'VOL',
    807 : 'WWW',
    509 : 'XCD',
    506 : 'XCL',
    508 : 'XDB',
    507 : 'XGE',
    505 : 'XIB',
    501 : 'XPD',
    502 : 'XSI',
    503 : 'XWA',
    504 : 'XWG',
    21  : 'YAP',
    1   : 'YCV',
    23  : 'YMP',
    13  : 'YNC',
    15  : 'YPA',
    9   : 'YPM',
    17  : 'YPP',
    7   : 'YPT',
    11  : 'YSU',
    6   : 'YWG',
    3   : 'YWJ',
    19  : 'YWP',
    22  : 'ZAP',
    2   : 'ZCV',
    24  : 'ZMP',
    14  : 'ZNC',
    16  : 'ZPA',
    10  : 'ZPM',
    18  : 'ZPP',
    8   : 'ZPT',
    12  : 'ZSU',
    5   : 'ZWG',
    4   : 'ZWJ',
    20  : 'ZWP',
    45  : 'JS1',
    46  : 'JS2',   
    47  : 'JC1',
    48  : 'JC2',
    144 : 'AG1',
    148 : 'MK1',
    68  : 'MWS',
    906 : 'DSM'
};

const DocStoreOptionNames = {
    34  : 'AGROPARTS INTERFACE SINGLE DEPOT   ',
    35  : 'AGROPARTS INTERFACE MULTI DEPOTS   ',
    144 : 'AGCO ECOMMERCE                     ',
    110 : 'Agco Parts E Billing Single Depot  ',
    111 : 'Agco Parts E Billing Multi Depot   ',
    113 : 'Agco Parts E Billing Mul Dep <30   ',
    125 : 'AGCO Warranty Single Depot         ',
    126 : 'AGCO Warranty Multi Depot          ',
    118 : 'AGCO WARRANTY SINGLE DEPOT         ',
    119 : 'AGCO WARRANTY MULTI DEPOT          ',
    43  : 'CNH EBILLING SINGLE DEPOT          ',
    44  : 'CNH EBILLING MULTI DEPOT           ',
    140 : 'Claas Parts eBilling Single Depot  ',
    141 : 'Claas Parts eBilling Multi Depot   ',
    124 : 'Claas Parts Inventory Manager - SD ',
    128 : 'Claas Parts Inventory Manager - MD ',
    122 : 'CNH Prim                           ',
    27  : 'CNH PRIM - 1 Depot Max 12 Gold Usrs',
    28  : 'CNH PRIM - 1 Depot 12+ Gold Users  ',
    29  : 'CNH PRIM for 2 Depots              ',
    30  : 'CNH PRIM for 3 or more Depots      ',
    25  : 'Document Store .PDF Writer         ',
    109 : 'John Deere DPM - JD PRISM/PMManage ',
    902 : 'Agco Franchise                     ',
    805 : 'Forms Designer                     ',
    904 : 'John Deere Franchise (DFA)         ',
    900 : 'New Holland Franchise              ',
    801 : 'Forms Printing                     ',
    808 : 'Hitachi Finance Stocking Scheme    ',
    903 : 'Main Franchise Zetor Tractors      ',
    804 : 'Ibcos Data Base                    ',
    130 : 'Parts E-Billing - Ibcos Format     ',
    135 : 'Parts EDI Orders - Ibcos Format    ',
    41  : 'KUHN PART LOCATOR SNG DEPOT        ',
    42  : 'KUHN PART LOCATOR MULTI DEPOT      ',
    104 : 'JD EBill - Warranty Single Depot   ',
    105 : 'JD EBill - Warranty Multi Depot    ',
    31  : 'JCB WARRANTY SINGLE DEPOT          ',
    32  : 'JCB WARRANTY MULTI DEPOT           ',
    36  : 'JCB WARRANTY SINGLE DEPOT          ',
    37  : 'JCB WARRANTY MULTI DEPOT           ',
    121 : 'John Deere Quote                   ',
    100 : 'JD EBill - Parts Single Depot      ',
    101 : 'JD EBill - Parts Multi Depot       ',
    120 : 'John Deere Machine Finder Pro      ',
    102 : 'JD EBill - Wholegoods Single Depot ',
    103 : 'JD EBill - Wholegoods Multi Depot  ',
    106 : 'John Deere Warranty Single Depot   ',
    107 : 'John Deere Warranty 2 Depots       ',
    108 : 'John Deere Warranty 3+ Depots      ',
    116 : 'JD WARRANTY SINGLE DEPOT           ',
    117 : 'JD WARRANTY MULTIP DEPOT           ',
    33  : 'CNH SERVICE KPIS                   ',
    806 : 'Lombard Finance Stocking Scheme    ',
    123 : 'Merchant Services - Verifone       ',
    510 : 'Web CRM                            ',
    132 : 'VERIFONE SINGLE DEPOT              ',
    133 : 'VERIFONE MULTI DEPOT               ',
    600 : 'Nominal Fixed Assets Register V2   ',
    112 : 'Postcode Anywhere                  ',
    800 : 'Customer/Supplier Print Email Fax  ',
    40  : 'Loyalty Card Module                ',
    802 : 'Parts PDA Picking Confirmatuion    ',
    49  : 'PORTAL JOB REQUEST                 ',
    550 : 'Events & Pop Up Notes              ',
    115 : 'Ransomes Parts E-Billing           ',
    511 : 'Gold Service 1                     ',
    512 : 'GOLD SERVICE PLUS                  ',
    513 : 'GOLD SALES                         ',
    39  : 'STIHL PRODUCT REG. MULTI DEPOT     ',
    803 : 'Text Messages                      ',
    38  : 'STIHL PRODUCT REG. SINGLE DEPOT    ',
    26  : 'Version 65 VAT upload to HMRC      ',
    901 : 'Volvo Franchise                    ',
    807 : 'Gold links to the WWW from W/goods ',
    509 : 'Web - Customer Database            ',
    506 : 'Web - Claas                        ',
    508 : 'Web - Dash Board                   ',
    507 : 'Web - General                      ',
    505 : 'Web - Ibcos In House               ',
    501 : 'Web - PDMS Scheduler               ',
    502 : 'Web - System Internal              ',
    503 : 'Web - Wholegood Advice             ',
    504 : 'Web - wholegoods                   ',
    21  : 'Doc Store Warranty Cr Single Depot ',
    1   : 'Doc Store Cust Access Single Depot ',
    23  : 'Doc Store Misc Pur Inv Single Depot',
    13  : 'Doc Store Nom/CashB Single Depot   ',
    15  : 'Doc Store Parts & Orders Single Dep',
    9   : 'Doc Store Plan Maint Single Depot  ',
    17  : 'Doc Store Parts PurInv Single Depot',
    7   : 'Doc Store Plant access Single Depot',
    11  : 'Doc Store Supp access Single Depot ',
    6   : 'Doc Store WG access Single Depot   ',
    3   : 'Doc Store WSJ access Single Depot  ',
    19  : 'Doc Store WG PurInv Single Depot   ',
    22  : 'Doc Store Warranty Cr Multi Depot  ',
    2   : 'Doc Store Cust access Multi Depot  ',
    24  : 'Doc Store Misc Pur Inv Multi Depot ',
    14  : 'Doc Store Nom/CashB Multi Depot    ',
    16  : 'Doc Store Parts Multi Depot        ',
    10  : 'Doc Store Plan Maint. Multi Depot  ',
    18  : 'Doc Store Parts PurInv Multi Depot ',
    8   : 'Doc Store Plant access Multi Depot ',
    12  : 'Doc Store Supp access Multi Depot  ',
    5   : 'Doc Store WG access Multi Depot    ',
    4   : 'Doc Store WSJ access Multi Depot   ',
    20  : 'Doc Store WG PurInv Multi Depot    ',
    45  : 'JD SvAP Single Depot               ',
    46  : 'JD SvAP Multi Depot                ',
    47  : 'JCB LiveLink Single Depot          ',
    48  : 'JCB LiveLink Multi Depot           ',
    148 : 'MARKETPLACE MODULE                 ',
    68  : 'SCHEDULER MODULE                   ',
    906 : 'CLAAS DSM                          '
};

const DocStoreOptions = {
    GOLD_SERVICE_PLUS,
    GOLD_SVAP_MULTI_DEPOT,
    GOLD_SVAP_SINGLE_DEPOT,
    GOLD_JCB_LIVELINK_SINGLE_DEPOT,
    GOLD_JCB_LIVELINK_MULTI_DEPOT,
    GOLD_PORTAL_JOB_REQUEST,
    GOLD_MARKETPLACE,
    GOLD_SCHEDULER,
    CLAAS_DSM,
    MANITOU_PORTAL

};

export {
    DocStoreOptionCodes,
    DocStoreOptionNames,
    DocStoreOptions
};