<template>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">
        <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>
        <purchase-order-modal-v2
            v-model             ="showPurchaseOrder"
            :order              ="selectedBasket"
            :item               ="selectedOrder"
            :supplierAccounts   ="supplierAccounts"
            @generatePurchaseOrder="purchaseOrderGenerate"
        />
        <ib-modal
        v-model="showConfirmCancel"
        modalClass="col-4 p-2"
        :dismissable="false"
        >
            <div class="container-fluid h-100">
                <div class = "row p-2">
                    <h3><i class="fas fa-exclamation-triangle"></i> Warning</h3>
                </div>
                    Are you sure you want to cancel this order?
                <hr>
                <div class = "float-right">
                    <button type="button" class="btn btn-secondary" @click="showConfirmCancel=false">No</button>&nbsp; 
                    <button type="button" class="btn btn-danger" @click="CancelOrder">Cancel Order</button>
                </div>
            </div> 
        </ib-modal>
        <div v-if="!loading">

            <div class="h-100 w-100">
                <div class="row h-100">
                    <div class="col-lg-3 col-md-3 col-sm-3"> <!-- Start of left hand column -->
                        <div class="row p-2"> <!-- Start of Filters Header -->
                        <div> 
                            <h5>Filters</h5>
                        </div>
                    </div> <!-- End of Filters Header -->

                    <!-- Transaction Status -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Status:</div>
                            <div class="col-9">
                                <ib-select-entry id="statuses"
                                    :items="statusList"
                                    noItemsMessage="No statuses available"
                                    displayProperty="text"
                                    v-model="filters.status"
                                ></ib-select-entry>
                            </div>
                        </div>

                        <!-- Transaction Seller -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Seller:</div>
                            <div class="col-9">
                            <ib-select-entry id="sellers"
                                :items="sellersFilterList"
                                noItemsMessage="No sellers available"
                                v-model="filters.seller"
                            ></ib-select-entry>
                            </div>
                        </div>


                        <!-- Depot -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Depot:</div>
                                <div class="col-9">
                                <ib-select-entry id="depots"
                                    :items="depots"
                                    noItemsMessage="No depots available"
                                    v-model="filters.depot"
                                ></ib-select-entry>
                            </div>
                        </div>
                    
                        <!-- From Date -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">From:</div>
                                <div class="col-9">
                                <ib-date-picker id="from"
                                    placeholder="From"
                                    :maxValue="filters.toDate"
                                    v-model="filters.fromDate"
                                ></ib-date-picker>
                            </div>
                        </div>

                        <!-- To Date -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">To:</div>
                                <div class="col-9">
                                <ib-date-picker id="to"
                                    placeholder="To"
                                    :minValue="filters.fromDate"
                                    v-model="filters.toDate"
                                ></ib-date-picker>
                            </div>
                        </div>

                        <!-- Sort by -->
                        <div class="row align-items-center pb-2">
                            <div class="col-3">Sort By:</div>
                                <div class="col-9">
                                <ib-select-entry id="sortBy"
                                    :items="sortList"
                                    noItemsMessage="No statuses available"
                                    v-model="filters.sortBy"
                                ></ib-select-entry>
                            </div>
                        </div>

					 <div class="row align-items-center pb-2">
                        <div class="col-3"></div>
                            <div class="col-9">
                            <i>Press </i><span class="badge badge-dark"> Ctrl + F </span>&nbsp;
                            <i> to search</i>
                            </div>                            
                    </div>

                </div> <!-- end of left column -->

                <div class="col-lg-9 col-md-9 col-sm-9"> <!-- Start of right hand column -->

                     <div class="row p-2"> <!-- Start of Purchases Header -->
                        <div class="flex-fill">
                            <span><h5>Purchases</h5></span>
                        </div>
                        <div class="flex-fill">
                            <span class="pl-1 align-items-center" style="float:right"> 
                                <button class="btn btn-primary mr-2"
                                    @click="refresh"
                                    :disabled="ordersLoading === true"
                                >
                                    <transition name="fade" mode="out-in">
                                        <span v-if="true === ordersLoading" key="spinner">
                                            <i class="fas fa-sync-alt fa-spin"></i>
                                        </span>
                                        <span v-else key="fixed">
                                            <i class="fas fa-sync-alt"></i>
                                        </span>
                                    </transition>
                                    <span class="ml-2">Refresh</span>
                                </button>
                            </span>
                        </div>
                    </div> <!-- End of Purchases Header -->

                    <div style="overflow-y: auto; height:calc(100vh - 75px);"> <!-- Start of purchases Area -->
                            <div v-for="(order,orderIndex) in filteredOrders" :key=orderIndex :item="order">
                                <order-card-renderer-v2 v-if="order.items.length > 1" v-bind:basket="order" :audienceIsBuyer="true" @cancelBulkOrder="cancelOrderShow" @showPurchaseOrderModal="purchaseOrderShow" /> 
                                <order-card-renderer v-else-if="order.items.length === 1" v-bind:order="order.items[0]" :audienceIsBuyer="true" :refBasketId="true" @cancelOrder="cancelOrderShow" @showPurchaseOrderModal="purchaseOrderShow" />    
                            </div>
                    </div>
                </div>
            </div>
            </div>

        </div>
        <div v-else>
            <ib-loading-spinner 
                message="Loading Purchases.."
                class="flex-grow-1"
            />
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p>
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</template>
<script>
import TransactionApi               from '@MP/api/transaction';
import ClientApi                    from '@MP/api/client';
import IbLoadingSpinner             from '@/components/IbLoadingSpinner';
import IbDatePicker                 from '@/components/form/IbDatePicker';
import IbSelectEntry                from '@/components/form/IbSelectEntry';
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import IbModal                      from '@/components/utilities/IbModal';
import { DateTime }                 from 'luxon';
import LicenceMixin                 from '@/mixins/LicenceMixin';
import OrderNumberMixin             from '@/mixins/OrderNumberMixin';
import { DocStoreOptions }          from '@/common/docstore-options';
import OrderCardRenderer            from '@MP/components/OrderCardRenderer';
import OrderCardRendererV2            from '@MP/components/v2/OrderCardRenderer';
import PurchaseOrderModal           from '@MP/components/PurchaseOrderModal';
import PurchaseOrderModalV2         from '@MP/components/v2/PurchaseOrderModal';
import SuppliersApi                 from '@/common/suppliers';
import MarketplacePasswordModal     from '@MP/components/MarketplacePasswordModal';

import { 
    mapGetters,
    mapActions,
    mapState
}       from 'vuex';

const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE
];

export default {
    name: 'MarketplacePurchasesV2',

    components: {
        IbLoadingSpinner,
        IbDatePicker,
        IbSelectEntry,
        OrderCardRenderer,
        IbModal,
        PurchaseOrderModal,
        PurchaseOrderModalV2,
        MarketplacePasswordModal,
        OrderCardRendererV2 

    },

    mixins: [
        DateMixin,
        LicenceMixin,
        OrderNumberMixin
    ],

    data() {
        return {
            doingUpload      : false, 
            prefixes         : [],
            profile          : null,
            loading          : true,
            showPasswordModal : true,
            useBulkOrderFunctionality: false,
 
            filters: {
                status       : {},
                fromDate     : '',
                toDate       : '',
                seller       : '- All Sellers -',
                depot        : '- All Depots -',
                sortBy       : 'Oldest Order First',
                type         : 'Purchases'
            },

            statusList: [ {"value": -1, "text": "- All Statuses -"},
                          {"value": -2, "text": "- Action Required -"}, 
                          {"value": 20, "text": "Awaiting Seller Acceptance"}, 
                          {"value": 30, "text": "Ready for Purchase Order"}, 
                          {"value": 40, "text": "Awaiting Seller Advice Note"},
                          {"value": 50, "text": "Seller Advice Note Generated"},
                          {"value": 90, "text": "Cancelled"},
                          {"value": 99, "text": "Seller Declined"}
                        ],
            sortList: [ "Oldest Order First", "Newest Order First", "Seller" ],
            filteredOrders: [],
            sellers: [],
            sellersFilterList: [],
            depots: [],
            requiredLicenses : requiredLicenses,
            supplierAccounts: [],
            showConfirmCancel : false,
            showPurchaseOrder : false,
            selectedBasket  : [],
            selectedOrder : {serverInfo: {}, clientInfo: {}}
       }
    },

    watch: {
        'filters.status.value': {
            immediate: true,
            handler  : function(newStatus){
                this.filterOrders();
            }
        },
        'filters.seller': {
            immediate: true,
            handler  : function(newSeller){
                this.filterOrders();
            },
        },
        'filters.depot': {
            immediate: true,
            handler  : function(newDepot){
                this.filterOrders();
            }
        },
        'filters.sortBy': {
            immediate: true,
            handler  : function(newSort){
                this.filterOrders();
            }
        },
        'filters.fromDate': {
            immediate: false,
            handler  : function(newDate){

                this.refresh();
            }
        },
        'filters.toDate': {
            immediate: false,
            handler  : function(newDate){
                this.refresh();
            }
        },

    },

    computed: {
      ...mapGetters('marketplaceorders', {
          baskets        : 'baskets',
          ordersLoading  : 'ordersLoading',
          ordersError    : 'ordersError',
      })
    },

    methods: {
        ...mapActions('marketplaceorders', {
          fetchOrders   : 'fetchOrders',
          buyerCancelOrder : 'buyerCancelOrder',
          bulkCancelOrder: 'bulkCancelOrder'
        }),

        async passwordValidated()
        {
            this.showPasswordModal = false; 
            await this.initialiseFilters();
            await this.refresh();
        },

        async refresh() {
            this.loading = true;
            await this.loadOrders();
            this.filterOrders();
            this.GetSupplierAccounts();
            this.loading = false;
        },
        
        async AcceptTransaction(transaction)
        {
            await TransactionApi.acceptTransaction(transaction);
        },

        async GetSupplierAccounts()
        {
            try
            {
                let suppliers = await SuppliersApi.getAllSuppliers();
                let supplierAccounts = suppliers.data;
                this.supplierAccounts = supplierAccounts.map(e => { return {id: e.Id, name: e.Id+" - "+e.Name.replace('(','').replace(')','')} });
            }
            catch(error)
            {
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
        },

        cancelOrderShow(order)
        {
            this.selectedOrder = order;
            this.showConfirmCancel = true;
        },

        purchaseOrderShow(value)
        {
            this.selectedBasket = value.orders;
            this.selectedOrder = value.item;
            this.showPurchaseOrder = true;
        },

        async CancelOrder()
        {
            try
            { 
                this.loading = true;
                this.showConfirmCancel = false;
                await this.bulkCancelOrder(this.selectedOrder.serverInfo);
                this.toast('b-toaster-top-right', 'success', 'order ' + this.shortenGuid(this.selectedOrder.serverInfo.basketId) + ' cancelled', true);
                await this.refresh();
                this.loading = false;
            }
            catch(err)
            {
                this.toast('b-toaster-top-right', 'danger', err, true);
            }
            finally
            {
                this.loading = false;
            }
        },

        async purchaseOrderGenerate(orderSupplier)
        {
            try
            { 
                this.loading = true;
                this.showPurchaseOrder = false;
                await ClientApi.buyerGeneratePurchaseOrderBulk(orderSupplier.order, orderSupplier.supplier)
                this.toast('b-toaster-top-right', 'success', 'order ' + this.shortenGuid(orderSupplier.order[0].serverInfo.basketId) + ' purchase order generated', true);
                await this.refresh();
                this.loading = false;
            }
            catch(error)
            {
                console.log(error.response.data);
                this.toast('b-toaster-top-right', 'danger', error.response.data, true);
            }
            finally
            {
                this.loading = false;
            }
        },

        async loadOrders() {
            console.log("Loading Orders");
            await this.fetchOrders(this.filters);
            console.log("Orders loaded");

            if(this.baskets.length > 0) {
                let items = this.baskets.map(order=> order.items)
                items = items.flat()
                this.sellers = items.map(item => item.serverInfo.sellerCompanyName)
                .filter((value, index, self) => self.indexOf(value) === index);
                this.sellers = _.sortBy( this.sellers);
                this.sellersFilterList = this.buildSelectList(this.sellers,"- All Sellers -");

                this.depots = items.map(item => item.serverInfo.buyerDepotName)
                .filter((value, index, self) => self.indexOf(value) === index);
                this.depots = _.sortBy( this.depots);
                this.depots = this.buildSelectList(this.depots,"- All Depots -");
            }
            else {
                this.sellersFilterList = ["- All Sellers -"];
                this.filters.seller = this.sellersFilterList[0];
                this.depots = ["- All Depots -"];
                this.filters.depot = this.depots[0];
            }

            if(this.filters.seller === undefined)
                this.filters.seller = this.sellersFilterList[0];
            if(this.filters.depot === undefined)
                this.filters.depot = this.depots[0];

            
        },

        filterOrders() {
            let actionableStatuses = [30];
             this.filteredOrders = this.baskets.map((order)=> {
                return {...order, items :order.items.filter(
                (item) => (item.serverInfo.status === this.filters.status.value || this.filters.status.value === -1 || this.filters.status.value === -2 && actionableStatuses.includes(item.serverInfo.status)) &&
                               (item.serverInfo.buyerDepotName          === this.filters.depot        || this.filters.depot        === '- All Depots -') &&
                               ( item.serverInfo.sellerCompanyName       === this.filters.seller       || this.filters.seller       === '- All Sellers -')
                )}
            });

            this.filteredOrders = this.filteredOrders.filter(order=> order.items.length > 0)

            if(this.filters.sortBy === "Oldest Order First") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'orderCreated');
            }
            if(this.filters.sortBy === "Newest Order First") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'orderCreated').reverse();
            }
            if(this.filters.sortBy === "Seller") {
                this.filteredOrders = _.sortBy( this.filteredOrders, 'sellerCompanyName' );
            }
        },

        async initialiseFilters() {
            this.filters.toDate     = this.getDate(new Date());
            this.filters.fromDate   = this.subtractMonths(3, this.filters.toDate);
            this.filters.status = this.statusList[1];
        },

         buildSelectList(array, defaultItem) {
            let items = []
            if (Array.isArray(array) && (0 < array.length)) {
                // Create new array with defaultItem as first item.
                items.push(defaultItem);
                items.push(...array);
            }
            return items;
        },

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Marketplace Purchases`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        }
    }
}
</script>