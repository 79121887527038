<template>
    <ib-info-modal :id="id" title="Listings Manager" iconColour="secondary">        
        Help Text Here : {{content}}
        <!-- <iframe src="https://goldman.ibcos.co.uk/goldman/index" class="w-100 h-70"></iframe> -->
    </ib-info-modal>

      
                                                                      
</template>

<script>
import IbInfoModal from '@/components/utilities/IbInfoModal';
import DateMixin, {
    DateFormat
}                               from '@/mixins/DateMixin';

export default {
    name: "ListingsManagerInfoModal",

    mixins: [
        DateMixin
    ],

    components: {
        IbInfoModal
    },

    props: {
        content: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
    },

    data() {
        return {

        }
    },

    computed: {
      

    }, /* END: computed */

    methods: {
      
    },

    filters: {
     
    }
}
</script>

<style lang="less" scoped>
label {
    font-weight: bolder;
    width: 180px;
    padding-left: 5px;
}
</style>