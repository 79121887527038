<template>
<div>
    <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">
        <div v-if="!uploadInProgress">
            <ib-modal
                    v-model="showConfirmation"
                    modalClass="col-8 p-2"
                    :dismissable="true"
                >
                <h2>
                    Update the following mappings?
                </h2>
                <div style="overflow-y: auto; height:180px;">
                    <div v-for="(change, index) in changes" class="p-2" :key="index">
                        {{ change }}
                    </div>
                </div>
                <div class="text-right">
                    <button type="button" class="btn btn-secondary" @click="showConfirmation=false">Cancel</button>&nbsp;
                    <button type="button" class="btn btn-success" @click="completeSave">Ok</button>
                </div>
            <div>

            </div>
            </ib-modal>
            <div class="container-fluid h-100 w-100">
                <div class="alert alert-info" role="alert">
                    Click the input boxes on the right and start typing to search for manufacturers.
                </div>
                    <h5>Prefix Mappings</h5>
                    <form autocomplete="off">
                        <div id="parent" class="container-fluid" style="overflow-y: auto; padding-bottom:20em; height:calc(100vh - 160px);">
                        <div v-for="prefix in allPrefixes" :key="prefix.Prefix" >
                            <div class="card-body">
                            <div class="row w-100">
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                    {{prefix.Prefix}}
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                <rs-dropdown
                                        :ref="prefix.Prefix+'dropdown'"
                                        :options="allManufacturers"
                                        v-on:selected="manufacturerSelected"
                                        :disabled="!successfullyLoaded"
                                        :maxItem="1000"
                                        :name="prefix.Prefix+'dropdown'"
                                        placeholder="Not Mapped">
                                    </rs-dropdown>
                                </div>
                            </div>
                            </div>
                            <hr style="margin: 0px; padding: 0px;"/>

                        </div>
                        </div>
                    </form>
                <div>
                    <!-- buttons -->
                    <div class="float-right pt-2">
                        <button type="button" class="btn btn-secondary" :disabled="saving" @click="refresh">Cancel</button>&nbsp;
                        <button type="button" class="btn btn-success" :disabled="saving" @click="save">Save</button> 
                    </div>
                </div>
                
            </div>
        </div>
        <div v-else>
            <ib-loading-spinner 
                    message="Listings Upload in progress."
                    class="flex-grow-1"
                />
                <center> You can't use this page until its finished. This may take some time...</center>
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p>
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</div>
</template>
<script>
import RulesApi            from '@MP/api/rules';
import ClientApi           from '@MP/api/client';
import RsDropdown          from '@/components/form/RomainSimonDropdown';
import IbModal             from '@/components/utilities/IbModal';
import MarketplacePasswordModal from '@MP/components/MarketplacePasswordModal';
import IbLoadingSpinner    from '@/components/IbLoadingSpinner';
import LicenceMixin        from '@/mixins/LicenceMixin';
import { DocStoreOptions } from '@/common/docstore-options';

const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE
];

export default {
    name: 'PrefixMappings',
    components: {
       RsDropdown,
       IbModal,
       IbLoadingSpinner,
       MarketplacePasswordModal
    },

    mixins: [
        LicenceMixin,
    ],


    data() {
        return {
            prefixMappings          : [],
            initialPrefixMappings   : [],
            allPrefixes             : [],
            allManufacturers        : [],
            mappings                : [],
            loading                 : true,
            saving                  : false,
            successfullyLoaded      : false,
            showConfirmation        : false,
            changes                 : [],
            mappingsForUpload       : null,
            uploadInProgress        : false,
            requiredLicenses        : requiredLicenses,
            timer                   : 0,
            licensedTimer           : 1,
            licenseChecks           : 0,
            showPasswordModal       : true
        }
    },
    computed: {

    },
    beforeDestroy(){
        window.clearInterval(this.timer);
        window.clearInterval(this.licensedTimer);
    },
    methods: {
         async checkIsUploading() {
            let lastUploaded = await ClientApi.getLastUploaded();
            this.uploadInProgress = lastUploaded.data.dateCompleted == "0001-01-01T00:00:00";
        },
        passwordValidated()
        {
            this.showPasswordModal = false;
            this.startCheckingLicense();
        },
        async startCheckingLicense()
        {
            this.checkLicensed();
                this.licensedTimer = window.setInterval(() => {
                    this.checkLicensed();
                }, 200);
        },
        async doCreated()
        {
            this.checkIsUploading();
            this.timer = window.setInterval(() => {
                this.checkIsUploading();
            }, 10000);
            this.refresh();
        },
        async checkLicensed()
        {
            this.licenseChecks += 1;
            if (this.hasDocStoreOptions(...this.requiredLicenses))
            {
                this.doCreated();
                window.clearInterval(this.licensedTimer);
            }
            if (this.licenseChecks > 5)
            {
                window.clearInterval(this.licensedTimer);
            }
        },
        async getPrefixMappings()
        {
            try
            {
                let allManufacturers = [];
                let prefixMappings = await ClientApi.getPrefixMappings();
                this.prefixMappings = prefixMappings.data;
                
                this.prefixMappings.forEach(e => allManufacturers.push({id: e.id, name: e.name}))
                allManufacturers = _.orderBy(allManufacturers, ['name'],['asc']);
                allManufacturers.unshift({id: -1, name:"Not Mapped"});
                this.allManufacturers = allManufacturers;
                this.initialPrefixMappings = JSON.parse(JSON.stringify(this.prefixMappings));

                this.allPrefixes.forEach(p => {
                    let childCompName = p.Prefix+'dropdown';
                    let child = this.$refs[childCompName][0];
                    let thisManufacturer = this.prefixMappings.filter(m => m.associatedPrefixesList && m.associatedPrefixesList.includes(p.Prefix));
                    if (thisManufacturer.length == 1) {
                        child.selectOption({id: thisManufacturer[0].id, name: thisManufacturer[0].name});
                    }
                });
                this.successfullyLoaded = true;
            }
            catch(err)
            {
                this.toast('b-toaster-top-right', 'danger', err.response.data, true);
            }
        },

        async getPrefixes(){
            let allPrefixes = await RulesApi.getPrefixes();
            this.allPrefixes = allPrefixes.data.data;
        },
       
        async refresh() {
            this.loading = true;
            this.prefixMappings = [];
            this.allPrefixes = [];
            this.allManufacturers = [];
            this.mappings = [];
            await this.getPrefixes();
            this.getPrefixMappings();
            this.loading = false;
        },

        async completeSave()
        {
            try
            {
                await ClientApi.postPrefixMappings(this.mappingsForUpload);
                this.showConfirmation = false;
                await this.refresh();
                this.toast('b-toaster-top-right', 'success', 'prefix mappings sucessfully saved', true);
            }
            catch (err)
            {
                this.toast('b-toaster-top-right', 'danger', err.response.data, true);
            }
        },

        async save() {
            this.saving = true;
            try
            {
                this.prefixMappings = this.prefixMappings.map(p => { 
                    p.associatedPrefixes = p.associatedPrefixesList.join('?');
                    return p;
                });
                this.mappingsForUpload = this.prefixMappings;
                let unmaps = [];
                let changes = [];
                console.log(this.prefixMappings);
                for (let i = 0; i < this.prefixMappings.length; i++)
                {
                    let difference = _.difference(this.prefixMappings[i].associatedPrefixesList, this.initialPrefixMappings[i].associatedPrefixesList);
                    let difference2 = _.difference(this.initialPrefixMappings[i].associatedPrefixesList, this.prefixMappings[i].associatedPrefixesList);
                    if (difference.length > 0)
                    {
                        changes.push(this.prefixMappings[i].name + " mapped to : " + difference.join(','));
                    }
                    if (difference2.length > 0)
                    {
                        unmaps = unmaps.concat(difference2);
                    }
                }
                if (unmaps.length > 0)
                {
                    changes.push(unmaps.join(",") + " unmapped.");
                }
                if (changes.length > 0)
                {
                    this.showConfirmation = true;
                    this.changes = changes;
                }
                else
                {
                    alert("Nothing to update");
                }
            }
            catch (err)
            {
                this.toast('b-toaster-top-right', 'danger', err.response.data, true);
            }
            this.saving = false;
        },

        manufacturerSelected(selection, name) {
            if (name && !this.loading) {
                console.log("the name is " + name);
                let thisPrefix = name.substring(0,2);
                let thisManufacturer = selection.option.name;
                this.prefixMappings.forEach(pm =>
                {
                    if(pm.associatedPrefixesList == null){
                        pm.associatedPrefixesList = []
                    }
                    if (pm.name == thisManufacturer)
                    {
                        if (!pm.associatedPrefixesList.includes(thisPrefix)) {
                            pm.associatedPrefixesList.push(thisPrefix);
                        }
                    }
                    else 
                    {
                        pm.associatedPrefixesList = pm.associatedPrefixesList.filter(e => e != thisPrefix);
                    }
                    pm.associatedPrefixes = pm.associatedPrefixesList.join('?');
                });
            }
        },

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Prefix Mappings`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },
    }
}
</script>