<template>
<div
    class="sidebar d-flex flex-row"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
>
    <div class="sidebar-drawer flex-grow-1">
        <div class="sidebar-content">
            <h2>{{ getMenuLabel(module) }}</h2>
            <nav class="d-flex flex-column">
                <div v-for="(group, index) in moduleRouteGroups"
                     :key="index"><h5 v-if="group.group != 'undefined'">{{ group.group }}</h5>
                    <div v-for="(route, index) in group.routes">
                        <div :id="route.name">
                            <a v-if="route.url !== undefined" :key="index"
                               :class="{'nav-link': true, 'disabled': !hasRoles(route)}"
                               :href="route.url">
                                <font-awesome-icon
                                    :icon="getMenuIcon(route)"
                                    size="lg"
                                    fixed-width
                                />
                                {{ getMenuLabel(route) }}
                            </a>
                            <router-link v-else
                                         :key="index"
                                         :class="{'nav-link': true, 'disabled': !hasRoles(route)}"
                                         :to="{ name: route.name }"
                            >
                                <font-awesome-icon
                                    :icon="getMenuIcon(route)"
                                    size="lg"
                                    fixed-width
                                />
                                {{ getMenuLabel(route) }}
                            </router-link>
                        </div>
                        <b-tooltip
                            v-if="!hasRoles(route)"
                            :target="route.name"
                            title="Access Restricted"
                            noninteractive/>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <div
        class="sidebar-toggle d-flex flex-column justify-content-center"
        @click="toggleOpen"
    >
        <div
            v-show="false === isOpen || true === hover"
        >
            <div
                ref="arrowIndicator"
                class="arrow-indicator d-flex justify-content-center align-items-center rounded-circle"
            >
                <font-awesome-icon
                    :icon="toggleIcon"
                    size="sm"
                    fixed-width
                />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    get     as _get,
    filter  as _filter,
}                       from 'lodash-es';
import {
    mapState,
    mapMutations,
    mapGetters,
}                       from 'vuex';
import * as mutation    from '@/store/mutation-types';
import LicenceMixin     from '@/mixins/LicenceMixin';

import Axios from 'axios';

export const SIDEBAR_COLLAPSED_WIDTH = '15px';
export const SIDEBAR_EXPANDED_WIDTH  = '250px';

export default {
    mixins: [
		LicenceMixin
    ],

    data() {
        return {
            hover : false,
            excludedRoutes:[]
        };
    },

    async beforeCreate(){
       const BASE_URL = `${process.env.VUE_APP_MP_CLIENT_API_BASE_URL}`;
            return await Axios.get(`${BASE_URL}marketplace/config/feature_configuration`)
                .then((response) =>
                {
                    let config = response.data;
                    if(!config.orderPartsPageVisible || !config.orderPartsPageVisible)
                    {
                        this.excludedRoutes.push("OrderParts");
                    }

                    if(config.purchasesBetaPage)
                    {
                        this.excludedRoutes.push("Purchases")
                    }
                    else
                    {
                        this.excludedRoutes.push("Purchases(beta)")
                    }

                    if(config.salesBetaPage)
                    {
                        this.excludedRoutes.push("Sales")
                    }
                    else
                    {
                        this.excludedRoutes.push("Sales(beta)")
                    }

                }, (_) => false);
    },

    computed: {
        ...mapState('core', {
            isOpen          : state => state.sidebar.open,

            activeModule    : state => state.quickbar.module,
        }),

        ...mapGetters('auth', [
            'hasBetaAccess',
            'getUserRoles'
        ]),

        module() {
            const module = this.$router.options.routes.find((r) => this.activeModule === r.name);
            return module;
        },

        moduleLabel() {
            const label = this.getMenuLabel(this.module);
            return label;
        },

        moduleRouteGroups() {
            const routes            = _get(this.module, 'children', []);
            const licensedRoutes    = routes.filter((r) => this.checkRouteLicences(r));
            const licensedAndMarketplaceEnabledRoutes = licensedRoutes.filter((r) => this.checkRoutesMarketplaceEnabled(r));

            const moduleRoutes      = licensedAndMarketplaceEnabledRoutes.filter((r) => this.getMenuLabel(r));

            const moduleRouteGroups = _.chain(moduleRoutes).groupBy("meta.menu.group").map((value, key) => ({ group: key, routes: value })).value();

            return moduleRouteGroups;
        },

        toggleIcon() {
            let icon = ['fas'];

            if (true === this.isOpen) {
                icon.push('chevron-left');
            }
            else {
                icon.push('chevron-right');
            }

            return icon;
        },
    },

    methods: {
        ...mapMutations('core', {
            toggleOpen  : mutation.TOGGLE_SIDEBAR_MENU,
        }),

        getMenuLabel(item) {
            const label = _get(item, 'meta.menu.label');
            return label;
        },

        getMenuIcon(item) {
            const icon  = _get(item, 'meta.menu.icon');
            return icon;
        },

        checkRoutesMarketplaceEnabled(route){
            return !this.excludedRoutes.includes(route.name);
        },

        /**
         * Verify whether a route is accessible with the users
         * licence options and beta status.
         */
        checkRouteLicences(route) {
            const docStoreOptions   = _get(route, 'meta.docStoreOptions', []);
            const isBetaRoute       = _get(route, 'beta', false);
            let isViewable          = true;

            if (isBetaRoute || docStoreOptions.length) {
                let isLicensed    = true;  // Default to true
                let isAllowedBeta = true;

                if (isBetaRoute) {
                    isAllowedBeta = this.hasBetaAccess;
                }

                // Verify DocStore licences
                if (docStoreOptions.length) {
                    isLicensed = this.hasDocStoreOptions(...docStoreOptions);
                }

                isViewable = isLicensed && isAllowedBeta;
            }

            return isViewable;
        },
        hasRoles(route) {
            return route.meta.roles ? route.meta.roles.every(value => this.getUserRoles.includes(value)) : true;
        }
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/colours.less';
@import '~@/assets/css/imports.less';

@border-colour              : darken(@grey, 10%);
@sidebar-toggle-width       : 30px;
@toggle-radius              : calc(@sidebar-toggle-width / 2);
@toggle-collapsed-position  : calc(@quickbar-width + @sidebar-collapsed-width - @toggle-radius);
@toggle-expanded-position   : calc(@quickbar-width + @sidebar-expanded-width - @toggle-radius);

.sidebar {
    background-color: @grey;
    min-width       : @sidebar-collapsed-width;
    max-width       : @sidebar-expanded-width;

    .sidebar-drawer {
        width       : 0px;
        color       : #333;
        overflow    : hidden;
        white-space : nowrap;

        .sidebar-content {
            padding : 0.5rem 0px 0px 1rem;

            h2 {
                padding-bottom  : 0.5rem;
            }

            .nav-link {
                font-size       : 15px;
                font-weight     : 500;
                color           : inherit;
                padding         : 0.5rem;

                &.disabled {
                    color: #6c757d;
                }

                &.router-link-exact-active {
                    color       : #007bff;
                }
            }
        }
    }

    .sidebar-toggle {
        border-right: @border-colour 1px solid;
        min-width   : @sidebar-collapsed-width;
        max-width   : @sidebar-collapsed-width;
        cursor      : pointer;

        .arrow-indicator {
            border          : @border-colour 1px solid;
            background-color: @light-grey;
            color           : @charcoal;
            position        : relative;
            left            : calc(@sidebar-collapsed-width - 13px);
            width           : 26px;
            height          : 26px;
        }

        &:hover .arrow-indicator {
            border          : darken(@ibcos-gold, 10%) 1px solid;
            background-color: @ibcos-gold;
        }
    }
}
</style>
