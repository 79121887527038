export default {
    /**
     * Determines if the browser currently running is Internet Explorer.
     */
    isIE() {
        let result = false;
        
        if(navigator.userAgent.indexOf('MSIE')!==-1 || 
           navigator.appVersion.indexOf('Trident/') > -1){
            result = true;
        }

        return result;
    }
};