<template>
	<div class="row h-100">
        <div class="col-md-12 h-100">
            <router-view class="h-100" />
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
    mapActions,
}                       from 'vuex';
import * as mutation    from '@/store/mutation-types';

export default {
    name: 'Marketplace',
}
</script>
