const GOLD_LOW_PRIORITY            = 1;
const GOLD_MED_PRIORITY            = 2;
const GOLD_HIGH_PRIORITY           = 3;
const GOLD_CRIT_PRIORITY           = 4;
const JD_HIGH_PRIORITY             = 1001;
const JD_CRITICAL_PRIORITY         = 1002;
const JD_DTC_PRIORITY              = 1003;

const RequestPriorityCodes = {
    GOLD_LOW_PRIORITY,
    GOLD_MED_PRIORITY,
    GOLD_HIGH_PRIORITY,
    GOLD_CRIT_PRIORITY,
    JD_HIGH_PRIORITY,
    JD_CRITICAL_PRIORITY,
    JD_DTC_PRIORITY
};

const RequestPriorityStrings = {
    //Generic Priority Levels
    1   : 'Low',
    2   : 'Medium',
    3   : 'High',
    4   : 'Critical',

    // John Deere Priority Levels
    1001    : 'High',
    1002    : 'Critical',
    1003    : 'DTC'
};

const JobRequestPriorities = {
    methods: {
        getPriorityForId(value) {
            let priorityName = RequestPriorityStrings[value];
            if(undefined === priorityName){
                priorityName = "Unknown";
            }
            return priorityName;
        }
    }
};

export default JobRequestPriorities;