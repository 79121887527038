import ApiService from '@/common/api-service';

const JOB_STATE_ROOT = "api/v1/job/state";

const JobStateApi = {

    ///
    /// GET REQUESTS
    ///

    /**
     * Request all sub-states from the server for the given
     * gold state.
     * @param {string} goldState 
     */
    getSubStates(goldState) {
        const url = `${JOB_STATE_ROOT}/${goldState}`;
        return ApiService.get(url);
    },

    /**
     * Request all sub-states from the server for the given
     * gold state.
     * @param {string} goldState 
     */
    getReadyToCompleteStatus() {
        const url = `${JOB_STATE_ROOT}/readytocompletestatus`;
        return ApiService.get(url);
    },

    ///
    /// POST REQUESTS
    ///

    /**
     * Add a new job sub-state via the API.
     * @param {string} name      - The new state name.
     * @param {string} goldState - The gold state the sub-state maps to.
     */
    addState(name, goldState) {
        const url = JOB_STATE_ROOT;
        return ApiService.post(url, { name, goldState });
    },

    ///
    // PATCH REQUESTS
    ///

    /**
     * Update the name of the state.
     * @param {string} id - The state ID.
     * @param {string} name - The state name.
     */
    renameState(id, name) {
        const url = `${JOB_STATE_ROOT}/${id}/name`;
        return ApiService.patch(url, { name });
    },

    /**
     * Move the state in the sequence of states.
     * @param {int} id - The state ID.
     * @param {int} target - The target state ID.
     */
    moveState(id, target) {
        const url = `${JOB_STATE_ROOT}/${id}/move`;
        return ApiService.patch(url, { target });
    },

    ///
    /// DELETE REQUESTS
    ///

    /**
     * Delete the sub-state via the API.
     * @param {int} id - The ID of the state to delete.
     */
    deleteState(id) {
        const url = `${JOB_STATE_ROOT}/${id}`;
        return ApiService.delete(url);
    },

};

export default JobStateApi;