import ApiService from '@/common/api-service';

const CHECKLIST_ROOT                        = "api/v1/workshop/checklist";
const JOB_ROOT                              = "api/v1/job";

const checklistApi = {
    /**
     * Get a template by identifier.
     * @param {string} id 
     */
    getById(id)  {
        const url = `${CHECKLIST_ROOT}/${id}`;
        return ApiService.get(url);
    },
    /**
     * Get all templates.
     */
    getAll() {
        return ApiService.get(CHECKLIST_ROOT);        
    },
    /**
     * Get a template's usage by identifier.
     * @param {string} id
     */
    getUsage(id){
        const url = `${CHECKLIST_ROOT}/${id}/usage`;
        return ApiService.get(url);        
    },
    /**
     * Delete a template by identifier.
     * @param {string} id
     */
    deleteById(id) {
        const url = `${CHECKLIST_ROOT}/${id}`;
        return ApiService.delete(url);
    },
    /**
     * Create a template.
     * @param {object} checklist
     */
    create(checklist){
        const url = `${CHECKLIST_ROOT}/create`;
        return ApiService.post(url, checklist);        
    },
    /**
     * Update a template by identifier.
     * @param {object} checklist
     */
    update(checklist){
        const url = `${CHECKLIST_ROOT}/${checklist.Id}`;
        return ApiService.put(url, checklist);
    },
    /**
     * Gets all checklists for a given jobId
     * @param {string} jobId
     */
    getAllChecklistsByJob(jobId){
        const url = `${JOB_ROOT}/${jobId}/checklists`;
        return ApiService.get(url);
    },
    /**
     * Update a checklist by the given JobId
     * @param {string} jobid
     * @param {object} checklist
     */
    updateChecklist(jobId, checklist){
        const url = `${JOB_ROOT}/${jobId}/checklist/${checklist.Id}`;
        return ApiService.put(url, checklist);
    }
}   

export default checklistApi;