<template>
<div class="edit-date-button">
	<!-- Glyph button -->
	<button class="btn btn-sm save-button"
		:class="[ modalShow || active ? activeColour : colour ]"
		:disabled="disabled"
		@mouseenter="active = true"
		@mouseleave="active = false"
		@click="openModel">
		<i class="fa-lg fa-fw"
			:class="[iconStyle, icon]"
		></i>
	</button>

	<b-modal v-model="modalShow"
		:title="title">
		<slot name="message">
			<p>Please select a date:</p>
		</slot>

		<ib-date-picker 
			ref="date"
			v-model="date"
			:placeholder="datePlaceholder"
		/>

		<div slot="modal-footer" class="w-100 ">
			<b-btn class="float-right"
				variant="success"
				@click="save">
				Save
			</b-btn>
			<b-btn class="float-right mr-2"
				variant="secondary"
				@click="closeModel">
				Cancel
			</b-btn>
       </div>
	</b-modal>
</div>
</template>

<script>
import IbDatePicker              from '@/components/form/IbDatePicker';
import DateMixin, { DateFormat } from '@/mixins/DateMixin';

export default {
	name: 'IbEditDateButton',

	mixins: [
		DateMixin
	],

	components: {
		IbDatePicker
	},

	props: {
		value: {
			type:     String,
			required: true
		},

		dateFormat: {
			type:     String,
			required: false,
			default:  'dd/MM/yyyy'
		},

		datePlaceholder: {
			type:     String,
			required: false,
			default:  'Select a date'
		},

		title: {
			type:     String,
			required: false,
			default:  'Edit date'
		},

		icon: {
			type:	  String,
			required: false,
			default:  'fa-calendar-alt'
		},

		iconStyle: {
			type:	  String,
			required: false,
			default:  'far'
		},

		colour: {
			type:	  String,
			required: false,
			default:  'text-dark'
		},

		activeColour: {
			type:	  String,
			required: false,
			default:  'text-success'
		},

		disabled: {
			type:     Boolean,
			required: false,
			default:  false
		}
	},

	data() {
		return {
			date:	null,
			modalShow: 	false,
			active:		false
		}
	},

	computed: {

		resultDate() {
			let result = null;

			if (this.date) {
				result = this.getDateString(this.date, DateFormat.DATE_ONLY);
			}

			return result;
		}

	},

	watch: {
		modalShow(newValue) {
			if (true === newValue) {
				// Get latest value from parent.
				this.date = this.value;
			}
		}
	},

	methods: {
		save() {
			this.closeModel();
			// Emit an input event to allow binding via v-model.
			this.$emit('input', this.resultDate);
		},

		openModel() {
			this.modalShow = true;
		},

		closeModel() {
			this.modalShow = false;
		}
	}
}
</script>

<style lang="less">
.edit-date-button {
	.datepicker-input {
		padding: 6px;
		width: 100%;
	}
}
</style>

<style lang="less" scoped>
.save-button {
	border: none;
	background: inherit;
	padding: 0px;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}
</style>
