<template>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">
        <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>

        <!-- Review and Upload Modal -->
        <review-and-upload
            v-model="showReviewAndUploadModal"
            :listingsDiff="listingDiff"
            :listingDiffError="listingDiffError"
            :listingDiffLoading="listingDiffLoading"
            :currentListings="currentListings"
            :currentListingsError="currentListingsError"
            :currentListingsLoading="currentListingsLoading"
            @uploadFinished="refreshPrelistings"
        />

        <div v-if="!uploadInProgress" class="container-fluid h-100">

            <!-- Add/Edit Rule Modal -->
            <add-edit-filter
                v-model="showAddEditFilterModal"
                :mode="addEditFilterMode"
                :selectedFilter="selectedFilter"
                @createFilter="createFilter"
                @deleteFilter="deleteFilter"
            />
        
            <!-- Preview Parts Modal -->
            <preview-parts
                v-model="showPreviewPartsModal"
                :prelistings="formattedPrelistings"
                :rules="orderedRulesWithPartQuantities"
                :prelistingsLoading="prelistingsLoading"
                @excludeParts="excludeParts"
                @exportToCSV="exportToCSV"
            />

            <!-- Excluded Parts Modal -->
            <excluded-parts
                v-model="showExcludedPartsModal"
                :exclusions="formattedExclusions"
                @includeParts="includeParts"
                @exportToCSV="exportToCSV"
            />
            
            <ib-warning-modal :value="showReviewAndUploadError" @hideWarning="hideWarning" 
            :message="reviewAndUploadError"/>

            <div class="row h-100">
                <div class="col-lg-3 col-md-3 col-sm-3"> <!-- Start of left hand column -->
                    <div class="row pb-2 align-items-baseline"> <!-- Start of Filters Header -->
                        <span class="align-items-baseline">
                            <ib-info-modal-markdown class="align-self-end" :id="infoFilters" title="Filters and Price Modifiers" iconColour="secondary" headerColour="light" :markdown="contextHelpFilters"/>
                        </span>
                        <span class="pl-2 pr-2 align-items-center">
                            <h5>Filters &amp; Modifiers</h5>
                        </span>
                        <span class="align-items-baseline">
                                <button type="button" class="btn btn-primary" @click="addFilter"><i class="fas fa-plus-circle"></i> Add Filter</button>
                        </span>
                    </div> <!-- End of Filters Header -->
                        
                    <div class="row"> <!-- Start of Filters area -->
                    <div id="accordion" role="tablist" class="w-100 pr-2" style="overflow-y: auto; height:calc(100vh - 74px);">
                        <a class="link" data-toggle="collapse" href="#DepotFilters" aria-expanded="false" aria-controls="DepotFilters">
                            <div class="card-header" role="tab" id="headingDepotFilters">
                                <h6 class="mb-0">
                                    Depot Filters ({{depotFilters.length}})
                                    <span v-if="productFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <filter-item  v-for="(filter, depotIndex) in depotFilters" 
                        :key="'depot' + depotIndex" :item="filter" :filterType="`DepotFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>

                        <a class="link" data-toggle="collapse" href="#TimeFilters" aria-expanded="false" aria-controls="TimeFilters">
                            <div class="card-header" role="tab" id="headingTimeFilters">
                                <h6 class="mb-0">    
                                    Date Filters ({{timeFilters.length}})
                                    <span v-if="timeFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <filter-item  v-for="(filter, timeIndex) in timeFilters"
                        :item="filter" :key="'time' + timeIndex" :filterType="`TimeFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>
                        
                        <a class="link" data-toggle="collapse" href="#QuantityFilters" aria-expanded="false" aria-controls="QuantityFilters">
                            <div class="card-header" role="tab" id="headingQuantityFilters">
                                <h6 class="mb-0">
                                    Quantity Filters ({{quantityFilters.length}})
                                    <span v-if="productFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <filter-item  v-for="(filter, quantityIndex) in quantityFilters" 
                        :key="'quantity' + quantityIndex" :item="filter" :filterType="`QuantityFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>

                        <a class="link" data-toggle="collapse" href="#PrefixFilters" aria-expanded="false" aria-controls="PrefixFilters">
                            <div class="card-header" role="tab" id="headingPrefixFilters">
                                <h6 class="mb-0">  
                                    Prefix Filters ({{prefixCodeFilters.length}})
                                    <span v-if="productFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>        

                        <filter-item  v-for="(filter, prefixIndex) in prefixCodeFilters" 
                        :key="'prefix' + prefixIndex" :item="filter" :filterType="`PrefixFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>
                        
                        <a class="link" data-toggle="collapse" href="#ProductFilters" aria-expanded="false" aria-controls="ProductFilters">
                            <div class="card-header" role="tab" id="headingProductFilters"> 
                                <h6 class="mb-0"> 
                                    Product Group Filters ({{productGroupFilters.length}})
                                    <span v-if="productFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <filter-item  v-for="(filter, productIndex) in productGroupFilters"
                        :key="'product' + productIndex" :item="filter" :filterType="`ProductFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>
                        <a class="link" data-toggle="collapse" href="#ClassCodeFilters" aria-expanded="false" aria-controls="ClassCodeFilters">
                            <div class="card-header" role="tab" id="headingClassCodeFilters">
                                <h6 class="mb-0"> 
                                    Class Code Filters ({{classCodeFilters.length}})
                                    <span v-if="productFiltersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <filter-item  v-for="(filter, classCodeIndex) in classCodeFilters" :item="filter"
                        :key="'classCode' + classCodeIndex" :filterType="`ClassCodeFilters`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>
                        
                        <a class="link" data-toggle="collapse" href="#PriceMods" aria-expanded="false" aria-controls="PriceMods">
                            <div class="card-header" role="tab" id="headingPriceMods">
                                <h6 class="mb-0">
                                    Price Modifiers ({{priceModifiers.length}})
                                    <span v-if="priceModifiersLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                                </h6>
                            </div>
                        </a>
                        <price-modifier  v-for="(filter, pricingIndex) in priceModifiers"
                        :item="filter" :key="'pricing' + pricingIndex" :category="`PriceMods`"
                        @dragged="dragStart" @dragEnd="dragEnd" @editFilter="editFilter" @deleteFilter="deleteFilter"/>

                    </div>

                    </div> <!-- End of Filters Area -->

                </div> <!-- End of left hand column -->

                <div class="col-lg-9 col-md-9 col-sm-9"> <!-- Start of right hand column -->
                    <div class="d-flex pr-2">
                        <div class="flex-fill">
                            <span><ib-info-modal-markdown class="align-self-end" :id="infoRules" title="Rules" iconColour="secondary" headerColour="light" :markdown="contextHelpRules"/></span>
                            <span class="h5 pl-2">Listing Rules Designer</span>
                            <span class="pl-2"><button type="button" class="btn btn-primary" @click="addListingRule"><i class="fas fa-plus-circle"></i> Add Rule</button></span>
                        </div>
                        <div class="flex-fill">
                            <span class="align-items-baseline"  style="float:right">
                                <button type="button" class="btn btn-primary" @click="showPreviewParts"><i class="fas fa-eye"></i> Preview Parts</button>
                            </span>
                            <span class="pl-1 align-items-center" style="float:right"> 
                                <button class="btn btn-primary mr-2"
                                    @click="refreshPrelistings"
                                    :disabled="prelistingsLoading === true"
                                >
                                    <transition name="fade" mode="out-in">
                                        <span v-if="true === prelistingsLoading" key="spinner">
                                            <i class="fas fa-sync-alt fa-spin"></i>
                                        </span>
                                        <span v-else key="fixed">
                                            <i class="fas fa-sync-alt"></i>
                                        </span>
                                    </transition>
                                    <span class="ml-2">Refresh</span>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div class="row pb-2 align-items-baseline"> <!-- Start of Rule Set Header -->

                    </div><!-- End of Rule Set Header -->
                    
                    <div style="overflow-y: auto; height:calc(60vh - 70px);"> <!-- Start of Rule Set Area -->
                    <div v-if="pageLoading">
                        <ib-loading-spinner 
                        message="Loading Rules..."
                        class="flex-grow-1"
                    />
                    </div>
                    <listing-rule v-else v-for="(listingRule,listingRuleIndex) in orderedRulesWithPartQuantities"
                        :item="listingRule" :key="'listingRule' + listingRuleIndex" v-bind:id="'listingRule' + listingRule.id" :pricingDragged="pricingDragged" :filterDragged="filterDragged" :timeFilterDragged="timeFilterDragged" :depotDragged="depotDragged" :uiDisabled="listingRuleUiDisabled"
                        @listingRuleChanged="listingRuleChanged" @scrollTo="scrollTo"/>
                    </div> <!-- End of Rule Set Area -->

                    <div class="row p-2">

                        <div class="col">                    
                            <div class="d-flex pr-2">
                                <div class="flex-fill">
                                         <div class="row pb-2 align-items-baseline"> <!-- Start of Filters Header -->
                                            <span class="align-items-baseline">
                                                <ib-info-modal-markdown class="align-self-end" :id="infoUpload" title="Review and Upload" iconColour="secondary" headerColour="light" :markdown="contextHelpUpload"/>
                                            </span>
                                            <span class="pl-2 pr-2 align-items-center">
                                                <h5>Listing Upload</h5>
                                            </span>
                                            <h5>
                                                <span class="badge badge-danger" :title="listingDiffError" v-if="orderedRulesWithPartQuantities.length > 0 && orderedRulesWithPartQuantities[0].forReview == 'ERROR'">
                                                    ERROR
                                                </span> 
                                            </h5>
                                        </div> 
                                </div>
                                <div class="flex-fill">
                                    <span class="pl-2 align-items-baseline"  style="float:right">
                                        <button type="button" class="btn btn-success" @click="showReviewAndUpload"><i class="fas fa-check-circle"></i> Review </button>
                                    </span>
                                    <span class=" align-items-baseline" style="float:right">
                                        <button type="button" class="btn btn-secondary" @click="showExcludedParts"><i class="fas fa-ban"></i> Excluded Parts</button>
                                    </span>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div class="row p-2"> <!-- Start of Rules Area  style="background-color:#777"-->
                        <div class="w-100" >
                        <ib-table
                            :items="orderedRulesWithPartQuantities"
                            :columns="rulesColumns"
                            :small=true
                            :loading=pageLoading
                            loadingMessage="Loading Rules..."
                            noItemsMessage="No Records Found"
                            :itemsPerPage="4">
                                <template 
                                    slot="lastUploadedOn"
                                    slot-scope="rule">
                                    {{ rule.item.lastUploadedOn | formatDate() }}
                                </template>
                                <template 
                                    slot="currentListings"
                                    slot-scope="rule">
                                    <div style="width:100px">
                                        {{ rule.item.currentListings }}
                                    </div>
                                </template>
                                <template 
                                    slot="forReview"
                                    slot-scope="rule">
                                    <div style="width:100px">
                                        <div v-if="rule.item.forReview == 'ERROR'">
                                            <span>N/A</span>
                                        </div>
                                        <div v-else>
                                            {{ rule.item.forReview }}
                                        </div>
                                    </div>
                                </template>                            
                                <template
                                    slot="isPublished"
                                    slot-scope="rule">
                                    <div class="custom-control custom-switch" style="width:200px" title="Use the Refresh button to update the 'For Review' count when changing Include in Upload status">
                                        <div class="float-left">
                                            <input @click="togglePublish(rule.item)" v-model="rule.item.isPublished" type="checkbox" class="custom-control-input" :id="`publishRule` + rule.item.id">
                                            <label v-if="rule.item.isPublished" class="custom-control-label" :for="`publishRule` + rule.item.id">Included</label>
                                            <label v-else class="custom-control-label" :for="`publishRule` + rule.item.id">Not Included</label>
                                        </div>
                                    </div>
                                </template>
                            </ib-table>
                        </div>
                        
                    </div> <!-- End of Rules Area -->

                </div> <!-- End of right hand column -->
            </div>
        </div>
        <div v-else>
            <ib-loading-spinner 
                    message=""
                    class="flex-grow-1"
                />
                <center>Listings Upload in progress.</center>
                <center>You can't use this page until its finished.</center>
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p>
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</template>
<script>
import IbTable                  from '@/components/table/IbTable';
import AddEditFilter            from '@MP/components/AddEditFilter';
import FilterItem               from '@MP/components/FilterItem';
import ListingRule              from '@MP/components/ListingRule';
import PriceModifier            from '@MP/components/PriceModifier';
import ClientApi                from '@MP/api/client';
import RulesApi                 from '@MP/api/rules';
import fileDownload             from 'js-file-download';
import ListingsManagerInfoModal from '@MP/views/ListingsManagerInfoModal'
import PreviewParts             from '@MP/components/PreviewParts';
import ExcludedParts            from '@MP/components/ExcludedParts';
import ReviewAndUpload          from '@MP/components/ReviewAndUpload';
import IbWarningModal           from '@/components/IbWarningModal';
import IbInfoModalMarkdown      from '@/components/utilities/IbInfoModalMarkdown';
import ContextHelpFilters       from '@DOCS/ListingsManagerFiltersandModifiers.md';
import ContextHelpRules         from '@DOCS/ListingsManagerListingRules.md';
import ContextHelpUpload        from '@DOCS/ListingsManagerListingUpload.md';
import IbLoadingSpinner         from '@/components/IbLoadingSpinner';
import LicenceMixin             from '@/mixins/LicenceMixin';
import { DocStoreOptions }      from '@/common/docstore-options';
import MarketplacePasswordModal from '@MP/components/MarketplacePasswordModal';

import { DateTime }             from 'luxon';
import { 
    mapGetters,
    mapActions,
    mapState
}       from 'vuex';

import DateMixin, {
    DateFormat,
}                               from '@/mixins/DateMixin';


const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE,
];

const RulesColumns = [
    {
        heading: 'Priority',
        property: 'priority',
        sortable: false,
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: false
    },
    {
        heading: 'Last Uploaded',
        property: 'lastUploadedOn',
        sortable: false,
    },
    {
        heading: 'Current Listings',
        property: 'currentListings',
        sortable: false,
    },
    {
        heading: 'For Review',
        property: 'forReview',
        sortable: false,
    },    
    {
        heading: 'Include in Upload',
        property: 'isPublished',
        sortable: false
    },
];


export default {
    name: 'ListingsManager',

    components: {
        IbTable,
        AddEditFilter,
        FilterItem,
        PriceModifier,
        ListingRule,
        ListingsManagerInfoModal,
        PreviewParts,
        ExcludedParts,
        ReviewAndUpload,
        IbWarningModal,
        IbInfoModalMarkdown,
        IbLoadingSpinner,
        MarketplacePasswordModal
    },


    mixins: [
        DateMixin,
        LicenceMixin
    ],
 

    async created() {
        this.checkLicensed();
            this.licensedTimer = window.setInterval(() => {
                this.checkLicensed();
            }, 200);    
    },
    beforeDestroy(){
        window.clearInterval(this.timer);
    },

                                                                                                    
//   ,ad8888ba,                                                                                    88  
//  d8"'    `"8b                                                          ,d                       88  
// d8'                                                                    88                       88  
// 88             ,adPPYba,  88,dPYba,,adPYba,  8b,dPPYba,  88       88 MM88MMM ,adPPYba,  ,adPPYb,88  
// 88            a8"     "8a 88P'   "88"    "8a 88P'    "8a 88       88   88   a8P_____88 a8"    `Y88  
// Y8,           8b       d8 88      88      88 88       d8 88       88   88   8PP""""""" 8b       88  
//  Y8a.    .a8P "8a,   ,a8" 88      88      88 88b,   ,a8" "8a,   ,a88   88,  "8b,   ,aa "8a,   ,d88  
//   `"Y8888Y"'   `"YbbdP"'  88      88      88 88`YbbdP"'   `"YbbdP'Y8   "Y888 `"Ybbd8"'  `"8bbdP"Y8  
//                                              88                                                     
//                                              88                                                     

    computed: {

      ...mapGetters('marketplacerules', {
          productFiltersLoading   : 'productFiltersLoading',
          prefixCodeFilters       : 'prefixCodeFilters',
          productGroupFilters     : 'productGroupFilters',
          classCodeFilters        : 'classCodeFilters',
          depotFilters            : 'depotFilters',
          quantityFilters         : 'quantityFilters',

          productFilterError      : "productFilterError",
          timeFilterError         : "timeFilterError",
          priceModifiersError     : "priceModifiersError",

          timeFiltersLoading      : 'timeFiltersLoading',
          timeFilters             : 'timeFilters',

          productFilters          : 'productFilters',

          priceModifiersLoading   : 'priceModifiersLoading',
          priceModifiers          : 'priceModifiers',

          listingRulesLoading     : 'listingRulesLoading',
          listingRules            : 'listingRules',
          listingRule             : 'listingRule',
          listingRuleUiDisabled   : 'listingRuleUiDisabled',

          exclusions              : 'exclusions',

          listingDiff            : 'listingDiff',
          listingDiffLoading     : 'listingDiffLoading',
          listingDiffError       : 'listingDiffError',

          currentListings        : 'currentListings',
          currentListingsLoading : 'currentListingsLoading',
          currentListingsError   : 'currentListingsError'

      }),
    orderedRulesWithPartQuantities() {
        let rulesWithPartQuantities = this.listingRules.map(e => ({...e, numberOfParts   : 'loading...',
                                                                         currentListings : 'loading...',
                                                                         forReview       : 'loading...'}));
        if(this.listingRuleCounts.length > 0) {
            rulesWithPartQuantities.forEach((rule) => {
                let matchingCount = this.listingRuleCounts.filter(counts => counts.ruleId==rule.id);
                if(matchingCount[0] != undefined) {
                    rule.numberOfParts = matchingCount[0].count;
                }
                else {
                    rule.numberOfParts = 0;
                }
            });
        }
        
        if(!this.listingDiffLoading && this.listingDiffError == "") {
            rulesWithPartQuantities = rulesWithPartQuantities.map(e => ({...e, forReview       : 0}));
            rulesWithPartQuantities.forEach((rule) => {
                let matchingAdditions = this.listingDiff.addedListings.filter(counts => counts.listingRuleId==rule.id);
                rule.forReview = matchingAdditions.length;
                
                let matchingChanges = this.listingDiff.changedListings.filter(counts => counts.listingRuleId==rule.id);
                rule.forReview += matchingChanges.length;
                
                let matchingRemovals = this.listingDiff.removedListings.filter(counts => counts.listingRuleId==rule.id);
                rule.forReview += matchingRemovals.length;
            });
        }
        else if (this.listingDiffError != "")
        {
            rulesWithPartQuantities = rulesWithPartQuantities.map(e => ({...e, forReview       : 0}));
            rulesWithPartQuantities.forEach((rule) => {
                rule.forReview = "ERROR";
            });
        }
        if(!this.currentListingsLoading) {
            rulesWithPartQuantities = rulesWithPartQuantities.map(e => ({...e, currentListings : 0}));
            rulesWithPartQuantities.forEach((rule) => {
                let matchingListings = this.currentListings.filter(counts => counts.listingRuleId==rule.id);
                rule.currentListings += matchingListings.length;
            });
        }

          return _.orderBy(rulesWithPartQuantities, 'priority')
        },
      formattedPrelistings() {
          var d = new Date();
          console.debug("main page: formatting prelistings start - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
        let preListingsWithExclusions = this.preListings.filter(p => !this.exclusions.find(e => e.partNumber === p.partNumber && e.depot === p.depot));
        if(this.listingRules.length > 0) {
            return preListingsWithExclusions.map(prelisting => 
            {
                let matchingRule = this.listingRules.find(g => g.id == prelisting.associatedRule);
                let ruleDescription = matchingRule != undefined ? matchingRule.description : "";
                return {
                        ...prelisting,
                        listingPrice: (prelisting.listingPrice.toFixed(2)).padEnd(4,0),
                        matchingListingRule: ruleDescription,
                        excludeSelected: false
                    };
            });
        }
        else {
            return [];
        }
      },
      formattedExclusions() {
          return this.exclusions.map(e => ({...e,includeSelected:false}));
      },
      contextHelpFilters(){
          return ContextHelpFilters;
      },
      contextHelpRules(){
          return ContextHelpRules;
      },
      contextHelpUpload(){
          return ContextHelpUpload;
      },

    },

                                                                                    
// 88b           d88                    88                               88            
// 888b         d888              ,d    88                               88            
// 88`8b       d8'88              88    88                               88            
// 88 `8b     d8' 88  ,adPPYba, MM88MMM 88,dPPYba,   ,adPPYba,   ,adPPYb,88 ,adPPYba,  
// 88  `8b   d8'  88 a8P_____88   88    88P'    "8a a8"     "8a a8"    `Y88 I8[    ""  
// 88   `8b d8'   88 8PP"""""""   88    88       88 8b       d8 8b       88  `"Y8ba,   
// 88    `888'    88 "8b,   ,aa   88,   88       88 "8a,   ,a8" "8a,   ,d88 aa    ]8I  
// 88     `8'     88  `"Ybbd8"'   "Y888 88       88  `"YbbdP"'   `"8bbdP"Y8 `"YbbdP"'  
                                                                                    
                                                                                    


    methods: {
        async passwordValidated()
        {
            await this.doCreated();
            this.showPasswordModal = false;
        },
        async doCreated()
        {
            this.pageLoading = true;
            this.checkIsUploading();
            this.timer = window.setInterval(() => {
                this.checkIsUploading();
            }, 10000);
            // get the vueX store loaded
            this.fetchRuleCounts();
            this.fetchListingDiff();
            this.fetchCurrentListings();

            let thingsToFetch = [
                this.fetchProductFilters(),
                this.fetchTimeFilters(),
                this.fetchPriceModifiers(),
                this.fetchExclusions(),
            ]
            await Promise.all(thingsToFetch);

            await this.fetchListingRules();
            this.pageLoading = false;

            if (this.productFilterError)
            {
                if (this.productFilterError.data)
                {
                    this.toast('b-toaster-top-center', 'danger', `Error loading rules engine: ${this.productFilterError.data}.`, true);
                }
                this.toast('b-toaster-top-center', 'danger', `Error loading rules engine: ${this.productFilterError}.`, true);
            }
        },
        async checkIsUploading() {
            try
            {
                let lastUploaded = await ClientApi.getLastUploaded();
                this.uploadInProgress = lastUploaded.data.dateCompleted == "0001-01-01T00:00:00";
            }
            catch(error)
            {
                console.log(error);
                this.toast('b-toaster-top-right', 'danger', error.response.data, true)
            }
        },
        async checkLicensed()
        {
            this.licenseChecks += 1;
            if (this.hasDocStoreOptions(...this.requiredLicenses))
            {
                window.clearInterval(this.licensedTimer);
            }
            if (this.licenseChecks > 5)
            {
                window.clearInterval(this.licensedTimer);
            }
        },
        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Rules Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },
        
        async fetchRuleCounts() {
            let counts = await RulesApi.getListingRuleCounts();
            this.listingRuleCounts = counts.data;
        },
        
        async exportToCSV(exportType,searchPhrase){
            this.isExporting = true;
            try
            {
                if(exportType == 'preListings') {
                    const response  = await RulesApi.getPrelistingCSVFile(searchPhrase);
                    const data      = response.data;
                    var d = new Date();
                    const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
                    const fileName  = `prelistings-${now}.csv`
                    fileDownload(data, fileName);
                }
            }
            catch(error){
                let errorMessage = 'An unexpected error occured';
                if(error.response){
                    try
                    {
                        const { data }      = error.response;
                        const message       = await RulesApi.readFileError(data);
                        errorMessage        = message;
                    }
                    catch(err){
                        //default error already set
                    }
                }
                this.toast('b-toaster-top-right', 'danger', errorMessage, true);
            }
            this.isExporting = false;
        },
        async scrollTo(listingRule) {
            const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }
            await sleep(50);
            console.debug("scrolling to " + listingRule);
            var elmnt = document.getElementById(listingRule);
            console.debug(elmnt);
            elmnt.scrollIntoView(false);
        },
        selectResult(selectedItem) {
            console.debug(selectedItem.partNumber);
        },
      ...mapActions('marketplacerules', {
          fetchProductFilters   : 'fetchProductFilters',
          fetchTimeFilters      : 'fetchTimeFilters',
          fetchPriceModifiers   : 'fetchPriceModifiers',
          fetchListingRules     : 'fetchListingRules',
          fetchPreListings      : 'fetchPreListings',
          fetchExclusions       : 'fetchExclusions',
          fetchListingDiff      : 'fetchListingDiff',
          fetchCurrentListings  : 'fetchCurrentListings',

          createProductFilter   : 'createProductFilter',
          updateProductFilter   : 'updateProductFilter',
          deleteProductFilter   : 'deleteProductFilter',
          
          createTimeFilter      : 'createTimeFilter',
          updateTimeFilter      : 'updateTimeFilter',
          deleteTimeFilter      : 'deleteTimeFilter',

          createPriceModifier   : 'createPriceModifier',
          updatePriceModifier   : 'updatePriceModifier',

          deletePriceModifier   : 'deletePriceModifier',
          
          createListingRule     : 'createListingRule',

          addExclusions         : 'addExclusions',
          deleteExclusions      : 'deleteExclusions',
          toggleListingRuleIsPublished  : 'toggleListingRuleIsPublished'
      }),
      async listingRuleChanged() {
            this.preListings = [];
      },

    async showPreviewParts(){
        console.debug("showing preview parts");
        this.refreshPrelistings();
        this.showPreviewPartsModal = true;
    },

    async showExcludedParts(){
        console.debug("showing excluded parts");
        this.showExcludedPartsModal = true;
    },

    hideWarning(){
        this.showReviewAndUploadError = false;
    },

    async showReviewAndUpload(){
        console.debug("showing review and upload");
        try
        { 
            let isAuthed = await ClientApi.getIsAuthed();
            if (!isAuthed.data)
            {
                this.toast('b-toaster-top-center', 'danger', `You cannot upload listings, you are not authorized to use the marketplace.`, true);
                return;
            }
            await this.refreshPrelistings();
            
            
        }
        catch (error)
        {
            this.toast('b-toaster-top-center', 'danger', "You cannot upload listings: "+error, true);
            return;
        }
        
        if (this.listingDiffError != "")
        {
            this.showReviewAndUploadError = true;
            this.reviewAndUploadError = "Cannot review and upload listings: " + this.listingDiffError +". Please rectify this issue before trying again.";
            return;
        }
        if (this.currentListingsError != "")
        {
            this.showReviewAndUploadError = true;
            this.reviewAndUploadError = "Cannot review and upload listings: " + this.currentListingsError +". Please rectify this issue before trying again.";
            return;
        }
        this.showReviewAndUploadModal = true;
    },

      async addListingRule(){
          const newRulesSet = {
              description: "New Listing Rule",
              includeProductFiltersList : [],
              excludeProductFiltersList : [],
              includeTimeFiltersList    : [],
              priceModifierID     : 1,
              showInResults       : true,
              isNew : true
          }
        let response = await this.createListingRule(newRulesSet);
        this.scrollTo("listingRule"+response.id);
      },

    async excludeParts(partsToExclude)
    {
        // pull over the known fields
        let modifiedParts = partsToExclude.map(({ depot, partNumber, description, productGroup, classCode }) => ({ depot, partNumber, description, productGroup, classCode }));
        // map in the missing ones
        modifiedParts = modifiedParts.map(p => ({...p, id: undefined, dateAdded: undefined, markedForDeletion: false}));

        //this.preListings = this.preListings.filter(p => !partsToExclude.find(e => e.partNumber === p.partNumber && e.depot === p.depot));
        
        if(partsToExclude.length > 0) {
            await this.addExclusions(modifiedParts);
            this.toast('b-toaster-top-center', 'success', `${partsToExclude.length} part(s) excluded.`, true);
        }
        else
        {
            this.toast('b-toaster-top-center', 'danger', `No parts were selected for exclusion.`, true);
        }

    },

    async includeParts(partsToInclude)
    {
        console.debug('Listings Manager - Including parts: ');
        console.debug(partsToInclude);

        if(partsToInclude.length > 0) {
            await this.deleteExclusions(partsToInclude);
            this.toast('b-toaster-top-center', 'success', `${partsToInclude.length} part(s) reincluded.`, true);
        }
        else
        {
            this.toast('b-toaster-top-center', 'danger', `No parts were selected for reinclusion.`, true);
        }
    },
      async refreshPrelistings() {
        var d = new Date();
        console.debug("main page: refresh prelistings start - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
        this.prelistingsLoading = true;
        this.pageLoading = true;
        this.fetchRuleCounts();
        await this.fetchExclusions();
        await this.fetchListingDiff();
        await this.fetchCurrentListings();
        await this.fetchListingRules();
        this.pageLoading = false;

        let p = await RulesApi.getPreListings();
        this.preListings = p.data;

        this.prelistingsLoading = false;
        if(this.preListings != undefined) {
            console.debug(this.preListings.length + " prelistings loaded " + Date());
        }
        else {
            console.debug("prelisting undefined");
        }        
        var d = new Date();
        console.debug("main page: refresh prelistings end - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
      },
      async createFilter(filterType, filterName, selectedItems, metric, modifier){
        console.debug("Creating Rule - " + filterType + " '" + filterName + "', containing " + selectedItems);
          // map our commands to the classList methods
          const fnmap = {
              'toggle': 'toggle',
              'show'  : 'add',
              'hide'  : 'remove'
          };
          const collapse = (selector, cmd) => {
          const targets = Array.from(document.querySelectorAll(selector));
          targets.forEach(target => {
              target.classList[fnmap[cmd]]('show');
          });
          }

          let updating = false;
          let id = 0;
          if(this.selectedFilter !== null) {
                id = this.selectedFilter.id;
                updating = true;
          }

          if(filterType === 'Product') {
              collapse('#ProductFilters','hide');
              const newRule = {
                id: id,
                description: filterName,
                filterType: 3,
                category: 3,
                values: selectedItems
              };
              if (updating == true) {
                await this.updateProductFilter(newRule);
              }
              else {
                await this.createProductFilter(newRule);
              }
              this.toastProductFilterModification(id, filterName);
              collapse('#ProductFilters','show');
          }
          else if(filterType === 'Class Code') {
              collapse('#ClassCodeFilters','hide');
              const newRule = {
                id: id,
                description: filterName,
                category: 1,
                filterType: 1,
                values: selectedItems
              };
              if (updating == true) {
                await this.updateProductFilter(newRule);
              }
              else {
                await this.createProductFilter(newRule);
              }
              this.toastProductFilterModification(id, filterName);
              collapse('#ClassCodeFilters','show');
          }
          else if(filterType === 'Depot') {
              collapse('#DepotFilters','hide');
              const newRule = {
                id: id,
                description: filterName,
                category: 5,
                filterType: 6,
                values: selectedItems
              };
              if (updating == true) {
                await this.updateProductFilter(newRule);
              }
              else {
                await this.createProductFilter(newRule);
              }
              this.toastProductFilterModification(id, filterName);
              collapse('#DepotFilters','show');
          }
          else if(filterType === 'Quantity') {
              collapse('#QuantityFilters','hide');
              let itemsArray = [];
              itemsArray.push(selectedItems);
              const newRule = {
                id: id,
                description: filterName,
                category: 6,
                filterType: 8,
                values: itemsArray
              };
              if (updating == true) {
                await this.updateProductFilter(newRule);
              }
              else {
                await this.createProductFilter(newRule);
              }
              this.toastProductFilterModification(id, filterName);
              collapse('#QuantityFilters','show');
          }
          else if(filterType === 'Prefix') {
              collapse('#PrefixFilters','hide');
              const newRule = {
                id: id,
                description: filterName,
                category: 2,
                filterType: 2,
                values: selectedItems
              };
              if (updating == true) {
                await this.updateProductFilter(newRule);
              }
              else {
                await this.createProductFilter(newRule);
              }
              this.toastProductFilterModification(id, filterName);
              collapse('#PrefixFilters','show');
          }
          else if(filterType === 'Date') {
             collapse('#TimeFilters','hide');
              const newRule = {
                id: id,
                description: filterName,
                category: metric,
                comparator: modifier,
                filterType: 5,
                months: parseInt(selectedItems, 10)
              };
              if (updating == true) {
                await this.updateTimeFilter(newRule);
              }
              else {
                await this.createTimeFilter(newRule);
              }
              this.toastDateFilterModification(id, filterName);
              collapse('#TimeFilters','show');
          }
          else if(filterType === 'Pricing') {
              collapse('#PriceMods','hide');
              const newModifier = {
                  id: id,
                  description: filterName,
                  priceType: metric,
                  amount: parseFloat(selectedItems, 2)
              };
              if (updating == true) {
                await this.updatePriceModifier(newModifier);
              }
              else {
                await this.createPriceModifier(newModifier);
              }
              this.toastPriceModifierModification(id, filterName);
              collapse('#PriceMods','show');
          } 
      },
      toastProductFilterModification(id, filterName) {
          if (this.productFilterError == null || this.productFilterError == undefined) {
            if (id == 0) {
                this.toast('b-toaster-top-center', 'success', `${filterName} filter Created.`, true);
            } else {
                this.toast('b-toaster-top-center', 'success', `${filterName} filter Updated.`, true);
                this.showAddEditFilterModal = false;
            }
          }
          else {
              this.toast('b-toaster-top-center', 'danger', `Error creating or updating filter: ${this.productFilterError}.`, true);
          }
      },
      toastDateFilterModification(id, filterName) {
          if (this.timeFilterError == null || this.timeFilterError == undefined) {
            if (id == 0) {
                this.toast('b-toaster-top-center', 'success', `${filterName} filter Created.`, true);
            } else {
                this.toast('b-toaster-top-center', 'success', `${filterName} filter Updated.`, true);
                this.showAddEditFilterModal = false;
            }
          }
          else {
              this.toast('b-toaster-top-center', 'danger', `Error creating or updating filter: ${this.timeFilterError}.`, true);
          }
      },
      toastPriceModifierModification(id, filterName) {
          if (this.priceModifiersError == null || this.priceModifiersError == undefined) {
            if (id == 0) {
                this.toast('b-toaster-top-center', 'success', `${filterName} modifier Created.`, true);
            } else {
                this.toast('b-toaster-top-center', 'success', `${filterName} modifier Updated.`, true);
                this.showAddEditFilterModal = false;
            }
          }
          else {
              this.toast('b-toaster-top-center', 'danger', `Error creating or updating modifier: ${this.priceModifiersError}.`, true);
          }
      },
      async addFilter() {
        this.addEditFilterMode = "Create New";
        this.selectedFilter = null;
        this.showAddEditFilterModal = true;
      },
      async editFilter(filter){
        this.addEditFilterMode = "Edit";
        this.selectedFilter = filter;
        console.debug("edit filter " + filter.id + " " + filter.description);
        this.showAddEditFilterModal = true;
      },
      async deleteFilter(filter){
        console.debug("request to delete filter " + filter.id + " category " + filter.filterType);
        
        if (filter.filterType <= 4 || filter.filterType == 6 || filter.filterType == 8) {
            if (confirm("Are you sure you wish to delete product filter -" + filter.description)) {
                await this.deleteProductFilter(filter);
                if (this.productFilterError == null || this.productFilterError == undefined) {
                    this.toast('b-toaster-top-center', 'success', `${filter.description} filter deleted.`, true);
                    
                }
                else {
                    this.toast('b-toaster-top-center', 'danger', `${this.productFilterError}.`, true);
                }
            }
        }
        if (filter.filterType == 5) {
            if (confirm("Are you sure you wish to delete time filter -" + filter.description)) {
                await this.deleteTimeFilter(filter);
                if (this.timeFilterError == null || this.timeFilterError == undefined) {
                    this.toast('b-toaster-top-center', 'success', `${filter.description} filter deleted.`, true);
                }
                else {
                    this.toast('b-toaster-top-center', 'danger', `${this.timeFilterError}.`, true);
                }
            }
        }
        if (filter.filterType == 7) {
            if (confirm("Are you sure you wish to delete price modifier -" + filter.description)) {
                await this.deletePriceModifier(filter);
                if (this.priceModifiersError == null || this.priceModifiersError == undefined) {
                    this.toast('b-toaster-top-center', 'success', `${filter.description} modifier deleted.`, true);
                }
                else {
                    this.toast('b-toaster-top-center', 'danger', `${this.priceModifiersError}.`, true);
                }
            }
        }
      },
      
      
      dragStart(type){
          this.pricingDragged = false;
          this.filterDragged    = false;
          this.timeFilterDragged = false;
          this.depotDragged = false;

          if(type == -1)
          {
              this.pricingDragged = true;
          }
          else if(type == 6) {             
              this.depotDragged = true;
          }
          else if (type == 5) {
              this.timeFilterDragged = true;
          }
          else
          {
              this.filterDragged    = true;
          }
      },

      dragEnd(){
          this.pricingDragged = false;
          this.filterDragged    = false;
          this.depotDragged = false;
          this.timeFilterDragged = false;
      },

      async togglePublish(rule){
            await this.toggleListingRuleIsPublished({'listingRuleId' : rule.id});
      },

    },
                                             
// 88888888ba,                                  
// 88      `"8b               ,d                
// 88        `8b              88                
// 88         88 ,adPPYYba, MM88MMM ,adPPYYba,  
// 88         88 ""     `Y8   88    ""     `Y8  
// 88         8P ,adPPPPP88   88    ,adPPPPP88  
// 88      .a8P  88,    ,88   88,   88,    ,88  
// 88888888Y"'   `"8bbdP"Y8   "Y888 `"8bbdP"Y8  
                                             
 
    data() {
        return {
            prelistingsLoading      : false,
            loading                 : false,
            error                   : null,
            pricingDragged          : false,
            filterDragged           : false,
            depotDragged            : false,
            timeFilterDragged       : false,
            state                   : null,
            results                 : null,
            showAddEditFilterModal  : false,
            showPreviewPartsModal   : false,
            showExcludedPartsModal  : false,
            showReviewAndUploadModal: false,
            selectedResult          : null,
            selectedFilter          : null,
            listingRulesUsingFilter : null,
            addEditFilterMode       : 'Create New',
            isExporting             : false,
            infoFilters             : 'filters',
            infoRules               : 'rules',
            infoUpload              : 'upload',
            rulesColumns            : RulesColumns,
            pageLoading             : false,
            preListings             : [],
            listingRuleCounts       : [],
            reviewAndUploadError    : "",
            showReviewAndUploadError: false,
            uploadInProgress        : false,
            docStoreOptions         : DocStoreOptions,
            requiredLicenses        : requiredLicenses,
            timer                   : 0,
            licensedTimer           : 1,
            licenseChecks           : 0,
            showPasswordModal       : true
        }
    },
}
</script>
<style lang="less" scoped>
    .card-header {
        background-color: #DBDBDB;
        
        a, a:hover, a:visited, a:active {
            color: black;
            text-decoration: none;
        }
        margin-bottom:    .2rem;
    }
    .link {
        text-decoration: none;
        color: black; 
    }
    

</style>