import ChecklistApi      from '@WS/api/checklist';
import { set, reset, commit }   from '../mutators';
import * as mutation    from '@/store/mutation-types';

const initialState = () => ({	
    checklistTemplates: {
        value   : [],
        loading : false,
        error   : null,
    }
});

const getters = {
    /**
     * Get all checklists.
     */
    checklistTemplates: state => {
        return state.checklistTemplates.value;
    },
    /**
     * Get a checklist template by id
     */
    selectedChecklistTemplate: ({checklistTemplates}) => id => {
        return _.find(checklistTemplates.value, c => c.Id === id);
    },
    /**
     * the checklists loading state
     */
    loading: ({checklistTemplates}) => checklistTemplates.loading
};

const mutations = {
    // Set state.
    [mutation.SET_CHECKLIST_TEMPLATES     ] : set('checklistTemplates.value'   , 'checklistTemplates'),
    [mutation.CHECKLIST_TEMPLATES_ERROR   ] : set('checklistTemplates.error'   , 'error'    ),
    [mutation.CHECKLIST_TEMPLATES_LOADING ] ({checklistTemplates}, {loading}){
        checklistTemplates.loading  = loading;
        checklistTemplates.error    = null;
    },
    [mutation.SET_CHECKLIST_TEMPLATE]({checklistTemplates}, checklistTemplate){
        if(null != checklistTemplate || undefined != checklistTemplate)
        {
            checklistTemplates.value.push(checklistTemplate);
        }
    },
    [mutation.UPDATE_CHECKLIST_TEMPLATE]({checklistTemplates}, checklistTemplate){
        let index = _.findIndex(checklistTemplates.value, c => c.Id == checklistTemplate.Id);
        if (-1 !== index){
            checklistTemplates.value[index] = checklistTemplate;
        }
    },
    [mutation.DELETE_CHECKLIST_TEMPLATE]({checklistTemplates}, id){
        const index = _.findIndex(checklistTemplates.value, c => c.Id == id);
        if (-1 !== index){
            checklistTemplates.value.splice(index, 1);
        }
    },

    // Clear state.
    [mutation.CLEAR_CHECKLIST_TEMPLATES       ] : (state) => state.checklistTemplates.value = [],
    [mutation.CLEAR_CHECKLIST_TEMPLATES_ERROR ] : (state) => state.checklistTemplates.error = null,

    // Reset state.
    [mutation.RESET                 ] : (state) => reset(state, initialState()),
};

const actions = {
    /**
     * Fetch all checklists templates for the current company from
     * the API and store in vuex.
     */
    async fetchChecklistTemplates({ commit }) {
        commit(mutation.CLEAR_CHECKLIST_TEMPLATES);
        commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: true });
        
        try {
            const response            = await ChecklistApi.getAll();
            const checklistTemplates = response.data.data;
            commit(mutation.SET_CHECKLIST_TEMPLATES, { checklistTemplates });
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.CHECKLIST_TEMPLATES_ERROR, { error: error });
        }
        finally{
            commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: false });
        }

    },
    /**
     * Fetch a checklist template for the current company from
     * the API and store in vuex.
     * @param {number} id - the id of the checklist
     */
    async fetchChecklistTemplate({ commit }, id) {
        commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: true });
        
        try {
            const response          = await ChecklistApi.getById(id);
            const checklistTemplate = response.data.data;
            
            commit(mutation.SET_CHECKLIST_TEMPLATES, checklistTemplate);
        }
        catch (error) {
            commit(mutation.CHECKLIST_TEMPLATES_ERROR, { error: error });
        }
        finally{
            commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: false });
        }
    },
    /**
     * Sends the checklist template create request to
     * the API and store in vuex.
     * @param {object} checklistTemplate - the checklist to create
     */
    async createChecklistTemplate({ commit }, checklistTemplate) {
        commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: true });
        
        try {
            const response     = await ChecklistApi.create(checklistTemplate);
            const created      = response.data.data;
            
            commit(mutation.SET_CHECKLIST_TEMPLATE, created);
        }
        catch (error) {
            commit(mutation.CHECKLIST_TEMPLATES_ERROR, { error: error });
        }
        finally{
            commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: false });   
        }

    },
    /**
     * Sends the checklist template update request to
     * the API and store in vuex.
     * @param {object} checklistTemplate - the checklist to create
     */
    async updateChecklistTemplate({ commit }, checklistTemplate) {
        commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: true });
        
        try {
            const response     = await ChecklistApi.update(checklistTemplate);
            const updated      = response.data.data;

            commit(mutation.UPDATE_CHECKLIST_TEMPLATE, updated)
        }
        catch (error) {
            commit(mutation.CHECKLIST_TEMPLATES_ERROR, { error: error });
        }
        finally{
            commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: false }); 
        }

    },
    /**
     * Sends the checklist template delete request to
     * the API and store in vuex.
     * @param {object} id - the id of the checklist
     */
    async deleteChecklistTemplate({ commit }, id) {
        commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: true });
        
        try {
            const response     = await ChecklistApi.deleteById(id);

            commit(mutation.DELETE_CHECKLIST_TEMPLATE, id);
        }
        catch (error) {
            commit(mutation.CHECKLIST_TEMPLATES_ERROR, { error: error });
        }
        finally{
            commit(mutation.CHECKLIST_TEMPLATES_LOADING, { loading: false });
        }
    },
};

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
}
