<template>
    <div class="date-input">
        <input 
            class="form-control"
            :class="{ 'is-invalid' : false === valid }"
            v-model="input.display"
            :state="input.valid"
            @keydown.enter="validateDateTime"
            @blur="validateDateTime"
            @focus="$event.target.select()"
        />

        <div v-if="!hideError">
            <b 
                class="error"
                :class="{ 'show-error' : false === input.valid }"
            >
                Invalid date/time.
            </b>
        </div>
    </div>
</template>

<script>
import DateMixin, { DateFormat }    from '@/mixins/DateMixin';
import { has as _has } from 'lodash-es';

/**
 * Component for inputting the date with similar functionality to date
 * fields within Gold.
 */
export default {
    name: 'IbDateInput',

    mixins: [
        DateMixin
    ],

    props: {

        value: {
            required: true,
            default: null
        },

        hideError: {
            type: Boolean,
            required: false,
            default: false
        },

        valid: {
            required: false,
            default:  null
        }

    },

    data() {
        return {
            input: {
                display: null,
                value:   null,
                valid:   null
            }
        }
    },
    
    created() {
        if (this.value) {
            this.input.value = this.value;
        }
    },

    methods: {

        /**
         * Validation function called when the display is changed.
         * Ensures the input is made up of only numerics and '/'.
         */
        validateDateTime() {
            if (!this.input.display) {
                //fail fast
                return;
            }

            this.input.valid = false;
            this.transformInput(this.input.display);
        },

        /**
         * Transforms the data input into a valid date time.
         */
        transformInput(input) {
            let regex = /^(?<day>\d+)(?<month>\d{2})(?<year>\d{4})$/g;
            let date  = this.getCurrentDateTime()
                            .startOf('day');
            let day   = date.date();
            let month = date.month();
            let year  = date.year();
            input     = input.replace(/\//g, '');
            // Just 2 characters enter; assume only day has been entered.
            if (2 >= input.length) {
                day = input;
            }
            else { 
                // DDMM
                if (4 >= input.length) {
                    regex = /^(?<day>\d+)(?<month>\d{2})$/g;                
                }
                // DDMMYY
                else if (6 >= input.length) {
                    regex = /^(?<day>\d+)(?<month>\d{2})(?<year>\d{2})/g;
                }

                let result = regex.exec(input);
                
                if (result) {
                    day        = result.groups.day ? result.groups.day 
                                                : day;

                    month      = result.groups.month ? result.groups.month
                                                    : month;
                    // Months are 0 based in moment js, so subtract 1 from entered month.
                    month--;
                    
                    year       = result.groups.year ? result.groups.year
                                                    : year;
                }

                // If we've only been provided a 2 character year, add the '20'
                // to the start.
                if (3 > year.length) {
                    year = '20' + year;
                }
            }

            date.date(day);
            date.month(month);
            date.year(year);

            if (date.isValid) {
                this.input.value = date;
                this.input.valid = null;
            }
        }

    },

    watch: {

        value(newValue) {
            if (newValue) {
                let display = newValue;

                if (newValue.toISOString) {
                    display = newValue.toISOString();
                }

                this.input.display = this.getDateString(display, this.DateFormat.DATE_ONLY);
            }
        },

        "input.value"(newValue) {
            this.input.display = null;
            if (newValue) {
                this.input.display = this.getDateString(newValue.toISOString(), this.DateFormat.DATE_ONLY);
            }
            
            this.$emit('input', this.input.value);
        }

    }
}
</script>

<style lang="less" scoped>

.error {
    color: red;
    visibility: hidden;
}

.show-error {
    visibility: visible;
}

</style>