<template>
    <div>
        <h3 style="display: inline-block;"><strong>Engineer</strong> {{engineer.Name}} | {{engineer.Id}}</h3>

        <div v-show="error" class="alert alert-danger alert-dismissible fade show" role="alert">
            {{error}}
        </div>

        <b-card class="engineer-data">
            <b-card-body class="engineer-data-body">
                <b-tabs content-class="pt-3">
                    <b-tab title="Details">
                        <summary-tab :engineer="engineer">
                        </summary-tab>
                    </b-tab>
                  
                    <b-tab v-if="hasDocStoreOption(docStoreOptions.GOLD_SERVICE_PLUS)" 
                           title="Stock">                
                        <van-stock-tab :engineer="engineer">
                        </van-stock-tab>
                    </b-tab>

                </b-tabs>
            </b-card-body>
       
            <b-card-footer class="engineer-data-footer">
                <ib-back-button size="lg"></ib-back-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import EngineerApi         from '@WS/api/engineer';
import LicenceMixin        from '@/mixins/LicenceMixin';
import IbBackButton        from '@/components/IbBackButton';
import { DocStoreOptions } from '@/common/docstore-options';

const SummaryTab  = () => import(/* webpackChunkName: "workshop" */ '@WS/components/engineer/SummaryTab');
const VanStockTab = () => import(/* webpackChunkName: "workshop" */ '@WS/components/engineer/VanStockTab');

export default {
    name: "Engineer",

    mixins: [
        LicenceMixin
    ],

    data(){
        return {
            engineer:        { Id : null},
            id:              null,
            error:           null,
            docStoreOptions: DocStoreOptions
        }
    },

    components: {
        SummaryTab,
        VanStockTab,

        IbBackButton
    },

    mounted(){
        this.id = this.$route.params.id;
    },

    methods:{
        getEngineerById(){
            const me = this;

            EngineerApi
                .getById(me.id)
                .then(
                    success => {
                        me.engineer = success.data.data;
                    },
                    failure => {
                        me.error = `Failed to get Engineer '${me.id}'. ${failure.message}.`;
                    }
                );
        }
    },

    watch: {
        id(newValue, oldValue){
            if (null !== newValue) {
                this.getEngineerById();
            }
        }
    }

    
}
</script>

<style scoped>

input {
    display: inline-block !important;
}

label  {
    display: inline-block;
    min-width: 80px;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
}

.engineer-data {
    height: 100vh - 300px;
}
.engineer-data-body {

}
.engineer-data-footer {

}
</style>
