<template>
    <div>
    <select
        class="custom-select"
        v-model="selectedCompany"
    >
        <option
            v-for="(option, index) in companyOptions"
            :key="index"
            :value="option.value"
        >
            {{ option.text }}
        </option>
    </select>
    <label v-show="(errorString)" style="color: #dc3545;">{{ errorString }}</label>
</div>
</template>

<script>
import {
    mapState,
    mapGetters,
    mapActions,
} from 'vuex';

export default {

    computed: {
        ...mapState('system', {
            companies       : state => state.company.list,
        }),

        ...mapState('auth', {
            rememberMe      : state => state.rememberMe,
        }),

        ...mapGetters('system', [
            'currentCompanyId',
        ]),

        ...mapState('system', {
            errorsEncountered  : state => state.company.error,
        }),

        errorString() {
            return this.errorsEncountered;
        },

        companyOptions() {
            const options = this.companies.map(company => {
                const text = `${company.Id} - ${company.Name}`;
                return {
                    value   : company.Id,
                    text    : text
                };
            });
            return options;
        },

        selectedCompany: {
            get() {
                return this.currentCompanyId;
            },
            set(companyId) {
                this.setCompany({
                    companyId,
                    rememberMe: this.rememberMe,
                });
            },
        },

    },

    methods: {
        ...mapActions('system', {
            setCompany  : 'setCurrentCompany',
        }),
    },
}
</script>