import ApiService from '@/common/api-service';
import FiltersEngineApiService from '@/common/rulesengine-api-service';

const RULES_ROOT = "api/v1/";
const RULES_ENGINE_ROOT = "rulesengine/v1";

const FiltersApi = {
    /**
    * Get the rules model
    */
    getRulesModel() {
        const url = `${RULES_ROOT}/`;
        return ApiService.get(url);
    },
    saveRulesModel(state) {
        const url = `${RULES_ROOT}/`;
        return ApiService.post(url, state);
    },
    pushListings(state) {
        const url = `${RULES_ROOT}/push`;
        return ApiService.post(url, state);
    },
    getParts() {
        const url = `${RULES_ROOT}/parts`;
        return ApiService.get(url);
    },
    getPreListings() {
        const url = `${RULES_ENGINE_ROOT}/prelistings`;
        return FiltersEngineApiService.get(url);
    },
    getPreListingsToBePublished() {
        const url = `${RULES_ENGINE_ROOT}/prelistings/published`;
        return FiltersEngineApiService.get(url);
    },

    getListingDiff() {
        const url = `${RULES_ENGINE_ROOT}/listings/diff`;
        return FiltersEngineApiService.get(url);
    },

    getLastUploaded() {
        const url = `${RULES_ENGINE_ROOT}/listings/lastuploaded`;
        return FiltersEngineApiService.get(url);
    },

    // product filters endpoints
    getProductFilters() {
        const url = `${RULES_ENGINE_ROOT}/product-filters`;
        return FiltersEngineApiService.get(url);
    },
    getProductFilter(filterID) {
        const url = `${RULES_ENGINE_ROOT}/product-filters/${filterID}`;
        return FiltersEngineApiService.get(url);
    },
    setProductFilter(filter) {
        const url = `${RULES_ENGINE_ROOT}/product-filters/`;
        return FiltersEngineApiService.post(url, filter);
    },
    deleteProductFilter(filter) {
        const url = `${RULES_ENGINE_ROOT}/product-filters/${filter.id}`;
        return FiltersEngineApiService.delete(url);
    },
    getProductFilterSetsForFilter(filterID) {
        const url = `${RULES_ENGINE_ROOT}/product-filters/${filterID}/listing-rules`;
        return FiltersEngineApiService.get(url);       
    },

    // time filters endpoints
    getTimeFilters() {
        const url = `${RULES_ENGINE_ROOT}/time-filters`;
        return FiltersEngineApiService.get(url);
    },
    getTimeFilter(filterID) {
        const url = `${RULES_ENGINE_ROOT}/time-filters/${filterID}`;
        return FiltersEngineApiService.get(url);
    },
    setTimeFilter(filter) {
        const url = `${RULES_ENGINE_ROOT}/time-filters/`;
        return FiltersEngineApiService.post(url, filter);
    },
    deleteTimeFilter(filter) {
        const url = `${RULES_ENGINE_ROOT}/time-filters/${filter.id}`;
        return FiltersEngineApiService.delete(url);
    },
    getTimeFilterSetsForFilter(filterID) {
        const url = `${RULES_ENGINE_ROOT}/time-filters/${filterID}/listing-rules`;
        return FiltersEngineApiService.get(url);       
    },

    // price modifier endpoints
    getPriceModifiers() {
        const url = `${RULES_ENGINE_ROOT}/price-modifiers`;
        return FiltersEngineApiService.get(url);
    },
    getPriceModifier(priceModifierID) {
        const url = `${RULES_ENGINE_ROOT}/price-modifiers/${priceModifierID}`;
        return FiltersEngineApiService.get(url);
    },
    setPriceModifier(priceModifier) {
        const url = `${RULES_ENGINE_ROOT}/price-modifiers/`;
        return FiltersEngineApiService.post(url, priceModifier);
    },
    deletePriceModifier(priceModifier) {
        const url = `${RULES_ENGINE_ROOT}/price-modifiers/${priceModifier.id}`;
        return FiltersEngineApiService.delete(url);
    },


    // listingRule endpoints
    getListingRules() {
        const url = `${RULES_ENGINE_ROOT}/listing-rules`;
        return FiltersEngineApiService.get(url);
    },
    getListingRuleCounts() {
        const url = `${RULES_ENGINE_ROOT}/prelistings/count`;
        return FiltersEngineApiService.get(url);
    },
    getListingRule(filtersetID) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/${filtersetID}`;
        return FiltersEngineApiService.get(url);
    },
    setListingRule(listingRule) {
        listingRule.IncludeProductFilters = listingRule.includeProductFiltersList.join(',');
        listingRule.ExcludeProductFilters = listingRule.excludeProductFiltersList.join(',');
        listingRule.IncludeTimeFilters = listingRule.includeTimeFiltersList.join(',');
        const url = `${RULES_ENGINE_ROOT}/listing-rules/`;
        return FiltersEngineApiService.post(url, listingRule);
    },
    shiftPriorityUp(listingRuleID) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/shift-priority-up/${listingRuleID}`;
        return FiltersEngineApiService.post(url);
    },
    shiftPriorityDown(listingRuleID) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/shift-priority-down/${listingRuleID}`;
        return FiltersEngineApiService.post(url);
    },
    deleteListingRule(listingRuleID) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/${listingRuleID}`;
        return FiltersEngineApiService.delete(url);
    },
    toggleShowInResults(listingRule) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/toggle-show-in-results/${listingRule.id}`;
        return FiltersEngineApiService.post(url);
    },
    toggleIsPublished(listingRule) {
        const url = `${RULES_ENGINE_ROOT}/listing-rules/toggle-is-published/${listingRule.id}`;
        return FiltersEngineApiService.post(url);
    },

    // part information support services
    getPrefixes() {
    const url = `${RULES_ROOT}/parts/prefixCode`;
    return ApiService.get(url);
    },
    getClassCodes() {
        const url = `${RULES_ROOT}/parts/classcode`;
        return ApiService.get(url);
    },
    getProductGroups() {
        const url = `${RULES_ROOT}/parts/productgroup`;
        return ApiService.get(url);
    },
    getDepots() {
        const url = `${RULES_ROOT}/parts/productgroup`;
        return ApiService.get(url);
    },
    getPrelistingCSVFile(searchPhrase){
        const url = `${RULES_ENGINE_ROOT}/prelistings/file?searchPhrase=${searchPhrase}`;
        return FiltersEngineApiService.getFile(url);
    },
    getAdditionsCSVFile(){
        const url = `${RULES_ENGINE_ROOT}/listings/diff/file/additions`;
        return FiltersEngineApiService.getFile(url);
    },
    getRemovalsCSVFile(){
        const url = `${RULES_ENGINE_ROOT}/listings/diff/file/removals`;
        return FiltersEngineApiService.getFile(url);
    },
    getChangesCSVFile(){
        const url = `${RULES_ENGINE_ROOT}/listings/diff/file/changed`;
        return FiltersEngineApiService.getFile(url);
    },
    getQuantityChangesCSVFile(){
        const url = `${RULES_ENGINE_ROOT}/listings/diff/file/quantity-changed`;
        return FiltersEngineApiService.getFile(url);
    },
    getPrelistingCSVFileToBePublished(){
        const url = `${RULES_ENGINE_ROOT}/prelistings/file/published`;
        return FiltersEngineApiService.getFile(url);
    },
    readFileError(message){
        const fileReader = new FileReader();
  
        return new Promise((resolve, reject) => {
            fileReader.onerror = () => {
            fileReader.abort();
            reject(new Error('Problem parsing file'));
            };

            fileReader.onload = () => {
            resolve(fileReader.result);
            };

            fileReader.readAsText(message);
        });
    },

    // part exclusion methods

    getExcludedParts(){
        const url = `${RULES_ENGINE_ROOT}/exclude-parts`;
        return FiltersEngineApiService.get(url);
    },
    setExcludedParts(parts) {
        const url = `${RULES_ENGINE_ROOT}/exclude-parts/`;
        return FiltersEngineApiService.post(url, parts);
    },

    getCurrentListings() {
        const url = `${RULES_ENGINE_ROOT}/listings/lastuploaded`;
        return FiltersEngineApiService.get(url);
    }
}
export default FiltersApi;