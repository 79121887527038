import ApiService 	from '@/common/api-service';

const JOB_ACTIVITY_V1_ROOT = "api/v1/job/activity";
const JOB_ACTIVITY_V2_ROOT = "api/v2/workshop/jobactivity";

const JobActivityApi = {
	/**
     * Insert a new job activity.
     * 
     * @param {object} activity - The job activity to update.
     * @returns Job activity model with a new ID.
     */
    insert(activity) {
        const url = `${JOB_ACTIVITY_V2_ROOT}`;
        return ApiService.post(url, activity);
    },

    /**
     * Update an existing job activity.
     * 
     * @param {object} activity - The job activity to update.
     * @returns 
     */
    update(activity) {
        const url = `${JOB_ACTIVITY_V2_ROOT}/${activity.Id}`;
        return ApiService.put(url, activity);
    },

    /**
     * Approve an existing job activity.
     * 
     * @param {object} activity - The job activity to approve.
     */
    approve(activity, rate) {
        let url = `${JOB_ACTIVITY_V2_ROOT}/${activity.Id}/approve`;

        if (rate) {
            url += `?rate=${rate}`;
        }
        
        return ApiService.post(url);
    },

    /**
     * Get all unapproved job activities for an engineer.
     * 
     * Also returns all approved activities on the same days to
     * provide a full picture of the engineers logged time.
     * @param {string} engineerId - ID of engineer to get activities for.
     * @param {string} depotId    - Limit activities to this depot.
     */
	getForEngineerForApproval(
        engineerId,
        depotId = null
    ) {
        const url = `${JOB_ACTIVITY_V1_ROOT}/engineer/${engineerId}/approve`;
        // Pass depot ID as a query parameter.
		const config = {
			params: {
				depot: depotId
			}
        };
        return ApiService.get(url, config);
    }
}

export default JobActivityApi;
