import ApiService from '@/common/api-service';

const ORDER_ROOT = "api/v1/ordertype";

const orderApi = {
	getAllOrderTypes() {
		const url = `${ORDER_ROOT}`
		return ApiService.get(url);
	},
};

export default orderApi;
