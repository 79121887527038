<template>
<div class="form-row dayfooter flex">	
	<div class="col-3 totalhours-label">
		<b>Total Hours:</b>
		{{ totalDuration | formatDuration }} 
	</div>
	<div class="col-9 dayfooter-content align-self-center">
			<button class="btn btn-success float-right" title="Approve"
				:disabled="disableApproveButton"
				@click="approve">
				Approve
			</button>
			<button class="btn btn-primary float-right mr-2" title="Add"
				v-show="hideAddButton === false"
				@click="add">
				Add Hours
			</button>
	</div>
</div>
</template>

<script>
import Moment               from 'moment';
import MomentDurationFormat from 'moment-duration-format';

export default {
	name: 'DayFooter',

	//https://vuejs.org/v2/style-guide/#Prop-definitions-essential
	props: {
		totalDuration: {
			type: 	  Object,
			required: true
		},
		hideAddButton: {
			type:     Boolean,
			requied:  true,
		},

		disableApproveButton: {
			type:     Boolean,
			required: true
		}
	},

	filters: {
        /**
		 * Return a duration string (Xh Ym) from a MomentJS duration object.
		 */
		formatDuration(value) {
			let duration = '';
			if (value !== null) {
				duration = value.format('h[h] m[m]', {
								trim: "none"
							});
			}
			return duration;
		}
    },

	methods: {
		add() {
			this.$emit('add');
		},

		approve() {
			this.$emit('approve');
		},

		getDateFromTime(time) {
			let date = Moment(time, "HH:mm")
			return date;
        },

        /**
		 * Create a MomentJS object from a datetime string.
		 */
		getDateFromDateTime(dateTime) {
			let date = Moment(dateTime, "YYYY-MM-DD HH:mm:ss")
			return date;
		},

        /**
		 * Return a time string (HH:MM) from a MomentJS object.
		 */
		formatAsTime(moment) {
			let formattedTime = moment.format('HH:mm');
			return formattedTime;
		},
        
        /**
         * Returns the total duration of the job activity passed in.
         */
        getDuration(jobActivity){
            const originalStart = this.getDateFromDateTime(jobActivity.Start);
            const originalEnd = this.getDateFromDateTime(jobActivity.End);

            const startTime = this.formatAsTime(originalStart);
            const endTime = this.formatAsTime(originalEnd);

            const start = this.getDateFromTime(startTime);
            const end = this.getDateFromTime(endTime);

            const difference = end - start;

            let momentDuration = Moment.duration(difference);

            return momentDuration;
        }
    }
}
</script>

<style scoped>
.dayfooter {
	height: 35px;
}
.totalhours {
	display: inline-block;
}
.totalhours-label {
	display: inline-block;
    padding-left: 0px !important;
	padding-right: 0px !important;
    width: 20%;
	line-height: 35px;
}
.totalhours-value {
	display: inline-block;
	margin-left: -5px;
}
</style>
