<template>
    <ib-modal 
        v-model="show"
        modalClass="w-25 p-2"
        :dismissable=false
    >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i class="fas fa-exclamation-triangle"></i> Warning</h3>
            </div>
            {{message}}
            <hr>
            <div class = "row p-2 float-right">
                <button class="btn btn-success" @click="hideWarning">Ok</button>
            </div>
        </div>
    </ib-modal>
</template>
<script>
import IbModal          from '@/components/utilities/IbModal';

export default {
    name: "IbWarningModal",

    components: {
        IbModal
    },

    data() {
        return {
            show       : false,
        }
    },

    props: {
          /**
         * Flag to indicate if modal is shown.
         */
        value: {
            required : true,
            type     : Boolean
        },
        message: {
            required : true,
            type     : String
        },
    },

    methods: {
        hideWarning() {
            this.$emit('hideWarning');
            this.show = false;
        }

    },
    watch: {
        value(newValue) {
            this.show = newValue;
        },
        show(newvalue) {
            if(this.show == false) {
                this.$emit('hideWarning');
            }
        }
    }
}
</script>
