import ApiService from '@/common/api-service';

const SUPPLIER_ROOT = "api/v1/supplier";

const SuppliersApi = {
    ///
    /// GET Requests
    ///
    /**
     * Get all suppliers.
    */
    getAllSuppliers() {
        const url = `${SUPPLIER_ROOT}`;
        return ApiService.get(url);
    }
};

export default SuppliersApi;
