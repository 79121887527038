import ApiService	 	from '@/common/api-service';
const ROOT_PATH 			= "api/v1/job/request/invoice/mappings";

const JobRequestInvoiceMappingApi ={

    async getJobRequestInvoiceMappings()
    {
        const url = `${ROOT_PATH}`; 
        return await ApiService.get(url);
    },

    async saveJobRequestInvoiceMappings(mappings){
        const url = `${ROOT_PATH}`; 
        return await ApiService.post(url, mappings);
    }

}

export default JobRequestInvoiceMappingApi;