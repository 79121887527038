<template>

    <input type="text"
           class="form-control"
           v-model="maskedText"
           :class="{ 'invalid': !isValid }"
           @focus="$event.target.select()"/>

</template>

<script>
import { RegexTokens } from '@/common/regex-tokens'

/**
 * This is a base component for masked input. Taking a mask to apply
 * to the text entered. As well as regex, max length and default 
 * characters to validate the input.
 */
export default {
    name: 'IbMaskedInput',

    props: {

        /**
         * The parent source of the v-model binding.
         */
        value: {
            required: true,
            default: null
        },
        /**
         * The mask that is applied to the text.
         */
        mask: {
            required: false,
            default: ''
        },
        /**
         * The maximum number of characters to allow
         * the user to enter into the input control.
         */
        maxLength: {
            required: false,
            default: null,
            type: Number
        },
        /**
         * The regex that if supplied is used to validate
         * the masked text.
         */
        regex: {
            required: false,
            default: null,
            type: RegExp
        },
        /**
         * The default character that if supplied is
         * used in place of token characters where
         * there is no value character to test.
         */
        defaultCharacter: {
            required: false,
            default: null
        },
        /**
         * The default value returned if there is no
         * masked text value.
         */
        defaultValue: {
            required: false,
            default: null
        }

    },

    data() {
        return {

            text:           this.value,
            element:        null,
            caretPosition:  null,

        }
    },

    created() {
        //If the text coming in is in a valid format, strip
        //the mask.
        if (true === this.isValid && this.text) {
            this.text = this.stripMask(this.text, this.mask);
        }        
    },

    mounted() {
        this.element = document.getElementById(this.id);
    },
    
    computed: {

        maskedText: {

            /**
             * Get the masked text by applying the mask to the current
             * text value.
             */
            get() {
                let value = this.applyMask();                

                if (this.defaultValue && !value) {
                    value = this.defaultValue;
                }

                this.setCaretPosition(this.caretPosition);

                return value;
            },

            /**
             * Set the masked value, for an addition add the new character
             * from the end of the value to the current text value. For a
             * deletion, strip the mask from the value and assign that to
             * the text value.
             */
            set(newValue) {
                let value          = newValue.slice(-1);
                let maskedText     = this.maskedText;
                let text           = this.text || '';
                this.caretPosition = this.mask.length;
                
                if (!this.maxLength || this.maxLength > text.length) {
                    text += value;
                }

                //if the masked text begins with the new value then it is 
                //a deletion at the end of the string. If the new value 
                //does not start with the masked text then it is a deletion
                //at the start or in the middle.
                if (true === maskedText.startsWith(newValue) || 
                    false === newValue.startsWith(maskedText)) {

                    text = this.stripMask(newValue);
                }

                this.text = text;

                this.getCaretPosition();
            }

        },
        
        /**
         * If a regex has been supplied, use it to test
         * the masked text to determine if it is valid.
         */
        isValid() {
            let valid = true;

            if (this.regex) {
                let regex = RegExp(this.regex);
                valid = regex.test(this.maskedText);
            }

            return valid;
        },
        
        /**
         * Unique identifier for input.
         */
        id() {
            let id = 'ib-masked-input-' + Math.floor(Math.random() * 1000000);

            return id;
        }

    },

    watch: {
        
        value(newValue, oldValue) {
            let value = this.stripMask(newValue);

            this.text = value;
        },

        maskedText(newValue, oldValue) {
            this.$emit('input', newValue);
        }
        
    },

    methods: {

        /**
         * Cycle backwards through the mask and text value, applying the mask.
         * Characters that are tokens are replaced with the value character if 
         * they pass the regex pattern the token represents, otherwise the default
         * character is applied. Characters that aren't tokens are applied to the
         * output. If there are extra characters left in the text value, they
         * are added to the output.
         * @returns {string} text value with the mask applied.
         */
        applyMask() {
            let output     = '';
            let maskIndex  = this.mask.length;
            let valueIndex = this.text ? this.text.length
                                   : 0;

            if (this.text) {
                while (maskIndex > 0) {
                    maskIndex--;
                    valueIndex--;

                    let newChar     = this.getNewChar(valueIndex, maskIndex);
                    //This value character needs to be processed again if the 
                    //mask character was not a token and it's not equal to
                    //the value character.
                    if (true === this.mask.includes(newChar) && this.mask[maskIndex] !== this.text[valueIndex]) {
                        valueIndex++;
                    }
                    //If the new char is default and there is a valid value
                    //character then the regex test was not passed and this 
                    //mask character needs to be processed again
                    if ('' === newChar && 0 < valueIndex) {
                        maskIndex++;
                    }

                    output = newChar + output;
                }

                if (0 < valueIndex) {
                    output = this.text.slice(0, valueIndex) + output;
                }  

            }

            return output;
        },

        /**
         * Checks if each character is present in the mask,
         * if it is then it is a non token character and can
         * be discarded. Otherwise it is added to the output.
         */
        stripMask(value, mask = this.mask) {
            let output = '';

            if (value) {
                let valueIndex = value ? value.length : 0;
                while (0 < valueIndex) {
                    valueIndex--;
                    let valueChar = value[valueIndex];

                    if (false == mask.includes(valueChar)) {
                        output = valueChar + output;
                    }
                }
            }

            //Strip out any preceding 0 characters.
            output = output.replace(/^0+/, '');

            return output;
        },

        /**
         * Get the caret position from the text box.
         */
        getCaretPosition() {
            let text = this.text || '';
            let maskedText = this.maskedText || '';
            let mask = this.mask;

            if (this.element && 1 < text.length) {
                this.caretPosition = this.element.selectionStart;

                if (mask.length >= maskedText.length && text.length <= maskedText.length) {
                    this.caretPosition++;
                }
            }
        },

        /**
         * Set the caret position of the text box.
         */
        setCaretPosition(position) {
            if (this.element && position) {
                this.$nextTick(() => {
                    this.element.focus();
                    this.element.setSelectionRange(position, position);
                });
            }
        },

        /**
         * Process the characters from the text and the mask at the
         * given indices.
         */
        getNewChar(valueIndex, maskIndex) {
            const maskChar  = this.mask[maskIndex];
            const token     = RegexTokens[maskChar];
            const valueChar = 0 <= valueIndex ? this.text[valueIndex] : null;
            let newChar = maskChar;

            if (token) {
                newChar = this.defaultCharacter || '';

                if (valueChar && token.pattern.test(valueChar)) {
                    newChar = valueChar;       
                }
            }

            return newChar;
        }

    }

}
</script>

<style lang="less" scoped>
@import './../../assets/colours';

.invalid {
    border: solid 3px @danger !important;
}

</style>
