import Vue              from 'vue';
import VueRouter        from 'vue-router';

import WorkshopRoutes   from '@/modules/workshop/router';
import InternalRoutes   from '@/modules/internal/router';
import MarketplaceRoutes   from '@/modules/marketplace/router';

Vue.use(VueRouter)

const baseRoutes = [
    {
        path    :     '*',
        redirect: {
            name    : 'Jobs'
        },
        meta    : {
            module  : false
        },
    }
];

// Merge in module routes
const debug = process.env.NODE_ENV !== 'production';
let routes = baseRoutes.concat(WorkshopRoutes);
routes = routes.concat(MarketplaceRoutes);

if (true == debug) {
    routes = routes.concat(InternalRoutes);
}

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
});

export default router;
