// Main UI.
export const SET_ACTIONBAR_BUTTONS      = 'SET_ACTIONBAR_BUTTONS';
export const CLEAR_ACTIONBAR_BUTTONS    = 'CLEAR_ACTIONBAR_BUTTONS';
export const SET_ACTIVE_MODULE          = 'SET_ACTIVE_MODULE';
export const CLEAR_ACTIVE_MODULE        = 'CLEAR_ACTIVE_MODULE';
export const TOGGLE_SIDEBAR_MENU        = 'TOGGLE_SIDEBAR_MENU';
export const OPEN_SETTINGS_MENU         = 'OPEN_SETTINGS_MENU';
export const CLOSE_SETTINGS_MENU        = 'CLOSE_SETTINGS_MENU';
export const OPEN_ACCOUNT_MENU          = 'OPEN_ACCOUNT_MENU';
export const CLOSE_ACCOUNT_MENU         = 'CLOSE_ACCOUNT_MENU';
