<template>
	<div class="container-fluid h-100">
		<!-- BEGIN ROW -->
		<div class="row h-100 mh-100">
			<div class="col-12 mh-100 h-100 p-0">
				<!-- BEGIN FORM-->
				<form
					class="p-0 m-0 h-100 needs-validation"
					@submit.prevent="validateJob()"
				>
					<!-- BEGIN Card -->
					<div class="card h-100 bg-light">
						<div class="card-header bg-secondary text-light d-flex">
							<span class="flex-grow-1">
								<i class="fas fa-fw fa-notes-medical"></i> Create Job
							</span>
							<request-info-modal
								class="align-self-end"
								:customer="selected.customerObj"
								:wholegood="selected.wholegoodObj"
								:latestRequest="selected.request"
							/>
						</div>

						<div
							class="card-body overflow-auto alert-dismissible fade show"
							role="alert"
						>
							<div class="alert alert-warning" v-if="isCustomerOnStop">
								Customer on Total Stop cannot create job
							</div>
							<div class="alert alert-warning" v-if="error">
								{{ error }}
							</div>
							<div class="form-row">
								<div class="col-6">
									<label>Invoice Description:</label>
									<div class="row">
										<div class="col-6">
											<input
												v-model="createJobRequest.InstructionLine1"
												class="form-control"
												:class="{
													'is-invalid': false === validation.InstructionLine1,
												}"
												type="text"
												placeholder="Description 1"
												maxlength="20"
											/>
											<div class="invalid-feedback">
												Required: Must provide first line of description.
											</div>
										</div>
										<div class="col-6">
											<input
												v-model="createJobRequest.InstructionLine2"
												class="form-control"
												placeholder="Description 2"
												maxlength="20"
											/>
										</div>
									</div>

									<label>Instructions:</label>
									<textarea
										v-model="createJobRequest.RequestText"
										placeholder="Instructions"
										class="form-control"
										:class="{ 'is-invalid': false === validation.Instructions }"
										aria-label="Instructions"
										rows="7"
									/>
									<div class="invalid-feedback">
										Required: Must provide instructions.
									</div>
									<fieldset class="form-group">
										<label>Job Location:</label>
										<input
											v-model="createJobRequest.AddressLine1"
											class="form-control mt-1"
											:class="{ 'is-invalid': false === validation.Address }"
											placeholder="Address Contact Name"
											maxlength="32"
										/>
										<input
											v-model="createJobRequest.AddressLine2"
											class="form-control mt-1"
											:class="{ 'is-invalid': false === validation.Address }"
											placeholder="Address Line 1"
											maxlength="32"
										/>
										<input
											v-model="createJobRequest.AddressLine3"
											class="form-control mt-1"
											:class="{ 'is-invalid': false === validation.Address }"
											placeholder="Address Line 2"
											maxlength="32"
										/>
										<input
											v-model="createJobRequest.AddressLine4"
											class="form-control mt-1"
											:class="{ 'is-invalid': false === validation.Address }"
											placeholder="Address Line 3"
											maxlength="32"
										/>
										<input
											v-model="createJobRequest.AddressLine5"
											class="form-control mt-1"
											:class="{ 'is-invalid': false === validation.Address }"
											placeholder="Address Line 4"
											maxlength="32"
										/>

										<div class="form-group row mt-1">
											<label for="postCode" class="col-sm-3 col-form-label"
												>Post Code:</label
											>
											<div class="col-sm-9">
												<input
													v-model="createJobRequest.Postcode"
													id="postCode"
													class="form-control"
													:class="{
														'is-invalid': false === validation.Address,
													}"
													placeholder="Postcode"
													maxlength="8"
												/>
												<div class="invalid-feedback">
													Required: Must provide an address.
												</div>
											</div>
										</div>
									</fieldset>
								</div>

								<div class="col-6">
									<div class="row">
										<div class="col-6">
											<label>Wholegood Clock Hours:</label>
											<input
												v-model="createJobRequest.ClockHours"
												class="form-control"
												placeholder="Clock hours"
											/>
										</div>
										<div class="col-6">
											<label>Priority:</label>
											<ib-select-entry
												id="priorityCodes"
												:items="priorityCodesList"
												displayProperty="Description"
												noItemsMessage="No priority codes available"
												v-model="selectedPriorityCode"
											/>
										</div>
									</div>
									<label>Invoice Type:</label>
									<ib-invoice-type-lookup
										@valid="invoiceTypeComponentValid = $event"
										v-model="selectedInvoiceType"
										:formValid="invoiceTypeValid"
										:formInvalidMessage="invoiceTypeMessage"
									/>

									<label>Invoice To:</label>
									<ib-customer-lookup
										:minAccountNo="selectedInvoiceType.FromCus"
										:maxAccountNo="selectedInvoiceType.ToCus"
										v-model="selectedInvoiceAccount"
										@valid="validation.InvoiceAccount = $event"
									/>

									<label>Wholegood Account:</label>
									<ib-customer-lookup
										v-model="selectedWholegoodAccount"
										@valid="validation.WholegoodAccount = $event"
									/>

									<label>Contact:</label>
									<ib-select-entry
										:items="contactList"
										v-if="contactList.length > 1"
										displayProperty="Name"
										noItemsMessage="No contacts available"
										v-model="selectedContact"
									/>

									<div class="form-row mt-1">
										<div class="col-6">
											<input
												v-model="createJobRequest.ContactName"
												class="form-control"
												placeholder="Contact Name"
											/>
										</div>
										<div class="col-6">
											<input
												v-model="createJobRequest.ContactNumber"
												class="form-control"
												placeholder="Contact Number"
											/>
										</div>
									</div>

									<label>Depot:</label>
									<ib-select-entry
										:items="depotList"
										:class="{ 'is-invalid': false == this.validation.Depot }"
										displayProperty="text"
										noItemsMessage="No depots available"
										v-model="selectedDepot"
									/>
									<span class="invalid-feedback">
										Required: Must select a depot.
									</span>

									<label
										>Engineer:
										<ib-checkbox
											text="Show engineers from all depots"
											v-model="showAllEngineers"
									/></label>
									<ib-select-entry
										id="engineers"
										:items="engineerList"
										displayProperty="Name"
										noItemsMessage="No engineers available"
										v-model="selectedEngineer"
									/>

									<div class="row">
										<div class="col-6">
											<label>Date:</label>
											<ib-date-time-picker
												id="requiredDate"
												:valid="requiredDate.valid"
												v-model="createJobRequest.RequestedFor"
											/>
										</div>
										<div class="col-6">
											<label>Estimated Duration (hh:mm):</label>
											<ib-time-input
												id="estimatedDuration"
												v-model="createJobRequest.EstimatedTime"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="card-footer text-right">
							<button
								type="button"
								class="btn btn-secondary"
								@click="cancelCreateJob"
							>
								<i class="fas fa-fw fa-chevron-left"></i> Cancel
							</button>
							&nbsp;&nbsp;
							<button
								type="submit"
								:class="{
									'btn-outline-success ib-cursor-denied': !formValid,
									'btn-success': formValid,
								}"
								class="btn"
								:disabled="
									false == formValid ||
										true == submitting ||
										true == this.isCustomerOnStop
								"
								@click.prevent="processJobRequests"
							>
								<i class="fas fa-fw fa-notes-medical"></i> Create Job
							</button>
						</div>
					</div>
					<!-- END Card -->
				</form>
				<!-- END FORM -->
			</div>
		</div>
		<!-- END ROW -->
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';

	import DateMixin, { DateFormat } from '@/mixins/DateMixin';
	import { ToastPlugin } from 'bootstrap-vue';

	/** APIs */
	import JobApi from '@WS/api/job';
	import ContactApi from '@WS/api/contact';
	import InvoiceTypeApi from '@WS/api/invoice-type';
	import DepotApi from '@WS/api/depot';

	/** Constants */
	import JobRequestPriorities from '@WS/common/job-request-priorities';
	import JobRequestManufacturers from '@WS/common/job-request-manufacturers';
	/** Components */
	import IbTable from '@/components/table/IbTable';
	import IbDatePicker from '@/components/form/IbDatePicker';
	import IbSelectEntry from '@/components/form/IbSelectEntry';
	import IbCheckbox from '@/components/form/IbCheckbox';
	import IbDateTimePicker from '@/components/form/IbDateTimePicker';
	import IbInvoiceTypeLookup from '@WS/components/lookups/IbInvoiceTypeLookup';
	import IbTimeInput from '@/components/form/IbTimeInput';
	import IbCustomerLookup from '@WS/components/lookups/IbCustomerLookup';
	import DepotList from '@WS/components/DepotList';
	import RequestInfoModal from '@WS/components/job-requests/RequestInfoModal';
	import { SET_SELECTED_REQUEST } from '@/store/mutation-types';

	const MANITOU = 3;
	const NO_ENGINEER = {
		Id: -1,
		Name: 'No Engineer',
	};

	const NO_CONTACT = {
		Id: -1,
		Name: 'No Contact',
	};

	const NO_DEPOT = {
		value: -1,
		text: 'No Depot',
	};

	const headings = [
		{
			heading: 'Priority',
			property: 'Priority',
			sortable: true,
		},
		{
			heading: 'Manufacturer',
			property: 'ManufacturerId',
			sortable: true,
		},
		{
			heading: 'Received',
			property: 'RequestReceivedOn',
			sortable: true,
		},
		{
			heading: 'Request Title',
			property: 'RequestTitle',
			sortable: true,
		},
	];

	const constraints = {
		requiredField: {
			presence: { allowEmpty: false },
		},
	};

	const invalidProgCodes = [2, 4, 9, -3, -15, -16, -19, -20, -23, -24];

	export default {
		name: 'CreateRequestedJob',

		mixins: [DateMixin, JobRequestManufacturers, JobRequestPriorities],

		components: {
			IbTable,
			DepotList,
			IbSelectEntry,
			IbDateTimePicker,
			IbCheckbox,
			IbInvoiceTypeLookup,
			IbTimeInput,
			IbCustomerLookup,
			RequestInfoModal,
		},

		data() {
			return {
				headings: headings,
				isLoading: false,
				error: '',
				depots: [],
				invoiceTypeValid: null,
				invoiceTypeMessage: null,

				invoiceTypeComponentValid: true,
				/**
				 * Data Structure to POST to the API server to create a job
				 * from a job request.
				 */
				createJobRequest: {
					ManufacturerId: null, //int
					RequestReceivedOn: null, //DateTimeOffset
					StatusId: null, //RequestStatus
					RequestTypeId: null, //RequestType
					RequestedFor: null, //DateTimeOffset
					Priority: null, //RequestPriority
					SerialNumber: null,
					WholegoodId: null,
					InstructionLine1: null,
					InstructionLine2: null,
					RequestText: null,
					OriginalRequestMessage: null,
					JobId: null,
					CustomerId: null,
					LastModifiedOn: null, //DateTimeOffset
					DepotId: null,
					RequestReferenceId: null,
					FitterId: null, // int
					InvoiceTypeId: null,
					DiaryCodeId: null,
					PriorityCodeId: null,
					ContactName: null,
					ContactNumber: null,
					RequestTitle: null,
					EstimatedTime: null, //TimeSpan
					ValidationStatusId: null, //RequestValidationStatus
					AddressLine1: null,
					AddressLine2: null,
					AddressLine3: null,
					AddressLine4: null,
					AddressLine5: null,
					Postcode: null,
				},

				requiredDate: {
					value: '',
					valid: true,
				},
				contacts: {
					loading: false,
					error: null,
					value: [],
				},

				// Selected objects for select lists.
				selectedInvoiceType: {},
				selectedPriorityCode: {},
				selectedInvoiceAccount: {},
				selectedWholegoodAccount: {},
				selectedContact: NO_CONTACT,
				selectedEngineer: NO_ENGINEER,
				selectedDepot: NO_DEPOT,

				selectedWholegoodAccountValidation: {
					valid: true,
					message: '',
				},

				// Validations & submission variables.
				validation: {
					InstructionLine1: false,
					Instructions: false,
					Address: false,
					InvoiceType: false,
					CustomerId: false,
					InvoiceAccount: false,
					WholegoodAccount: false,
					Depot: false,
				},

				// Disables the job create button if the form is not valid yet.
				formValid: true,
				// Set to true whilst submitting the form to disable the job create button against multiple clicks.
				submitting: false,
				//Set true if customer is on stop
				isCustomerOnStop: false,
				// show all the engineers - rather than just those from the selected depot
				showAllEngineers: false,
			};
		},

		created() {
			this.fetchDepots();
		},

		mounted() {
			if (!_.isEmpty(this.selected.request)) {
				this.allocateRequest(this.selected.request);
			}

			if (!_.isEmpty(this.selected.customerObj)) {
				this.selectedInvoiceAccount = this.selected.customerObj;
				this.selectedWholegoodAccount = this.selected.customerObj;
				this.setAddressDetails();
			}
		},

		watch: {
			'selected.request': {
				immediate: true,
				handler: function(newRequest) {
					if (_.isEmpty(newRequest)) {
						this.cancelCreateJob();
					}

					this.allocateRequest(newRequest);
				},
			},

			'validation.InvoiceAccount'() {
				this.validateJob();
			},

			invoiceTypeComponentValid() {
				this.validateJob();
			},

			createJobRequest: {
				deep: true,
				handler: function() {
					this.validateJob();
				},
			},

			selectedEngineer(newEngineer) {
				if (newEngineer) {
					_.set(this.createJobRequest, 'FitterId', newEngineer.Id);
				}
			},

			selectedPriorityCode(priorityCode) {
				if (priorityCode) {
					_.set(
						this.createJobRequest,
						'PriorityCodeId',
						priorityCode.PriorityCode
					);
				}
			},

			selectedContact(newContact) {
				if (newContact && newContact.Id != -1) {
					let name = newContact.Name;
					let number = newContact.Phone;

					if (newContact.Mobile) {
						number = newContact.Mobile;
					}

					_.set(this.createJobRequest, 'ContactName', name);
					_.set(this.createJobRequest, 'ContactNumber', number);
				} else {
					_.set(this.createJobRequest, 'ContactName', '');
					_.set(this.createJobRequest, 'ContactNumber', '');
				}
			},

			selectedInvoiceType(newInvoiceType) {
				this.validateJob();
				if (newInvoiceType) {
					_.set(this.createJobRequest, 'InvoiceTypeId', newInvoiceType.Type);
					this.validateInvoiceTypeCode('InvoiceTypeId');
				}
			},

			selectedWholegoodAccount(newCustomer, oldCustomer) {
				const newId = _.get(newCustomer, 'AccountNo');
				const oldId = _.get(oldCustomer, 'AccountNo');

				if (newId && newId !== oldId) {
					this.fetchContactCodes();
					this.setAddressDetails();
				}
			},

			selectedInvoiceAccount(newAccount) {
				let accountNo = _.get(newAccount, 'AccountNo');
				_.set(this.createJobRequest, 'CustomerId', accountNo);
				this.setIsCustomerOnStop();
			},

			selectedDepot(newValue) {
				if (this.selectedDepot.value != NO_DEPOT.value) {
					_.set(this.createJobRequest, 'DepotId', this.selectedDepot.value);
				}
				if (!this.engineerList.some((e) => e.Id == this.selectedEngineer.Id)) {
					// user has switched the depot to one that does not contain the selected engineer
					// set to "No Engineer"
					this.selectedEngineer = NO_ENGINEER;
				}
			},
			showAllEngineers(newValue) {
				if (!this.engineerList.some((e) => e.Id == this.selectedEngineer.Id)) {
					// user has switched the depot to one that does not contain the selected engineer
					// set to "No Engineer"
					this.selectedEngineer = NO_ENGINEER;
				}
			},
		},

		computed: {
			...mapState({
				currentCompanyId: 'company',
				currentDepotId: 'depot',
			}),

			...mapState('engineer', {
				engineers: 'engineers',
			}),

			...mapState('jobrequest', {
				selected: 'selection',
				priorityCodes: 'priorities',
			}),

			/**
			 * Returns an array of engineers starting with an 'All Engineers' option.
			 * If no engineers are loaded, returns an empty array.
			 */
			engineerList() {
				var sortedEngineers = this.sortEngineersByFirstName(
					this.engineers.value,
					this.showAllEngineers
				);
				const engineersSelectList = this.buildSelectList(
					sortedEngineers,
					NO_ENGINEER
				);

				return engineersSelectList;
			},

			/**
			 * Returns an array of customer contacts.
			 * If no contacts are loaded, returns an empty array.
			 */
			contactList() {
				const contacts = this.buildSelectList(this.contacts.value, NO_CONTACT);
				return contacts;
			},

			/**
			 * Creates a list of depots for display. Name is reformatted to include the
			 * depot short name.
			 */
			depotList() {
				const depots = this.depots.map((depot) => {
					let text = `${depot.Id} - ${depot.Name}`;
					if (depot.ShortName && depot.Name != depot.ShortName) {
						text += ` (${depot.ShortName})`;
					}

					return {
						value: depot.Id,
						text: text,
					};
				});

				return this.buildSelectList(depots, NO_DEPOT);
			},

			/**
			 * Returns an array of priority codes.
			 * If no priority codes are loaded, returns an empty array.
			 */
			priorityCodesList() {
				return this.priorityCodes.value;
			},
		},

		methods: {
			cancelCreateJob() {
				this.$emit('cancel');
			},

			jobCreated(request) {
				this.$emit('created', request);
			},

			validateJob() {
				// Initialise to true - otherwise we can never pass validation
				let requestIsValid = true;

				if (false == this.isLoading && !_.isEmpty(this.createJobRequest)) {
					this.validation.InstructionLine1 = this.validateRequired(
						'InstructionLine1'
					);
					this.validation.Instructions = this.validateRequired('RequestText');
					this.validation.Address = this.validateAddress();

					let invoiceTypeRequiredSatisfied = this.validateRequired(
						'InvoiceTypeId'
					);
					let invoiceTypeCodeProgCodeSatisfied = this.validateInvoiceTypeCode(
						'InvoiceTypeId'
					);
					this.validation.InvoiceType =
						(this.invoiceTypeComponentValid == null ||
							this.invoiceTypeComponentValid == true) &&
						invoiceTypeRequiredSatisfied &&
						invoiceTypeCodeProgCodeSatisfied;
						
					this.validation.CustomerId =
						this.validateRequired('CustomerId') &&
						_.get(this.selectedInvoiceAccount, 'AccountNo') ===
							_.get(this.createJobRequest, 'CustomerId');
					this.validation.WholegoodAccount =
						false == _.isEmpty(this.selectedWholegoodAccount);
					this.validation.Depot = this.validateNotDefault(
						'DepotId',
						NO_DEPOT.value
					);
				}

				for (const field in this.validation) {
					requestIsValid = requestIsValid && this.validation[field];
				}

				this.formValid = requestIsValid;
			},

			// Invalid WSJ Workshop Program Codes
			// 4  Self Billing Invoices		            2
			// 6  Machinery Sales   			        4
			// 11 VAT Inclusive Pricing   		        9
			// 17 IDT POS ( No % Uplift or vat ) 	    -3
			// 29 Manufacture {WHOLEGD} 		        -15
			// 30 Serial Manufacture 			        -16
			// 33 Machinery Sales No Specific Trade In  -19
			// 36 Planned Maintenance Contract		    -20
			// 39 {WHOLEGD} Stage Payments  		    -23
			// 40 Fixed Asset Disposal			        -24
			validateInvoiceTypeCode() {
				let valid = true;
				if (invalidProgCodes.includes(this.selectedInvoiceType.ProgCode)) {
					this.invoiceTypeValid = false;
					valid = false;
					this.invoiceTypeMessage = 'Invalid Invoice Type Program Code';
				} else {
					this.invoiceTypeValid = null;
				}

				return valid;
			},

			/**
			 * Check that a value is set for a required field
			 */
			validateRequired(path) {
				let valid = false;
				let value = _.get(this.createJobRequest, path);
				if (undefined !== value && 0 !== _.trim(value).length) {
					valid = true;
				}
				return valid;
			},

			/**
			 * Validates that the value of the property defined by 'path' is not the default or blank.
			 */
			validateNotDefault(path, defaultValue) {
				let valid = false;
				let value = _.get(this.createJobRequest, path);

				if (value && value !== defaultValue) {
					valid = true;
				}

				return valid;
			},

			/**
			 * Validate whether a required field has a value.
			 * I think this could be bumped to a more global level if we decide to use this pattern.
			 */
			validateRequiredField(validationObject, value) {
				let validation = this.$validate.validate(
					{ requiredField: value },
					constraints
				);
				if (validation) {
					validationObject.message = validation.requiredField[0];
					validationObject.valid = false;
					return false;
				} else {
					validationObject.message = '';
					validationObject.valid = true;
					return true;
				}
			},

			/**
			 * Validate that we have some input on the address. Given the free
			 * form nature of Gold inputs theres nothing specific we can validate
			 * against.
			 *
			 * Can not validate post codes as Ireland doesn't use them
			 * extensively yet.
			 */
			validateAddress() {
				const address =
					_.get(this.createJobRequest, 'AddressLine1', '') +
					_.get(this.createJobRequest, 'AddressLine2', '') +
					_.get(this.createJobRequest, 'AddressLine3', '') +
					_.get(this.createJobRequest, 'AddressLine4', '') +
					_.get(this.createJobRequest, 'AddressLine5', '') +
					_.get(this.createJobRequest, 'Postcode', '');
				let valid = false;

				if (0 != address.length) {
					valid = true;
				}

				return valid;
			},

			async allocateRequest(request) {
				const me = this;

				try {
					me.isLoading = true;
					// Assign all the values from request to createJobRequest.
					for (const prop in request) {
						_.set(me.createJobRequest, prop, request[prop]);
					}

					me.selectedPriorityCode = _.find(me.priorityCodes.value, function(
						code
					) {
						return request.PriorityCodeId === code.PriorityCode;
					});

					if(me.createJobRequest.ManufacturerId===MANITOU)
					{
						_.set(me.createJobRequest, 'ClockHours', Math.max(me.createJobRequest.ClockHours,me.$parent.goldClockHours(me.selected.wholegoodObj)));
					}

					_.set(me.createJobRequest, 'RequestedFor', null);

					let estimatedTime = me.formatDuration(request.EstimatedTime, 'hh:mm');
					_.set(me.createJobRequest, 'EstimatedTime', estimatedTime);

					// set the invoice type on loading a new request
					await me.getInvoiceType();
				} catch (error) {
					console.error('Failed setting the selected request.', error);
				} finally {
					me.isLoading = false;
					me.validateJob();
				}
			},

			/**
			 * Returns an array of Customer Contact Names.
			 */
			fetchContactCodes() {
				const me = this;
				const accountNo = _.get(me.selectedWholegoodAccount, 'AccountNo');

				me.contacts.value = {};
				if (!_.isEmpty(accountNo)) {
					ContactApi.getContactsByCustomer(accountNo).then(
						(success) => {
							me.contacts.value = success.data.data;
						},
						(failure) => {
							me.error = `Failed to Fetch Contacts. ${failure.response.data.message}.`;
						}
					);
				}
			},

			/**
			 * Ask the server for a list of depots this job could be created at.
			 * TODO: Should pull this out of state somewhere.
			 */
			fetchDepots() {
				const me = this;
				DepotApi.getAllDepotsForCompany(this.currentCompanyId).then(
					(success) => {
						me.depots = success.data.data;

						me.selectedDepot = _.find(me.depotList, function(d) {
							return me.createJobRequest.DepotId == d.value;
						});
					},
					(failure) => {
						me.error = `Failed to get list of depots. ${failure.message}.`;
					}
				);
			},

			/**
			 * Returns the address details for the selected wholegood customer
			 */
			setAddressDetails() {
				let name = _.get(this.selectedWholegoodAccount, 'Name');
				let address1 = _.get(this.selectedWholegoodAccount, 'Address1');
				let address2 = _.get(this.selectedWholegoodAccount, 'Address2');
				let address3 = _.get(this.selectedWholegoodAccount, 'Address3');
				let address4 = _.get(this.selectedWholegoodAccount, 'Address4');
				let postcode = _.get(this.selectedWholegoodAccount, 'PostCode');

				if (
					false == _.isEmpty(this.selectedWholegoodAccount.DeliveryAddress1)
				) {
					address1 = _.get(this.selectedWholegoodAccount, 'DeliveryAddress1');
					address2 = _.get(this.selectedWholegoodAccount, 'DeliveryAddress2');
					address3 = _.get(this.selectedWholegoodAccount, 'DeliveryAddress3');
					address4 = _.get(this.selectedWholegoodAccount, 'DeliveryAddress4');
					postcode = _.get(this.selectedWholegoodAccount, 'DeliveryPostCode');
				}

				_.set(this.createJobRequest, 'AddressLine1', name);
				_.set(this.createJobRequest, 'AddressLine2', address1);
				_.set(this.createJobRequest, 'AddressLine3', address2);
				_.set(this.createJobRequest, 'AddressLine4', address3);
				_.set(this.createJobRequest, 'AddressLine5', address4);
				_.set(this.createJobRequest, 'Postcode', postcode);
			},

			/**
			 * Returns the ID of the selected engineer, otherwise null if the selected
			 * engineer is 'All Engineers'.
			 */
			engineerFilter() {
				let filter = null;
				if (NO_ENGINEER !== this.filters.engineer) {
					filter = this.filters.engineer.Id;
				}
				return filter;
			},

			/**
			 * Returns a new array of the selected customer contacts, otherwise null.
			 */
			contactFilter() {
				let filter = null;
				if (NO_CONTACT !== this.filters.contact) {
					filter = this.filters.contact.Id;
				}
				return filter;
			},
			sortEngineersByFirstName(engineers, showAllEngineers) {
				var engineersClone = engineers.map((engineer) =>
					Object.assign({}, engineer)
				); //Put in seprate variable so that It would not change state in store

				// prefix firstname and name with DepotId
				engineersClone = engineersClone.map((engineer) => ({
					...engineer,
					FirstName: 'Depot ' + engineer.DepotId + ' - ' + engineer.FirstName,
				}));
				engineersClone = engineersClone.map((engineer) => ({
					...engineer,
					Name: 'Depot ' + engineer.DepotId + ' - ' + engineer.Name,
				}));
				let sortedEngineers = engineersClone.sort(function(
					engineerA,
					engineerB
				) {
					const engineerAFirstName = engineerA.FirstName.toLowerCase();
					const engineerBFirstName = engineerB.FirstName.toLowerCase();
					if (engineerAFirstName < engineerBFirstName) return -1;
					if (engineerAFirstName > engineerBFirstName) return 1;
					return 0;
				});

				// remove any engineers who have a 'left date' set
				sortedEngineers = sortedEngineers.filter(
					(engineer) => engineer.LeftDate === null
				);

				if (this.showAllEngineers === false) {
					sortedEngineers = sortedEngineers.filter(
						(engineer) => engineer.DepotId == this.selectedDepot.value
					);
				}
				return sortedEngineers;
			},
			/**
			 * Returns a new array with defaultItem at index 0 and all items specified
			 * in array appended afterwards.
			 */
			buildSelectList(array, defaultItem) {
				let items = [];
				if (Array.isArray(array) && 0 < array.length) {
					// Create new array with defaultItem as first item.
					items.push(defaultItem);
					items.push(...array);
				}
				return items;
			},

			/**
			 * Creates the workshop job
			 */
			async processJobRequests() {
				const me = this;

				try {
					me.submitting = true;
					// Make sure that the job is valid before we try anything at all.
					me.validateJob();
					if (!this.formValid) {
						throw 'Form is invalid; Cannot create job.';
					}

					let success = await JobApi.createJob(me.createJobRequest);
					let createdResponse = success.data.data;

					me.jobCreated(createdResponse);
				} catch (error) {
					let message = 'Server not responding';
					if (error.response) {
						message = error.response.data.message;
					}
					me.toast('b-toaster-top-center', 'danger', message, true);
					me.error = `Failed to create Job Request  ${me.createJobRequest.Id} - ${message}. `;
				} finally {
					me.submitting = false;
				}
			},

			async getInvoiceType() {
				let invoiceTypeId = this.createJobRequest.InvoiceTypeId;

				try {
					const searchResponse = await InvoiceTypeApi.getInvoiceTypeById(
						invoiceTypeId
					);

					if (true === searchResponse.data.success) {
						this.selectedInvoiceType = searchResponse.data.data;
					}
				} catch (error) {
					console.error(`Failed fetching invoice type ${invoiceTypeId}`, error);
				}
			},

			toast(toaster, variant, message, append = false) {
				const payload = {
					title: `Workshop Job Request`,
					variant: variant,
					toaster: toaster,
					solid: true,
					appendToast: append,
				};
				this.$bvToast.toast(message, payload);
			},
			setIsCustomerOnStop() {
				let customerOnStop = _.get(this.selectedInvoiceAccount, 'Stop');
				switch (customerOnStop) {
					case 'Total Stop':
						this.isCustomerOnStop = true;
						break;
					default:
						this.isCustomerOnStop = false;
						break;
				}
			},
		},
	};
</script>

<style lang="css" scoped>
	.ib-cursor-denied {
		cursor: not-allowed;
	}
</style>
