<template>
    <div class="container-fluid">

        <div class="container-fluid h-75">
            <div class="btn-toolbar justify-content-start mb-2" role="toolbar">
                <h3>Claas DSM - Validation Issues</h3>

                <div class="row pl-5">
                    <span>
                        <input class="form-control" type="text" placeholder="Search" v-model="searchPhrase">
                    </span>
                    <span class="pl-2">
                        <button type="button" class="btn btn-secondary" @click="clearSearch">Clear</button>
                    </span>
                </div>
                <!-- Spacer - Expand to use all available space -->
                <div class="flex-grow-1"></div>

                <!-- Refresh dsm validation messages -->
                <button class="btn btn-primary mr-2" @click="refresh" :disabled="true === this.loading">
                    <transition name="fade" mode="out-in">
                        <span v-if="true === this.loading" key="spinner">
                            <i class="fas fa-sync-alt fa-spin"></i>
                        </span>
                        <span v-else key="fixed">
                            <i class="fas fa-sync-alt"></i>
                        </span>
                    </transition>
                    <span class="ml-2">Refresh</span>
                </button>


            </div>

            <!-- Error -->
            <b-alert variant="danger" dismissible :show="null !== error" @dismissed="error = null">
                <h5 class="alert-heading">Error:</h5>
                <p class="mb-0">{{ error }}</p>
            </b-alert>

            <div v-if="!this.pageLoading">
                <div class="row h-75">
                    <div class="col-12 h-100">
                        <div class="card h-100 bg-light shadow-sm">
                            <div class="card-header bg-secondary text-light">
                                <i class="fas fa-fw fa-lg fa-wrench"></i> Validation Issues
                            </div>

                            <div class="card-body pt-0 pb-0 pl-1 pr-1 overflow-auto" id="messageTableContainer">

                                <div v-if="!dsmValidationMessages || 0 == dsmValidationMessages.length"
                                    class="d-flex h-100 justify-content-center align-items-center">
                                    <div role="alert" class="alert alert-secondary text-muted shadow-sm">
                                        No Validation Messages
                                    </div>
                                </div>

                                <ib-table v-else id="messageTable" :columns="headings" :items="dsmValidationMessages"
                                    :value="selectedResult" :filterValue="searchPhrase" :filterProperties="searchColumns"
                                    :loadingMessage="loadingMessage" :itemsPerPage="30">
                                    <template slot="LastUpdateDate" slot-scope="data">
                                        <span class="overflowHidden">
                                            {{ data.item.LastUpdateDate | formatDate() }}
                                        </span>
                                    </template>

                                    <template slot="Message" slot-scope="data">
                                        <span class="overflowHidden">
                                            {{ data.item.Message }}
                                        </span>
                                    </template>

                                    <template slot="JobId" slot-scope="data">
                                        <span class="overflowHidden">
                                            {{ data.item.JobId }}
                                        </span>
                                    </template>

                                    <template slot="StatusCode" slot-scope="data">
                                        <span class="overflowHidden">
                                            {{ data.item.StatusCode }}
                                        </span>
                                    </template>
                                </ib-table>

                            </div>
                        </div>

                    </div>

                </div>
                <br>
                <ib-back-button size="lg"></ib-back-button>
            </div>
            <div v-else>
                <ib-loading-spinner message="Loading..." class="align-middle h-75" />
            </div>
        </div>
    </div>
</template>
    
<script>
import { mapGetters } from 'vuex';
import DSMApi from '@WS/api/dsm';
import DateMixin from '@/mixins/DateMixin';
import IbTable from '@/components/table/IbTable';
import IbBackButton from '@/components/IbBackButton';
import IbLoadingSpinner from '@/components/IbLoadingSpinner';

const headings = [
    {
        heading: 'Date',
        property: 'LastUpdateDate',
        class: 'lastUpdateDate',
        sortable: true,
    },
    {
        heading: 'Message',
        property: 'Message',
        class: 'message',
        sortable: true
    },
    {
        heading: 'Job Id',
        property: 'JobId',
        class: 'jobIdCol',
        sortable: true
    },
    {
        heading: 'Status Code',
        property: 'StatusCode',
        class: 'statusCodeCol',
        sortable: true
    }
];

const SearchColumns = [
    'Message', 'JobId'
];

export default {
    name: 'DSMJobValidationMessageList',

    mixins: [
        DateMixin
    ],

    components: {
        IbTable,
        IbBackButton,
        IbLoadingSpinner
    },

    data() {
        return {
            headings: headings,
            loading: false,
            error: null,
            pageLoading: true,
            dsmValidationMessages: [],
            loadingMessage: "Loading messages...",
            searchColumns: SearchColumns,
            selectedResult: null,
            searchPhrase: ""
        }
    },

    components: {
        IbTable,
        IbBackButton,
        IbLoadingSpinner
    },

    async created() {
        this.pageLoading = true;
    },
    computed: {
        ...mapGetters('system', {
            companyId: 'currentCompanyId',
        }),
    },

    methods: {
        clearSearch() {
            this.searchPhrase = "";
        },
        async refresh() {
            console.log("Refreshing Page Content");
            await this.fetchMessages();
            this.pageLoading = false;
        },
        async fetchMessages() {
            const me = this;
            me.loading = true;
            await DSMApi
                .getDSMValidationMessges()
                .then(
                    success => {
                        me.dsmValidationMessages = success.data.data;
                        me.loading = false;
                    },
                    failure => {
                        me.error = `Failed to get DSM Validation Messages. ${failure.response.data.message}.`;
                        me.loading = false;
                    }
                );

        },
    }, //END methods
    watch: {
        companyId: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    this.pageLoading = true;
                    this.refresh()
                }
            }
        },
    },
}

</script>
    
<style lang="less" scoped>
/deep/ .jobIdCol {
    width: 8%;
    ;
}

/deep/ .lastUpdateDate {
    width: 16%;
}

/deep/ .statusCodeCol {
    width: 15%;
}

/deep/ .message {
    width: 15%;
}

/deep/ .overflowHidden {
    float: left;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.overflowHiddenTable {
    height: 75% !important;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    table-layout: fixed;
}
</style>
    