<template>
    <div v-if="hasDocStoreOptions(...requiredLicenses)">

        <ib-modal 
            v-model="showRemoveFromBasketModal"
            modalClass="w-25 p-2"
            :dismissable=false
        >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i class="fas fa-exclamation-triangle ib-modal-info-icon"></i> Remove Part </h3>
                Remove part '{{partForRemovalFromBasket.displayPartNumber}}' from basket?
            </div>
            <hr>
            <div class = "row">
                <div class = "col-12 text-right pr-2">                
                    <button class="btn btn-secondary" @click="showRemoveFromBasketModal = false">Cancel</button> &nbsp;
                    <button class="btn btn-danger" @click="executeRemoveFromBasket()">Remove</button> &nbsp;
                </div>
            </div>
        </div>
        </ib-modal>

        <ib-modal 
            v-model="showClearBasketModal"
            modalClass="w-25 p-2"
            :dismissable=false
        >
        <div class="container-fluid h-100">
            <div class = "row p-2">
                <h3><i style="color:#007bff;" class="fas fa-exclamation-triangle"></i> Clear Basket </h3>
            </div>
                Remove all lines from basket? 
            <hr>
            <div class = "row">
                <div class = "col-12 text-right pr-2">                
                    <button class="btn btn-secondary" @click="showClearBasketModal = false">Cancel</button> &nbsp;
                    <button class="btn btn-danger" @click="executeClearBasket()">Clear</button> &nbsp;
                </div>
            </div>
        </div>
        </ib-modal>
        <configure-order-modal v-model="showConfigureOrderModal" :depotId="selectedDepot.toString()" :basket="basket" @removeFromBasketAfterOrder="removeFromBasketAfterOrdering" @removeFromBasket="removeFromBasketFromOrder"/>
        <marketplace-password-modal v-model="showPasswordModal" :dismissable="false" @passwordValidated="passwordValidated"/>        
        <div v-if="!loading">

            <div class="h-100 w-100">
                <div class="row h-100">
                    <div class="col-lg-4 col-md-4 col-sm-4">
                        <!-- Start of left hand column -->
                        <div class="row p-2">
                            <!-- Start of Filters Header -->
                            <div></div>
                        </div>
                        <!-- End of Filters Header -->
                        <b-card no-body>
                            <b-tabs class="order-parts-tab" v-model="tabIndex">
                                <b-tab
                                    class="p-3"
                                    title="Suggested Stock Order"
                                >
                                    <div class="row align-items-center pb-2">
                                        <div class="col-4">From:</div>
                                        <div class="col-8">
                                            <ib-date-picker
                                                id="from"
                                                placeholder="From"
                                                v-model="ssoFromDate"
                                            ></ib-date-picker>
                                        </div>
                                    </div>

                                    <div class="row align-items-center pb-2">
                                        <div class="col-4">To:</div>
                                        <div class="col-8">
                                            <ib-date-picker
                                                id="to"
                                                placeholder="To"
                                                v-model="ssoToDate"
                                            ></ib-date-picker>
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <button
                                            class="btn btn-primary"
                                            :disabled="loadReports || searchDisabled"
                                            @click="loadSSOReports()"
                                        >
                                            Search
                                            <i
                                                class="fas fa-solid fa-search"
                                            ></i>
                                        </button>
                                    </div>
                                </b-tab>
                                <b-tab class="p-3" title="Parts Search" active>
                                    <!-- Manufacturers-->
                                    <div class="row align-items-center pb-2">
                                        <div class="col-4">Manufacturer:</div>
                                        <div class="col-8">
                                            <rs-dropdown
                                                :disabled="searchDisabled"
                                                :options="allManufacturers"
                                                v-on:selected="
                                                    manufacturerSelected
                                                "
                                                :maxItem="1000"
                                                placeholder="Not Selected"
                                            >
                                            </rs-dropdown>
                                        </div>
                                    </div>
                                    <!-- Part no-->
                                    <div class="row align-items-left pb-2">
                                        <div class="col-4">Part No. :</div>
                                        <div class="col-8">
                                            <label
                                                id="prefix-label"
                                                style="color:gray"
                                            >
                                                {{ selectedPrefixes }}
                                                <b-tooltip
                                                    placement="bottom"
                                                    target="prefix-label"
                                                    :title="hiddenPrefixes"
                                                />
                                            </label>
                                            <input
                                                class="ml-2"
                                                type="text"
                                                :disabled="searchDisabled"
                                                v-model="partNumberForSearch"
                                            />
                                        </div>
                                    </div>
                                    <div class="text-right">
                                        <button
                                            class="btn btn-primary"
                                            v-on:click="doAggregatedSearch"
                                            :disabled="searchDisabled"
                                        >
                                            Search
                                            <i
                                                class="fas fa-solid fa-search"
                                            ></i>
                                        </button>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </b-card>
                        <!-- Depot-->
                        <div class="row align-items-center pb-3 pt-3">
                            <div class="col-4">Buying Depot:</div>
                            <div class="col-8">
                                <select
                                    class="custom-select"
                                    v-model="selectedDepot">
                                    <option v-for="(option, index) in dropdownDepots" 
                                        :key="index"
                                        :value="option.value">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        
                        <!-- <div class="row align-items-center pb-2">
                            <div class="float-right pt-2">
                                 <button class="btn btn-primary" v-on:click=""> Import suggested order </button> 
                            </div>
                        </div>-->
                        <div class="row align-items-center">
                            <div class="col-12"  v-show="mappingContent.length>0">
                                <div class="alert alert-warning" role="alert">
                                    <i class="fas fa-fw fa-lg fa-info-circle"></i>&nbsp;{{mappingContent}} 
                                </div>              
                            </div>
                        </div>
                        <div>
                            <!-- <button class="btn btn-primary" v-on:click=""> Import suggested order </button> -->
                            <h4> Basket - {{selectedDepotName}} ({{basket.length}}) </h4>
                            <div class="text-right button-separator">
                                <button type="button" class="btn btn-success mb-4" v-show="basket.length>0" v-on:click="configureOrder()">Proceed to Order</button>
                                <button type="button" class="btn btn-danger mb-4" :disabled="basket.length == 0"  v-on:click="showClearBasketModal = true"> Clear All </button>
                            </div>
                            <div class="basket-scroll" :style="basketHeight">
                                <div v-for="item in basket" :key="item.localPrefix+'-'+item.partNumber">
                                    <slot :item="item">
                                        <div class="row align-items-center pb-2 mr-4">
                                            <div class="col-4">
                                                <span>{{item.displayPartNumber}}</span> 
                                            </div>
                                            <div :title="item.description" class="col-4 text-overflow-hidden">
                                                {{item.description}}
                                            </div>
                                            <div class="col-2">
                                                <input style="width:70px; padding-top:0px; padding-bottom:0px;" :value="item.number" type="number" min="0" :max='item.quantity' step="1" @change="updateFromBasket(item,$event)"/> &nbsp;
                                            </div>
                                            <div class="col-1">
                                                <button style="font-weight:bold; color:red; background:none; border: none; padding: 0;" v-on:click="removeFromBasket(item)" title="remove from basket">X</button> &nbsp;
                                            </div>
                                            <div class="col-1" v-show="item.partError != '' && item.partError != null" :title="item.partError">
                                                <i style="color:orange;" class="fas fa-fw fa-lg fa-exclamation-triangle"></i>
                                            </div>
                                        </div>
                                    </slot>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-8"> <!-- Start of right hand column -->
                        <div v-show="tabIndex == 1">   
                            <div class="row p-2"> <!-- Start of Header -->
                                <div>
                                    <span><h5>{{tableHeader}}</h5></span>
                                </div>
                            </div> <!-- End of Purchases Header -->
                            <div v-if="!searchResultsLoading" style="overflow-y: auto; height:calc(100vh -70px); "> <!-- Start of search results table -->
                                <ib-table
                                    :items="formattedSearchResults"
                                    :columns="searchColumns"
                                    :small=true
                                    :loading=loading
                                    loadingMessage="Loading Search..."
                                    noItemsMessage="No Records Found"
                                    :itemsPerPage="15"> 
                                    <template 
                                        slot="number"
                                        slot-scope="result">    
                                        <input style="width:100px; padding-top:0px; padding-bottom:0px;" v-model="result.item.number" type="number" min="0" :max='result.item.quantity' step="1" value="0"/> &nbsp;
                                        <button title="Add To Basket" class="btn p-0 m-0 tn-primary" v-on:click="addToBasket(result.item)"><i class="fa fa-plus-circle"/></button>                                
                                    </template>                           
                                    <template
                                        slot="priceRange"
                                        slot-scope="data">
                                        <div v-if="data.item.priceLow != 0" class="text-right ">
                                             £{{ data.item.priceLow | getCurrencyValue()}} - £{{ data.item.priceHigh | getCurrencyValue()}}
                                        </div>
                                        <div v-if="data.item.priceLowEur != 0" class="text-right ">
                                             €{{ data.item.priceLowEur | getCurrencyValue()}} - €{{ data.item.priceHighEur | getCurrencyValue()}}
                                        </div>
                                    </template>
                                    <template
                                        slot="quantity"
                                        slot-scope="data">
                                        <div class="text-right pr-5">
                                             {{data.item.quantity}}
                                        </div>
                                        </template>
                                </ib-table>
                            </div>
                            <div v-else>
                                <ib-loading-spinner 
                                    message="Loading.."
                                    class="flex-grow-1"
                                />
                            </div><!-- End of search result Area -->
                            <p class="text-right" id="pricerange-label" style="color:gray">
                                <i>*excluding VAT and P&amp;P</i>
                             </p>
                        </div>
                        <div v-show="tabIndex == 0">
                            <suggested-stock-order
                                :from="ssoFromDate"
                                :to="ssoToDate"
                                :loadReports="loadReports"
                                :selectedDepot="selectedDepot"
                                :selectedDepotName="selectedDepotName"
                                :basket="basket"
                                @add-to-basket="addToBasket"
                                @report-load-complete="loadReports=false"
                                ref="sso"
                            ></suggested-stock-order>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <ib-loading-spinner 
                message="Loading.."
                class="flex-grow-1"
            />
        </div>
    </div>
    <div v-else class="alert" style="background-color:#DBDBDB">
		<h4
			class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
		>
			Gold Marketplace Licensing
		</h4>
		<p> 
			Gold Marketplace Module requires licensing, please contact your Account Manager for further details.
		</p>
	</div>
</template>

<script> 

import DepotApi             from '@WS/api/depot';
import IbTable              from '@/components/table/IbTable';
import RulesApi             from '@MP/api/rules';
import ClientApi            from '@MP/api/client';
import RsDropdown           from '@/components/form/RomainSimonDropdown';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';
import LicenceMixin         from '@/mixins/LicenceMixin';
import { DocStoreOptions }  from '@/common/docstore-options';
import IbToolTip            from '@/components/utilities/IbToolTip';
import { mapGetters, mapState }       from 'vuex';
import CurrencyMixin        from '@/mixins/CurrencyMixin';
import StorageService 	    from '@/common/storage-service';
import IbModal              from '@/components/utilities/IbModal';
import MarketplacePasswordModal from '@MP/components/MarketplacePasswordModal';
import ConfigureOrderModal from '@MP/components/ConfigureOrderModal';
import IbDatePicker from "@/components/form/IbDatePicker";
import SuggestedStockOrder from "@MP/components/SuggestedStockOrder";


const requiredLicenses = [
    DocStoreOptions.GOLD_MARKETPLACE 
];

const SELECT_MANU_ERROR = "Please select a manufacturer to search.";
const ENTER_SUFFIX_ERROR = "Please enter a part number suffix to search.";
const NO_LISTINGS_ERROR = "No marketplace listings were returned by the search.";
const BASKET_QUANTITY_ERROR = "Added quantity must be a positive whole number";
const BASKET_QUANTITY_HIGH_ERROR = "Added quantity must be less than or equal to the Qty on Marketplace";
const BASKET_QUANTITY_NOW_TOO_LOW = "Marketplace no longer has the quantity of this part necessary to fulfil your order";

const ALL_BASKET_ITEMS_ERRORED_ERROR = "All basket items have missing part record or prefix mapping issues. Please rectify at least one issue to continue.";

const SearchColumns = [
    {
        heading: 'Basket Quantity',
        property: 'number',
        sortable: false
    },
    { heading: 'Part No.',
        property: 'displayPartNumber',
        sortable: false,
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: false,
    },
    {
        heading: 'Qty on Marketplace',
        property: 'quantity',
        sortable: false,
    },
    {
        heading: 'Price Range*',
        property: 'priceRange',
        sortable: false,
    }
];
export default {
    name: 'MarketplaceProfile',

mixins: [
        LicenceMixin,
        CurrencyMixin
    ],

components: {
        IbTable,
        RsDropdown,
        IbLoadingSpinner,
        IbModal,
        IbToolTip,
        MarketplacePasswordModal,
        ConfigureOrderModal,
        IbDatePicker,
        SuggestedStockOrder
    },

    computed:{
        ...mapGetters('system', {
          companyId   : 'currentCompanyId'
        }),

        ...mapState('auth', {
          storedUser: (state) => state.user
        }),

        formattedSearchResults()
        {
            return this.searchResults.map(e => {
                if(typeof(e.localPrefix) == 'undefined' || e.localPrefix === null){
                    e.localPrefix = '??'
                }
                e.displayPartNumber = e.localPrefix + "-" + e.partNumber;
                
                return e;
            })
        },

        selectedDepotName()
        {
            let selectedDepot = this.localDepots.filter(e => e.Id == this.selectedDepot);
            if (selectedDepot == null || selectedDepot.length == 0)
            {
                return "";
            }
            else
            {
                return selectedDepot[0].ShortName;
            }
        },
        basketHeight(){
            return this.mappingContent.length > 0 ? "max-height:calc(100vh - 475px)" : "max-height:calc(100vh - 410px)";
        }
    },

    data() {
        return {           
            currentBasket           : "",
            allPrefixes             : [], 
            allManufacturers        : [],
            mappingContent          : "",
            selectedPrefixes        : "??-",
            selectedManufacturer    : "",
            selectedManufacturerName:"",
            hiddenPrefixes          : "",
            showPasswordModal       : true,
            loading                 : true,
            requiredLicenses        : requiredLicenses,
            timer                   : 0,
            licensedTimer           : 1,
            licenseChecks           : 0,
            partNumberForSearch     : "",
            searchColumns           : SearchColumns,
            searchResults           : [],
            tableHeader             : "Search Results",
            basket                  : [],
            showRemoveFromBasketModal : false,
            partForRemovalFromBasket : {},
            showClearBasketModal    : false,
            localDepots             : [],
            serverDepots            : [],
            dropdownDepots          : [],
            selectedDepot           : "0",
            searchDisabled          : false,
            searchResultsLoading    : false,
            showConfigureOrderModal : false,
            tabIndex: 0,
            ssoFromDate: "",
            ssoToDate: "",
            loadReports: true
        }
    },

    watch: {
        'selectedDepot': {
            immediate: true,
            handler  : async function(value){
                let currentTab = this.tabIndex;
                this.loadReports = true; 
                if (value != 0 && value != undefined)
                {
                   await this.loadBasket(true);
                }
                else
                {
                    await this.loadBasket();
                }
                await  this.quantityUpdateBasedOnBasket();
                this.tabIndex = currentTab;
            }
        },
        'companyId': {
            immediate: true,
            handler  : async function(value){
                if (value != null)
                {
                    await this.refresh();
                    this.loadReports = true;
                    await this.loadBasket(true);
                    this.quantityUpdateBasedOnBasket();
                }
            }
        }
    },

    async beforeMount() {
        this.checkLicensed();
            this.licensedTimer = window.setInterval(() => {
                this.checkLicensed();
            }, 200);
    },
     
    created() {
        this.loadBasket();
    },

    beforeDestroy(){
        window.clearInterval(this.licensedTimer);
        window.clearInterval(this.timer);
    },

    methods: {
        async passwordValidated(){
            this.showPasswordModal = false;
        },
        async getPrefixes()
        {
            let allPrefixes = await RulesApi.getPrefixes();
            this.allPrefixes = allPrefixes.data.data;
        },
        configureOrder()
        {
            if (this.basket.filter(b => (b.partError == "" || b.partError == null || b.partError == BASKET_QUANTITY_NOW_TOO_LOW)).length == 0)
            {
                this.toast('b-toaster-top-center', 'danger', ALL_BASKET_ITEMS_ERRORED_ERROR, true);
                return;
            }
            this.showConfigureOrderModal = true;
        },
        removeFromBasket(item)
        {
            this.partForRemovalFromBasket = item;
            this.showRemoveFromBasketModal = true;
        },
        executeClearBasket()
        {
            this.basket.forEach(b => {
                this.partForRemovalFromBasket = b;
                this.executeRemoveFromBasket();
            });
            this.showClearBasketModal = false;
            StorageService.delete(this.currentBasket);
        },
        removeFromBasketAfterOrdering(item)
        {
            this.basket.forEach(e => {
                if(e.displayPartNumber == item.partNumber){
                    if (item.totalQuantity == e.number)
                    {
                        this.partForRemovalFromBasket = e;
                        this.executeRemoveFromBasket();
                    }
                    else
                    {
                        e.number = e.number - item.totalQuantity;
                    }
                    return e;
                }
            });
            StorageService.setObject(this.currentBasket, this.basket, true);
            this.quantityUpdateBasedOnBasket();

            this.searchResults = this.searchResults.map(e => 
            {
                if(e.displayPartNumber == item.partNumber)
                {
                    e.quantity -= item.totalQuantity;
                }
                return e;
            });

            this.$refs.sso.reduceMarketplaceQuantity(item);
        },
        removeFromBasketFromOrder(item)
        {
            this.basket.forEach(e => {
                if(e.partNumber == item.originalPartNumber && e.manufacturer == item.manufacturerId){
                    if (item.totalQuantity == e.number)
                    {
                        this.partForRemovalFromBasket = e;
                        this.executeRemoveFromBasket();
                    }
                    else
                    {
                        e.number = e.number - item.totalQuantity;
                    }
                    return e;
                }
            });
            StorageService.setObject(this.currentBasket, this.basket, true);
            this.quantityUpdateBasedOnBasket();
        },
        executeRemoveFromBasket()
        {
            let item = this.partForRemovalFromBasket;
            this.basket = this.basket.filter(e => !(e.partNumber == item.partNumber && e.manufacturer == item.manufacturer));
            this.searchResults = this.searchResults.map(e => {
                if (e.partNumber == item.partNumber && e.manufacturer == item.manufacturer)
                {
                    e.number = 0;
                }
                return e;
            });
            this.showRemoveFromBasketModal = false;
            StorageService.setObject(this.currentBasket, this.basket, true);
        },
        updateFromBasket(itemtoUpdate,event){
            let updatedNumber = event.target.value;
            let item = { ...itemtoUpdate };
            if (updatedNumber < 1 || updatedNumber % 1 != 0)
            {
                this.toast('b-toaster-top-center', 'danger', BASKET_QUANTITY_ERROR, true);
                //return;
            }
            else if (updatedNumber > item.quantity)
            {
                this.toast('b-toaster-top-center', 'danger', BASKET_QUANTITY_HIGH_ERROR, true);
                //return;
            } 
            else{
                item.number = +updatedNumber;
                this.searchResults  = this.searchResults.map(e => {
                    if (e.manufacturer == item.manufacturer && e.partNumber == item.partNumber)
                    {
                        e.number = item.number; 
                    }
                    return e;
                });
            }
            
            this.basket = this.basket.map(e => {
                if (e.manufacturer == item.manufacturer && e.partNumber == item.partNumber)
                {
                    return item; 
                }
                return e;
            });
            StorageService.setObject(this.currentBasket, this.basket, true);
        },
        addToBasket(itemToAdd)
        {
            let item = { ...itemToAdd };
            if (item.number < 1 || item.number % 1 != 0)
            {
                this.toast('b-toaster-top-center', 'danger', BASKET_QUANTITY_ERROR, true);
                return;
            }
            else if (item.number > item.quantity)
            {

                this.toast('b-toaster-top-center', 'danger', BASKET_QUANTITY_HIGH_ERROR, true);
                return;
            } 
            item.number = +item.number;
            if(this.basket.filter(e => e.manufacturer == item.manufacturer && e.partNumber == item.partNumber).length == 0)
            {
                this.basket.push(item);
            }
            else
            {
                this.basket = this.basket.map(e => {
                    if (e.manufacturer == item.manufacturer && e.partNumber == item.partNumber)
                    {
                        return item; 
                    }
                    return e;
                });
            }
            StorageService.setObject(this.currentBasket, this.basket, true);
        },
        async refresh() {
            this.loading = true;

            try{
                this.prefixMappings = [];
                this.allPrefixes = [];
                this.allManufacturers = [];
                this.mappings = [];
                this.mappingContent = "";
                this.selectedPrefixes = "??-";
                this.hiddenPrefixes = "";
                this.localDepots   =[];
                this.serverDepots  = [];
                this.dropdownDepots = [];
                await this.getPrefixes();
                this.getPrefixMappings();
                await this.loadDepots();
                await this.getDepots();
                this.tableHeader= "Search Results";
                this.selectedDepot = this.dropdownDepots[0].value;
            }
            catch(err)
            {
                this.toast('b-toaster-top-center', 'danger', err.response.data, true);
                this.searchDisabled = true;
                this.loading = false;
            }
        },
        async doAggregatedSearch()
        {
            if(this.selectedManufacturer == ''){
                this.toast('b-toaster-top-center', 'danger', SELECT_MANU_ERROR, true);
                return;
            }
            else if(this.partNumberForSearch.length<1){
                this.toast('b-toaster-top-center', 'danger', ENTER_SUFFIX_ERROR, true);
                return;
            }
            else if(this.partNumberForSearch == "." || this.partNumberForSearch == ".."){
                this.toast('b-toaster-top-center', 'danger', "'"+this.partNumberForSearch + "' is not a valid part number for search", true);
                return;
            }
            this.searchResultsLoading = true; 
            this.searchDisabled = true;
            try
            {
                this.tableHeader= "Search Results"
                this.searchResults = await ClientApi.doAggregatedSearch(this.partNumberForSearch.toUpperCase(), this.selectedManufacturer, this.selectedDepot);
                this.searchResults = this.searchResults.data;
                if (this.searchResults.length<1)
                {
                    this.toast('b-toaster-top-center', 'danger', NO_LISTINGS_ERROR, true);
                }
                this.tableHeader += " for "+this.selectedManufacturerName+" - "+this.partNumberForSearch+" ("+this.searchResults.length+")"

                this.quantityUpdateBasedOnBasket();
            }
            catch (error)
            {
                this.toast('b-toaster-top-center', 'danger', error.response.data, true);
            }
            finally
            {
                this.searchDisabled = false;
                this.searchResultsLoading = false;
            }
        },
        quantityUpdateBasedOnBasket()
        {
          this.searchResults = this.searchResults.map(e => {
                    e.number = 0;
                    this.basket.forEach(b =>{
                        if (e.partNumber == b.partNumber && e.manufacturer == b.manufacturer)
                        {
                            e.number = b.number;
                        }
                    })
                    return e;
                })
        },
        async checkLicensed()
        {
            this.licenseChecks += 1;
            if (this.hasDocStoreOptions(...this.requiredLicenses))
            {
                window.clearInterval(this.licensedTimer);
            }
            if (this.licenseChecks > 5)
            {
                window.clearInterval(this.licensedTimer);
            }
        },
        async getPrefixMappings()
        {
            let allManufacturers = [];
            let prefixMappings = await ClientApi.getPrefixMappings();
            this.prefixMappings = prefixMappings.data;
            
            this.prefixMappings.forEach(e => allManufacturers.push({id: e.id, name: e.name}))
            allManufacturers = _.orderBy(allManufacturers, ['name'],['asc']);
            allManufacturers.unshift({id: -1, name:"Not Selected"});
            this.allManufacturers = allManufacturers;
            this.initialPrefixMappings = JSON.parse(JSON.stringify(this.prefixMappings));
            this.successfullyLoaded = true;
        
        },
        manufacturerSelected(selection, name) {
            if (name && !this.loading) {
                let thisManufacturer = selection.option.name;
                this.prefixMappings.forEach(pm =>
                {
                    if(pm.associatedPrefixesList == null){
                        pm.associatedPrefixesList = []
                    }
                    if (pm.name == thisManufacturer)
                    {
                        let count = pm.associatedPrefixesList.length
                        this.selectedManufacturer = pm.id;
                        this.selectedManufacturerName = thisManufacturer;
                        if (count<=0) {
                            this.hiddenPrefixes = ""; 
                            this.mappingContent = 'No prefix mapped for "'+thisManufacturer+'"';
                            this.selectedPrefixes = '??-'
                        }
                        else{
                            if(count == 1){
                                this.hiddenPrefixes = ""; 
                                this.selectedPrefixes =  pm.associatedPrefixesList[0];
                            }
                            else if(count > 2){
                                this.selectedPrefixes = pm.associatedPrefixesList[0]+ " (+"+(count-1)+")"
                                this.hiddenPrefixes = pm.associatedPrefixesList.join('/')//for showing prefixes on tooltip
                            }
                            else{
                                this.selectedPrefixes = pm.associatedPrefixesList.join('/')
                            }
                            this.selectedPrefixes+= " - ";
                            this.mappingContent = "";
                        }
                    }
                });
            }
        },
        async getDepots() {
            let ddCount = 0;
            let me = this;
            let localDepots = await DepotApi.getAllDepots();
            this.localDepots = localDepots.data.data;
            
            this.localDepots.forEach(depot =>{ 
                if(me.serverDepots.filter(e => e.depotId == depot.Id && e.marketplaceEnabled).length >0){
                    ddCount = 1;
                    this.dropdownDepots.push(({value: depot.Id, text: depot.Id +' - '+depot.ShortName}));
                }
            } );
            if(ddCount == 0){
                this.searchDisabled = true;
                this.mappingContent = "Please enable a depot in the Marketplace Profile to use this page";
                this.dropdownDepots.push(({value: 0, text: 'No Depots Enabled'}));
            }
            else
            {
                this.searchDisabled = false;
            }
        },
        async loadDepots(){
            let serverDepots = await ClientApi.getDepots(); //depots that are marketplace enabled
            serverDepots = serverDepots.data;
            this.serverDepots = serverDepots;
        },
        async loadBasket(depotSwitch){
            if (depotSwitch)
            {
              this.loading = true;
            }
            if(this.selectedDepot === "0")
            {
              return;
            }
            this.currentBasket = this.storedUser.Email+":"+this.companyId+":"+this.selectedDepot;
            if (StorageService.any(this.currentBasket, true)) 
            {
              this.basket = StorageService.getObject(this.currentBasket);
              if(this.basket.length > 0)
              {
                  try
                  {
                      let partsRequest = this.basket.map((e)=>{
                          let request = {
                          partNumber: e.partNumber.toUpperCase(),
                          manufacturerId: e.manufacturer,
                          buyerDepotId: this.selectedDepot
                          }
                          return request;
                      })    
                      let aggregatedResponse = await ClientApi.doAggregatedCheck(partsRequest);
                      let items =  aggregatedResponse.data;
                      this.basket = this.basket.map(e =>{
                          var newItem = items.find(x=> x.partNumber === e.partNumber);
                          newItem.displayPartNumber = (newItem.localPrefix == null ? "??" : newItem.localPrefix) + "-" + newItem.partNumber;
                              newItem.number = e.number;
                              if ((newItem.partError == null || newItem.partError == "") && newItem.number > newItem.quantity)
                              {
                                  newItem.partError = BASKET_QUANTITY_NOW_TOO_LOW;
                              }
                              e = newItem;
                              return e;
                      })
                  }
                  catch(err)
                  {
                      console.log(err)
                  }   
              }
            }
            else {
              this.basket = []
            }
            this.loading = false;
        },
        loadSSOReports() {
            if(this.ssoFromDate || this.ssoToDate)
            {
                if(!this.ssoFromDate)
                {
                    this.toast(
                    "b-toaster-top-center",
                    "danger",
                    "From date is required",
                    true
                    );
                    return;
                }
                if(!this.ssoToDate)
                {
                    this.toast(
                    "b-toaster-top-center",
                    "danger",
                    "To date is required",
                    true
                    );
                    return;
                }
                let fromDate = new Date(this.ssoFromDate);
                let toDate = new Date(this.ssoToDate);
                if (fromDate > toDate) {
                    this.toast(
                        "b-toaster-top-center",
                        "danger",
                        "From date should be less then or equal to To date",
                        true
                    );
                    return;
                }
            }
            this.loadReports = true;
        },
        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Order Parts`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        }

    }
}
</script>
<style>
.fa-plus-circle{
    color: green;
}
.cellAlign{
    margin-top:10px;
}
.basket-scroll{
    overflow-x:hidden;
    overflow-y: auto;
}
.text-overflow-hidden{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.order-parts-tab > .tab-content{
    min-height: 165px;
}
</style>

<style lang="less" scoped>
@import '../../../assets/styles/ib-common';
</style>