import ApiService from '@/common/api-service';

const SYSTEM_ROOT = "api/v1/system";

const SystemApi = {

    /**
     * Get the current Gold licence info from the API.
     */
    getLicenceInfo() {
        const url = `${SYSTEM_ROOT}/licence/info`;
        return ApiService.get(url);
    }

};

export default SystemApi;