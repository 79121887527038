<template>
    <ib-info-modal id="request-info-modal" title="Contact Information" iconColour="white">        
        <div class="card-header bg-secondary text-light">
            <i class="fas fa-fw fa-lg fa-address-book"></i> {{ customer && customer.AccountNo }} - {{ customer && customer.Name }}
        </div>
        <label>Account:</label> {{ customer && customer.AccountNo }}<br />
        <label>Name:</label> {{ customer && customer.Name }}<br />
        <label>Telephone:</label> {{ customer && customer.Telephone }}<br />
        <label>Telephone (Alt):</label> {{ customer && customer.TelephoneAlt }}<br />
        <label>Email:</label> <a :href="`mailto:${customer && customer.Email}`">{{ customer && customer.Email }}</a><br />
        <label>Contact:</label> {{ customer && customer.Name }}<br />
        <label>Address:</label> {{ customer && accountAddress }}<br />
        <label>Delivery Address:</label> {{ customer && deliveryAddress }}<br />
        
        <div class="card-header bg-secondary text-light mt-4">            
            <i class="fas fa-fw  fa-lg fa-tractor"></i> {{ wholegood.Id }} - {{ wholegood.Model }}            
        </div>        
        <label>Wholegood Number:</label> {{ wholegood.Id }}<br />
        <label>Registration:</label> {{ wholegood.RegistrationNumber }}<br />
        <label>Serial Number:</label> {{ wholegood.SerialNumber }}<br />
        <label>Make:</label> {{ wholegood.Make }}<br />
        <label>Model:</label> {{ wholegood.ModelCode }}<br />
        <label>Year:</label> {{ wholegood.Year }}<br />
        <label>Clock Hours:</label> {{latestRequest.ClockHours}} (Latest) {{ goldClockHours }} (Gold)<br />
        <label>Warranty End:</label> {{ wholegood.WarrantyEndDate | formatDate(DateFormat.DATE_ONLY) }}  {{ wholegood.WarrantyEndHours | formatClockHours }} <br />
        <label>Extended Warranty End:</label> {{ wholegood.WarrantyExtendedDate | formatDate(DateFormat.DATE_ONLY) }} {{ wholegood.WarrantyExtendedHours | formatClockHours }} <br />
        <label>Specification:</label><div class="px-3">{{ wholegood.Specification }}</div>
    </ib-info-modal>                                                            
</template>

<script>
import IbInfoModal from '@/components/utilities/IbInfoModal';
import DateMixin, {
    DateFormat
}                               from '@/mixins/DateMixin';

export default {
    name: "RequestInfoModal",

    mixins: [
        DateMixin
    ],

    components: {
        IbInfoModal
    },

    props: {
        customer: {
            type: Object,
            required: false
        },

        wholegood: {
            type: Object,
            required: true
        },

        latestRequest: {
            type: Object,
            required: true
        }
    },

    data() {
        return {

        }
    },

    computed: {
        /**
         * Primary account address held on the customer record.
         * 
         * This may not be set so will return "-" if there is no value otherwise will return the 
         * parts of the adddress that are set
         */
        accountAddress(){
            let address = "-";
            let parts   = new Array();

            this._setAddress(parts, this.customer.Address1);
            this._setAddress(parts, this.customer.Address2);
            this._setAddress(parts, this.customer.Address3);
            this._setAddress(parts, this.customer.Address4);
            this._setAddress(parts, this.customer.PostCode);

            if (false === _.isEmpty(parts)) {
                address = parts.join(", ");
            }

            return address;
        }, 

        /**
         * Default delivery address held on the customer record.
         * 
         * This may not be set so will return "-" if there is no value otherwise will return the 
         * parts of the adddress that are set
         */
        deliveryAddress() {
            let address = "-";
            let parts   = new Array();

            this._setAddress(parts, this.customer.DeliveryAddress1);
            this._setAddress(parts, this.customer.DeliveryAddress2);
            this._setAddress(parts, this.customer.DeliveryAddress3);
            this._setAddress(parts, this.customer.DeliveryAddress4);
            this._setAddress(parts, this.customer.DeliveryPostCode);

            if (false === _.isEmpty(parts)) {
                address = parts.join(", ");
            }

            return address;           
        },

        goldClockHours(){
            const me = this;
            let result = '0';
            if(false === _.isNil(me.wholegood)){
                result = Math.max(me.wholegood.ClockHoursAtLastService, me.wholegood.ClockHoursAtLastService);
            }
            
            return result;
        }

    }, /* END: computed */

    methods: {
        /**
         * Utility to help build up the account and delivery address strings.
         */
        _setAddress(list, value) {
            if (true == _.isArray(list) && false == _.isEmpty(value)){
                list.push(value);
            }
            return list;
        }
    },

    filters: {
        formatClockHours(hours){
            let output = "";
            if (!hours) return output;

            if (0 != hours){
                output = `${hours} hours`;
            }
            return output;
        }
    }
}
</script>

<style lang="less" scoped>
label {
    font-weight: bolder;
    width: 180px;
    padding-left: 5px;
}
</style>