import Vue from 'vue';

const API_ROOT = `${process.env.VUE_APP_AUDIT_URL}api/v1/`;

const AuditApi = {

    queryRecordChanges(query, variables) {
        const url = `${API_ROOT}record`;
        return Vue.axios({
            method: 'post',
            url: url,
            data: {
                query,
                variables
            }
        });
    }

};

export default AuditApi;