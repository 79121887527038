<template>
	<div class="form-row job-activity-edit"
		:class="{ 'deleted': isDeleted}"
		:style="{ 'background-color' : backgroundColour }">
			<!-- Start Time -->
			<div class="col-1">
				<ib-time-input v-if="isEditable"
							   v-model="startTime"
							   class="form-control form-control-sm rounded-0 time-input flex-grow-1"/>
				<div v-else
					class="readonly-time">
					{{ startTime }}
				</div>
			</div>
			<!-- Stop Time -->
			<div class="col-1">
				<div v-if="isEditable"
					class="d-flex">
					<ib-time-input v-if="isEditable"
					 			   	 v-model="endTime"
									 :class="{ 'multi-day' : isMultiDay }"
								     class="form-control form-control-sm rounded-0 time-input flex-grow-1"/>
					<div v-if="isMultiDay"
						class="edit-date-container">
						<ib-edit-date-button v-model="endDate"
							title="Change Stop Date"
							icon="fa-exclamation-triangle"
							iconStyle="fas"
							activeColour="text-warning"
							:disabled="!isEndTimeValid">
							<template slot="message">
								<div>
									<p>Activity <b>{{ startTime }}</b> - <b>{{ endTime }}</b> for job <b>{{ jobActivity.JobId }}</b> stops on a different day.</p>
									<div class="row">
										<div class="col-2">
											<p class="mb-0"><b>Start:</b></p>
											<p><b>Stop:</b></p>
										</div>
										<div class="col-10">
											<p class="mb-0">{{ this.jobActivity.Start | formatDate }}</p>
											<p>{{ this.jobActivity.End | formatDate }}</p>
										</div>
									</div>
									<p>If this is incorrect, please select a new stop date below:</p>
								</div>
							</template>
						</ib-edit-date-button>
					</div>
				</div>
				<div v-else
					class="readonly-time">
					{{ endTime }}
				</div>
			</div>
		<!-- Duration -->
		<div class="col-md-1"
			:class="{ 'invalid-duration': !isDurationValid, 'warning-duration': isDurationTooLong }">
			{{ duration | formatDuration('h[h] m[m]', 'none') }}
		</div>
		<div class="col-md-1">
			<!-- Setup job number as a link to edit job -->
			<b-link :to="{ name: 'EditJob', params: { id: jobActivity.JobId } }">
				{{ jobActivity.JobId }}
			</b-link>
		</div>		
		<!-- Customer -->
		<div class="col-md-2">
			{{ jobActivity.Customer }}
		</div>
		<!-- Comments -->
		<div class="col-md-3 text-truncate">
			<span :id="`jobactivity-${jobActivity.Id}-comment`">
				{{ jobActivity.Comment }}
			</span>
			<b-popover :target="`jobactivity-${jobActivity.Id}-comment`"
				title="Comment"
				triggers="hover"
				:content="jobActivity.Comment">
			</b-popover>
		</div>
		
		<!-- Labour rate -->
		<div class="col-md-2">
			<labour-rate-list
				v-if="isEditable"
				class="ml-2"
				small
				:labourCategories="labourCategories"
				@rateChanged="onRateChanged" />
		</div>

		<!-- Inline controls (Delete and Approve) -->
		<div class="col-md-1 d-flex justify-content-between icon-container">

			<!-- Activity Type with Popover -->
			<!-- Assigning a tab index allows the focus trigger to be used with a div -->
			<div v-b-popover.hover.focus.left="activityType.content"  
				:title="activityType.title"
				:data-html="true"
				
				tabindex="-1">
				<i class="fas fa-fw fa-lg"
					:class="activityType.icon"
				></i>
			</div>

			<div v-b-popover.hover.html="descriptionPopover"
				title="Description"
				tabindex="-1">
				<i class="fas fa-fw fa-lg fa-info"
				></i>
			</div>

			<div v-b-popover.hover.html="engineerStoryPopover"
				data-trigger="hover"
				title="Engineer Story"
				tabindex="-1">
				<i class="fas fa-fw fa-lg fa-book"
				></i>
			</div>
			

			<b-popover :target="`jobactivity-${jobActivity.Id}-comment`"
				title="Comment"
				triggers="focus"
				:content="jobActivity.Comment">
			</b-popover>

			<ib-delete-button v-if="isEditable"
				@delete="deleteJobActivity"
				backgroundColour="transparent"
			></ib-delete-button>

			<i v-if="isApproved"
				class="far fa-fw fa-check-square fa-lg text-success"
			></i>
			<ib-checkbox v-if="isEditable"
				v-model="isSelected"
				:disabled="!isValid"
				backgroundColour="transparent"
			></ib-checkbox>
		</div>

	</div>

	
</template>

<script>
// Utilities.
import DateMixin, { DateFormat }	from '@/mixins/DateMixin';

// Components.
import IbCheckbox            		from '@/components/form/IbCheckbox';
import IbDeleteButton        		from '@/components/IbDeleteButton';
import IbEditDateButton      		from '@/components/IbEditDateButton';
import IbTimeInput			 		from '@/components/form/IbTimeInput';
import LabourRateList 				from '@WS/components/LabourRateList';

// APIs.
import JobsApi               		from '@WS/api/job';
import JobActivityApi        		from '@WS/api/job-activity';

// Constants.
import { JobActivityStatus } 		from '@WS/common/job-activity-status';
import { JobActivityType }   		from '@WS/common/job-activity-type';

const NO_FLATRATE        = -1;
const DURATION_MAX_HOURS = 20;


//contents: Object = {};// <--- where to store all contents for el-popovers



var something;

export default {
	name: 'JobActivityEdit',

	mixins: [
		DateMixin
	],

	components: {
		IbCheckbox,
		IbDeleteButton,
		IbEditDateButton,
		IbTimeInput,
		LabourRateList
	},

	//https://vuejs.org/v2/style-guide/#Prop-definitions-essential
	props: {
		activity: {
			type:     Object,
			required: true
		},
		index: {
			type: 	  Number,
			required: true
		},

		labourCategories: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			activityType:   		null,

			// Time strings bound to input boxes.
			startTime:      		null,
			endTime:       		 	null,

			startDate:				null,
			// Date object bound to datepicker.
			endDate:				null,
			isSelected:     		false,
			approving:				false,
			deleting:				false,
			labourRate: 			null,
			engineerPopoverText:	"",
		}
	},

	created() {
		this.initialise();
	},

	computed: {
		/**
		 * Returns a clone of the parent job activity model.
		 * @returns {object} Cloned JobActivity object.
		 */
		jobActivity() {
			const jobActivity = {
				...this.activity
			}
			return jobActivity;
		},
		
		/**
		 * Returns edited start time as a string, or null if invalid.
		 * @returns {string} Edited start time.
		 */
		editedStart() {
			let editedStart = null;
			if (true === this.isStartTimeValid && this.startDate) {
				const start	= `${this.startDate} ${this.startTime}`;
				editedStart = this.getDateTime(start, 'DD/MM/YYYY HH:mm');
			}
			return editedStart;
		},

		/**
		 * Returns edited end time as a string, or null if invalid.
		 * @returns {string} Edited end time.
		 */
		editedEnd() {
			let editedEnd = null;
			if (true === this.isEndTimeValid && this.endDate) {
				// Convert date object to date string.
				const end = `${this.endDate} ${this.endTime}`;
				editedEnd = this.getDateTime(end, 'DD/MM/YYYY HH:mm');
			}
			return editedEnd;
		},

		/**
		 * Returns duration as a string, or null if invalid.
		 * @returns {string} Duration.
		 */
		duration() {
			let duration = null;
			if (this.editedStart && this.editedEnd) {
				duration = this.getDuration(this.editedStart, this.editedEnd);
			}
			return duration;
		},

		/**
		 * Returns true if end date of job activity occurs on a date after the
		 * start date of activity, otherwise false.
		 */
		isMultiDay() {
			let isMultiDay = false;
			if (this.editedStart && this.editedEnd) {
			isMultiDay = this.isDateAfter(this.editedEnd, this.editedStart, 'day');
			}
			return isMultiDay;
		},

		/**
		 * Returns true if start time is valid, otherwise false.
		 */
		isStartTimeValid() {
			const isValid = this.validateTimeFormat(this.startTime);
			return isValid;
		},

		/**
		 * Returns true if end time is valid, otherwise false.
		 */
		isEndTimeValid() {
			const isValid = this.validateTimeFormat(this.endTime);
			return isValid;
		},

		/**
		 * Returns true if duration is valid, otherwise false.
		 */
		isDurationValid() {
			const isValid = this.validateDuration(this.duration);
			return isValid;
		},

		/**
		 * Returns true if duration is over 20 hours, otherwise false.
		 */
		isDurationTooLong() {
			let isTooLong = false;
			if (true === this.isDurationValid) {
				const duration  = this.getDurationHours(this.duration);
				isTooLong = DURATION_MAX_HOURS < duration;
			}
			return isTooLong;
		},

		/**
		 * Returns true if all values are valid, otherwise false.
		 */
		isValid() {
			const isValid = this.isStartTimeValid && this.isEndTimeValid && this.isDurationValid;
			return isValid;
		},

		/**
		 * Returns status job activity.
		 */
		status() {
			return this.jobActivity.Status;
		},

		/**
		 * Returns true if job activity status is approved, otherwise false.
		 */
		isApproved() {
			const isApproved = JobActivityStatus.APPROVED === this.jobActivity.Status;
			return isApproved;
		},

		/**
		 * Returns true if job activity status is deleted, otherwise false.
		 */
		isDeleted() {
			const isDeleted = JobActivityStatus.DELETED === this.jobActivity.Status;
			return isDeleted;
		},

		/**
		 * Returns true if job activity is neither approved nor deleted, otherwise false.
		 */
		isEditable() {
			const isEditable = !(this.isApproved || this.isDeleted);
			return isEditable;
		},

		/**
		 * Returns true if the job activity model has the ApprovedStart
		 * and ApprovedEnd properties set to a valid date, otherwise
		 * false.
		 * @returns {boolean} Is extended job activity model.
		 */
		isExtendedJobActivity() {
			const result = (false === this.isMinDate(this.activity.ApprovedStart)) &&
						   (false === this.isMinDate(this.activity.ApprovedEnd));
			return result;
		},

		isEvenRow() {
			const isEven = (this.index % 2 == 0);
			return isEven;
		},

		backgroundColour() {
			let colour = 'white';

			if (true === this.isEvenRow) {
				colour = '#f0f0f0';
			}

			return colour;
		}
	},

	watch: {
		duration(value) {
			var obj = {
				Duration: value,
				Id: this.jobActivity.Id,
				isDeleted: this.isDeleted,
				Type: this.jobActivity.Type
			};	
			this.$emit("onDurationChanged", obj);
		},

		isApproved(value) {
			this.$emit("isApprovedChanged", value);
		},

		isSelected(value) {
			this.$emit("isSelectedChanged", value);
		},

		isDeleted(value) {
			var obj = {
				isDeleted: value,
				Id: this.jobActivity.Id,
				Duration: this.duration,
				Type: this.jobActivity.Type
			}

			this.$emit("isDeletedChanged", obj);
		},

		isValid(value) {
			// Clear selection state
			this.isSelected = false;
			this.$emit("isValidChanged", value);
		}
	},

	methods: {
		/**
		 * Initialise component to use passed JobActivity.
		 */
		initialise() {
			this.activityType  	= this.setupActivity();

			this.startDate		= this.getDateString(this.activity.Start, DateFormat.DATE_ONLY);
			this.endDate		= this.getDateString(this.activity.End, DateFormat.DATE_ONLY);
			
			// If approved and is extended job activity, use approved times.
			if ((JobActivityStatus.APPROVED === this.activity.Status) &&
				(true === this.isExtendedJobActivity)) {
				this.startTime 	= this.getDateString(this.activity.ApprovedStart, 'HH:mm');
				this.endTime   	= this.getDateString(this.activity.ApprovedEnd,   'HH:mm');
			}
			else {
				this.startTime	= this.getDateString(this.activity.Start, 'HH:mm');
				this.endTime	= this.getDateString(this.activity.End,   'HH:mm');
			}
		},

		descriptionPopover()
		{
			let lines = (this.jobActivity.Description == null || this.jobActivity.Description == undefined) ? '' : this.jobActivity.Description.trim(' ').split('\n');
			let html = lines.join('<br />');
            
			return (html == '') ? 'Description Not Set' : html;
		},


		engineerStoryPopover()
		{
			var lines = [];
			var amountOfCharsToShow = 999; //slicing starts at 0 so this is 1000.
			const lRegex = /#\s\w*/ig;
			var engineerStuff = this.jobActivity.EngineerUserId;
						
			JobsApi.getNotesRecords(this.jobActivity.JobId)
				.then((response) => {
					response.data.data.forEach(function(value) {
						if(value.UserId.toString() == engineerStuff) //value.Id
						{
							if(value.Note != null && value.Note != undefined  && value.Note.trim() != '' && value.Note.trim() != '#')
							{
								lines.push(value.Note == null || value.Note == undefined ? '' : value.Note.trim());
							}
						}
					});

					let html = lines.join('<br />');
					if(html != '')
					{
						html = (html.length > amountOfCharsToShow ? "&hellip;" : '') + html.slice(-amountOfCharsToShow);
					}
					console.log(html.length);
					this.engineerPopoverText = (html == '' ? 'No Engineer Story' : html.replaceAll(lRegex, '<strong>$&</strong>'));
				});
					
			return this.engineerPopoverText;
		},            

		setupActivity() {
			let activity = {
				icon :   'fa-stopwatch',
				title:   'TA',
				content: 'Time and Attendance'
			};

			if (JobActivityType.TRAVEL === this.jobActivity.Type) {
				activity.icon    = 'fa-truck';
				activity.title   = 'Travel';
				activity.content = 'Standard Travel';
			}
			else if (NO_FLATRATE != this.jobActivity.FlatRate) {
				activity.icon    = 'fa-wrench';
				activity.title   = 'Flat Rate';
				activity.content = this.jobActivity.FlatRateDescription;
			}
			else if (JobActivityType.LABOUR === this.jobActivity.Type) {
				activity.icon    = 'fa-wrench';
				activity.title   = 'Labour';
				activity.content = 'Standard Labour';
			}
			return activity;
		},

		/**
         * Approve job activity on the API server.
         */
        async approveJobActivity() {
			let jobActivity = this.jobActivity;
			if (true === this.isEditable && true === this.isValid) {
				this.approving = true;
				
				jobActivity = this.updateJobActivity(jobActivity);

				try {
					await JobActivityApi.update(jobActivity);
					const approveResponse  = await JobActivityApi.approve(jobActivity, this.labourRate);
					const approvedActivity = {
						...approveResponse.data.data,
						ApprovedStart:	this.jobActivity.ApprovedStart,
						ApprovedEnd:	this.jobActivity.ApprovedEnd,
						Description:	this.jobActivity.Description,
						Customer:		this.jobActivity.Customer
					};
					// Notify parent that job activity has been approved.
					this.$emit('approve', approvedActivity);
				}
				catch(failure) {
					const error = `Failed to approve time (${this.startTime} - ${this.endTime}) for job ${jobActivity.JobId}. ${this.getResponseError(failure)}`;
					console.error(error);
					this.$emit('error', {
						id:          Date.now(),
						message:     error,
						jobActivity: jobActivity
					});
				}
				finally {
					this.approving = false;
				}
			}
		},

		/**
         * Delete job activity from the API server.
         */
        async deleteJobActivity() {
			if (true === this.isEditable) {
				this.deleting = true;
				this.$emit('clearErrors');

				try {
					const deleteResponse  = await JobsApi.deleteJobActivity(this.jobActivity);
					const deletedActivity = {
						...deleteResponse.data.data,
						Description: this.jobActivity.Description,
						Customer: 	 this.jobActivity.Customer
					};
					this.isSelected   	  = false;
					// Notify parent that job activity has been deleted.
					this.$emit('delete', deletedActivity);
				}
				catch(failure) {
					const error = `Failed to delete time (${this.startTime} - ${this.endTime}) for job ${this.jobActivity.JobId}.  ${this.getResponseError(failure)}`;
					console.error(error);
					this.$emit('error', {
						id:          Date.now(),
						message:     error,
						jobActivity: this.jobActivity
					});
				}
				finally {
					this.deleting = false;
				}
			}
		},

		/** 
         * Get message from response, if there is one.
		 * @param {object} responseError - Error response object.
         */
        getResponseError(responseError) {
            let error = '';
            if (responseError.response && responseError.response.data) {
                error += responseError.response.data.message;
            }
            else {
                error += responseError.message;
            }
            return error;
        },

		/**
		 * Update job activity approved fields with edited values for start,
		 * end and duration.
		 * @param {object} jobActivity - The job activity model to update.
		 * @returns {object} The updated job activity model.
		 */
		updateJobActivity(jobActivity) {
			jobActivity.ApprovedStart    = this.editedStart;
			jobActivity.ApprovedEnd      = this.editedEnd;
			jobActivity.ApprovedDuration = this.duration;
			return jobActivity;
		},

		/**
		 * Returns true if the time string is in the format H:mm or HH:mm,
		 * otherwise false.
		 * @param {string} time - The time string to validate.
		 * @returns {boolean} - True if valid, otherwise false.
		 */
		validateTimeFormat(time) {
			const isValid = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
			return isValid;
		},

		/**
		 * Event handler triggered when the labour
		 * rate is selected.
		 */
		onRateChanged(newRate) {
			this.labourRate = newRate;
		}
	}
}
</script>

<style lang="less">

@import '../../../../assets/colours';

.job-activity-edit {
	padding-bottom: 5px;
	padding-top: 10px;
}

.time-input {
	font-size: 15px !important;
	margin-top: -5px;
	padding: 0px 4px;
}

.edit-date-container {
	border: solid 1px #ced4da;
	border-left: none;
	margin-top: -5px;
	padding: 4px 2px;
}

.readonly-time {
	padding-left: 9px;
}

.deleted {
	color: @disabled;
	text-decoration: line-through;
}

.icon-container {
	font-size: 14px;
}

.warning-duration {
	color: @warning !important;
	font-weight: bolder !important;
}

.invalid-time {
	border: solid 3px @danger !important;
}

.invalid-duration {
	color: @danger !important;
	font-weight: bolder !important;
}

@media (max-aspect-ratio: 4/3)
{
	.multi-day {
		width: 66% !important;
		padding: 4px 1px !important;
	}
}
</style>
