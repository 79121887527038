export const SET_LICENCE_INFO           = 'SET_LICENCE_INFO';
export const SET_LICENCE_INFO_ERROR     = 'SET_LICENCE_INFO_ERROR';
export const SET_SELECTED_COMPANY       = 'SET_SELECTED_COMPANY';
export const SET_COMPANIES              = 'SET_COMPANIES';
export const SET_COMPANIES_LOADING      = 'SET_COMPANIES_LOADING';
export const SET_COMPANIES_ERROR        = 'SET_COMPANIES_ERROR';
export const SET_SELECTED_DEPOT         = 'SET_SELECTED_DEPOT';
export const SET_DEPOTS                 = 'SET_DEPOTS';
export const SET_DEPOTS_LOADING         = 'SET_DEPOTS_LOADING';
export const SET_DEPOTS_ERROR           = 'SET_DEPOTS_ERROR';
export const SET_GOLD_VERSION           = 'SET_GOLD_VERSION';
export const SET_GOLD_VERSION_LOADING   = 'SET_GOLD_VERSION_LOADING';
export const SET_GOLD_VERSION_ERROR     = 'SET_GOLD_VERSION_ERROR';
export const CLEAR_SELECTED_COMPANY     = 'CLEAR_SELECTED_COMPANY';
export const CLEAR_COMPANIES            = 'CLEAR_COMPANIES';
export const CLEAR_COMPANIES_ERROR      = 'CLEAR_COMPANIES_ERROR';
export const CLEAR_SELECTED_DEPOT       = 'CLEAR_SELECTED_DEPOT';
export const CLEAR_DEPOTS               = 'CLEAR_DEPOTS';
export const CLEAR_DEPOTS_ERROR         = 'CLEAR_DEPOTS_ERROR';
export const CLEAR_GOLD_VERSION         = 'CLEAR_GOLD_VERSION';
export const CLEAR_GOLD_VERSION_ERROR   = 'CLEAR_GOLD_VERSION_ERROR';
export const SET_IS_ROLE_REQUIRED   = 'SET_IS_ROLE_REQUIRED';
