const TEMPLATES = 'ChecklistTemplates';
const CREATE 	= 'ChecklistTemplateCreate';
const EDIT     	= 'ChecklistTemplateEdit';
const DUPLICATE = 'ChecklistTemplateCreateDuplicate';

export const CHECKLIST_ROUTES = {
	TEMPLATES,
	CREATE,
	EDIT,
	DUPLICATE
};