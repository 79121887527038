<template>
<div id="accordion" role="tablist" class="w-100 pr-2" style="overflow-y: auto;">
    <div :id="`listingRule` + item.id">
        <div  class="card-header stickyHeader">
            
            <div class="row">
                <div class="col-lg-1">
                    
                    <a @click="isExpanded = !isExpanded" class="link" data-toggle="collapse" :href="'#listingRule' + item.id + 'body'" aria-expanded="false" aria-controls="ruleset">
                        <div v-show="!isExpanded" ><i class="fas fa-chevron-right fa-lg"></i></div>
                        <div v-show="isExpanded" ><i class="fas fa-chevron-down fa-lg"></i></div>
                    </a>

                </div>
                <div class="col-lg-4">
                    <div v-if="editingListingRule">
                        <input type="text" v-model="listingRuleDescription"/>&nbsp;
                        <button class="btn btn-success btn-sm" @click="changeDescription">Ok</button>&nbsp;
                        <button class="btn btn-secondary btn-sm" @click="editingListingRule = false">Cancel</button>&nbsp;
                    </div>
                    <div v-else>
                        {{item.description}}
                        <div class="float-right">
                            <span style="margin-left: 10px;" @click="editListingRule" title="Edit Rule Set"><i class="fas fa-pencil-alt clickable-text transparent"></i></span>&nbsp;
                            <span style="margin-left: 10px;" @click="removeListingRule(item)" title="Delete Rule Set"><i class="fas fa-trash-alt clickable-text transparent"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2">
                    Parts: {{item.numberOfParts}}
                </div>                
                <div class="col-lg-2">
                    Priority: {{item.Priority}} 
                    <span @click="moveUpPriority" title="Move Up"><i class="fas fa-arrow-up clickable-text transparent"></i></span>&nbsp;
                    <span @click="moveDownPriority" title="Move Down"><i class="fas fa-arrow-down clickable-text transparent"></i></span>
                    <span v-if="listingRulesLoading" style="float:right"><i class="fas fa-sync fa-spin"></i></span>
                </div>
                <div class="col-lg-3 custom-control custom-switch">
                    <div class="float-right">
                        <input @click="toggleShowInResults()" v-model="item.showInResults" type="checkbox" class="custom-control-input" :id="`showListingRule` + item.id">
                        <label class="custom-control-label" :for="`showListingRule` + item.id">Show in Preview</label>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="card-body collapse" :id="'listingRule' + item.id + 'body'">
           <b> I want to list parts from Depot(s): </b>
            <div class="d-flex flex-wrap">
                <div class="p-2 depot-item" v-for="(filter, includesIndex) in includeDepotFilters(item.id)" :key="includesIndex" :title="filter.Values">
                    {{filter.description}}
                    <span @click="removeFilter(`include`, filter)" href="#" title="Remove"><i class="fas fa-minus-circle clickable-text transparent"></i></span>
                </div>
                <div :class="{'depot-item' : true, 'transparent' : !depotDragged, 'drop-target' : depotDragged, 'drop-area-text' : true}">
                    <ib-drop @drop="move" :disabled="!depotDragged" :name="`include`">
                        <div>
                            drop filters here
                        </div>
                    </ib-drop>
                </div>
            </div>

            <b>Date filters:</b>
            <div class="d-flex flex-wrap">
                <div class="p-2 time-filter-item" v-for="(filter, includesIndex) in includeTimeFilters(item.id)" :key="includesIndex" :title="filter.Values">
                    {{filter.description}}
                    <span @click="removeFilter(`include`, filter)" href="#" title="Remove"><i class="fas fa-minus-circle clickable-text transparent"></i></span>
                </div>
                <div :class="{'time-filter-item' : true, 'transparent' : !timeFilterDragged, 'drop-target' : timeFilterDragged, 'drop-area-text' : true}">
                    <ib-drop @drop="move" :disabled="!timeFilterDragged" :name="`include`">
                        <div>
                            drop filters here
                        </div>
                    </ib-drop>
                </div>
            </div>

            <b>Which have:</b>
            <div class="d-flex flex-wrap">
                <div class="p-2 filter-item" v-for="(filter, includesIndex) in includeNonPartFilters(item.id)" :key="includesIndex" :title="filter.Values">
                    {{filter.description}}
                    <span @click="removeFilter(`include`, filter)" href="#" title="Remove"><i class="fas fa-minus-circle clickable-text transparent"></i></span>
                </div>
                <div :class="{'filter-item' : true, 'transparent' : !filterDragged, 'drop-target' : filterDragged, 'drop-area-text' : true}">
                    <ib-drop @drop="move" :disabled="!filterDragged" :name="`include`">
                        <div>
                            drop filters here
                        </div>
                    </ib-drop>
                </div>
            </div>
            <b>Excluding:</b>
            <div class="d-flex flex-wrap">
                <div class="p-2 filter-item" v-for="(filter, excludeIndex) in excludeNonPartFilters(item.id)" :key="excludeIndex" :title="filter.Values">
                    {{filter.description}}
                    <span @click="removeFilter(`exclude`, filter)" href="#" title="Remove"><i class="fas fa-minus-circle clickable-text transparent"></i></span>
                </div>
                <div :class="{'filter-item' : true, 'transparent' : !filterDragged, 'drop-target' : filterDragged, 'drop-area-text' : true}">
                <ib-drop @drop="move" :disabled="!filterDragged" :name="`exclude`">
                    <div>
                        drop filters here
                    </div>
                </ib-drop>
                </div>
            </div>
            <b>Priced At:</b>
            <div class="d-flex flex-row">
            <div class="p-2 price-modifier-item">{{getPriceModifierForListingRule(item.id)}}</div>
                <div :class="{'price-modifier-item' : true, 'transparent' : !pricingDragged, 'drop-target' : pricingDragged, 'drop-area-text' : true}">
                    <ib-drop @drop="move" :disabled="!pricingDragged" :name="`priceModifier`">
                        <div>
                            replace price modifier
                        </div>
                    </ib-drop>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import IbDrag from '@/components/utilities/IbDrag';
import IbDrop from '@/components/utilities/IbDrop';
import { 
    mapGetters,
    mapActions,
    mapState
}       from 'vuex';

export default {
    name: 'ListingRule',
        
    components: {
        IbDrag,
        IbDrop
    },

    props: {

        /**
         * The item to display.
         */
        item: {
            type:     Object,
            required: true
        },
        pricingDragged: {
            type:     Boolean,
            required: true
        },
        timeFilterDragged: {
            type:     Boolean,
            required: true
        },
        filterDragged: {
            type:     Boolean,
            required: true
        },
        depotDragged: {
            type: Boolean,
            required: true
        },
        uiDisabled: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            editingListingRule : false,
            listingRuleDescription : null,
            isNew : false,
            isExpanded : false,
        }
    },
    computed: {
      ...mapGetters('marketplacerules', {
          includeNonPartFilters          : 'includeNonPartFilters',
          includeTimeFilters             : 'includeTimeFilters',
          includeDepotFilters            : 'includeDepotFilters',
          excludeNonPartFilters          : 'excludeNonPartFilters',
          listingRulesError              : 'listingRulesError',
          listingRulesLoading            : 'listingRulesLoading',
          getPriceModifierForListingRule : 'getPriceModifierForListingRule',
      }),
    },

    created() {
        if (this.item.isNew)
            this.editListingRule();
    },

    methods: {
        ...mapActions('marketplacerules', {
          updateListingRule         : 'updateListingRule',
          removeFilterFromListingRule : 'removeFilterFromListingRule',
          addFilterToListingRule      : 'addFilterToListingRule',
          deleteListingRule         : 'deleteListingRule',
          renameListingRule         : 'renameListingRule',
          changeListingRulePriority : 'changeListingRulePriority',
          toggleListingRuleShowInResults : 'toggleListingRuleShowInResults'
        }),
         toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Rules Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },
        editListingRule() {
            this.listingRuleDescription = this.item.description;
            this.editingListingRule = true;
        },
        async toggleShowInResults(item)
        {
            await this.toggleListingRuleShowInResults({'listingRuleId' : this.item.id});
        },
        async removeListingRule(item){
            let desc = item.description;
            if (confirm("Are you sure you wish to delete listing rule -" + desc)) {
                await this.deleteListingRule(item.id);
                await this.$emit('listingRuleChanged');
                 if (this.listingRuleError == null || this.listingRuleError == undefined) {
                        this.toast('b-toaster-top-center', 'success', `${desc} listing rule removed.`, true);
                    }
                    else {
                        this.toast('b-toaster-top-center', 'danger', `Error removing listing rule: ${desc}.`, true);
                    }
                this.editingListingRule = false;
            }
        },
        changeDescription() {
            this.renameListingRule({'listingRuleId' : this.item.id, 'description' : this.listingRuleDescription});
            if (this.listingRuleError == null || this.listingRuleError == undefined) {
           
                this.toast('b-toaster-top-center', 'success', `${this.listingRuleDescription} listing rule Renamed.`, true);
            }
            else {
                this.toast('b-toaster-top-center', 'danger', `Error renaming listing rule: ${this.listingRuleDescription}.`, true);
            }
            this.editingListingRule = false;
            this.$emit('listingRuleChanged');
        },
        move(e) {
            let parameters = {'listingRuleId': this.item.id, 'list': e.dropTarget, 'filterId': e.id, 'filterType' : e.filterType};
            this.addFilterToListingRule(parameters);
            this.$emit('listingRuleChanged');
        },
        removeFilter(list, filter) {
            let parameters = {'listingRuleId': this.item.id, 'list': list, 'filter': filter};
            this.removeFilterFromListingRule(parameters);
            this.$emit('listingRuleChanged');
        },
        moveUpPriority() {
            document.body.style.cursor = "wait";
            let parameters = {'listingRuleId' : this.item.id, 'direction' : 'up'}
            this.changeListingRulePriority(parameters);
            this.$emit('scrollTo', 'listingRule' + this.item.id);
            document.body.style.cursor = "default";
        },
        moveDownPriority() {
            document.body.style.cursor = "wait";
            let parameters = {'listingRuleId' : this.item.id, 'direction' : 'down'}
            this.changeListingRulePriority(parameters);
            this.$emit('scrollTo', 'listingRule' + this.item.id);
            document.body.style.cursor = "default";
        }
    }
     
}
</script>
<style lang="less" scoped>

.filter-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #397CFC;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.time-filter-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #7D52A0;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.depot-item {
     background-color: #f9f9f9;
    border-left: .3rem solid #008080;
    margin-bottom:    .1rem;
    padding:          .5rem;
}

.price-modifier-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #4BB74D;
    margin-bottom:    .1rem;
    padding:          .5rem;
    
}

.drop-target {
    font-size: 25px;
    padding: 0px;
    margin: 0px;
	-webkit-animation: pulsate-fwd 0.5s ease-in-out infinite both;
	        animation: pulsate-fwd 0.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-1 11:39:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.drop-area-text {
    color: #ffffff;
}

.transparent {
    opacity:0.3;
    z-index: 9;

}

.clickable-text {
    cursor: pointer;
}

.stickyHeader {
    position: sticky; 
    top: 0;
    background-color: #6C757D;
        
        a, a:hover, a:visited, a:active {
            color: black;
            text-decoration: none;
        }
    overflow: hidden;
    z-index: 10;
}

.card-header {
    background-color: #F0F0F0;
    color: black;
}

</style>