import Vue    from 'vue';
import App    from './App.vue';
import router from './router';
import store  from './store';
import filter from './filters';
import VueCookies from 'vue-cookies';

import ApiService from './common/api-service';

// Setup Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// Setup Bootstrap-Vue
import BootstrapVue from 'bootstrap-vue';

Vue.use(BootstrapVue);

import 'bootstrap-vue/dist/bootstrap-vue.css';

// Polyfill Web Animations API (WAAPI)
import 'web-animations-js';

//Setup vue2-particles
import Particles from "vue2-particles";
Vue.use(Particles);

// Setup Moment.js
import VueMoment from 'vue-moment';

Vue.use(VueMoment);

// Setup Lodash
import lodash from 'lodash';
Object.defineProperty(Vue.prototype, '$_', { value: lodash });

// Setup Validatejs
import validate from 'validate.js';
Object.defineProperty(Vue.prototype, '$validate', { value: validate });

// Configure Font Awesome
import { library }          from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
}                           from '@fortawesome/vue-fontawesome';
import { IconDefinitions }  from '@/assets/js/fontawesome-icons';

library.add(IconDefinitions);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

// Setup lightbox
import 'lightbox2/dist/css/lightbox.css';
import lightbox from 'lightbox2/dist/js/lightbox-plus-jquery.js';
import {SET_IS_ROLE_REQUIRED} from "@/store/mutation-types";

Vue.use(lightbox);

// Setup Axios
ApiService.init();

// Check auth before each page load
router.beforeEach(
    (to, from, next) => {
        return Promise.all([store.dispatch('auth/checkAuth')])
            .then(next);
    }
);

router.beforeEach((to, from, next) => {
  
  if (to.meta.roles
      && to.meta.roles.length) {
    
    let roles = store.getters['auth/getUserRoles'];
    
    let isRolesExist = to.meta.roles.every(value => roles.includes(value));
    if (isRolesExist) {
      store.commit(`system/${SET_IS_ROLE_REQUIRED}`, {isRoleRequired: false})
    } else {
      store.commit(`system/${SET_IS_ROLE_REQUIRED}`, {isRoleRequired: true})
    }
  }
  next()
})

// Log user out when credentials expire
Vue.axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (false === error.config.url.includes('account/login') &&
      error.response && error.response.status === 401)
    {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  }
);

// Setup Cookies
Vue.use(VueCookies);
// Turn off production tip displayed in the console
Vue.config.productionTip = false;

const v = new Vue({
  router,
  store,
  render: h => h(App)
});

router.onReady(() => {v.$mount('#app');});
