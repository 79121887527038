const NOT_VALIDATED_YET         = 1000;
const NO_WHOLEGOOD_FOUND        = 2010;
const MULTIPLE_WHOLEGOODS_FOUND = 2020;
const NO_CUSTOMER_FOUND         = 2030;
const NO_INVOICE_TYPE           = 2040;
const CUSTOMER_NOT_EXIST        = 2050;
const VALIDATED                 = 3000;

const ValidationStatus = {
    NOT_VALIDATED_YET,
    NO_WHOLEGOOD_FOUND,
    MULTIPLE_WHOLEGOODS_FOUND,
    NO_CUSTOMER_FOUND,
    NO_INVOICE_TYPE,
    CUSTOMER_NOT_EXIST,
    VALIDATED
};

const ValidationStatusStrings = {
    1000 : 'Not Validated Yet',
    2010 : 'No Wholegood found for Serial Number',
    2020 : 'Multiple Wholegoods found for Serial Number',
    2030 : 'No Customer Number for Wholegood',
    2040 : 'No Invoice Type found',
    2050 : 'Customer number not found in Gold',
    3000 : 'Validated'
};

const ValidationSuggestionStrings = {
    1000 : '',
    2010 : '',
    2020 : 'Please check wholegood enquiry for the same serial number.  Add a - to the serial number for previously sold wholegoods, then refresh this page.',
    2030 : 'Please correct wholegood using amend wholegood.  Enter a customer and save changes, then refresh this page.',
    2040 : '',
    2050 : 'The customer ID on the request does not exist in Gold: ',
    3000 : ''
};


const JobRequestValidationStatus = {
    methods: {
        getStatusForId(value) {
            let statusName = ValidationStatusStrings[value];
            if(undefined === statusName){
                statusName = "Unknown";
            }
            return statusName;
        },
        getStatusSuggestionForId(value) {
            let suggestionName = ValidationSuggestionStrings[value.ValidationStatusId];
            if (value.ValidationStatusId == 2050) {
                suggestionName= "The customer " + value.CustomerId + " associated with wholegood " + value.WholegoodId + " does not exist";
            }
            if(undefined === suggestionName){
                suggestionName = "Unknown";
            }
            return suggestionName;
        }
    }
};

export default JobRequestValidationStatus;