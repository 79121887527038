<template>
<div
    ref="quickbar"
    class="quickbar bg-dark"
>
    <div class="d-flex flex-column justify-content-center h-100">
        <div
            class="logo no-text-select"
        >
            <img class="align-self-start" src="@/assets/img/ibcos-logo.png">
        </div>

        <div
            class="module-links d-flex flex-column align-items-center flex-grow-1"
        >
            <quickbar-button
                v-for="(module, index) in modules"
                :key="index"
                :icon="getModuleIcon(module)"
                :active="true === isActiveModule(module)"
                @click="moduleClicked(module)"
            />
        </div>

        <div class="system-links d-flex flex-column align-items-center">
            <quickbar-button
                :icon="[ 'fas', 'cog' ]"
                :active="isSettingsOpen"
                @click="openSettingsMenu"
            />

            <quickbar-button
                :icon="[ 'fas', 'user' ]"
                :active="isAccountOpen"
                :disabled="isAccountOpen"
                @click.stop="openAccountMenu"
            />
        </div>
    </div>
</div>
</template>

<script>
import { get as _get }  from 'lodash-es';
import {
    mapState,
    mapMutations
}                       from 'vuex';
import * as mutation    from '@/store/mutation-types';
import QuickbarButton   from './QuickbarButton';

export const QUICKBAR_WIDTH = '60px';

export default {
    components: {
        QuickbarButton,
    },

    mounted() {
        let moduleName = this.$router.currentRoute.fullPath.split('/')[1];
        let moduleNameParsed = moduleName.charAt(0).toUpperCase() + moduleName.slice(1);
        this.setActiveModule({ module: moduleNameParsed });
    },

    watch: {
        '$route' () {
            let moduleName = this.$router.currentRoute.fullPath.split('/')[1];
            let moduleNameParsed = moduleName.charAt(0).toUpperCase() + moduleName.slice(1);
            this.setActiveModule({ module: moduleNameParsed });
        }
    },

    computed: {
        ...mapState('core', {
            isSidebarOpen   : state => state.sidebar.open,

            isSettingsOpen  : state => state.settingsMenu.open,

            isAccountOpen   : state => state.accountMenu.open,

            activeModule    : state => state.quickbar.module,
        }),

        modules() {
            const routes  = this.$router.options.routes;
            const modules = routes.filter(route => true === route.meta.module);

            return modules;
        },
    },

    methods: {
        ...mapMutations('core', {
            toggleSidebarOpen   : mutation.TOGGLE_SIDEBAR_MENU,

            openSettingsMenu    : mutation.OPEN_SETTINGS_MENU,

            openAccountMenu     : mutation.OPEN_ACCOUNT_MENU,

            setActiveModule     : mutation.SET_ACTIVE_MODULE,
        }),

        getModuleName(module) {
            const name = _get(module, 'name', null);
            return name;
        },

        getModuleIcon(module) {
            const icon = _get(module, 'meta.menu.icon');
            return icon;
        },

        isActiveModule(module) {
            const moduleName    = this.getModuleName(module);
            const isActive      = this.activeModule === moduleName;
            return isActive;
        },

        moduleClicked(module) {
            const moduleName = this.getModuleName(module);
            this.setActiveModule({ module: moduleName });
            // Open sidebar if closed.
            if (false === this.isSidebarOpen) {
                this.toggleSidebarOpen();
            }
        },
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/colours.less';
@import '~@/assets/css/imports.less';

.quickbar {
    min-width   : @quickbar-width;
    max-width   : @quickbar-width;

    .logo {
        padding     : 11px;

        img {
            width   : 38px;
            height  : 38px;
        }
    }

    .module-links {
        padding : 10px 0px;

        :last-child {
            margin-bottom   : 0px;
        }
    }

    .system-links {
        padding : 10px 0px;

        :last-child {
            margin-bottom   : 0px;
        }
    }
}
</style>
