export const CHECKLIST_TEMPLATES_LOADING     = 'CHECKLIST_TEMPLATES_LOADING';
export const CHECKLIST_TEMPLATES_ERROR       = 'CHECKLIST_TEMPLATES_ERROR';

export const CLEAR_CHECKLIST_TEMPLATES       = 'CLEAR_CHECKLIST_TEMPLATES';
export const CLEAR_CHECKLIST_TEMPLATES_ERROR = 'CLEAR_CHECKLIST_TEMPLATES_ERROR';

export const SET_CHECKLIST_TEMPLATES         = 'SET_CHECKLIST_TEMPLATES';

export const SET_CHECKLIST_TEMPLATE          = 'SET_CHECKLIST_TEMPLATE';

export const CLEAR_CHECKLIST_TEMPLATE        = 'CLEAR_CHECKLIST_TEMPLATE';

export const CREATE_CHECKLIST_TEMPLATE       = 'CREATE_CHECKLIST_TEMPLATE';

export const UPDATE_CHECKLIST_TEMPLATE       = 'UPDATE_CHECKLIST_TEMPLATE';

export const DELETE_CHECKLIST_TEMPLATE       = 'DELETE_CHECKLIST_TEMPLATE';
