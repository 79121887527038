import Vue              from 'vue';
import Vuex             from 'vuex';
import * as mutation    from '@/store/mutation-types';

// Import auto loaded modules.
import modules          from './modules';

Vue.use(Vuex);

const rootActions = {
    /**
     * Calls the reset mutation of all registered store
     * modules.
     */
    resetAppState({ commit }) {
        for (const moduleName in modules) {
            const module = modules[moduleName];
            if (module.mutations.hasOwnProperty(mutation.RESET)) {
                commit(`${moduleName}/${mutation.RESET}`);
            }
        }
    },
};

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules,
    actions : rootActions,
    // Only use strict mode in development!
    strict  : debug
});
