const SCHEDULED   = 1;
const IN_PROGRESS = 2;
const LOGGED      = 3;
const APPROVED    = 4;
const DELETED     = 999;

const JobActivityStatus = {
    SCHEDULED,
    IN_PROGRESS,
    LOGGED,
    APPROVED,
    DELETED
};

export { JobActivityStatus };
