import ApiService from '@/common/api-service';

// Temporarily hardcoded the company as company 1.
const TRANSACTION_ROOT = "api/v1/marketplace";

const TransactionApi = {
	acceptTransaction(transaction) {
		const url = `${TRANSACTION_ROOT}/process_seller_advice`
		return ApiService.post(url, [transaction]);
	},
};

export default TransactionApi;
