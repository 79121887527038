import { get as _get }  from 'lodash-es';
import { set, reset }   from '../mutators';
import * as mutation    from '../mutation-types';
import {
    filter as _filter,
    map    as _map,
}                       from 'lodash';
import { hubConnection } from 'signalr-no-jquery';
import ApiService from '@/common/api-service';

const initialState = () => ({	
    goldlocked: {
        IsLocked : false,
        Reason : ""
    }
});

const getters = {
	goldlocked: state => {
        return state.goldlocked.IsLocked;
    },
    goldlockedreason : state => {
        return state.goldlocked.Reason
    }
}

const mutations = {
    [mutation.SET_GOLDLOCKED                         ] : set('goldlocked.IsLocked',                        'IsLocked'           ),
    [mutation.SET_GOLDLOCKEDREASON                         ] : set('goldlocked.Reason',                        'Reason'           )
}

const actions = {
    startmessageQueue( { dispatch, commit} )
    {
        let connection = hubConnection(`${process.env.VUE_APP_API_BASE_URL}signalr`);
        const hubProxy = connection.createHubProxy('GoldPortalHub');

        hubProxy.on('GoldLockedMessage', function(NewIsLocked,  NewReason) 
        {
            console.log("message received  -  NewIsLocked: " + NewIsLocked + " NewReason:" + NewReason);

            commit(mutation.SET_GOLDLOCKED, { IsLocked: NewIsLocked });
            commit(mutation.SET_GOLDLOCKEDREASON, { Reason: NewReason });
            if (NewIsLocked == false)
            {
                //gold api has signalled to this session that gold has become unlocked. 
                //completely re-load the page to pick up and changes to the app or data.
                window.location.reload();
            }
        });
     
         connection.disconnected(function () {
             console.log('message queue disconnected');
             setTimeout(function() {
                dispatch("startconnection", connection); 
            }, 5000); // Restart connection after 5 seconds.

         });

         connection.reconnected(
            function () {
                console.log('message queue re-connected');
                dispatch("getlockedstatus");
            }

         );
     
         dispatch("startconnection", connection);
         console.log("connected to message queue");
     
    },
    async startconnection( {dispatch, commit }, connection) 
    {

        let connected = false;
        do {
            try
            {
                await connection.start()
                .done(function(){ connected = true; console.log('message queue, connection ID=' + connection.id); dispatch("getlockedstatus"); })
                .fail(function(){ connected = false; console.log('failed to connect message queue'); });
            }
            catch (e) {
                console.log("exception connecting to message queue " + JSON.stringify(e));
                connected = false;
            }
            console.log("connected " + connected);
    } while (!connected);
    
    },
    async getlockedstatus({dispatch, commit })
    {
        let response = await ApiService.get("/api/v1/GoldRest/Utilities/GoldLockedStatus");

        if (response?.data?.success == true)
        {
            commit(mutation.SET_GOLDLOCKED, { IsLocked: response.data.data.IsLocked });
            commit(mutation.SET_GOLDLOCKEDREASON, { Reason: response.data.data.Reason });
        }
        console.log("get locked return status=" + response?.data?.success + " is locked=" + response?.data?.data?.IsLocked + " reason=" + response?.data?.data?.Reason);

    }
}

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
};