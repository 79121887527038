<template>
    <div :id="category" class="collapse" role="tabpanel" :aria-labelledby="category">
        <ib-drag :transferData="{
            id              : item.id,
            type            : item.priceType,
            name            : item.description
            }"
            :enabled="true"
            @dragEnd="dragEnd">
            <div :test-data="item.description" :test-data-id="'filter-7-'+item.id"  class="price-modifier-item card-body" v-on:mousedown="dragged(-1)" v-on:mouseup="dragEnd">
                <div class="row" :title="itemDesc">
                    <div class="col-lg-10">
                        {{item.description}}
                    </div>
                    <div v-if="item.id != 1" class="col-lg-1" @click="editModifier(item)">
                        <span :test-data="item.description+'edit'"  title="Edit Filter"><i class="fas fa-pencil-alt clickable-text transparent"></i></span>
                    </div>
                </div>
            </div>
        </ib-drag>
    </div>
</template>

<script>
import IbDrag from '@/components/utilities/IbDrag';
import IbDrop from '@/components/utilities/IbDrop';

/**
 * Component representing an indivicdual item within the 
 * Kanban board. Providing slots for customisation and events
 * for capturing when an item is clicked.
 */
export default {
    name: 'PriceModifier',
        
    components: {
        IbDrag,
        IbDrop
    },

    props: {

        /**
         * The item to display.
         */
        item: {
            type:     Object,
            required: true
        },
        category: {
            type:       String,
            required:   true
        }

    },

   computed: {
       itemDesc() {
           let name = ""
            if (this.item.priceType == 1) {
                name = "Dealer Nett";
            }
            else if (this.item.priceType == "2") {
                name = "RRP";
            }
            else if (this.item.priceType == "3") {
                name = "Cost";
            }
                
            if (this.item.amount < 1)
            {
                name += ' Minus ';
                name += (100 - Math.abs(this.item.amount * 100).toFixed(8)).toString();
            }
            else 
            {
                name += ' Plus ';
                name += (Math.abs(this.item.amount * 100).toFixed(8) - 100).toString();
            } 
            return name + '%';
        }
   },

    methods: {

        /**
         * Event handler for the drop event.
         * 
         * Emits a 'move' event attaching information about this item.
         */
        move(data) {            
            if (!this.readonly) {
                this.$emit('move', { 
                    toItemIndex   : this.index,
                    ...data
                });
            }
        },
        dragged(type){
            this.$emit('dragged',type);
        },
        dragEnd() {
            this.$emit('dragEnd');
        },
        editModifier(filter) {
            filter.filterType = 7;
            this.$emit('editFilter',filter);
        },
        deleteModifier(filter) {
            filter.filterType = 7;
            this.$emit('deleteFilter',filter);
        },

    }
}
</script>

<style lang="less" scoped>

.price-modifier-item {
    background-color: #f9f9f9;
    border-left: .3rem solid #4BB74D;
    margin-bottom:    .1rem;
    padding:          .5rem;
}
.transparent {
    opacity:0.3;
}

.clickable-text {
    cursor: pointer;
}
</style>