import ApiService from '@/common/api-service';

const COMPANY_ROOT = "api/v1/company"

const CompanyApi = {
    getAllCompanies() {
        const url = `${COMPANY_ROOT}/all`;
        return ApiService.get(url);
    },
    getSyncedCompanies() {
        const url = `${COMPANY_ROOT}/synced`;
        return ApiService.get(url);
    },
    getCompany(id) {
        const url = `${COMPANY_ROOT}/${id}`;
        return ApiService.get(url);
    },
    getInfo() {
        const url = `info`;
        return ApiService.get(url);
    }
};

export default CompanyApi;