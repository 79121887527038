import ApiService from '@/common/api-service';

const IVT_ROOT = "api/v1/workshop/invoicetype";

const InvoiceTypeApi = {
    
    ///
    /// GET
    ///

    /**
     * Search invoice types for the term specified.
     * @param {string} term - The search term.
     */
    searchInvoiceTypes(term) {
        const url = `${IVT_ROOT}/search/${term}`;
        return ApiService.get(url);
    },

    /**
     * Get list of invoice types and invoice names.
     */
    getInvoiceTypeNames() {
        const url = `${IVT_ROOT}/names`;
        return ApiService.get(url);
    },

    /**
     * Get the invoice type matching the ID specified.
     * @param {string} id 
     */
    getInvoiceTypeById(id) {
        const url = `${IVT_ROOT}/${id}`;
        return ApiService.get(url);
    }

};

export default InvoiceTypeApi;