const DATE_TIME                 = 'DD/MM/YYYY hh:mma';
const DATE_TIME_NO_LEADING_ZERO = 'DD/MM/YYYY h:mma';
const DATE_TIME_STAMP           = 'DD/MM/YYYY HH:mm:ss';
const DATE_ONLY                 = 'DD/MM/YYYY';
const TIME_ONLY                 = 'hh:mma';
const TIME_STAMP                = 'HH:mm';

const DateFormat = {
    DATE_TIME,
    DATE_TIME_NO_LEADING_ZERO,
    DATE_TIME_STAMP,
    DATE_ONLY,
    TIME_ONLY,
    TIME_STAMP
};

export { DateFormat };
