<template>
<div class="d-flex justify-content-center align-items-center p-2">
	<div class="wrapper">
		<div class="loader"></div>
		<p class="message">{{message}}</p>
	</div>
</div>
</template>

<script>
export default {
	name: 'IbLoadingSpinner',
	
	props: {
		message: null
	}
}
</script>

<style lang="less" scoped>
@import '../assets/colours';

.loader {
	border: 10px solid @grey;
	border-top: 10px solid @gold;
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

.message {
	text-align: center;
	margin-top: 10px;
}

.wrapper {
	line-height: 100%;
	vertical-align: middle;
}

@keyframes spin {
	0%   { transform: rotate(0deg);   }
	100% { transform: rotate(360deg); }
}
</style>
