import { render, staticRenderFns } from "./OrderCard.vue?vue&type=template&id=1515369e&scoped=true"
import script from "./OrderCard.vue?vue&type=script&lang=js"
export * from "./OrderCard.vue?vue&type=script&lang=js"
import style0 from "./OrderCard.vue?vue&type=style&index=0&id=1515369e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1515369e",
  null
  
)

export default component.exports