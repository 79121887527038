<template>
    <div>
        <b-form-select v-model="selectedRate"
                       :class="{ 'inline-picker': small }"
                       :options="options"/>
    </div>
</template>

<script>

/**
 * Component for selecting a labour rate. Displays
 * the labour rate number and the description of
 * labour rate.
 */
export default {
    name: 'LabourRateList',

    props: {

        labourCategories: {
            type    : Array,
            required: true
        },

        small: {
            type    : Boolean,
            default : false
        }

    },

    data() {
        return {

            RATE_CHANGED: 'rateChanged',
            AUTOMATIC:    'AUTOMATIC',
            selectedRate: null

        }
    },

    created() {
        this.selectedRate = "AUTOMATIC";
    },

    methods: {

        /**
         * Taking the rate description and index; format
         * the rate in '{index} - {rate}' pattern.
         * @param rate - the labour rate description.
         * @param index - the index of the labour rate (between 1 - 12).
         */
        formatRate(rate, index) {
            let formattedRate;

            if (rate) {
                formattedRate = `${index + 1} - ${rate}`;
            }

            return formattedRate;
        }

    },

    computed: {

        /**
         * The labour rates formatted for the picker.
         */
        options() {
            let categories = _.map(this.labourCategories, (category, index) => {
                return this.formatRate(category.Description, index);
            });
            categories     = _.compact(categories);
            categories.unshift(this.AUTOMATIC);
 
            return categories;
        }

    },

    watch: {

        /**
         * Event handler for when the selected rate is changed.
         */
        selectedRate(newValue, oldValue) {
            let rate;

            if (this.AUTOMATIC != newValue) {
                rate = newValue.substring(0, newValue.indexOf(' '));
            }
            
            this.$emit(this.RATE_CHANGED, rate);
        }

    }
}
</script>

<style scoped lang="less">

.inline-picker {
    padding-top:    0.1rem;
    padding-bottom: 0.1rem;
    font-size:      90%;
    height:         1.75rem !important;
    font-weight:    600;
    margin-top:     -5px;
}

</style>