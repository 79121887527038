<template>
    <div class="invoice-lookup">

        <!-- Input -->
        <div class="input-group">
            <input ref="input"
                type="text"
                class="form-control"
                :class="{ 'invalid': false === item.valid }"
                v-model="item.display"
                :state="item.valid"
                @keydown.enter="getInvoiceType"
                @blur="getInvoiceType"
                @keydown.question="openModal" 
                @focus="$event.target.select()"/>
            <div class="input-group-append">
                <button type="button"
                        class="btn ib-append-button"
                        tabindex="-1"
                        @click="openModal">
                        <div v-show="!item.searching">
                            <i class="fas fa-search"/>
                        </div>
                        <div v-show="item.searching">
                            <i class="fas fa-sync-alt fa-spin"/>
                        </div>
                </button>
            </div>
        </div>

        <!-- Error -->
        <div>
            <b class="error"
            :class="{ 'show-error' : false === item.valid }">
            
                {{invalidMessage}}
            <!-- <span v-if="formInvalidMessage !== null">
                {{formInvalidMessage}}                
            </span>
            <span v-else>
                Invalid invoice type.                            
            </span> -->
            </b>
        </div>

        <!-- Modal -->
        <b-modal v-model="showModal"
                 class="lookup-modal"
                 size="lg"
                 title="Invoice Type Lookup"
                 @ok="handleOk"
                 @hidden="clear"
                 @shown="focusInput">

            <!-- Search Box -->
            <div class="search">
                <div class="input-group">
                    <input ref="search"
                           type="text"
                           class="form-control"
                           placeholder="Enter search text"
                           v-model="filter">
                    <div class="input-group-append">
                        <button type="button"
                                class="btn btn-secondary rounded-right-borders"
                                :disabled="!filter"
                                @click="clear">Clear</button>
                    </div>
                </div>
            </div>

            <!-- Results -->
            <div class="table">
                <ib-table :items="table.items"
                          :columns="table.columns"
                          :noItemsMessage="messages.noResult"
                          :itemsPerPage="10"
                          :small="true"
                          :loading="table.searching"
                          :loadingMessage="messages.searching"
                          v-model="table.selectedItem"/>
            </div>
        </b-modal>                 
    </div>
</template>

<script>
import IbLookupBase   from '@/components/form/IbLookupBase';
import IbTable        from '@/components/table/IbTable';
import InvoiceTypeApi from '@WS/api/invoice-type';

const TABLE_COLUMNS = [
    {
        heading:  'Type',
        property: 'Type',
        sortable: true,
        class:    'typeCol'
    },
    {
        heading:  'Description',
        property: 'Desc',
        sortable: true
    },
    { 
        heading: 'Program Code',
        property: 'ProgramCodeDescription',
        sortable: true
    }
];

const FILTERED_PROPERTIES = [
    'Type',
    'Desc'
];

export default {
    name: 'IbInvoiceTypeLookup',

    extends: IbLookupBase,

    components: {
        IbTable
    },

    props: {
        
        value: {
            required: true,
            default: null
        },
        formValid: {
            required: false,
            default: true
        },
        formInvalidMessage: {
            required: false,
            default: null
        }
    },

    mounted() {
        this.table.columns          = TABLE_COLUMNS;
        this.table.filterProperties = FILTERED_PROPERTIES;
    },

    data() {
        return {
            invalidMessage: ""
        }
    },

    methods: {
        
        /**
         * Focus the input control.
         */
        focusEntry() {
            this.$refs.input.focus();
        },

        /**
         * Get the formatted name of the invoice type specified.
         * Returning empty string if no invoice type.
         */
        getInvoiceTypeName(invoiceType) {
            let name = null;

            if (false === _.isEmpty(invoiceType)) {
                name = `${invoiceType.Type} - ${invoiceType.Desc}`;
            }

            return name;
        },

        /**
         * Get the invoice type specified. Assumes invoice type ID is being entered
         * and will attempt to extract from what is entered. Before calling API endpoint
         * that will try to get the invoice type with the matching ID.
         */        
        async getInvoiceType() {
            this.item.searching = true;
            let invoiceType     = null;
            let invoiceTypeId   = this.item.display;

            if (!invoiceTypeId) {
                //fail fast.
                this.item.searching = false;
                this.item.valid     = false;
                return;
            }

            if (-1 != invoiceTypeId.indexOf(' ')) {
                invoiceTypeId = invoiceTypeId.substr(0, invoiceTypeId.indexOf(' '));
            }

            try {
                const searchResponse = await InvoiceTypeApi.getInvoiceTypeById(invoiceTypeId);
                this.item.valid = false;
                this.invalidMessage = "Invalid invoice type.";
                if (true === searchResponse.data.success) {
                    this.item.valid = this.formValid;
                    this.item.value = searchResponse.data.data;
                    this.invalidMessage = this.formInvalidMessage;
                }
            }
            finally {
                this.item.searching = false;
            }
        },

        /**
         * Search invoice types. Search against their ID and description.
         */
        async search() {
            this.table.searching    = true;
            this.table.items        = [];
            this.table.selectedItem = null;
            
            try {
                const searchResponse    = await InvoiceTypeApi.searchInvoiceTypes(this.filter);
                let invoiceTypes        = searchResponse.data.data;
                invoiceTypes            = _.map(invoiceTypes, _.partialRight(_.pick, ['Type', 'Desc', 'FromCus', 'ToCus', 'ProgramCodeDescription' ]));
                this.table.items = invoiceTypes;
            }
            catch (error) {
                this.table.error = error.message;
            }
            finally {
                this.table.searching = false;
            }
        }

    },

    watch: {

        formInvalidMessage(newValue) {
            this.invalidMessage = newValue;
        },

        value(newValue) {
            if (newValue) {
                this.item.valid = this.formValid;
            }
            this.item.value = newValue;
        },

        "item.value"(newValue) {
            this.item.display = this.getInvoiceTypeName(this.item.value);
            this.$emit('input', this.item.value);
        },

        formValid(newValue) {
            this.item.valid = newValue;
        },

        "item.valid"(newValue) {
            this.$emit('valid', this.item.valid);
        }

    }

}
</script>

<style lang="less" scoped>

.error {
    color: red;
    visibility: hidden;
}

.show-error {
    visibility: visible !important;
}

.invalid {
    border-style: solid;
    border-width: thin;
    border-color: red;
}

.lookup-modal {
    .search {
        min-height: 60px;
    }

    .table {
        min-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

/deep/ .typeCol {
    width: 75px;
}

</style>