<template>
<button type="button"
    class="btn"
    :class="[buttonStyleClass, buttonSizeClass]"
    @click="navigateBack">
    <i class="fas fa-angle-left fa-lg"></i>&nbsp;
    <slot>Back</slot>
</button>
</template>

<script>
export default {
    name: 'IbBackButton',

    props: {
		buttonStyle: {
            type:       String,
            default:    'dark',
            validator(value) {
                // The value must match one of these strings.
                return [
                    'primary',
                    'secondary',
                    'success',
                    'danger',
                    'warning',
                    'info',
                    'light',
                    'dark'
                ].indexOf(value) !== -1
            }
        },
        size: {
            type:       String,
            default:    '',
            validator(value) {
                // The value must match one of these strings.
                return [
                    '',
                    'sm',
                    'lg'
                ].indexOf(value) !== -1
            }
        }
    },
    
    computed: {
        /**
         * Returns a Bootstrap button style class.
         */
        buttonStyleClass() {
            const styleClass = `btn-${this.buttonStyle}`;
            return styleClass;
        },

        /**
         * Returns a Bootstrap button size class.
         */
        buttonSizeClass() {
            let sizeClass = `btn-${this.size}`;
            if ('' === this.size) {
                sizeClass = this.size;
            }
            return sizeClass;
        }
    },

    methods: {
        /**
         * Navigate back one step in the history stack.
         * @see {@link https://router.vuejs.org/guide/essentials/navigation.html#router-go-n}
         */
        navigateBack() {
            this.$router.go(-1);
        }
    }
}
</script>
