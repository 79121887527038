export const SET_REQUEST_SUMMARIES  = 'SET_REQUEST_SUMMARIES';
export const REMOVE_REQUEST_SUMMARY = 'REMOVE_REQUEST_SUMMARY';
export const SET_REQUESTS           = 'SET_REQUESTS';
export const SET_REQUESTS_LOADING   = 'SET_ENGINEERS_LOADING';
export const SET_REQUESTS_ERROR     = 'SET_REQUESTS_ERROR';
export const SET_SELECTED_WHOLEGOOD = 'SET_SELECTED_WHOLEGOOD';
export const SET_SELECTED_WHOLEGOOD_OBJ = 'SET_SELECTED_WHOLEGOOD_OBJ';
export const SET_SELECTED_CUSTOMER  = 'SET_SELECTED_CUSTOMER';
export const SET_SELECTED_REQUEST   = 'SET_SELECTED_REQUEST';
export const REMOVE_REQUEST         = 'REMOVE_REQUEST';
export const SET_PRIORITIES         = 'SET_PRIORITIES';
export const SET_PRIORITIES_LOADING = 'SET_PRIORITIES_LOADING';
export const SET_PRIORITIES_ERROR   = 'SET_PRIORITIES_ERROR';
