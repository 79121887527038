import ApiService from '@/common/api-service';

// Temporarily hardcoded the company as company 1.
const DEPOT_ROOT = "api/v1/company";

const depotApi = {
	getAllDepots() {
		const url = `${DEPOT_ROOT}/current/depot`
		return ApiService.get(url);
	},

	getAllDepotsForCompany(id) {
		if (!id) {
			id = "current";
		}
		const url = `${DEPOT_ROOT}/${id}/depot`
		return ApiService.get(url);
	}
};

export default depotApi;
