import ApiService from '@/common/api-service';

const PARTS_ROOT = "api/v1/parts";

const PartsApi = {
    ///
    /// GET Requests
    ///
    /**
     * Get all part prefixes.
     */
    getPrefixes() {
        const url = `${PARTS_ROOT}/prefixes`;
        return ApiService.get(url);
    },

    getMemoParts() {
        const url = `${PARTS_ROOT}/memoparts`;
        return ApiService.get(url);
    },

    ///
    /// POST Requests
    ///

    ///
    /// PUT Requests
    ///
};

export default PartsApi;
