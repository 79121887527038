export const WorkshopTaskStatus = {
    Created     : 0,
    InProgress  : 10,
    Logged      : 20,
    Completed   : 30
};

export const ROLE = {
    WSJJob: '24a92f6e-c448-4d7b-8aa8-cc41c4b81f65',
    WSJTimeAppro: '3963d649-8d07-4158-b07e-fe361630cc00',
    WSJKanban: '3aa1113c-d3bf-47ce-bf21-42f8fd3f43f3',
    WSJEngineer: '44876276-98df-4b09-b93f-1d8900ff5e33',
    WSJScheduler: '57baf527-55e7-411c-ad0f-074231f01f8f',
    WSJSearch: '6c000de2-58e2-4d85-9725-d8ee913c4b99',
    WSJChecklist: 'a3694309-e4df-4f27-9d83-0a6e650608f7',
    WSJReqests: 'd8c87ad4-c736-4361-bc8f-a00e33032da4',
    CLAASDSMAdmin:'89e7ddc0-1c09-4e57-ae17-ded54254a7f3'
};
