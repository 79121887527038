<template>
<div class="quickbar-button">
    <div
        class="button-container"
        :class="{ 'active' : active }"
        @click="onClick"
    >
        <font-awesome-icon
            v-if="icon"
            :icon="icon"
            size="lg"
            fixed-width
        />
        <span v-else>{{ text }}</span>
    </div>
</div>
</template>

<script>
export default {
    props: {
        icon: {
            type    : Array,
            default : null,
        },

        text: {
            type    : String,
            default : '',
        },

        active      : {
            type    : Boolean,
            default : false,
        },

        disabled    : {
            type    : Boolean,
            default : false,
        },
    },

    methods: {
        onClick(eventArgs) {
            if (true === this.disabled) {
                return;
            }

            this.$emit('click', eventArgs);
        },
    },
}
</script>

<style lang="less" scoped>
@import '~@/assets/css/colours.less';

.quickbar-button {
    padding : 4px;

    .button-container {
        display         : flex;
        justify-content : center;
        align-items     : center;
        width           : 32px;
        height          : 32px;
        color           : rgb(182, 182, 182);
        border-radius   : 50%;
        cursor          : pointer;

        span {
            font-weight : bold;
        }

        &:hover {
            background-color: darken(@ibcos-gold, 8%);
            color           : @charcoal;
        }

        &.active {
            background-color: inherit;
            color           : @ibcos-gold;
        }
    }
}
</style>
