import RulesApi                 from '@MP/api/rules';
import { set, reset, commit }   from '../mutators';
import * as mutation            from '@/store/mutation-types';

const initialState = () => ({	
    productFilters: {
        value   : [],
        loading : false,
        error   : null,
    },
    timeFilters: {
        value   : [],
        loading : false,
        error   : null,
    },
    priceModifiers: {
        value   : [],
        loading : false,
        error   : null,
    },
    listingRules: {
        value   : [],
        loading : false,
        error   : null,
        uiDisabled : false
    },
    preListings: {
        value    : [],
        loading  : false,
        error    : null,
    },
    exclusions: {
        value    : [],
        loading  : false,
        error    : null,
    },
    listingDiff: {
        value   : {addedListings:[], removedListings:[], changedListings:[]},
        loading : false,
        error   : "" 
    },
    currentListings: {
        value   : [],
        loading : false,
        error   : ""
    }
});

const getters = {

                                                                        
//     ,ad8888ba,                                                             
//     d8"'    `"8b              ,d      ,d                                    
//    d8'                        88      88                                    
//    88             ,adPPYba, MM88MMM MM88MMM ,adPPYba, 8b,dPPYba, ,adPPYba,  
//    88      88888 a8P_____88   88      88   a8P_____88 88P'   "Y8 I8[    ""  
//    Y8,        88 8PP"""""""   88      88   8PP""""""" 88          `"Y8ba,   
//     Y8a.    .a88 "8b,   ,aa   88,     88,  "8b,   ,aa 88         aa    ]8I  
//      `"Y88888P"   `"Ybbd8"'   "Y888   "Y888 `"Ybbd8"' 88         `"YbbdP"'  

// https://ascii.co.uk/text

    /**
     * Get all product rules.
     */
    productFilters: state => {
        return state.productFilters.value;
    },

    includeDepotFilters: state => id => {
        let listingRule = _.find(state.listingRules.value, c => c.id === id);
        let includeObjects = _.filter(state.productFilters.value, c => _.includes(listingRule.includeProductFiltersList, c.id));
        let partRules = _.filter(includeObjects, i => i.filterType === 6);
        return partRules;
    },

    includeNonPartFilters: state => id => {

        let listingRule = _.find(state.listingRules.value, c => c.id === id);
        let includeObjects = _.filter(state.productFilters.value, c => _.includes(listingRule.includeProductFiltersList, c.id));
        let nonPartRules = _.filter(includeObjects, i => i.filterType !== 4 && i.filterType !== 5 && i.filterType !== 6);
        return nonPartRules;
    },

    includeTimeFilters: state => id => {
        let listingRule = _.find(state.listingRules.value, c => c.id === id);
        let timeFilters = _.filter(state.timeFilters.value, c => _.includes(listingRule.includeTimeFiltersList, c.id));
        return timeFilters;
    },

    excludeNonPartFilters: state => id => {
        let listingRule = _.find(state.listingRules.value, c => c.id === id);
        let excludeObjects = [];

        excludeObjects = _.filter(state.productFilters.value, c => _.includes(listingRule.excludeProductFiltersList, c.id));

        let nonPartRules = _.filter(excludeObjects, i => i.filterType !== 4 && i.filterType !== 5 && i.filterType !== 6);

        return nonPartRules;
    },


    /**
     * Get a product filter by id
     */
    productFilter: ({productFilters}) => id => {
        return _.find(productFilters.value, c => c.id === id);
    },
    /**
     * the product rules loading state
     */
    productFiltersLoading: ({productFilters}) => productFilters.loading,

     /**
     * the product rules error state
     */
    productFilterError: ({productFilters}) => productFilters.error,

    /**
     * the product rules error state
     */
    timeFilterError: ({timeFilters}) => timeFilters.error,

    /**
     * the product rules error state
     */
    priceModifiersError: ({priceModifiers}) => priceModifiers.error,

    listingRulesError: ({listingRules}) => listingRules.error,

    listingRuleUiDisabled: ({listingRules}) => listingRules.uiDisabled,
    /**
     * filters for product rules
     */
    prefixCodeFilters: state => {
          if (state.productFilters.value != null)
              return state.productFilters.value.filter(function(e) {
                  return e.filterType === 2;
              });
          else {
              return null;
          }
    },
    quantityFilters: state => {
        if (state.productFilters.value != null)
            return state.productFilters.value.filter(function(e) {
                return e.filterType === 8;
            });
        else {
            return null;
        }
    },
    productGroupFilters: state => {
        if (state.productFilters.value != null)
        {
            return state.productFilters.value.filter(function(e) {
                return e.filterType === 3;
            });
        }
        else {
            return null;
        }
    },
    classCodeFilters: state => {
        if (state.productFilters.value != null)
            return state.productFilters.value.filter(function(e) {
                return e.filterType === 1;
            });
        else {
            return null;
        }
    },
    depotFilters: state => {
        if (state.productFilters.value != null)
            return state.productFilters.value.filter(function(e) {
                return e.filterType === 6;
            });
        else {
            return null;
        }
    },

    /**
     * Get all time rules.
     */
    timeFilters: state => {
        return state.timeFilters.value;
    },
    /**
     * Get a time filter by id
     */
    timeFilter: ({timeFilters}) => id => {
        return _.find(timeFilters.value, c => c.id === id);
    },
    /**
     * the product rules loading state
     */
    timeFiltersLoading: ({productFilters}) => productFilters.loading,

    
    /**
     * Get all price modifiers.
     */
    priceModifiers: state => {
        return state.priceModifiers.value;
    },
    /**
     * Get a priceModifier by id
     */
    priceModifier: ({priceModifiers}) => id => {
        return _.find(priceModifiers.value, c => c.id === id);
    },
    /**
     * the price modifiers loading state
     */
    priceModifiersLoading: ({priceModifiers}) => priceModifiers.loading,
    /**
     * Get a priceModifier for filter set
     */
    getPriceModifierForListingRule: state => id => {
        let listingRule = _.find(state.listingRules.value, c => c.id === id);
        let priceMod = _.find(state.priceModifiers.value, c => c.id === listingRule.priceModifierID);

        if(listingRule.priceModifierID === 0) {
            priceMod = { description : "Retail Price"};
        }

        return priceMod.description;
    },

    
    /**
     * Get all filter sets.
     */
    listingRules: state => {
        let unsortedList = state.listingRules.value;
        let sortedList = _.sortBy( unsortedList, 'Priority' );
        return sortedList;
    },
    /**
     * Get a filter set by id
     */
    listingRule: ({listingRules}) => id => {
        return _.find(listingRules.value, c => c.Id === id);
    },
    /**
     * the filter sets loading state
     */
    listingRulesLoading: ({listingRules}) => listingRules.loading,




    /**
     * Get prelistings.
     */
    preListings: ({exclusions,preListings}) => {
        var d = new Date();
        console.debug("store: getter for prelistings (removing exclusions) start - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
        let prelistingsWithExclusions = preListings.value.filter(p => !exclusions.value.find(e => e.partNumber === p.partNumber && e.depot === p.depot));
        
        var d = new Date();
        console.debug("store: getter for prelistings (removing exclusions) end - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());

        return prelistingsWithExclusions;
    },

    /**
     * the prelistings loading state
     */
    preListingsLoading: ({preListings}) => preListings.loading,

    /**
     * the exclusions
     */
     exclusionsLoading: ({exclusions}) => exclusions.loading,

     exclusions: ({exclusions}) => exclusions.value,

     listingDiffLoading: ({listingDiff}) => listingDiff.loading,
     
     listingDiff: ({listingDiff}) => listingDiff.value,

     listingDiffError: ({listingDiff}) => listingDiff.error,

     currentListingsLoading: ({currentListings}) => currentListings.loading,
     
     currentListings: ({currentListings}) => currentListings.value,

     currentListingsError: ({currentListings}) => currentListings.error,
     

};



// 88b           d88                                        88                                    
// 888b         d888               ,d                 ,d    ""                                    
// 88`8b       d8'88               88                 88                                          
// 88 `8b     d8' 88 88       88 MM88MMM ,adPPYYba, MM88MMM 88  ,adPPYba,  8b,dPPYba,  ,adPPYba,  
// 88  `8b   d8'  88 88       88   88    ""     `Y8   88    88 a8"     "8a 88P'   `"8a I8[    ""  
// 88   `8b d8'   88 88       88   88    ,adPPPPP88   88    88 8b       d8 88       88  `"Y8ba,   
// 88    `888'    88 "8a,   ,a88   88,   88,    ,88   88,   88 "8a,   ,a8" 88       88 aa    ]8I  
// 88     `8'     88  `"YbbdP'Y8   "Y888 `"8bbdP"Y8   "Y888 88  `"YbbdP"'  88       88 `"YbbdP"'  
                                                                                               
// https://ascii.co.uk/text        

const mutations = {
    // Set state product rules.
    [mutation.SET_PRODUCT_FILTERS     ] : set('productFilters.value'   , 'productFilters'    ),

    [mutation.PRODUCT_FILTERS_ERROR   ] : set('productFilters.error'   , 'error'           ),

    [mutation.PRODUCT_FILTERS_LOADING ] ({productFilters}, {loading}){
        productFilters.loading  = loading;
    },
    [mutation.SET_PRODUCT_FILTER]({productFilters}, productFilter){
        if(null != productFilter || undefined != productFilter)
        {
            productFilters.value.push(productFilter);
        }
    },    
    [mutation.UPDATE_PRODUCT_FILTER]({productFilters}, productFilter){
        productFilters.value = productFilters.value.map(e => {
                if (e.id == productFilter.id) {
                    return productFilter;
                }
                else {
                    return e;
                }
             });
    },
    [mutation.DELETE_PRODUCT_FILTER]({productFilters}, id){
        productFilters.value = productFilters.value.filter(e => e.id != id);
    },



    // Set state time rules
    [mutation.SET_TIME_FILTERS        ] : set('timeFilters.value'      , 'timeFilters'       ),
    [mutation.TIME_FILTERS_ERROR      ] : set('timeFilters.error'      , 'error'           ),
    [mutation.TIME_FILTERS_LOADING ] ({timeFilters}, {loading}){
        timeFilters.loading  = loading;
    },
    [mutation.SET_TIME_FILTER]({timeFilters}, timeFilter){
        if(null != timeFilter || undefined != timeFilter)
        {
            timeFilters.value.push(timeFilter);
        }
    },    
    [mutation.UPDATE_TIME_FILTER]({timeFilters}, timeFilter){
        timeFilters.value = timeFilters.value.map(e => {
            if (e.id == timeFilter.id) {
                return timeFilter;
            }
            else {
                return e;
            }
         });
    },
    [mutation.DELETE_TIME_FILTER]({timeFilters}, id){

        timeFilters.value = timeFilters.value.filter(e => e.id != id);
    },
    

    // Set state price modifiers
    [mutation.SET_PRICE_MODIFIERS        ] : set('priceModifiers.value'     , 'priceModifiers'       ),
    [mutation.PRICE_MODIFIERS_ERROR      ] : set('priceModifiers.error'     , 'error'           ),
    [mutation.PRICE_MODIFIERS_LOADING ] ({priceModifiers}, {loading}){
        priceModifiers.loading  = loading;
    },
    [mutation.SET_PRICE_MODIFIER]({priceModifiers}, priceModifier){
        if(null != priceModifier || undefined != priceModifier)
        {
            priceModifiers.value.push(priceModifier);
        }
    },    
    [mutation.UPDATE_PRICE_MODIFIER]({priceModifiers}, priceModifier){
        priceModifiers.value = priceModifiers.value.map(e => {
            if (e.id == priceModifier.id) {
                return priceModifier;
            }
            else {
                return e;
            }
         });
    },
    [mutation.DELETE_PRICE_MODIFIER]({priceModifiers}, id){
        priceModifiers.value = priceModifiers.value.filter(e => e.id != id);
    },
    
    

    // Set state filter sets
    [mutation.SET_LISTINGRULES        ] : set('listingRules.value'     , 'listingRules'       ),
    [mutation.LISTINGRULES_ERROR      ] : set('listingRules.error'     , 'error'           ),
    [mutation.LISTINGRULES_UI_DISABLED] : set('listingRules.uiDisabled', 'uiDisabled'           ),
    [mutation.LISTINGRULES_LOADING ] ({listingRules}, {loading}){
        listingRules.loading  = loading;
    },
    [mutation.SET_LISTINGRULE]({listingRules}, listingRule){
       
        listingRule.priority = listingRules.value.length ? Math.max(...listingRules.value.map(e => e.priority)) + 1 : 1;
        if(null != listingRule || undefined != listingRule)
        {
            listingRules.value.push(listingRule);
        }
    },    
    [mutation.UPDATE_LISTINGRULE]({listingRules}, listingRule){
        let index = _.findIndex(listingRules.value, c => c.Id == listingRule.Id);
        if (-1 !== index){
            listingRules.value[index] = listingRule;
        }
    },
    [mutation.ADD_FILTER_TO_LISTINGRULE]({listingRules}, parameters){
        let index = _.findIndex(listingRules.value, c => c.id == parameters.listingRuleId);
        let listingRule = _.find(listingRules.value, c => c.id === parameters.listingRuleId);
        if(parameters.list === 'exclude' && parameters.filterType < 5 || parameters.filterType == 8) {
            if (!listingRule.excludeProductFiltersList.includes(parameters.filterId)) {
                listingRule.excludeProductFiltersList.push(parameters.filterId);
            }
            listingRule.includeProductFiltersList = listingRule.includeProductFiltersList.filter(x => x != parameters.filterId);
        }
        if(parameters.list === 'include' && (parameters.filterType < 5 || parameters.filterType == 6 || parameters.filterType == 8)) {
            if (!listingRule.includeProductFiltersList.includes(parameters.filterId)) {
                listingRule.includeProductFiltersList.push(parameters.filterId);
            }
            listingRule.excludeProductFiltersList = listingRule.excludeProductFiltersList.filter(x => x != parameters.filterId);
        }
        if(parameters.list === 'include' && parameters.filterType == 5) {
            if (!listingRule.includeTimeFiltersList.includes(parameters.filterId)) {
                listingRule.includeTimeFiltersList.push(parameters.filterId);
            }
        }
        if(parameters.list === 'priceModifier') {
            listingRule.priceModifierID = parameters.filterId;
        }                

        if (-1 !== index){
            listingRules.value[index] = listingRule;
        }
    },
    [mutation.REMOVE_FILTER_FROM_LISTINGRULE]({listingRules}, parameters){
        let index = _.findIndex(listingRules.value, c => c.id == parameters.listingRuleId);
        let listingRule = _.find(listingRules.value, c => c.id === parameters.listingRuleId);

        if(parameters.list === 'exclude' && parameters.filter.filterType < 5  || parameters.filter.filterType == 8) {
            const ruleIndex = _.findIndex(listingRule.excludeProductFiltersList, c => c == parameters.filter.id);
            if (-1 !== ruleIndex){
                listingRule.excludeProductFiltersList.splice(ruleIndex, 1);
            }
        }

        if(parameters.list === 'include' && (parameters.filter.filterType < 5 || parameters.filter.filterType == 6 || parameters.filter.filterType == 8)) {
            const ruleIndex = _.findIndex(listingRule.includeProductFiltersList, c => c == parameters.filter.id);
            if (-1 !== ruleIndex){
                listingRule.includeProductFiltersList.splice(ruleIndex, 1);
            }
        }


        if(parameters.list === 'include' && parameters.filter.filterType == 5) {
            const ruleIndex = _.findIndex(listingRule.includeTimeFiltersList, c => c == parameters.filter.id);
            if (-1 !== ruleIndex){
                listingRule.includeTimeFiltersList.splice(ruleIndex, 1);
            }
        }  
        
        if (-1 !== index){
            listingRules.value[index] = listingRule;
        }
    },
    [mutation.RENAME_LISTINGRULE]({listingRules}, parameters){
        let index = _.findIndex(listingRules.value, c => c.id == parameters.listingRuleId);
        let listingRule = _.find(listingRules.value, c => c.id === parameters.listingRuleId);

        listingRule.description = parameters.description;
        
        if (-1 !== index){
            listingRules.value[index] = listingRule;
        }
    },
    [mutation.TOGGLE_LISTINGRULE_SHOW_IN_RESULTS]({listingRules}, parameters){        
        listingRules.value = listingRules.value.map(rs => {
            if (rs.id == parameters.listingRuleId) {
                rs.showInResults = !rs.showInResults;
                return rs;
            }
            return rs;
        })
    },
    [mutation.TOGGLE_LISTINGRULE_IS_PUBLISHED]({listingRules}, parameters){        
        listingRules.value = listingRules.value.map(rs => {
            if (rs.id == parameters.listingRuleId) {
                rs.isPublished = !rs.isPublished;
                return rs;
            }
            return rs;
        })
    },
    [mutation.CHANGE_LISTINGRULE_PRIORITY]({listingRules}, parameters){
        let currentPriority = _.find(listingRules.value, c => c.id === parameters.listingRuleId).priority;
        let maxPriority = Math.max(...listingRules.value.map(e => e.priority));

        listingRules.value = listingRules.value.map(x => {
            if (x.id === parameters.listingRuleId){
                if(parameters.direction === 'up' && x.priority > 1) {
                    x.priority = x.priority - 1;
                }
                else if (x.priority < maxPriority) {
                    x.priority = x.priority + 1;
                }
            } 
            else if (parameters.direction === 'up' && x.priority < maxPriority && x.priority === currentPriority - 1) {
                x.priority ++;
            }
            else if (parameters.direction !== 'up' && x.priority > 1 && x.priority === currentPriority + 1) {
                x.priority --;
            }
            return x;
        });        
    },
    [mutation.DELETE_LISTINGRULE]({listingRules}, id){
        listingRules.value = listingRules.value.filter(x => x.id != id)
    },
    



    // Set preListings
    [mutation.SET_PRELISTINGS       ] : set('preListings.value'    , 'preListings'     ),
    [mutation.PRELISTINGS_ERROR     ] : set('preListings.error'    , 'error'           ),
    [mutation.PRELISTINGS_LOADING   ] ({preListings}, {loading}){
        preListings.loading  = loading;
        preListings.error    = null;
    },
    [mutation.SET_PRELISTING]({preListings}, preListing){
        if(null != preListing || undefined != preListing)
        {
            preListings.value.push(preListing);
        }
    },    
    [mutation.UPDATE_PRELISTING]({preListings}, preListing){
        let index = _.findIndex(preListings.value, c => c.Id == preListing.Id);
        if (-1 !== index){
            preListings.value[index] = preListing;
        }
    },
    [mutation.DELETE_PRELISTING]({preListings}, id){
        const index = _.findIndex(preListings.value, c => c.Id == id);
        if (-1 !== index){
            preListings.value.splice(index, 1);
        }
    },
        


    // Set exclusions
    [mutation.SET_EXCLUSIONS       ] : set('exclusions.value'    , 'exclusions'     ),
    [mutation.EXCLUSIONS_ERROR     ] : set('exclusions.error'    , 'error'           ),
    [mutation.EXCLUSIONS_LOADING   ] ({exclusions}, {loading}){
        exclusions.loading  = loading;
        exclusions.error    = null;
    },


    // Clear state product filters.
    [mutation.CLEAR_PRODUCT_FILTERS       ]   : (state) => state.productFilters.value = [],
    [mutation.CLEAR_PRODUCT_FILTERS_ERROR ]   : (state) => state.productFilters.error = null,

    // Clear state time filters.
    [mutation.CLEAR_TIME_FILTERS       ]      : (state) => state.timeFilters.value = [],
    [mutation.CLEAR_TIME_FILTERS_ERROR ]      : (state) => state.timeFilters.error = null,

    // Clear state price modifiers.
    [mutation.CLEAR_PRICE_MODIFIERS       ] : (state) => state.priceModifiers.value = [],
    [mutation.CLEAR_PRICE_MODIFIERS_ERROR ] : (state) => state.priceModifiers.error = null,

    // Clear state filter sets.
    [mutation.CLEAR_LISTINGRULES       ]       : (state) => state.listingRules.value = [],
    [mutation.CLEAR_LISTINGRULES_ERROR ]       : (state) => state.listingRules.error = null,

    // Clear state preListings.
    [mutation.CLEAR_PRELISTINGS       ]     : (state) => state.preListings.value = [],
    [mutation.CLEAR_PRELISTINGS_ERROR ]     : (state) => state.preListings.error = null,

    // Clear exclusions.
    [mutation.CLEAR_EXCLUSIONS       ]      : (state) => state.exclusions.value = [],
    [mutation.CLEAR_EXCLUSIONS_ERROR ]      : (state) => state.exclusions.error = null,

    // Reset state.
    [mutation.RESET                     ]   : (state) => reset(state, initialState()),
    
    [mutation.RESET_DIFF_STATE          ] (state)
    {
        state.listingDiff.value = {addedListings:[], removedListings:[], changedListings:[]};
        state.listingDiff.error = "";
    } ,

    [mutation.RESET_CURRENT_LISTINGS_STATE          ] (state)
    {
        state.currentListings.value = [];
        state.currentListings.error = "";
    } ,

    // Add Exclusion
    [mutation.ADD_EXCLUSIONS]({exclusions}, partsToExclude){
        if(null != partsToExclude || undefined != partsToExclude)
        {
            console.debug({partsToExclude});
            
            exclusions.value.push(...partsToExclude.partsToExclude);
        }
    },    
    // Remove Exclusion
    [mutation.DELETE_EXCLUSIONS]({exclusions}, includedParts){
        exclusions.value = exclusions.value.filter(p => !includedParts.partsToInclude.find(e => e.partNumber === p.partNumber && e.depot === p.depot));
    },

    // Set listing diff
    [mutation.SET_LISTING_DIFF] : set('listingDiff.value'    , 'listingDiff'     ),
    [mutation.LISTING_DIFF_ERROR     ] ({listingDiff}, {error}){
        listingDiff.error = error;
    },
    [mutation.LISTING_DIFF_LOADING   ] ({listingDiff}, {loading}){
        listingDiff.loading  = loading;
    },
    [mutation.CLEAR_LISTING_DIFF       ]      : (state) => state.listingDiff.value = null,

    // Set current listings 
    [mutation.SET_CURRENT_LISTINGS] : set('currentListings.value'    , 'currentListings'     ),

    [mutation.CURRENT_LISTINGS_ERROR     ] ({currentListings}, {error}){
        currentListings.error = error;
    },
    [mutation.CURRENT_LISTINGS_LOADING   ] ({currentListings}, {loading}){
        currentListings.loading  = loading;
    },
    [mutation.CLEAR_CURRENT_LISTINGS       ]      : (state) => state.currentListings.value = null,
};


                                                                         
//        db                          88                                    
//       d88b                   ,d    ""                                    
//      d8'`8b                  88                                          
//     d8'  `8b     ,adPPYba, MM88MMM 88  ,adPPYba,  8b,dPPYba,  ,adPPYba,  
//    d8YaaaaY8b   a8"     ""   88    88 a8"     "8a 88P'   `"8a I8[    ""  
//   d8""""""""8b  8b           88    88 8b       d8 88       88  `"Y8ba,   
//  d8'        `8b "8a,   ,aa   88,   88 "8a,   ,a8" 88       88 aa    ]8I  
// d8'          `8b `"Ybbd8"'   "Y888 88  `"YbbdP"'  88       88 `"YbbdP"'  
                                                                         
// https://ascii.co.uk/text          

const actions = {
    /**
     * Fetch all product rules for the current company from
     * the API and store in vuex.
     */
    async fetchProductFilters({ commit }) {
        commit(mutation.CLEAR_PRODUCT_FILTERS);
        commit(mutation.PRODUCT_FILTERS_ERROR, { error: null });
        commit(mutation.PRODUCT_FILTERS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.getProductFilters();
            var productFilters = response.data;
            productFilters = productFilters.map(productFilter => ({...productFilter,filterType:((productFilter.category >= 5) ? (productFilter.category == 6 ? 8 : productFilter.category + 1) : productFilter.category)})).sort((a, b) => new Date(b.lastModifiedOn) - new Date(a.lastModifiedOn));
            commit(mutation.SET_PRODUCT_FILTERS, {productFilters});
        }
        catch (error) {
            commit(mutation.RESET);
            console.log("HERE IS THE ERROR");
            console.log(error);
            commit(mutation.PRODUCT_FILTERS_ERROR, { error: error });
        }
        finally{
            commit(mutation.PRODUCT_FILTERS_LOADING, { loading: false });
        }

    },
    /**
     * Fetch a product filter for the current company from
     * the API and store in vuex.
     * @param {number} id - the id of the checklist
     */
    async fetchProductFilter({ commit }, id) {
        commit(mutation.PRODUCT_FILTERS_LOADING, { loading: true });
        commit(mutation.PRODUCT_FILTERS_ERROR, { error: null });
        try {
            const response    = await RulesApi.getProductFilter(id);
            const productFilter = response.data;
            
            commit(mutation.SET_PRODUCT_FILTER, productFilter);
        }
        catch (error) {
            commit(mutation.PRODUCT_FILTERS_ERROR, { error: error.result.data });
        }
        finally{
            commit(mutation.PRODUCT_FILTERS_LOADING, { loading: false });
        }
    },
    /**
     * Sends the product filter create request to
     * the API and store in vuex.
     * @param {object} productFilter - the product filter to create
     */
    async createProductFilter({ commit }, productFilter) {
        commit(mutation.PRODUCT_FILTERS_LOADING, { loading: true });
        commit(mutation.PRODUCT_FILTERS_ERROR, { error: null });
        try {
            productFilter.values = productFilter.values.join(",");
            const response     = await RulesApi.setProductFilter(productFilter);
            productFilter.id     = response.data.id;
            commit(mutation.SET_PRODUCT_FILTER, productFilter);
        }
        catch (error) {
            commit(mutation.PRODUCT_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRODUCT_FILTERS_LOADING, { loading: false });   
        }

    },
    /**
     * Sends the product filter update request to
     * the API and store in vuex.
     * @param {object} productFilter - the product filter to create
     */
    async updateProductFilter({ commit }, productFilter) {
        commit(mutation.PRODUCT_FILTERS_ERROR, { error: null });
        commit(mutation.PRODUCT_FILTERS_LOADING, { loading: true });
        
        try {
            productFilter.values = productFilter.values.join(",");
            const response     = await RulesApi.setProductFilter(productFilter);
            productFilter.id     = response.data.id;

            commit(mutation.UPDATE_PRODUCT_FILTER, productFilter)
        }
        catch (error) {
            commit(mutation.PRODUCT_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRODUCT_FILTERS_LOADING, { loading: false }); 
        }

    },
    /**
     * Sends the product filter delete request to
     * the API and store in vuex.
     * @param {object} id - the id of the product filter
     */
    async deleteProductFilter({ commit }, id) {
        commit(mutation.PRODUCT_FILTERS_LOADING, { loading: true });
        commit(mutation.PRODUCT_FILTERS_ERROR, { error: null });
        
        try {
            const response     = await RulesApi.deleteProductFilter(id);
            commit(mutation.DELETE_PRODUCT_FILTER, response.data.id);
        }
        catch (error) {
            commit(mutation.PRODUCT_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRODUCT_FILTERS_LOADING, { loading: false });
        }
    },

    /**
     * Fetch all time filters for the current company from
     * the API and store in vuex.
     */
    async fetchTimeFilters({ commit }) {
        commit(mutation.CLEAR_TIME_FILTERS);
        commit(mutation.TIME_FILTERS_ERROR, { error: null });
        commit(mutation.TIME_FILTERS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.getTimeFilters();
            
            let timeFilters = response.data;

            timeFilters = timeFilters.map(timeFilter => ({...timeFilter,filterType:5}));
            timeFilters.sort((a, b) => new Date(b.lastModifiedOn) - new Date(a.lastModifiedOn));
            commit(mutation.SET_TIME_FILTERS, {timeFilters});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.TIME_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.TIME_FILTERS_LOADING, { loading: false });
        }

    },
    /**
     * Fetch a time filter for the current company from
     * the API and store in vuex.
     * @param {number} id - the id of the time filter
     */
    async fetchTimeFilter({ commit }, id) {
        commit(mutation.TIME_FILTERS_LOADING, { loading: true });
        commit(mutation.TIME_FILTERS_ERROR, { error: null });
        try {
            const response    = await RulesApi.getTimeFilter(id);
            let timeFilter = response.data;
            
            timeFilter = timeFilter.map(timeFilter => ({...timeFilter,category:4}));
            
            commit(mutation.SET_TIME_FILTERS, timeFilter);
        }
        catch (error) {
            commit(mutation.TIME_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.TIME_FILTERS_LOADING, { loading: false });
        }
    },
    /**
     * Sends the time filter create request to
     * the API and store in vuex.
     * @param {object} timeFilter - the time filter to create
     */
    async createTimeFilter({ commit }, timeFilter) {
        commit(mutation.TIME_FILTERS_LOADING, { loading: true });
        commit(mutation.TIME_FILTERS_ERROR, { error: null });
        
        try {
            const response     = await RulesApi.setTimeFilter(timeFilter);
            timeFilter.id     = response.data.id;
          
            commit(mutation.SET_TIME_FILTER, timeFilter);
        }
        catch (error) {
            commit(mutation.TIME_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.TIME_FILTERS_LOADING, { loading: false });   
        }

    },
    /**
     * Sends the product filter update request to
     * the API and store in vuex.
     * @param {object} timeFilter - the time filter to create
     */
    async updateTimeFilter({ commit }, timeFilter) {
        commit(mutation.TIME_FILTERS_LOADING, { loading: true });
        commit(mutation.TIME_FILTERS_ERROR, { error: null });

        try {
            const response     = await RulesApi.setTimeFilter(timeFilter);
            timeFilter.id  = response.data.id;

            commit(mutation.UPDATE_TIME_FILTER, timeFilter)
        }
        catch (error) {
            commit(mutation.TIME_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.TIME_FILTERS_LOADING, { loading: false }); 
        }

    },
    /**
     * Sends the time filter delete request to
     * the API and store in vuex.
     * @param {object} id - the id of the time filter
     */
    async deleteTimeFilter({ commit }, id) {
        commit(mutation.TIME_FILTERS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.deleteTimeFilter(id);

            commit(mutation.DELETE_TIME_FILTER, response.data.id);
        }
        catch (error) {
            commit(mutation.TIME_FILTERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.TIME_FILTERS_LOADING, { loading: false });
        }
    },






    /**
     * Fetch all price modifiers for the current company from
     * the API and store in vuex.
     */
    async fetchPriceModifiers({ commit }) {
        commit(mutation.CLEAR_PRICE_MODIFIERS);
        commit(mutation.PRICE_MODIFIERS_ERROR, { error: null });
        commit(mutation.PRICE_MODIFIERS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.getPriceModifiers();
            const priceModifiers = response.data;
            commit(mutation.SET_PRICE_MODIFIERS, {priceModifiers});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.PRICE_MODIFIERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRICE_MODIFIERS_LOADING, { loading: false });
        }

    },
    /**
     * Fetch a price modifier for the current company from
     * the API and store in vuex.
     * @param {number} id - the id of the price modifier
     */
    async fetchPriceModifier({ commit }, id) {
        commit(mutation.PRICE_MODIFIERS_LOADING, { loading: true });
        commit(mutation.PRICE_MODIFIERS_ERROR, { error: null });
        
        try {
            const response    = await RulesApi.getPriceModifier(id);
            const priceModifier = response.data;
            
            commit(mutation.SET_PRICE_MODIFIER, priceModifier);
        }
        catch (error) {
            commit(mutation.PRICE_MODIFIERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRICE_MODIFIERS_LOADING, { loading: false });
        }
    },
    /**
     * Sends the price modifier create request to
     * the API and store in vuex.
     * @param {object} priceModifier - the price modifier to create
     */
    async createPriceModifier({ commit }, priceModifier) {
        commit(mutation.PRICE_MODIFIERS_LOADING, { loading: true });
        commit(mutation.PRICE_MODIFIERS_ERROR, { error: null });
        
        try {
            const response     = await RulesApi.setPriceModifier(priceModifier);

            priceModifier.id      = response.data.id;
            
            commit(mutation.SET_PRICE_MODIFIER, priceModifier);
        }
        catch (error) {
            commit(mutation.PRICE_MODIFIERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRICE_MODIFIERS_LOADING, { loading: false });   
        }

    },
    /**
     * Sends the price modifier update request to
     * the API and store in vuex.
     * @param {object} priceModifier - the price modifier to create
     */
    async updatePriceModifier({ commit }, priceModifier) {
        commit(mutation.PRICE_MODIFIERS_LOADING, { loading: true });
        commit(mutation.PRICE_MODIFIERS_ERROR, { error: null });
        
        try {
            const response     = await RulesApi.setPriceModifier(priceModifier);
            priceModifier.id   = response.data.id;

            commit(mutation.UPDATE_PRICE_MODIFIER, priceModifier)
        }
        catch (error) {
            commit(mutation.PRICE_MODIFIERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRICE_MODIFIERS_LOADING, { loading: false }); 
        }

    },
    /**
     * Sends the price modifier delete request to
     * the API and store in vuex.
     * @param {object} id - the id of the price modifier
     */
    async deletePriceModifier({ commit }, id) {
        commit(mutation.PRICE_MODIFIERS_LOADING, { loading: true });
        commit(mutation.PRICE_MODIFIERS_ERROR, { error: null });
        
        try {
            const response     = await RulesApi.deletePriceModifier(id);

            commit(mutation.DELETE_PRICE_MODIFIER, response.data.id);
        }
        catch (error) {
            commit(mutation.PRICE_MODIFIERS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRICE_MODIFIERS_LOADING, { loading: false });
        }
    },





    /**
     * Fetch all filter sets for the current company from
     * the API and store in vuex.
     */
    async fetchListingRules({ commit }) {
        commit(mutation.CLEAR_LISTINGRULES);
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        try {
            const response     = await RulesApi.getListingRules();
            const listingRules     = response.data;
            commit(mutation.SET_LISTINGRULES, {listingRules});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.LISTINGRULES_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }

    },
    /**
     * Fetch a filter set for the current company from
     * the API and store in vuex.
     * @param {number} id - the id of the filter set
     */
    async fetchListingRule({ commit }, id) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            const response    = await RulesApi.getListingRule(id);
            const listingRule = response.data;
            
            commit(mutation.SET_LISTINGRULE, listingRule);
        }
        catch (error) {
            commit(mutation.LISTINGRULES_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },
    /**
     * Sends the filter set create request to
     * the API and store in vuex.
     * @param {object} listingRule - the filter set to create
     */
    async createListingRule({ commit }, listingRule) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.setListingRule(listingRule);
            listingRule.id = response.data.id;
            commit(mutation.SET_LISTINGRULE, listingRule);
            return listingRule;
        }
        catch (error) {
            commit(mutation.LISTINGRULES_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });   
        }

    },
    /**
     * Sends the filter set update request to
     * the API and store in vuex.
     * @param {object} listingRule - the filter set to update
     */
    async updateListingRule({ commit }, listingRule) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.setListingRule(listingRule);
            listingRule.id = response.data.id;

            commit(mutation.UPDATE_LISTINGRULE, listingRule.id)
        }
        catch (error) {
            commit(mutation.LISTINGRULES_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false }); 
        }

    },
    /**
     * Sends the filter set delete request to
     * the API and store in vuex.
     * @param {object} id - the id of the filter set
     */
    async deleteListingRule({ commit }, id) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            await RulesApi.deleteListingRule(id);
            commit(mutation.DELETE_LISTINGRULE, id);
        }
        catch (error) {
            commit(mutation.LISTINGRULES_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },
    /**
     * removes a filter from the filter set
     * the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} list - the name of the list to remove the filter from
     *  {object} filter - the filter to remove
     */
    async removeFilterFromListingRule({ commit, state }, parameters) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {       
            commit(mutation.REMOVE_FILTER_FROM_LISTINGRULE, parameters);
            let listingRule = _.find(state.listingRules.value, c => c.id === parameters.listingRuleId);
            await RulesApi.setListingRule(listingRule);
        }
        catch (error) {
            console.error("error removing filter from listingRule " + error);
            commit(mutation.LISTINGRULES_ERROR, { error: error });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },
    /**
     * adds a filter to the filter set
     * in the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} list - the name of the list to remove the filter from
     *  {object} filter - the filter to remove
     */
    async addFilterToListingRule({ commit, state }, parameters) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            commit(mutation.ADD_FILTER_TO_LISTINGRULE, parameters);
            let listingRule = _.find(state.listingRules.value, c => c.id === parameters.listingRuleId);
            await RulesApi.setListingRule(listingRule);
        }
        catch (error) {
            console.error("error adding filter to listingRule " + error);
            commit(mutation.LISTINGRULES_ERROR, { error: error });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },
    /**
     * renames a filter set
     * in the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} description - the new name of the listingRule
     *  {object} filter - the filter to remove
     */
    async renameListingRule({ commit, state }, parameters) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            commit(mutation.RENAME_LISTINGRULE, parameters);
            let listingRule = _.find(state.listingRules.value, c => c.id === parameters.listingRuleId);
            await RulesApi.setListingRule(listingRule);
        }
        catch (error) {
            console.error("error renaming listingRule " + error);
            commit(mutation.LISTINGRULES_ERROR, { error: error });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },
     /**
     * toggles a listingRule's shown in results flag
     * in the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} description - the new name of the listingRule
     *  {object} filter - the filter to remove
     */
    async toggleListingRuleShowInResults({ commit, state }, parameters) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            commit(mutation.TOGGLE_LISTINGRULE_SHOW_IN_RESULTS, parameters);
            let listingRule = _.find(state.listingRules.value, c => c.id === parameters.listingRuleId);
            await RulesApi.toggleShowInResults(listingRule);
        }
        catch (error) {
            console.error("error toggling listingRule show in results" + error);
            commit(mutation.LISTINGRULES_ERROR, { error: error });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },

     /**
     * toggles a listingRule's is published flag
     * in the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} description - the new name of the listingRule
     */
      async toggleListingRuleIsPublished({ commit, state }, parameters) {
        commit(mutation.LISTINGRULES_LOADING, { loading: true });
        
        try {
            commit(mutation.TOGGLE_LISTINGRULE_IS_PUBLISHED, parameters);
            let listingRule = _.find(state.listingRules.value, c => c.id === parameters.listingRuleId);
            await RulesApi.toggleIsPublished(listingRule);
        }
        catch (error) {
            console.error("error toggling listingRule is published" + error);
            commit(mutation.LISTINGRULES_ERROR, { error: error });
        }
        finally{
            commit(mutation.LISTINGRULES_LOADING, { loading: false });
        }
    },


    /**
     * change filter set priority
     * in the API and store in vuex.
     * @param {object} parameters containing:
     *  {object} listingRuleId - the id of the filter set
     *  {object} direction - 'up' or 'down' in priority
     *  {object} filter - the filter to remove
     */
    async changeListingRulePriority({ commit, state }, parameters) {
        if(!state.listingRules.uiDisabled) {
            commit(mutation.LISTINGRULES_LOADING, { loading: true });
            commit(mutation.LISTINGRULES_UI_DISABLED, { uiDisabled: true });
            try {
                if (parameters.direction === 'up') {
                    await RulesApi.shiftPriorityUp(parameters.listingRuleId);
                }
                else {
                    await RulesApi.shiftPriorityDown(parameters.listingRuleId);
                }
                
                commit(mutation.CHANGE_LISTINGRULE_PRIORITY, parameters);
            }
            catch (error) {
                commit(mutation.LISTINGRULES_ERROR, { error: error });
            }
            finally{
                commit(mutation.LISTINGRULES_LOADING, { loading: false });
                commit(mutation.LISTINGRULES_UI_DISABLED, { uiDisabled: false });
            }
        }
    },

    /**
     * Fetch all product rules for the current company from
     * the API and store in vuex.
     */
    async fetchPreListings({ commit }) {
        
        var d = new Date();
        console.debug("store: action fetch prelistings start - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
        commit(mutation.CLEAR_PRELISTINGS);
        commit(mutation.PRELISTINGS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.getPreListings();
            const preListings = response.data;
            commit(mutation.SET_PRELISTINGS, {preListings});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.PRELISTINGS_ERROR, { error: error });
        }
        finally{
            commit(mutation.PRELISTINGS_LOADING, { loading: false });
            var d = new Date();
            console.debug("store: action fetch prelistings end - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
    
        }
    },

    /**
     * Fetch all product rules for the current company from
     * the API and store in vuex.
     */
     async fetchPreListingsToBePublished({ commit }) {
        commit(mutation.CLEAR_PRELISTINGS);
        commit(mutation.PRELISTINGS_LOADING, { loading: true });
        
        try {
            const response     = await RulesApi.getPreListingsToBePublished();
            const preListings = response.data;
            commit(mutation.SET_PRELISTINGS, {preListings});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.PRELISTINGS_ERROR, { error: error.response.data });
        }
        finally{
            commit(mutation.PRELISTINGS_LOADING, { loading: false });
        }

    },

    /**
     * Fetch all product rules for the current company from
     * the API and store in vuex.
     */
     async fetchExclusions({ commit }) {
        var d = new Date();
        console.debug("store: fetch exclusions start - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());
        commit(mutation.CLEAR_EXCLUSIONS);
        commit(mutation.EXCLUSIONS_LOADING, { loading: true });
        try {
            const response     = await RulesApi.getExcludedParts();
            const exclusions = response.data;
            commit(mutation.SET_EXCLUSIONS, {exclusions});
        }
        catch (error) {
            commit(mutation.RESET);
            commit(mutation.EXCLUSIONS_ERROR, { error: error });
        }
        finally{
            commit(mutation.EXCLUSIONS_LOADING, { loading: false });
        }
        var d = new Date();
        console.debug("store: fetch exclusions end - " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds() + "." + d.getMilliseconds());

    },

    async addExclusions({commit, state}, partsToExclude) {
        commit(mutation.ADD_EXCLUSIONS, {partsToExclude});
        try {
            const response     = await RulesApi.setExcludedParts(state.exclusions.value);
        }
        catch {

        }
        finally {

        }
    },

    async deleteExclusions({commit, state}, partsToInclude) {
        commit(mutation.DELETE_EXCLUSIONS, {partsToInclude});
        try {
            const response     = await RulesApi.setExcludedParts(state.exclusions.value);
        }
        catch {

        }
        finally {

        }
    },

    async fetchListingDiff({ commit }) {
        commit(mutation.RESET_DIFF_STATE);
        commit(mutation.LISTING_DIFF_LOADING, { loading: true });
        console.debug("store - fetching listing diff");
        try {
            const response     = await RulesApi.getListingDiff();
            const listingDiff = response.data;
            console.log(listingDiff);
            commit(mutation.SET_LISTING_DIFF, {listingDiff});
        }
        catch (err) {
            commit(mutation.LISTING_DIFF_ERROR, { error: err.response.data});
            commit(mutation.SET_LISTING_DIFF, {listingDiff: {addedListings: [], removedListings: [], changedListings: [] }});
            console.log(err.response.data);
        }
        finally{
            commit(mutation.LISTING_DIFF_LOADING, { loading: false });
        }

    },

    async fetchCurrentListings({ commit }) {
        commit(mutation.RESET_CURRENT_LISTINGS_STATE);
        commit(mutation.CURRENT_LISTINGS_LOADING, { loading: true });
        console.debug("store - fetching current listings");
        try {
            const response     = await RulesApi.getCurrentListings();
            const currentListings = response.data;
            commit(mutation.SET_CURRENT_LISTINGS, {currentListings});
        }
        catch (err) {
            console.log(err);
            commit(mutation.CURRENT_LISTINGS_ERROR, { error: err.response.data});
            commit(mutation.SET_CURRENT_LISTINGS, {currentListings: []});
            console.log(err.response.data);
        }
        finally{
            commit(mutation.CURRENT_LISTINGS_LOADING, { loading: false });
        }

    },

};

export default {
    namespaced  : true,
	state       : initialState(),
	getters,
	mutations,
	actions
}
