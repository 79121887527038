import ApiService from '@/common/api-service';

const SCHEDULER_ROOT = 'api/v1/workshop/scheduler';

export default {
    /**
     * Get all defined scheduler departments.
     */
    getDepartments() {
        const url = `${SCHEDULER_ROOT}/departments`;
        return ApiService.get(url);
    }
};