import Moment               from 'moment';
import MomentDurationFormat from 'moment-duration-format';
import { DateFormat }       from '@/common/date-format';

const DEFAULT_DATE = '0001-01-01';

const DateMixin = {
    created() {
        MomentDurationFormat(Moment);
    },

    data() {
        return {
            // Make date format import available in templates of components.
            // N.B If a component defines it's own DateFormat property this value
            // will be overwritten.
            DateFormat: DateFormat
        }
    },

    methods: {
        /**
         * Returns the specified date as an ISO8601 standard date
         * string. Returns null if the specified date is not valid.
         * @param {(string|date)} date - Date to format as ISO8601.
         * @param {string} [format] - Format of date string.
         */
        getDateTime(
            date,
            format = null
        ) {
            const dateObj   = Moment(date, format);
            const utcString = dateObj.toISOString();
            return utcString;
        },

        /**
         * Returns only the date component of the specified date as
         * an ISO8601 standard date string. Returns null if the
         * specified date is not valid.
         * @param {(string|date)} date - Date to format as ISO8601.
         * @param {string} [format] - Format of date string.
         */
        getDate(
            date,
            format = null
        ) {
            const dateObj   = Moment(date, format).utc();
            const dateOnly  = dateObj.startOf('day');
            const utcString = dateOnly.toISOString();
            return utcString;
        },

        /**
         * Get the current date/time as a moment
         * object.
         */
        getCurrentDateTime() {
            return Moment();
        },

        /**
         * Return a date object using the specified value and format.
         * @param {string} value - The date/time string.
         * @param {string} format - The format of the string.
         */
        getDateObject(
            value,
            format
        ) {
            return Moment(value, format);
        },

        /**
         * Returns the duration between two datetimes and
         * as a formatted string, or null if the duration
         * is invalid.
         * @param {string} start - Start Date/Time string.
         * @param {string} end - End Date/Time string.
         * @param {string} [format] - Format to return the duration in.
         * @returns {?string} Duration string.
         */
        getDuration(
            start,
            end,
            format = 'hh:mm:ss'
        ) {
            let   formatted  = null;            
            const startDate  = Moment(start);
            const endDate    = Moment(end);
            const difference = endDate - startDate;
            const duration   = Moment.duration(difference);
            if (true === duration.isValid()) {
                formatted = this._getDurationString(duration, format);
            }
            return formatted;
        },

        /**
         * Returns the duration as a hours.
         * @param {string} value - Duration string. 
         * @return {number} The number of hours.
         */
        getDurationHours(value) {
            const duration = Moment.duration(value);
            return duration.asHours();
        },

        /**
         * Returns a date string in the specfied format if the date
         * value is valid, otherwise null.
         * Defaults to the format 'DD/MM/YYYY hh:mma' if no format
         * is provided.
         * @param {string} value - Date/Time string.
         * @param {string} [format] - Format to return the date in.
         * @returns {?string} Date string in the specfied format.
         * @see {@link https://momentjs.com/docs/#/displaying/format/}
        */
        getDateString(
            value,
            format = DateFormat.DATE_TIME
        ) {
            const dateString = this.$options.filters.formatDate(value, format);
            return dateString;
        },

        /**
         * Returns a duration string in the specified format if the duration 
         * value is valid, otherwise null.
         * @param {string} value - Duration string.
         * @param {string} [format]  - Format to return the duration in.
         * @param {(boolean|string)} [trim] - Trim largest/smallest magnitude
         * zero value tokens.
         * @param {number} [precision] - Number of decimal fraction or integer
         * digits to display for the final value.
         * @returns {?string} Duration string in the specified format.
         * @see {@link https://www.npmjs.com/package/moment-duration-format}
         */
        formatDuration(
            value,
            format    = 'hh:mm:ss',
            trim      = false,
            precision = null
        ) {
            const formatted = this.$options.filters.formatDuration(value, format, trim, precision);
            return formatted;
        },

        /**
         * Returns true if the duration is greater than
         * zero milliseconds, otherwise false.
         * @param {string} value - Duration string.
         * @returns {boolean}
         */
        validateDuration(value) {
            let   isPositive = false;
            const duration   = Moment.duration(value);
            if (true === duration.isValid()) {
                isPositive = (0 < duration.asMilliseconds());
            }
            return isPositive;
        },

        /**
		 * Convert a duration to a string of the format HH:mm:ss.
         * @param {object} duration - MomentJS Duration object.
         * @param {string} [format]  - Format to return the duration in.
         * @param {(boolean|string)} [trim] - Trim largest/smallest magnitude
         * zero value tokens.
         * @param {number} [precision] - Number of decimal fraction or integer
         * digits to display for the final value.
         * @returns {?string} Duration string in the specified format.
         * @see {@link https://www.npmjs.com/package/moment-duration-format}
		 */
		_getDurationString(
            duration,
            format    = 'hh:mm:ss',
            trim      = false,
            precision = null
        ) {
            let formatted = null;
            // Check that duration is a MomentJS Duration object.
            if ((null !== duration) && ('function' === typeof(duration.format)))
            {
                formatted = duration.format({
                    template:  format,
                    trim:      trim,
                    precision: precision
                });
            }
            return formatted;
        },

        /**
         * Returns the specified date plus the specified number of days as an
         * ISO8601 standard date string.
         * @param {numeric} days - Number of days to add.
         * @param {(string|date)} [date] - Date to add to, defaults to now.
         */
        addDays(
            days,
            date = null
        ) {
            const formatted = this._addToDate(days, 'days', date);
            return formatted;
        },

        /**
         * Returns the specified date plus the specified number of months as an
         * ISO8601 standard date string.
         * @param {numeric} months - Number of months to add.
         * @param {(string|date)} [date] - Date to add to, defaults to now.
         */
        addMonths(
            months,
            date = null
        ) {
            const formatted = this._addToDate(months, 'months', date);
            return formatted;
        },

        /**
         * Returns the specified date minus the specified number of days as an
         * ISO8601 standard date string.
         * @param {numeric} days - Number of days to subtract.
         * @param {(string|date)} [date] - Date to subtract from, defaults to now.
         */
        subtractDays(
            days,
            date = null
        ) {
            const formatted = this._subtractFromDate(days, 'days', date);
            return formatted
        },

        /**
         * Returns the specified date minus the specified number of months as an
         * ISO8601 standard date string.
         * @param {numeric} months - Number of months to subtract.
         * @param {(string|date)} [date] - Date to subtract from, defaults to now.
         */
        subtractMonths(
            months,
            date = null
        ) {
            const formatted = this._subtractFromDate(months, 'months', date);
            return formatted
        },

        /**
         * Returns the specified MomentJS date minus the specified number of units
         * as an ISO8601 standard date string.
         * @param {numeric} amount - Number of the specified unit to subtract.
         * @param {'years'|'months'|'days'|'hours'|'minutes'|'seconds'} unit - The
         * unit to subtract.
         * @param {(string|date)} [date] - Date to subtract from, defaults to now.
         */
        _subtractFromDate(amount, unit, date) {
            let originalDate = Moment(date).utc();
            if (false === originalDate.isValid()) {
                originalDate = Moment().utc();
            }
            const newDate = originalDate.subtract(amount, unit);
            const formatted = newDate.toISOString();
            return formatted;
        },

        /**
         * Returns the specified MomentJS date plus the specified number of units
         * as an ISO8601 standard date string.
         * @param {numeric} amount - Number of the specified unit to subtract.
         * @param {'years'|'months'|'days'|'hours'|'minutes'|'seconds'} unit - The
         * unit to subtract.
         * @param {(string|date)} [date] - Date to subtract from, defaults to now.
         */
        _addToDate(amount, unit, date) {            
            let originalDate = Moment(date).utc();
            if (false === originalDate.isValid()) {
                originalDate = Moment().utc();
            }
            const newDate = originalDate.add(amount, unit);
            const formatted = newDate.toISOString();
            return formatted;
        },

        /**
         * Returns true if the specified Date/Time string
         * is a valid date, otherwise false.
         * @param {string} value - Date/Time string.
         * @returns {boolean}
         */
        validateDateTime(value) {
            let isValid = false;
            if ('string' === typeof(value)) {
                const date = Moment(value);
                isValid    = date.isValid();
            }
            return isValid;
        },

        /**
         * Determines if the date passed in is the minimum
         * date.
         * @param {string} value - Date/Time string.
         * @returns {boolean}
         */
        isMinDate(value) {
            let isMin     = false;
            const isValid = this.validateDateTime(value);

            if (true === isValid) {
                const minDateString = "0001-01-01T00:00:00+00:00";
                const minDate       = Moment(minDateString);
                const valueDate     = Moment(value);
                
                isMin = this.isDateSame(minDate, valueDate);                                
            }

            return isMin;
        },
        
        /**
         * Check if one date occurs before another.
         * @param {string} date1 - Date to check occurs first chronologically.
         * @param {string} date2 - Date to check occurs second chronologically.
         * @param {('year'|'month'|'week'|'isoWeek'|'day'|'hour'|'minute'|'second')} [unit]
         *      - Unit to use to limit granularity of comparison. Default is millisecond.
         * @return {boolean}
         * @see {@link https://momentjs.com/docs/#/query/is-before/}
         */
        isDateBefore(
            date1,
            date2,
            unit = 'millisecond'
        ) {
            const isBefore = Moment(date1).isBefore(date2, unit);
            return isBefore;
        },

        /**
         * Check if two dates are the same.
         * @param {string} date1 - First Date/Time to compare.
         * @param {string} date2 - Second Date/Time to compare.
         * @param {('year'|'month'|'week'|'isoWeek'|'day'|'hour'|'minute'|'second')} [unit]
         *      - Unit to use to limit granularity of comparison. Default is millisecond.
         * @return {boolean}
         * @see {@link https://momentjs.com/docs/#/query/is-same/}
         */
        isDateSame(
            date1,
            date2,
            unit = 'millisecond'
        ) {
            const isSame = Moment(date1).isSame(date2, unit);
            return isSame;
        },
        
        /**
         * Check if one date occurs after another.
         * @param {string} date1 - Date to check occurs second chronologically.
         * @param {string} date2 - Date to check occurs first chronologically.
         * @param {('year'|'month'|'week'|'isoWeek'|'day'|'hour'|'minute'|'second')} [unit]
         *      - Unit to use to limit granularity of comparison. Default is millisecond.
         * @return {boolean}
         * @see {@link https://momentjs.com/docs/#/query/is-after/}
         */
        isDateAfter(
            date1,
            date2,
            unit = 'millisecond'
        ) {
            const isAfter = Moment(date1).isAfter(date2, unit);
            return isAfter;
        }
    },

    filters: {
        /**
         * Returns a date string in the specfied format if the date value is
         * valid, otherwise null.
         * Defaults to the format 'DD/MM/YYYY hh:mma' if no format is provided.
         * @param {string} value - Date/Time string.
         * @param {string} [format] - Format to return the date in.
         * @returns {?string} Date string in the specfied format.
         * @see {@link https://momentjs.com/docs/#/displaying/format/}
        */
        formatDate(
            value,
            format = DateFormat.DATE_TIME
        ) {
            let formatted = null;
            // Protect against default dates.
            if (value && (false === value.startsWith(DEFAULT_DATE))) {
                const date = Moment(value);
                if (true === date.isValid()) {
                    formatted = date.format(format);
                }
            }

            return formatted;
        },

        /**
         * Returns a duration string in the specified format if the duration 
         * value is valid, otherwise null.
         * @param {string} value - Duration string.
         * @param {string} [format]  - Format to return the duration in.
         * @param {(boolean|string)} [trim] - Trim largest/smallest magnitude
         * zero value tokens.
         * @param {number} [precision] - Number of decimal fraction or integer
         * digits to display for the final value.
         * @returns {?string} Duration string in the specified format.
         * @see {@link https://www.npmjs.com/package/moment-duration-format}
         */
        formatDuration(
            value,
            format    = 'hh:mm:ss',
            trim      = false,
            precision = null
        ) {
            let   formatted = null;
            const duration  = Moment.duration(value);
            if (true === duration.isValid()) {
                formatted = duration.format({
                    template:  format,
                    trim:      trim,
                    precision: precision
                });
            }
            return formatted;
        }
    }
};

export default DateMixin;
export { DateFormat };
