import { mapState } from 'vuex';
import store        from '@/store';

const LicenceMixin = {

    /**
     * Route guard that checks all of the options referenced in the meta
     * object of the route are licenced. Rejecting the navigation if any
     * are not.
     * 
     * @param {*} to - the route being navigated to.
     * @param {*} from - the route navigating from.
     * @param {*} next 
     */
    beforeRouteEnter(to, from, next) {
        //We need to wait for licences to be loaded - if we don't this will get an empty licenceInfo.DocStoreOptions array - For this we watch it.
        //To handle for issues we've added an abtriary 5 second time out. The ulitmate cause for this is due to an await missing within the auth but applying that causes broader issues.
        
        //Raise a promise to run along side any other aync stuff happening, allow us to wait for licences to be called (which is also async)
        const waitForLicenceInfo = new Promise((resolve, reject) => {
            // If data is available, resolve it.
            if (store.state.system.licenceInfo.DocStoreOptions.length > 0) {
                resolve();
            } else {
                // Arbitrary 5 second time in case the array isn't populated quickly (like it should be)
                const timeout = setTimeout(() => {
                    unwatch();  // Remove the watcher once timeout is reached
                    reject(new Error("Timeout waiting for licenceInfo.DocStoreOptions")); //reject the promise.
                }, 5000);
    
                // Watch docstoreoptions array for changes until the data is loaded
                const unwatch = store.watch(
                    (state) => state.system.licenceInfo.DocStoreOptions,
                    (newVal) => {
                        if (newVal.length > 0) {
                            //clean up watcher and promise
                            clearTimeout(timeout); 
                            unwatch(); 
                            resolve();
                        }
                    }
                );
            }
        });
    
        waitForLicenceInfo.then(() => {
            const licenceInfo     = store.state.system.licenceInfo;
            const docStoreOptions = to.meta.docStoreOptions;
    
            let licensed = false;
    
            if (docStoreOptions) {
                licensed = _.some(docStoreOptions, option => licenceInfo.DocStoreOptions.includes(option));
            } else {
                licensed = true;
            }
    
            next(licensed);
        }).catch((error) => {
            console.error(error);
            //If the 5 second timeout throws it will be caught here allowing it to continue
            next(false);
        });
    },

    computed: {
        ...mapState('system', {
            licensedDocStoreOptions : state => state.licenceInfo.DocStoreOptions,
        }),
    },

    methods: {
        
        /**
         * Checks the licensed DocStore options for the specified DocStore
         * option.
         * 
         * USAGE:
         * 
         *      v-if="hasDocStoreOption('XXX')"
         * 
         * @param {string} option - the DocStore option to check for.
         * @returns {boolean} boolean flag to indicate if option is licensed.
         */
        hasDocStoreOption(option) {
            const licensedDocStoreOptions = this.licensedDocStoreOptions;
            let licensed                  = false;

            if (licensedDocStoreOptions) {
                licensed = licensedDocStoreOptions.includes(option);
            }

            return licensed;
        },

        /**
         * Checks the licensed DocStoreOptions for any of the specified DocStore
         * options.
         * 
         * USAGE:
         *  
         *      v-if="hasDocStoreOptions('XXX', 'YYY', 'ZZZ')"
         * 
         * @param {string} options - a collection of DocStore options.
         * @returns {boolean} - boolean flag to indicate if any of the options are licenced.
         */
        hasDocStoreOptions() {
            let options  = [...arguments];
            let licensed = _.some(options, option => this.hasDocStoreOption(option));

            return licensed;
        }

    }
};

export default LicenceMixin;