const OPEN      = 'Open';
const INITIATED = 'Initiated';
const LIVE      = 'Live';
const ON_HOLD   = 'On Hold';
const LOGGED    = 'Logged';
const CLOSED    = 'Closed';
const PRINTED   = 'Printed';
const DELETED   = 'Deleted';
const MOBILE    = 'Mobile';

const JobStatus = {
    OPEN,
    INITIATED,
    LIVE,
    ON_HOLD,
    LOGGED,
    CLOSED,
    PRINTED,
    DELETED,
    MOBILE
};

const JobStatusList = [
    OPEN,
    INITIATED,
    LIVE,
    ON_HOLD,
    LOGGED,
    CLOSED,
    PRINTED,
    DELETED,
    MOBILE
];

export { JobStatus, JobStatusList };
