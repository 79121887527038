<template>
<div class="d-flex flex-column">
    <h1>Audit</h1>

    <div class="d-flex flex-row">
        <div class="filters mr-3">
            <b>Company</b>
            <input 
                type="text"
                class="form-control mb-3"
                @focus="$event.target.select()"
                v-model="filters.company"
                @blur="fetchRecordChanges"
                @keydown.enter="fetchRecordChanges"
            />
            
            <b>Depot</b>
            <input
                type="text"
                class="form-control mb-3"
                @focus="$event.target.select()"
                v-model="filters.depot"
                @blur="fetchRecordChanges"
                @keydown.enter="fetchRecordChanges"
            />

            <b>Application</b>
            <input
                type="text"
                class="form-control mb-3"
                @focus="$event.target.select()"
                v-model="filters.application"
                @blur="fetchRecordChanges"
                @keydown.enter="fetchRecordChanges"
            />

            <b>Program</b>
            <input
                type="text"
                class="form-control mb-3"
                @focus="$event.target.select()"
                v-model="filters.program"
                @blur="fetchRecordChanges"
                @keydown.enter="fetchRecordChanges"
            />

            <b>Type</b>
            <input
                type="text"
                class="form-control mb-3"
                @focus="$event.target.select()"
                v-model="filters.type"
                @blur="fetchRecordChanges"
                @keydown.enter="fetchRecordChanges"
            />

        </div>
        <div class="flex-grow-1 ml-3">
            <ib-table
                :items="items"
                :columns="tableColumns"
                noItemsMessage="No Records Found"
                :itemsPerPage="25"
            >

                <template 
                    slot="Date"
                    slot-scope="data">
                    {{ data.item.Date || formatDate() }}
                </template>
            </ib-table>
        </div>
    </div>
</div>
</template>

<script>
import DateMixin, { DateFormat } from '@/mixins/DateMixin';

import IbTable    from '@/components/table/IbTable';
import AuditQuery from '@DEV/graphQl/auditQuery';

const TableColumns = [
    {
        heading: 'User',
        property: 'userId',
        sortable: true
    },
    {
        heading: 'Company',
        property: 'company',
        sortable: true,
    },
    {
        heading: 'Depot',
        property: 'depot',
        sortable: true
    },
    {
        heading: 'Application',
        property: 'application',
        sortable: true
    },
    {
        heading: 'Program',
        property: 'program',
        sortable: true
    },
    {
        heading: 'Type',
        property: 'type',
        sortable: true
    },
    {
        heading: 'Date',
        property: 'date',
        sortable: true
    }
];

export default {
    name: 'Audit',

    mixins: [
        DateMixin
    ],

    components: {
        IbTable
    },
    
    data() {
        return {
            response: null,
            tableColumns: TableColumns,
            loading:      false,
            error:        null,
            filters: {
                company:     null,
                depot:       null,
                application: null,
                program:     null,
                type:        null
            }
        }
    },

    mounted() {
        this.fetchRecordChanges();
    },

    computed: {
        items() {
            return this.$_.get(this.response, 'data.data.default');
        }
    },

    methods: {
        async fetchRecordChanges() {
            const me = this;
            me.loading = true;

            try {
                const variables = {
                    company:     me.filters.company,
                    depot:       me.filters.depot,
                    application: me.filters.application,
                    program:     me.filters.program,
                    type:        me.filters.type,
                }
                me.response = await AuditQuery.queryRecordChanges(variables);
            }
            catch (error) {
                me.error = error;
            }
            finally {
                me.loading = false;
            }
        }
    }
}
</script>

<style lang="less" scoped>

.filters {
    width: 15%;
}

</style>