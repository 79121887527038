<template>
<div class="content card">
	<div class="card-header">
		<ib-collapse-toggle
			class="float-left"
			:collapseId="collapseId"/>
		<h4
			class="align-self-center float-left header-labels"
			v-b-toggle="collapseId"
		>{{ date }}</h4>
		<b-badge
			v-if="activitiesToApprove"
			pill
			variant="danger"
			class="ml-1"
		>{{ activitiesToApprove }}</b-badge>
	</div>

	<b-collapse visible :id="collapseId">
	<div class="card-body">
		<!-- Errors -->
		<b-alert variant="danger"
			:show="showErrors">
			<h5>Issues</h5>
			<ul class="error-list">
				<li class="error"
					v-for="error in orderedErrors"
					:key="error.id">
					{{ error.message }}
				</li>
			</ul>
		</b-alert>

		<day-header ref="header"
			:allSelected="areAllSelected"
			@select-all-checked="selectAllChanged"
		></day-header>

		<job-activity-edit ref="jobActivity"
			v-for="(jobActivity, index) in orderedJobActivities"
			:key="jobActivity.Id"
			:activity="jobActivity"
			:index="index"
			:labourCategories="labourCategories"
			@isApprovedChanged="checkIsApprovedForAll"
			@isSelectedChanged="checkIsSelectedForAll"
			@isDeletedChanged="onDurationChanged"
			@isValidChanged="checkIsValidForAll"
			@clearErrors="clearErrors"
			@error="onError"
			@onDurationChanged="onDurationChanged"
			@approve="updateActivity"
			@delete="updateActivity"
		></job-activity-edit>

		<job-activity-add ref="add"
			v-if="showAdd"
			:date="date"
			:engineerId="engineerId"
			:lastEndTime="lastJobActivity.End"
			@cancel="showAdd = false"
			@save="addJobActivity"
			@error="onError"
		></job-activity-add>
	</div>

	<div class="card-footer">
		<day-footer ref="footer"
			:hideAddButton="showAdd"
			:disableApproveButton="checkIsApproving"
			:total-duration=totalDuration
			@add="showAdd = true"
			@approve="onApprove"
		></day-footer>
	</div>
	</b-collapse>

	<b-modal v-model="showLabourRateModal"
			 title="Labour Rate Set"
			 ok-title="Yes"
			 cancel-title="No"
			 cancel-variant="dark"
			 :hide-header-close="true"
			 :no-close-on-backdrop="true"
			 :no-close-on-esc="true"
			 @ok="handleLabourRateModalYes"
			 @cancel="handleLabourRateModalNo">

		<p>You've chosen to set the labour rate for a time entry. This will not have the usual overtime calculations applied to it.</p>
		<p>Are you sure?</p>

	</b-modal>
</div>
</template>

<script>
//Utilities
import DateMixin, { DateFormat }	from '@/mixins/DateMixin';

import DayHeader					from './DayHeader';
import DayFooter					from './DayFooter';
import JobActivityEdit				from './JobActivityEdit';
import JobActivityAdd				from './JobActivityAdd';
import Moment						from 'moment';
import MomentDurationFormat			from 'moment-duration-format';
import IbCollapseToggle				from '@/components/IbCollapseToggle';

import { JobActivityType }			from '@WS/common/job-activity-type';
import { JobActivityStatus }		from '@WS/common/job-activity-status';

export default {
	name: 'DayTimes',

	mixins: [
		DateMixin
	],

	components: {
		IbCollapseToggle,
		DayHeader,
		DayFooter,
		JobActivityEdit,
		JobActivityAdd
	},

	props: {
		engineerId: {
			type:     Number,
			required: true
		},

		date: {
			type:     String,
			required: true
		},

		activities: {
			type:     Array,
			required: true
		},

		labourCategories: {
			type: Array,
			required: true
		}
	},

	data() {
		return {
			jobActivities:		 this.activities,
			totalDuration:		 Moment.duration(null),
			activityDurations:	 [],
			errors:				 [],
			areAllApproved:		 false,
			areAllSelected:		 false,
			isApproving:		 false,
			areAllValid:		 true,
			showAdd:			 false,
			showLabourRateModal: false,
			awaitingApproval:	 null
		};
	},

	computed: {
		/**
		 * Return all job activities sorted in chronological order by start
		 * time, then end time.
		 */
		orderedJobActivities() {
			const ordered = this.mapSort(this.jobActivities);
			return ordered;
		},

		/**
		 * Return all errors ordered ascending by job activity start time.
		 */
		orderedErrors() {
			let orderedErrors = _.orderBy(this.errors, 'jobActivity.Start', 'asc');
			return orderedErrors;
		},

		/**
		 * Return last job activity from ordered job activities array.
		 */
		lastJobActivity() {
			const lastIndex = this.orderedJobActivities.length - 1;
			let lastJobActivity = null;
			if (lastIndex >= 0) {
				lastJobActivity = this.orderedJobActivities[lastIndex];
			}
			return lastJobActivity;
		},

		/**
		 * Return true if there are one or more errors, otherwise false.
		 */
		showErrors() {
			let show = this.errors.length > 0;
			return show;
		},

		jobActivityTimes(){
			return this.jobActivities.map(function(act){
				return {
					Start: act.Start,
					End: act.End,
					DeletedBy: act.DeletedBy
				}
			});
		},

		collapseId(){
			const id = "day-times-" + this.date;
			return id;
		},

		activitiesToApprove(){
			let count = 0;

			count = this.jobActivities
						.filter(activity => JobActivityStatus.APPROVED > activity.Status)
						.length;

			return count;
		},
		checkIsApproving(){

			return true === this.areAllApproved || true === this.isApproving;
		}
	},

	methods: {
		/**
		 * Use a map sort to order job activities chronologically based on
		 * Start (ApprovedStart for approved activities) and then End
		 * (ApprovedEnd for approved activities).
		 * @param {object[]} jobActivities - Array of job activity models
		 * to sort.
		 * @returns {object[]} Sorted Array of job activity models.
		 */
		mapSort(jobActivities) {
			const tempArray = jobActivities.map((activity, index) => {
				let start = activity.Start;
				let end   = activity.End;
				// Handle approved extended Job Activity models.
				if ((JobActivityStatus.APPROVED === activity.Status) &&
					(true === this.isExtendedJobActivity(activity))) {
					start = activity.ApprovedStart;
					end   = activity.ApprovedEnd;
				}
				return { index, start, end };
			});

			const ordered = _.orderBy(tempArray, ['start', 'end'], 'asc');

			const result = _.map(ordered, activity => {
				return jobActivities[activity.index];
			});

			return result;
		},

		/**
		 * Returns true if the job activity model has the ApprovedStart
		 * and ApprovedEnd properties set to a valid date, otherwise
		 * false.
		 * @param {object} activity - Job activity model.
		 * @returns {boolean} Is extended job activity model.
		 */
		isExtendedJobActivity(activity) {
			const result = (false === this.isMinDate(activity.ApprovedStart)) &&
						   (false === this.isMinDate(activity.ApprovedEnd));
			return result;
		},

		/**
		 * Update isSelected flag for all JobActivityEdit child components where
		 * the job activity is unapproved.
		 */
		selectAllChanged(isSelected) {
			this.getEditableJobActivities()
				.filter(component => component.isValid)
				.forEach(component => component.isSelected = isSelected);
		},

		/**
		 * Check if isApproved flag is set on all JobActivityEdit child components.
		 */
		checkIsApprovedForAll(value) {
			this.areAllApproved = this.$refs.jobActivity
									  .every(component => component.isApproved);
		},

		/**
		 * Check if isSelected flag is set on all JobActivityEdit child components
		 * where the job activity is unapproved.
		 */
		checkIsSelectedForAll(value) {
			this.areAllSelected = this.getEditableJobActivities()
									  .every(component => component.isSelected);
		},

		/**
		 * Check if isValid flag is set on all JobActivityEdit child components
		 * where the job activity is unapproved.
		 */
		checkIsValidForAll(value) {
			this.areAllValid = this.getEditableJobActivities()
								   .every(component => component.isValid);
		},

		/**
		 * Call approve on all selected JobActivityEdit child components where
		 * the job activity is unapproved.
		 */
		async approveForDay() {
			this.clearErrors();
			const selectedComponents = this.getEditableJobActivities();

			/* 
				Fire each approval request and wait for the response before
				firing the next. This is to prevent file locking and errors
				in overtime calculations.
			*/
			try {
				this.isApproving = true;
				for(const component of selectedComponents) {
					if (component.isSelected === true){
						await component.approveJobActivity();
					}
				}
			}
			finally {
				this.isApproving = false;
			}			 	
		},

		/**
		 * Event handler for the approved button. If any labour rates are specified,
		 * verify with the user they are okay for this.
		 */
		onApprove() {
			const selectedComponents = this.getEditableJobActivities();
			for(const component of selectedComponents) {
				if (component.labourRate) {
					this.showLabourRateModal = true;
				}
			}

			//No labour rate is specified, approve as normal.
			if (!this.showLabourRateModal) {
				this.approveForDay();
			}
		},

		/**
		 * Event handler for the 'Yes' button on the verification modal.
		 */
		handleLabourRateModalYes() {
			this.approveForDay();
		},

		/**
		 * Event handler for the 'No' button on the verification modal.
		 */
		handleLabourRateModalNo() {
			this.showLabourRateModal = false;
		},

		/**
		 * Callback from job activity edit controller indicating
		 * a job activity has been updated (approved/deleted).
		 * @param {object} jobActivity - The updated job activity.
		 */
		updateActivity(jobActivity) {
			const index = _.findIndex(this.jobActivities, (activity) => {
				return jobActivity.Id === activity.Id;
			});
			// Replace activity in array with updated activity.
			this.jobActivities.splice(index, 1, jobActivity);
			this.clearErrors();
		},

		/**
		 * Add newly created job activity to job activity collection.
		 */
		addJobActivity(jobActivity) {
			this.jobActivities.push(jobActivity);
			this.showAdd 		= false;
			this.areAllApproved = false;
			this.clearErrors();
		},

		/**
		 * Add error to errors array.
		 */
		onError(message) {
			this.errors.push(message);
		},

		/**
		 * Helper: Returns all JobActivityEdit child components which are
		 * able to be edited.
		 */
		getEditableJobActivities() {
			let editable = this.$refs.jobActivity
							   .filter(component => component.isEditable);

			editable = _.orderBy(editable, [ 'activity.Start', 'activity.End' ], 'asc')

			return editable;
		},

		/**
		 * Remove all errors from errors array.
		 */
		clearErrors() {
			this.errors.splice(0, this.errors.length);
		},

		onDurationChanged(value) {
			let found = new Boolean(false);
			if (null != this.activityDurations) {
				for (var i = 0; i < this.activityDurations.length; i++) {
					if (value.Id == this.activityDurations[i].Id) {
						this.activityDurations[i] = value;
						found = true;
					}
				}
			}
			
			if (false == found) {
				this.activityDurations.push(value);
			}

			this.updateTotalDuration();
		},

		updateTotalDuration() {
            let totalDuration = Moment.duration(null);

			for (var i = 0; i < this.activityDurations.length; i++) {
				let duration = this.activityDurations[i];
				if (false == duration.isDeleted && JobActivityType.ATTENDANCE != duration.Type) {
					totalDuration.add(duration.Duration);
				}
			}

           	this.totalDuration = totalDuration;
        }
	}
}
</script>

<style scoped>
.content {
	margin-bottom: 25px;
}

.card-body {
	padding-left: 8px;
	padding-right: 10px;
}

.alert {
	margin-bottom: 8px;
}

.error-list {
	margin: 0px;
	padding-left: 15px;
}

.error {
	margin: 0px;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
	display: none;
}

.header-labels {
	margin: 0px;
	line-height: 35px;
}

h4:hover{
	cursor: pointer;
}
</style>