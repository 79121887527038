<template>
    <ib-modal
        v-model="show"
        modalClass="col-11"
        :dismissable="true"
        >
        <div style="height:750px">
            
            <div>
                <h5>Excluded Parts</h5>
                Excluded parts will not be uploaded, select items to be reincluded 
            </div>
            <div class="row pl-3 w-100 pt-2 pb-2">
                <span>
                    <input class="form-control" type="text" placeholder="Search" v-model="searchPhrase">
                </span>
                <span class="pl-2">
                    <button type="button" class="btn btn-secondary" @click="clearSearch">Clear</button>
                </span>
                <span class="pl-2">
                    <button type="button" class="btn btn-primary" @click="exportToCSV">Export</button>
                </span>
            </div>
            <ib-table
                :items="exclusions"
                :columns="resultsColumns"
                noItemsMessage="No Records Found"
                :itemsPerPage="16"
                :small="true"
                :value="selectedResult"
                :filterValue="searchPhrase"
                :filterProperties="searchColumns"
                :filterDelay="50"
                @input="selectResult">
                <template
                    slot       ="includeSelected"
                    slot-scope ="data"
                    >    
                    <input type="checkbox" name="include" :id="data.item.partNumber" v-model="data.item.includeSelected"/>
                </template>
            </ib-table>
        </div>
        
        <div>
            <button type="button" class="btn btn-secondary" @click="includeParts"><i class="fas fa-plus-circle"></i> Reinclude Selected</button>
        </div>
    </ib-modal>
</template>
<script>
import IbTable      from '@/components/table/IbTable';
import IbModal      from '@/components/utilities/IbModal';
import { DateTime } from 'luxon';


const ResultsColumns = [
    {
        heading: 'Include',
        property: 'includeSelected',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Depot',
        property: 'depot',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Part Number',
        property: 'partNumber',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Description',
        property: 'description',
        sortable: true,
        class: 'large-column'
    },
    {
        heading: 'Product Group',
        property: 'productGroup',
        sortable: true,
        class: 'medium-column'
    },
    {
        heading: 'Class Code',
        property: 'classCode',
        sortable: true,
        class: 'medium-column'
    },
];

const SearchColumns = [
    'partNumber','description'
];



export default {
    name: 'ExcludedParts',
    
    components: {
        IbModal,
        IbTable,
    },

    created() {
    },

    data() {
        return {
            show            : false,
            resultsColumns  : ResultsColumns,
            searchColumns   : SearchColumns,
            selectedResult  : null,
            searchPhrase    : ""
        }
    },

    computed: {
        
    },

    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            required : true,
            type     : Boolean
        },

        exclusions: {
            required    : true,
            type        : Array
        }

    },

    methods: {
        includeParts() {
            console.debug("Excluding Parts, emmiting");
            //emit the exclude
            let partsToInclude = this.exclusions.filter(function(p) {
                    return p.includeSelected === true;
                });
            this.$emit('includeParts', partsToInclude);
        },
        
        selectResult() {

        },

        clearSearch() {
            this.searchPhrase = "";
        },

        exportToCSV() {
            let csv = 'Part Number,Description,Product Group,Class Code,Depot\n';

            let exclusionsToExport = this.exclusions.map(({ partNumber, description, productGroup, classCode, depot }) => ({ partNumber, description, productGroup, classCode, depot }));
            if(this.searchPhrase != "") {
                exclusionsToExport = exclusionsToExport.filter(e => e.partNumber.toLowerCase().includes(this.searchPhrase.toLowerCase()) || e.description.toLowerCase().includes(this.searchPhrase.toLowerCase()) );
            }

            exclusionsToExport.forEach((e) => {
                e.description = '"' + e.description.replaceAll('"','""') + '"';
                e.partNumber = '"' + e.partNumber.replaceAll('"','""') + '"';
            });

            exclusionsToExport.forEach((row) => {
                    csv += Object.values(row).join(',');
                    csv += "\n";
            });

            var d = new Date();
            const now       = d.getFullYear().toString() + d.getMonth().toString().padStart(2, '0') + d.getDate().toString().padStart(2, '0') + "_" + d.getHours().toString().padStart(2, '0') + d.getMinutes().toString().padStart(2, '0') + d.getSeconds().toString().padStart(2, '0');
            const fileName  = `exclusions-${now}.csv`

            const anchor = document.createElement('a');
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            anchor.target = '_blank';
            anchor.download = fileName;
            anchor.click();
        },
    },

    watch: {

        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },
  

    }
    
}
</script>
<style lang="less" scoped>
    hr { margin: 5px 0 10px 0; }

/deep/ .medium-column {
    min-width: 100px;
    width: 100px;
}

/deep/ .large-column {
    min-width: 200px;
    width: 200px;
}

</style>