import cookiesService from "@/common/cookies-service";

export default {
    /**
     * Returns the value of the specified key from session storage,
     * or falls back to local storage if not found.
     * @param {*} key
     */
    get(key) {
        let item = cookiesService.get(key);
        try {

            item = JSON.parse(item);
            if (item === '') {
                item = null;
            }
        } catch (e) {
            item = null;
        }

        return item;
    },
    /**
     * Returns the object, based on key from session or local storage
     * @param {*} key
     * @returns json Object
     */
    getObject(key) {
        let item = window.localStorage.getItem(key);

        if (item === null) {
            item = window.sessionStorage.getItem(key);
        }

        return JSON.parse(item);
    },
    /**
     * Store the value of the specified key in either session storage,
     * or local storage.
     * @param {*} key
     * @param {*} value
     * @param {*} storeLocal
     */
    set(key, value, storeLocal = false) {
        let storage = window.sessionStorage;
        if (storeLocal) {
            storage = window.localStorage;
        }
        storage.setItem(key, value);
        cookiesService.set(key, value);
    },
    /**
     * Store the Object value of the specified key in either session storage,
     * or local storage.
     * @param {*} key
     * @param {*} value
     * @param {*} storeLocal
     */
    setObject(key, value, storeLocal = false) {
        let storage = window.sessionStorage;
        if (storeLocal) {
            storage = window.localStorage;
        }
        storage.setItem(key, JSON.stringify(value));
        cookiesService.set(key, value);
    },

    /**
     * Delete the value of the specified key from both session and
     * local storage.
     * @param {*} key
     */
    delete(key) {
        window.sessionStorage.removeItem(key);
        window.localStorage.removeItem(key);
        cookiesService.remove(key);
    },

    /**
     * Delete all values from both session and local storage.
     */
    deleteAll() {
        window.sessionStorage.clear();
        window.localStorage.clear();
    },

    clearLocalStorage() {
        window.localStorage.clear();
    },
    any(key, local = false) {
        let keyFound = false;
        if (local && key in localStorage) {
            keyFound = true;
        } else if (!local && key in sessionStorage) {
            keyFound = true;
        }

        return keyFound;

    }
};
