import Vue from 'vue';
export default {
  /**
   * Returns the value of the specified key from cookies
   * @param {*} key
   */
  get(key) {
    if (this.any(key)) {
      return Vue.$cookies.get(key);
    } else {
      return "";
    }
  },

  /**
   * Store the value of the specified key in cookies and
   * set expireTime
   * @param {*} key
   * @param {*} value
   * @param {*} expireTime
   */
  set(key, value, expireTime = '9d') {
    /* Remove existing to avoid duplicate */
    if (this.any(key)) {
      this.remove(key);
    }
    Vue.$cookies.set(key, JSON.stringify(value), expireTime);
  },

  /**
   * Delete the value of the specified key from cookies.
   * @param {*} key
   */
  remove(key) {
    if (this.any(key)) {
      Vue.$cookies.remove(key);
    }
  },
  /**
   * check a cookie exist.
   * @param {*} key
   */
  any(key) {
    return Vue.$cookies.isKey(key);
  },
};
