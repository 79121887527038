import AuditApi from '@DEV/api/audit';

const BASE_RECORD_QUERY = 
`query\(
    $userId: String, $company: String, $depot: String, $application: String, $program: String,
    $address: String, $from: DateTimeOffset, $to: DateTimeOffset, $eventId: Int, $type: String, $dataStore: String,
    $action: Action
\)
\{
   default\(
        userId: $userId, company: $company, depot: $depot, application: $application, program: $program,
        address: $address, from: $from, to: $to, eventId: $eventId, type: $type, dataStore: $dataStore,
        action: $action
    \)
   \{
        {{FIELDS}}   
   \}
\}`;

const BASE_RECORD_FIELDS = "id, userId, company, depot, application, program, address, date, isModified, eventId, type, dataStore, action, old, new, changes { field, old, new }"

const AuditQuery = {

    queryRecordChanges(
        variables,
        fields
    ) {
        if (!fields) {
            fields = BASE_RECORD_FIELDS;
        }

        const query = BASE_RECORD_QUERY.replace("{{FIELDS}}", fields);
        
        return AuditApi.queryRecordChanges(query, variables);
    }

};

export default AuditQuery;