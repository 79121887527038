<template>
<ib-modal
            v-model="show"
            modalClass="col-8 p-2"
            :dismissable="false"
        >
    <div>
        <ib-warning-modal :value="showAddEditError" @hideWarning="hideWarning" :message="addEditError"/>
        <h5>{{mode}} {{type}}</h5>
        <hr>
        <div><b>Title * </b><input id="name" type="text" v-model="filterName" style="width:70%"><br></div>
        <hr>
        <div class="btn-group btn-group-toggle">
            <label :class="{'btn' : true, 'btn-secondary' : filter==`Depot`, 'btn-outline-secondary' : filter!=`Depot`, 'disabled' : depotDisabled}">
                <input type="radio" name="filter" id="depotFilter" value="Depot" v-model="filter" :disabled = "depotDisabled">Depot
            </label>
            <label :class="{'btn' : true, 'btn-secondary' : filter==`Date`, 'btn-outline-secondary' : filter!=`Date`, 'disabled' : dateFilterDisabled}">
                <input type="radio" name="filter" id="dateFilter" value="Date" v-model="filter" :disabled = "dateFilterDisabled">Date
            </label>
            <label :class="{'btn' : true, 'btn-secondary' : filter==`Quantity`, 'btn-outline-secondary' : filter!=`Quantity`, 'disabled' : quantityDisabled}">
                <input type="radio" name="filter" id="quantityFilter" value="Quantity" v-model="filter" :disabled = "quantityDisabled">Quantity
            </label>
            <label  :class="{'btn' : true, 'btn-secondary' : filter==`Prefix`, 'btn-outline-secondary' : filter!=`Prefix`, 'disabled' : prefixDisabled}">
                <input type="radio" name="filter" id="prefixFilter" value="Prefix" v-model="filter" :disabled = "prefixDisabled">Prefix
            </label>
            <label  :class="{'btn' : true, 'btn-secondary' : filter==`Product`, 'btn-outline-secondary' : filter!=`Product`, 'disabled' : productGroupsDisabled}">
                <input type="radio" name="filter" id="productFilter" value="Product" v-model="filter" :disabled = "productGroupsDisabled">Product
            </label>
            <label  :class="{'btn' : true, 'btn-secondary' : filter==`Class Code`, 'btn-outline-secondary' : filter!=`Class Code`, 'disabled' : classCodesDisabled}">
                <input type="radio" name="filter" id="classCodeFilter" value="Class Code" v-model="filter" :disabled = "classCodesDisabled">Class Code
            </label>
            <label :class="{'btn' : true, 'btn-secondary' : filter==`Pricing`, 'btn-outline-secondary' : filter!=`Pricing`, 'disabled' : pricingDisabled}">
                <input type="radio" name="filter" id="Pricing" value="Pricing" v-model="filter" :disabled = "pricingDisabled">Pricing
            </label>
        </div>
        <hr>
        <div style="overflow-y: auto; height:280px;">
            <div v-if="filter==`Depot`" class="boxwide">
                <div v-for="(depot, index) in depots.data" class="p-2" :key="index">
                    <input type="checkbox" name ="depot" :id="depot.Id" v-model="depot.selected" @click="delayedRename()"/>&nbsp;{{depot.Id}}
                    <br><span class="text-muted description-text">{{depot.ShortName}}<br>{{depot.Name}} </span>
                </div>
            </div>
            <div v-if="filter==`Prefix`" class="boxwide">
                <div>
                    <a href="#" @click="selectAllPrefixes">Select All</a> / <a href="#" @click="selectNonePrefixes">Select None</a>
                </div>
                <div v-for="(prefix, index) in prefixes.data" class="p-2" :key="index">
                    <input type="checkbox" name ="prefix" :id="prefix.Prefix" v-model="prefix.selected" @click="delayedRename()"/>&nbsp;{{prefix.Prefix}}
                    <br><span class="text-muted description-text">{{prefix.Desc}}</span>
                </div>
            </div>
            <div v-if="filter==`Quantity`" class="boxwide">
                Quantity more than: <input id="quantity" min="0" type="number" v-model="quantity" v-on:keyup="delayedRename()" @input="delayedRename()">
            </div>
            <div v-if="filter==`Class Code`" class="boxwide">
                <div>
                    <a href="#" @click="selectAllClassCodes">Select All</a> / <a href="#" @click="selectNoneClassCodes">Select None</a>
                </div>
                <div v-for="(classCode, index) in classCodes.data" class="p-2" :key="index">
                    <input type="checkbox" name ="classCode" :id="classCode.Clcod" v-model="classCode.selected" @click="delayedRename()"/> &nbsp; {{classCode.Clcod}}
                    <span class="text-muted description-text">{{classCode.Cldes}}</span>
                </div>
            </div>
            <div v-if="filter==`Product`" class="boxwide">
                <div>
                    <a href="#" @click="selectAllProductGroups">Select All</a> / <a href="#" @click="selectNoneProductGroups">Select None</a>
                </div>
                <div v-for="(productGroup, index) in productGroups.data" class="p-2" :key="index">
                    <input type="checkbox" name ="productGroup" :id="productGroup.PrdGroup" v-model="productGroup.selected" @click="delayedRename()"/> &nbsp; {{productGroup.PrdGroup}}
                    <br><span class="text-muted description-text">{{productGroup.Desc}}</span>
                </div>
            </div>
            <div v-if="filter==`Date`">
                <select name="date" id="dateType" v-model="dateMetric" @change="delayedRename()">
                    <option value="Last Purchase">Last Purchase</option>
                    <option value="Last Sold">Last Sold</option>
                    <option value="Most Recent">Most Recent</option>
                </select> &nbsp;
                <select name="date" id="dateType" v-model="dateComparitor" @change="delayedRename()">
                    <option value="Greater Than">Greater Than</option>
                    <option value="Less Than">Less Than</option>
                </select> &nbsp;                
                <input id="dateMonths" type="number" v-model="dateMonths" min="0" step="1" value="0" v-on:keyup="delayedRename()" @input="delayedRename()"> months ago
            </div>
            <div v-if="filter==`Pricing`">
                 <select name="priceType" id="priceType" v-model="priceType" @change="delayedRename()">
                    <option value=3>Dealer Nett</option>
                    <option value=2>RRP</option>
                    <option value=1>Cost</option>
                </select> &nbsp;
                <select name="date" id="plusMinus" v-model="plusMinus" @change="delayedRename()">
                    <option value="Minus">Minus</option>
                    <option value="Plus">Plus</option>
                </select> &nbsp;
               
                <input id="percent" type="number" v-model="percent" v-on:keyup="delayedRename()" @input="delayedRename()"> %
            </div>
        </div>
        <hr>
        <div class="text-right">
            <span style="margin-right:30px"> Create another <input type="checkbox" v-model="createAnother"/></span>
            <span class="pr-2"><button type="button" class="btn btn-secondary" @click="show=false" test-data-id="add-edit-filter-Cancel">Cancel</button></span>
            <span><button type="button" class="btn btn-success pl-2" @click="createFilter" test-data-id="add-edit-filter-Ok">{{(selectedFilter === null) ? 'Create' : 'Update' }}</button></span>
            <span class="pl-2"><button v-if="selectedFilter !== null" type="button" class="btn btn-danger pl-2" @click="deleteFilter">Delete Filter</button></span>
        </div>
    </div>
</ib-modal>
</template>
<script>
import IbModal      from '@/components/utilities/IbModal';
import rulesApi     from '@MP/api/rules';
import depotApi     from '@WS/api/depot';
import IbWarningModal           from '@/components/IbWarningModal';

const dateMetrics = {
    LASTPURCHASE: "Last Purchase",
    LASTSOLD: "Last Sold",
    MOSTRECENT: "Most Recent"
};

const dateComparitors = {
    GREATERTHAN: "Greater Than",
    LESSTHAN: "Less Than"
};

const filterTypes = {
    PREFIX: "Prefix",
    CLASSCODE: "Class Code",
    PRODUCT: "Product",
    PRICING: "Pricing",
    DATE: "Date",
    DEPOT: "Depot",
    QUANTITY: "Quantity"
};

const priceTypes = {
    DEALERNETT: "Dealer Nett",
    RRP: "RRP",
    COST: "Cost"

};

export default {
    name: 'AddEditFilter',
    
    components: {
        IbModal,
        IbWarningModal,
    },

    async created() {
        await this.getDepots();
        await this.getPrefixes();
        await this.getClassCodes();
        await this.getProductGroups();
    },

    data() {
        return {
            filter: "Depot",
            type: "Filter",
            show: false,
            depots: [],
            prefixes: [],
            classCodes: [],
            productGroups: [],
            dateMode: false,
            prefixMode: false,
            filterName: null,
            percent: 0,
            dateMonths: null,
            dateMetric: "Last Sold",
            plusMinus: "Minus",
            priceType: 1,
            metric: 0,
            modifier: 0,
            quantity: 0,
            dateComparitor : dateComparitors.LESSTHAN,
            prefixDisabled : false,
            productGroupsDisabled : false,
            classCodesDisabled : false,
            partNumberDisabled : false,
            dateFilterDisabled : false,
            pricingDisabled : false,
            depotDisabled : false,
            quantityDisabled : false,
            createAnother: false,
            showAddEditError : false,
            addEditError : "",
        }
    },

    props: {
        /**
         * The source of the v-model binding.
         */
        value: {
            required : true,
            type     : Boolean
        },
        mode: {
            required : true,
            type     : String
        },
        selectedFilter: {
            required : false,
            type     : Object
        }
    },

    methods: {

        hideWarning(){
            this.showAddEditError = false;
        },

        toast(toaster, variant, message, append = false) {
            const payload = {
                title: `Filters Designer`,
                variant: variant,
                toaster: toaster,
                solid: true,
                appendToast: append
            };
            this.$bvToast.toast(message, payload);
        },

        selectAllPrefixes(){
            this.prefixes.data = this.prefixes.data.map(prefix => ({...prefix,selected:true}));
        },
        selectNonePrefixes(){
            this.prefixes.data = this.prefixes.data.map(prefix => ({...prefix,selected:false}));
        },

        selectAllClassCodes(){
            this.classCodes.data = this.classCodes.data.map(prefix => ({...prefix,selected:true}));
        },
        selectNoneClassCodes(){
            this.classCodes.data = this.classCodes.data.map(prefix => ({...prefix,selected:false}));
        },

        selectAllProductGroups(){
            this.productGroups.data = this.productGroups.data.map(prefix => ({...prefix,selected:true}));
        },
        selectNoneProductGroups(){
            this.productGroups.data = this.productGroups.data.map(prefix => ({...prefix,selected:false}));
        },

        async getPrefixes(){
            console.debug("Getting Prefixes");
            let prefixes = await rulesApi.getPrefixes();
            prefixes.data = prefixes.data.data.map(prefix => ({...prefix,selected:false}));
            prefixes.data = prefixes.data.filter(p => p.Prefix != "**");
            this.prefixes = prefixes;
        },

        async getClassCodes(){
            console.debug("Getting Class Codes");
            let classCodes = await rulesApi.getClassCodes();
            classCodes.data = classCodes.data.data.map(classCode => ({...classCode,selected:false}));
            this.classCodes = classCodes;
        },

        async getDepots() {
            console.debug("Getting Depots");
            let depots = await depotApi.getAllDepots();
            depots.data = depots.data.data.map(depot => ({...depot, selected:false}));
            this.depots = depots;
        },

        async getProductGroups(){
            console.debug("Getting Product Groups");
            let productGroups = await rulesApi.getProductGroups();
            productGroups.data = productGroups.data.data.map(productGroup => ({...productGroup,selected:false}));
            this.productGroups = productGroups;
        },

        createFilter(){
            let selectedItems = [];
            if(this.filterName === "" || this.filterName === " ") {
                this.addEditError = "Title cannot be empty.";
                this.showAddEditError = true;
                return;
            }
           
           if(this.filter === filterTypes.DEPOT)
            {
                selectedItems = this.depots.data.filter(function(p) {
                    return p.selected === true;
                });
                selectedItems = selectedItems.map(p => p.Id);
            }
            
            if(this.filter === filterTypes.PREFIX)
            {
                selectedItems = this.prefixes.data.filter(function(p) {
                    return p.selected === true;
                });
                selectedItems = selectedItems.map(p => p.Prefix);
            }

            if(this.filter === filterTypes.CLASSCODE)
            {
                selectedItems = this.classCodes.data.filter(function(p) {
                    return p.selected === true;
                });
                selectedItems = selectedItems.map(c => c.Clcod);
            }

            if(this.filter === filterTypes.PRODUCT)
            {
                selectedItems = this.productGroups.data.filter(function(p) {
                    return p.selected === true;
                });
                selectedItems = selectedItems.map(p => p.PrdGroup);
            }

            if(this.filter === filterTypes.PRICING)
            {
                if (this.percent.length === 0 || this.percent < 0) {
                    this.addEditError = "Price modifier percentage must be a positive number.";
                    this.showAddEditError = true;
                    return;
                }
                let percentageAmount = this.percent / 100.00
                this.metric = parseInt(this.priceType);
                if(this.plusMinus === 'Plus') {
                    selectedItems = 1 + percentageAmount;
                    this.modifier = 1;
                }
                else {
                    if (this.percent > 100) {
                        this.addEditError = "You cannot discount more than 100% off a price.";
                        this.showAddEditError = true;
                        return;
                    }
                    selectedItems = 1 - percentageAmount;
                    this.modifier = 2;
                }
            }

            if(this.filter === filterTypes.QUANTITY)
            {
                selectedItems = this.quantity;
                if (this.quantity < 0)
                {
                    this.addEditError = "Quantity filter value cannot be less than 0.";
                    this.showAddEditError = true;
                    return;
                }
            }
            
            if(this.filter === filterTypes.DATE)
            {
                if (this.dateMonths.length === 0 || this.dateMonths <= 0 || !Number.isInteger(Number(this.dateMonths))) {
                    this.addEditError = "Number of months must be a positive whole number.";
                    this.showAddEditError = true;
                    return;
                }
                selectedItems = this.dateMonths;
            
                if(this.dateMetric === dateMetrics.LASTSOLD)
                {
                    this.metric = 1;
                }
                if(this.dateMetric === dateMetrics.LASTPURCHASE)
                {
                    this.metric = 2;
                } 
                if(this.dateMetric === dateMetrics.MOSTRECENT)
                {
                    this.metric = 3;
                }

                if(this.dateComparitor === dateComparitors.LESSTHAN)
                {
                    this.modifier = 2;
                }
                if(this.dateComparitor === dateComparitors.GREATERTHAN)
                {
                    this.modifier = 1;
                } 

            }
            if (selectedItems.length === 0 && this.partNumber === "") {
                this.addEditError = "One or more values must be selected/added.";
                this.showAddEditError = true;
                return;
            }
            this.partNumber = "";
            this.$emit('createFilter', this.filter, this.filterName, selectedItems, this.metric, this.modifier);
            this.clearSelectedFlags();

            if (!this.createAnother){
                this.show = false;
            }

            this.selectedFilter = null;
            
        },
        deleteFilter() {
            this.show = false;
            this.$emit('deleteFilter', this.selectedFilter);
            this.clearSelectedFlags();
        },
        clearSelectedFlags() {
            console.debug("clearing depot selected flags");
            let depots = this.depots;
            depots.data = depots.data.map(depot => ({...depot,selected:false}));
            this.depots = depots;
            
            console.debug("clearing prefix selected flags");
            let prefixes = this.prefixes;
            prefixes.data = prefixes.data.map(prefix => ({...prefix,selected:false}));
            this.prefixes = prefixes;

            console.debug("clearing class code selected flags");
            let classCodes = this.classCodes;
            classCodes.data = classCodes.data.map(classCode => ({...classCode,selected:false}));
            this.classCodes = classCodes;

            console.debug("clearing product group selected flags");
            let productGroups = this.productGroups;
            productGroups.data = productGroups.data.map(productGroup => ({...productGroup,selected:false}));
            this.productGroups = productGroups;

            console.debug("clearing quantity selected flags");
            this.quantity = 0;
            
            this.dateMetric = dateMetrics.LASTPURCHASE;
            this.dateComparitor = dateComparitors.GREATERTHAN;
            this.dateMonths = 0;

            this.plusMinus = "Minus"
            this.priceType = "3";

            this.percent = 0;

            this.prefixDisabled = false;
            this.quantityDisabled = false;
            this.productGroupsDisabled = false;
            this.classCodesDisabled = false;
            this.dateFilterDisabled = false;
            this.pricingDisabled = false;
            this.depotDisabled = false;

            this.selectedFilter = null;
            this.filterName = "";
        },
        async delayedRename() {
            const sleep = (milliseconds) => {
                return new Promise(resolve => setTimeout(resolve, milliseconds))
            }
            await sleep(50);
            this.generateNewName()
        },
        async generateNewName() {
            if(this.filter === filterTypes.DEPOT) {
                let currentlySelectedItems = this.depots.data.filter(function(p) {
                        return p.selected === true;
                    });
                let suggestedName = "";
                currentlySelectedItems.forEach(item => {
                    suggestedName += item.Name + " or ";
                });
                suggestedName = suggestedName.substring(0, suggestedName.length - 4);
                this.filterName = (suggestedName !== "") ? "Depots " + suggestedName  : "";
            }
            if(this.filter === filterTypes.PREFIX) {
                let currentlySelectedItems = this.prefixes.data.filter(function(p) {
                        return p.selected === true;
                    });
                let suggestedName = "";
                currentlySelectedItems.forEach(item => {
                    suggestedName += item.Desc + " or ";
                });
                suggestedName = suggestedName.substring(0, suggestedName.length - 4);
                this.filterName = (suggestedName !== "") ? suggestedName + " Prefixes" : "";
            }
            if(this.filter === filterTypes.PRODUCT) {
                let currentlySelectedItems = this.productGroups.data.filter(function(p) {
                        return p.selected === true;
                    });
                let suggestedName = "";
                currentlySelectedItems.forEach(item => {
                    suggestedName += item.Desc + " or ";
                });
                suggestedName = suggestedName.substring(0, suggestedName.length - 4);
                this.filterName = (suggestedName !== "") ? suggestedName + " Product Groups" : "";
            }
            if(this.filter === filterTypes.CLASSCODE) {
                let currentlySelectedItems = this.classCodes.data.filter(function(p) {
                        return p.selected === true;
                    });
                let suggestedName = "";
                currentlySelectedItems.forEach(item => {
                    suggestedName += item.Cldes + " or ";
                });
                suggestedName = suggestedName.substring(0, suggestedName.length - 4);
                this.filterName = (suggestedName !== "") ? suggestedName + " Class Codes" : "";
            }
            if(this.filter === filterTypes.QUANTITY) {
                 this.filterName = "Quantity Greater Than " + this.quantity;
            }
            if(this.filter === filterTypes.DATE) {
                let dateAppender = ""
                if (this.dateMetric == "Most Recent") {
                    dateAppender = " Activity"
                }
                this.filterName = this.dateMetric + dateAppender + " " + this.dateComparitor + " " + this.dateMonths + " Months Ago";
            }
            if(this.filter === filterTypes.PRICING)
            {
                let priceTypeName = ""
                if (this.priceType === "3") {
                    priceTypeName = priceTypes.DEALERNETT;
                }
                else if (this.priceType === "2") {
                    priceTypeName = priceTypes.RRP;
                }
                else if (this.priceType === "1") {
                    priceTypeName = priceTypes.COST;
                }
                this.filterName = priceTypeName + " " + this.plusMinus + " " + this.percent + "%";
            }
            if (this.filterName.length > 90) {
                this.filterName = " ";
            }
             
        },
        
    },

    watch: {
            
        selectedFilter(newValue, oldValue) {
            if(newValue !== null) {
                this.filterName = newValue.description;
                if(newValue.filterType === 3) {
                    this.filter = "Product";
                    let productGroups = this.productGroups;
                    productGroups.data = this.productGroups.data.map(productGroup => ({...productGroup,selected:newValue.values.includes(productGroup.PrdGroup)}));
                    this.productGroups = productGroups;
                }
                if(newValue.filterType === 1) {
                    this.filter = "Class Code";
                    let classCodes = this.classCodes;
                    classCodes.data = this.classCodes.data.map(classCode => ({...classCode,selected:newValue.values.includes(classCode.Clcod)}));
                    this.classCodes = classCodes;   
                }
                if(newValue.filterType === 2) {
                    this.filter = "Prefix";
                    let prefixes = this.prefixes;
                    prefixes.data = this.prefixes.data.map(prefix => ({...prefix,selected:newValue.values.includes(prefix.Prefix)}));
                    this.prefixes = prefixes;     
                }
                if(newValue.filterType === 5) {
                    this.filter = "Date";
                    this.dateMonths = newValue.months;
                    this.metric = newValue.category;
                    this.comparator = newValue.comparator;
                    if (newValue.category == 1 )
                        this.dateMetric = dateMetrics.LASTSOLD;
                    else if (newValue.category == 2)
                        this.dateMetric = dateMetrics.LASTPURCHASE;
                    else 
                        this.dateMetric = dateMetrics.MOSTRECENT;
                    
                    if (newValue.comparator == 1 )
                        this.dateComparitor = dateComparitors.GREATERTHAN;
                    else if (newValue.comparator == 2)
                        this.dateComparitor= dateComparitors.LESSTHAN;
                }
                if(newValue.filterType === 6) {
                    this.filter = "Depot";
                    let depots = this.depots;
                    depots.data = this.depots.data.map(depot => ({...depot,selected:newValue.values.includes(depot.Id)}));
                    this.depots = depots;     
                }
                if(newValue.filterType === 7) {
                    this.filter = "Pricing";
                    this.priceType = newValue.priceType.toString();
                    if (newValue.amount < 1)
                    {
                        this.plusMinus = 'Minus';
                        this.percent = 100 - Math.abs(newValue.amount * 100).toFixed(8);
                    }
                    else 
                    {
                        this.plusMinus = 'Plus';
                        this.percent = Math.abs((newValue.amount * 100)).toFixed(8) - 100;
                    }
                }
                if(newValue.filterType === 8) {
                    this.filter = "Quantity";
                    this.quantity = newValue.values;                  
                }
                this.prefixDisabled = true;
                this.productGroupsDisabled = true;
                this.classCodesDisabled = true;
                this.dateFilterDisabled = true;
                this.pricingDisabled = true;
                this.depotDisabled = true;
                this.quantityDisabled = true;
            }
        },

        show(newValue) {
            this.$emit('input', this.show);
            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
            }
        },

        value(newValue) {
            this.show = newValue;
        },

        filter(newValue, oldValue) {
            if(this.filter === filterTypes.PRICING)
                this.type = "Price Modifier";
            else
                this.type = this.filter + " Filter";

            if(this.selectedFilter === null) {
                this.clearSelectedFlags();
                this.filterName = "";
            }
        },
        prefixes(newValue, oldValue) {
            //auto-generate new filter name
            this.generateNewName();
        },

        filterName(newValue, oldValue) {
            if(this.selectedFilter === null) {
                if (newValue == null || newValue == "") {
                    this.prefixDisabled = false;
                    this.productGroupsDisabled = false;
                    this.classCodesDisabled = false;
                    this.dateFilterDisabled = false;
                    this.pricingDisabled = false;
                    this.depotDisabled = false;
                    this.quantityDisabled = false;
                } else 
                {
                    this.prefixDisabled = true;
                    this.productGroupsDisabled = true;
                    this.classCodesDisabled = true;
                    this.dateFilterDisabled = true;
                    this.pricingDisabled = true;
                    this.depotDisabled = true;
                    this.quantityDisabled = true;
                }
            }
            if (newValue.length > 90) {
                this.filterName = newValue.substring(0,89);
            }
        }

    }
    
}
</script>
<style scoped>
      .box2 {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;
    }
    .box>* {
        flex: 1 1 160px;
    }

    .box {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
    }
    .boxwide {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
    .description-text {
        font-size: 12px;
    }

</style>