<template>
    <transition name="fade">
        <div 
            v-if="show"
            class="ib-modal-mask d-flex align-items-center justify-content-center overflow-hidden" :style="isSecondOrder ? ' z-index: 1079 ' : 'z-index: 1069'"
            @click="maskClicked"
        >
            <div 
                class="ib-modal d-flex flex-column p-1 overflow-hidden"
                :class="modalClass"
            >
                <div class="ib-modal-header pt-1 pr-1 pb-0 pl-1 d-flex">
                    <div class="flex-grow-1">
                        <slot name="header"></slot>
                    </div>
                    <div
                        v-if="dismissable"
                        class="flex-shrink-1 mr-2 ml-3 ib-modal-close"
                        @click="show = false"
                    >
                        <i class="fas fa-times"></i>
                    </div>
                </div>
                <hr 
                    v-if="hasHeader"
                    class="w-100 my-2"
                />

                <div class="ib-modal-body d-flex flex-column justify-content-center p-2 flex-grow-1 flex-shrink-1 overflow-auto">
                    <slot></slot>
                </div>

                <hr 
                    v-if="hasFooter"
                    class="w-100 my-2"
                />
                <div class="ib-modal-footer pt-0 pr-1 pb-1 pl-1">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/**
 * Component for providing modal functionality.
 */
export default {
    name: 'IbModal',

    props: {
        
        /**
         * The flag indicating if the modal should show.
         */
        value: {
            type:     Boolean,
            required: true
        },

        /**
         * The class to pass to the modal.
         */
        modalClass: {
            type:     String,
            required: false,
            default:  ''
        },

        /**
         * Prevent the modal from being dismissed.
         */
        dismissable: {
            type    : Boolean,
            default : true
        },

        isSecondOrder: {
            type    : Boolean,
            default : false 
        },

        isUnescapable: {
            type    : Boolean,
            default : false 
        }

    },

    data() {
        return {
            show : this.value,
        }
    },

    created() {
        window.addEventListener('keyup', this.onKeyUp);
    },

    beforeDestroy() {
        window.removeEventListener('keyup', this.onKeyUp);
    },

    computed: {
        
        /**
         * Flag indicating if the header slot has been provided.
         */
        hasHeader() {
            return false === _.isEmpty(this.$slots['header']);
        },

        /**
         * Flag indicating if the footer slot has been provided.
         */
        hasFooter() {
            return false === _.isEmpty(this.$slots['footer']);
        }

    },

    methods: {

        maskClicked(event) {            
            if (event.toElement == this.$el && this.dismissable) {
                this.show = false;
            }
        },

        onKeyUp(event) {
            const ESC_KEY = 27;

            if (ESC_KEY == event.keyCode) {
                if(!this.isUnescapable){
                    this.show = false;
                }
            }
        }

    },
    
    watch: {

        value(newValue) {
            this.show = newValue;
        },

        show(newValue) {
            this.$emit('input', newValue);
        }

    },
}
</script>

<style lang="less" scoped>

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.1s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0
}

.ib-modal {
    background-color: white;
    min-width: 25%;
    min-height: 15%;
    max-height: 90%;
    border-radius: 2.5px;
    transition: all .1s ease;
}

.ib-modal:focus {
    outline: none;
}

.ib-modal-close {
    cursor: pointer;
    transition: all .1s ease;
}

.ib-modal-close:hover {
    transform: scale(1.25);
}

.ib-modal-header {
    font-size: 1rem;
    line-height: 1.5;
}

.ib-modal-mask {
    position:         fixed;
    top:              0;
    left:             0;
    width:            100vw;
    height:           100vh;
    z-index:          1069;
    background-color: rgba(0, 0, 0, 0.5);
}



</style>