<template>
    <table
        class="table"
        :class="tableClasses"
    >
        <thead :class="headerClasses">
            <tr>
                <th
                    v-for="column in columns"
                    :key="`column-${column.property}`"
                    scope="col"
                    :class="column.class"
                >
                    <div class="d-flex">
                        <div class="pr-1">
                            <slot :name="`${column.heading}-heading`">
                                {{ column.heading }}
                            </slot>
                        </div>
                        <ib-sort-buttons
                            v-if="column.sortable"
                            :sorted="column.property === sort.property"
                            :sortedDescending="sort.descending"
                            @sortAscending="setSort(column.property, false)"
                            @sortDescending="setSort(column.property, true)"
                        ></ib-sort-buttons>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody v-if="false === loading">
            <tr
                v-for="(item, index) in itemsToDisplay"
                :key="`row-${index}`"
                :class="[{ 'selected': selectedItem === item }, item.rowClass]"
                @click="selectItem(item)"
            >
                <td
                    v-for="column in columns"
                    :key="`cell-${column.property}-${index}`"
                    :class="[{ 'hide-overflow': column.hideOverflow }, column.cellClass]"
                >
                    <!-- Bind slot scope to item, accessible via the 'item'
                        property of the template slot-scope -->
                    <slot
                        :name="column.property"
                        :item="item"
                    >
                        {{ getObjectProperty(item, column.property) }}
                    </slot>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <!-- Display message if no items have been passed to the table -->
            <tr v-if="true === loading">
                <td
                    :colspan="columns.length"
                    class="text-center"
                >
                    <ib-loading-spinner :message="loadingMessage" />
                </td>
            </tr>
            <!-- Display message if no items have been passed to the table -->
            <tr v-else-if="false === hasItems">
                <td
                    :colspan="columns.length"
                    class="text-center"
                >
                    {{ noItemsMessage }}
                </td>
            </tr>
            <!-- Display message if no items match the search filter -->
            <tr v-else-if="false === hasSearchResults">
                <td
                    :colspan="columns.length"
                    class="text-center"
                >
                    {{ noSearchResultsMessage }}
                </td>
            </tr>
            <tr v-else-if="requiresPagination">
                <td :colspan="columns.length">
                    <b-pagination
                        v-model="pagination.page"
                        class="mb-0 mt-1"
                        align="center"
                        :per-page="itemsPerPage"
                        :total-rows="itemsFiltered.length"
                        :limit="pagination.maxPageLinks"
                    ></b-pagination>
                </td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import IbSortButtons    from '@/components/table/IbSortButtons';
import IbLoadingSpinner from '@/components/IbLoadingSpinner';

export default {
    name: 'IbTable',

    components: {
        IbSortButtons,
        IbLoadingSpinner
    },

    props: {
        /**
         * Used in conjunction with v-model. Binding to
         * value from a parent component will enable
         * item selection within the table.
         */
        value: {
            type    : Object,
            default : undefined
        },
        /**
         * Column configurations.
         * [
         *     {
         *         heading:        'Title',     [Column heading to display]
         *         property:       'propA',     [Object property to display in the column cell]
         *         sortable:       true,        [Is the column sortable?]
         *         class:          'colClass',  [CSS class to apply to column]
         *         hideOverflow:   true         [Should overflow text be hidden?]
         *     }
         * ]
         */
        columns: {
            type    : Array,
            default : () => []
        },
        /**
         * Data items to display in the table.
         */
        items: {
            type    : Array,
            default : () => []
        },
        /**
         * Search filter to apply to the data items.
         */
        filterValue: {
            type    : String,
            default : null
        },
        /**
         * Properties on the item to filter on.
         */
        filterProperties: {
            type    : Array,
            default : () => []
        },
        /**
         * The duration, in seconds, to wait after filterValue
         * changes before performing a search.
         */
        filterDelay: {
            type    : Number,
            default : 500    // 0.5s.
        },
        /**
         * Number of items to display per page of the table.
         */
        itemsPerPage: {
            type    : Number,
            default : 10
        },
        /**
         * Indicates whether the data for this table is being loaded.
         */
        loading: {
            type    : Boolean,
            default : false
        },
        /**
         * Message to display whilst loading items.
         */
        loadingMessage: {
            type    : String,
            default : "Loading items..."
        },
        /**
         * Message to display when no items are provided to the table.
         */
        noItemsMessage: {
            type    : String,
            default : "No items to show."
        },
        /**
         * Message to display when no search results are found.
         */
        noSearchResultsMessage: {
            type    : String,
            default : "No items found that match the search criteria."
        },
        /**
         * Indicates whether to apply zebra-striping to all rows
         * within the table.
         */
        bordered: {
            type    : Boolean,
            default : false
        },
        /**
         * Indicates whether to apply zebra-striping to all rows
         * within the table.
         */
        striped: {
            type    : Boolean,
            default : false
        },
        /**
         * Indicates whether to apply zebra-striping to all rows
         * within the table.
         */
        rowHover: {
            type    : Boolean,
            default : true
        },
        /**
         * Indicates whether to apply zebra-striping to all rows
         * within the table.
         */
        small: {
            type    : Boolean,
            default : false
        },
        /**
         * CSS classes to apply to the thead element.
         */
        headerClasses: {
            type    : String,
            default : '',
        },
    },

    data() {
        return {
            selectedItem:           null,

            sort: {
                property:           null,
                descending:         false
            },

            search: {
                filter:             null
            },

            pagination: {
                page:               1,
                itemsPerPage:       2,
                maxPageLinks:       10
            },

            // CSS classes to apply to table.
            tableClasses: {
                'table-bordered':   this.bordered,
                'table-striped':    this.striped,
                'table-hover':      this.rowHover,
                'table-sm':         this.small
            }
        }
    },

    computed: {
        /**
         * Returns a filtered array of items.
         */
        itemsFiltered() {
            const filter            = this.search.filter || '';
            const filterProperties  = this.filterProperties;
            let   items             = this.items;
            // Filter items if a search term has been set.
            if (0 < filter.trim().length) {
                items = this.filter(items, filterProperties);
            }
            // Notify parent of new filtered item count.
            this.$emit('itemsCountChanged', items.length);
            return items;
        },

        /**
         * Returns a sorted array of items.
         */
        itemsSorted() {
            let items = this.itemsFiltered;
            // Sort items if a sort property has been set.
            if (this.sort.property) {
                items = this.sortByProperty(items, this.sort.property, this.sort.descending);
            }
            return items;
        },

        /**
         * Returns items to display for current page.
         */
        itemsToDisplay() {
            const items = this.getItemsForPage(this.pagination.page, this.itemsSorted);
            return items;
        },

        /**
         * Returns true if items is an array of one or more
         * objects, otherwise false.
         */
        hasItems() {
            const result = Array.isArray(this.items) && (0 < this.items.length);
            return result;
        },

        /**
         * Returns true if itemsFiltered is an array of one
         * or more objects, otherwise false.
         */
        hasSearchResults() {
            const result = Array.isArray(this.itemsFiltered) && (0 < this.itemsFiltered.length);
            return result;
        },

        /**
         * Returns true if itemsFiltered is an array containing
         * more objects than specified by itemsPerPage,
         * otherwise false.
         */
        requiresPagination() {
            const result = Array.isArray(this.itemsFiltered) && (this.itemsPerPage < this.itemsFiltered.length);
            return result;
        },

        /**
         * Delay updating the search filter and cancel any
         * pending updates if a newer update arrives before the
         * delay has expired.
         * N.B. Returns a debounced function to enable use of
         * the prop filterDelay which is not available for use
         * when referenced from a method (not initiated at
         * creation time).
         */
        debouncedFilter() {
            return this.$_.debounce(() => this.search.filter = this.filterValue, this.filterDelay);
        }
    },

    watch: {
        /**
         * Watch for changes to the filter supplied from
         * the parent component.
         * @param {string} newValue - The new filter value.
         * @param {string} oldValue - The old filter value.
         */
        filterValue(newValue, oldValue) {
            this.debouncedFilter();
            this.pagination.page = 1;
        },
        value(newValue, oldValue) {
           this.selectedItem = newValue;
        }
    },

    methods: {
        /**
         * Return all items from the provided array that match
         * the filter provided by the user
         * Searches for a match in the Id, Type, Description,
         * AccoutName and ContactName properties of the job
         * model.
         * @param {Object[]} items - The items to search.
         * @param {string[]} filterProperties - Item properties to filter on.
         */
        filter(items, filterProperties) {
            // Fail fast if items is not an array.
            if (false === Array.isArray(items)) {
                console.warn(`Cannot search items, items is not an array.`);
                return items;
            }
            if (false === Array.isArray(filterProperties) || 0 >= filterProperties.length) {
                console.warn(`Cannot search items, no properties set to filter on.`);
                return items;
            }

            const regex         = new RegExp(this.search.filter, 'i');
            const filteredItems = items.filter(item => {
                let result = false;
                // Only search the properties specifed in the
                // filterProperties prop.
                filterProperties.forEach(prop => {
                    const value = item[prop];
                    result = result || regex.test(value);
                });
                return result;
            });
            return filteredItems;
        },

        /**
         * Sort array of items by the specified property,
         * defaults to sorting ascending.
         * @param {Object[]} items - The items to sort.
         * @param {string} property - The item property to sort by.
         * @param {boolean} descending - Sort descending?
         */
        sortByProperty(items, property, descending = false) {
            // Fail fast if items is not an array.
            if (false === Array.isArray(items)) {
                console.warn(`Cannot sort items, items is not an array.`);
                return items;
            }

            const order       = descending ? 'desc' : 'asc';
            const sortedItems = this.$_.orderBy(
                items,
                [(obj) => {
                    let value = obj[this.sort.property];
                    // Handle numeric string values.
                    if (false === isNaN(value)) {
                        value = parseFloat(value);
                    }
                    return value;
                }],
                [order]
            );
            return sortedItems;
        },

        /**
         * Set property to sort by and direction to sort in.
         * @param {string} property - The item property to sort by.
         * @param {boolean} descending - Sort descending?
         */
        setSort(property, descending = false) {
            this.sort.property   = property;
            this.sort.descending = descending;
        },

        /**
         * Return an array of items to display for the
         * specified items page.
         * @param {integer} page - The page of items to display.
         * @param {Object[]} items - The complete items array.
         */
        getItemsForPage(page, items) {
            const startPos  = (page - 1) * this.itemsPerPage;
            const endPos    = startPos + this.itemsPerPage;
            const pageItems = items.slice(startPos, endPos);
            return pageItems;
        },

        /**
         * Update v-model binding with latest item selection.
         */
        selectItem(item) {
            if (undefined !== this.value) {
                this.selectedItem = item;
                this.$emit('input', item);
            }
        },

        /**
         * Gets the value at the path of the specified object,
         * or returns null if the value cannot be resolved.
         * @param {Object} item - The object to query.
         * @param {string} path - The path of the property to get.
         */
        getObjectProperty(item, path) {
            const value = this.$_.get(item, path, null);
            return value;
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../assets/colours';

.table {
    margin-bottom: 0;
    
    th {
        border-top: none;
    }
}

.hide-overflow {
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selected {
    background: lighten(@lightgold, 40%) !important;
}
</style>
