<template>
<div
    class="btn-group"
    role="group">
    <button
        ref="ascending"
        type="button"
        class="btn align-top"
        :class="{ active: isAscending }"
        @click="sortAscending">
        <p>&uarr;</p>
        <b-tooltip
            title="Sort Ascending"
            triggers="hover"
            :target="() => $refs.ascending"
        ></b-tooltip>
    </button>
    <button
        ref="descending"
        type="button"
        class="btn"
        :class="{ active: isDescending }"
        @click="sortDescending">
        <p>&darr;</p>
        <b-tooltip
            title="Sort Descending"
            triggers="hover"
            :target="() => $refs.descending"
        ></b-tooltip>
    </button>
</div>
</template>

<script>
export default {
    name: 'IbSortButtons',

    props: {
        /**
         * True if this control is applying a sort order,
         * otherwise false.
         */
        sorted: {
            type:       Boolean,
            default:    false
        },
        /**
         * True if sorting descending, otherwise false.
         */
        sortedDescending: {
            type:       Boolean,
            default:    false
        }
    },

    computed: {
        isAscending() {
            const result = (true === this.sorted) && (false === this.sortedDescending);
            return result;
        },

        isDescending() {
            const result = (true === this.sorted) && (true === this.sortedDescending);
            return result;
        }
    },

    methods: {
        sortAscending() {
            this.$emit('sortAscending');
        },

        sortDescending() {
            this.$emit('sortDescending');
        }
    }
}
</script>

<style lang="less" scoped>
@import '../../assets/colours';

button {
    padding: 0;
    background-color: transparent;
    color: rgb(188, 188, 188);

    &.active {
        color: @black;
    }
    &:focus {
        box-shadow: none;
    }
    p {
        margin-bottom: 0px;
        margin-top: -5px;
    }
}
</style>
