<template>
    <div>
        <ib-warning-modal v-model="showWarning" @hideWarning="hideWarning" 
        :message="`These settings change the behaviour of the Workshop Job Request System and should not be modified by unauthorised users.`"/>

        <h4>Job Requests Configuration</h4>
        <hr>
        <div v-if="loading" style="overflow-y: auto; height:calc(100vh - 150px);" class="text-center">
            <ib-loading-spinner :message="`Loading...`" />
           
        </div>
        <div v-else>
            <div>
                <div>
                    <h6>Email Notifications:</h6>
                </div>
                <hr>
                <div>
                    List emails here separated by ; 
                    <br/>e.g. a@test.com;b@test.com <span  :style="{color: 'red', paddingLeft:'1em'}">{{ errorMessage }}</span>
                    <textarea @input="validateEmails" name="emails"  :class="{ 'border-danger': !emailsValid }" style="width: 100%;" v-model="emails"  rows="3"></textarea>
                </div>
                <!-- buttons -->
                <div class="float-right">
                    <button type="button" class="btn btn-secondary" @click="loadSettings">Revert</button>&nbsp;
                    <button type="button" :disabled="disabled" class="btn btn-success" @click="saveSettings">Ok</button>
                </div>
            </div>
            <div class="clearfix" v-show="jobRequestMappingFields.length > 0">
                <div>
                    <h6>Invoice Type mapping for job requests:</h6>
                </div>
                <hr>
                <diV>
                    <div class="row align-items-center">
                        <div v-for="(field, fieldIndex) in jobRequestMappingFields" :key="fieldIndex"  class="col-md-6 col-sm-12">
                            <label>
                                {{ field.label }}
                            </label>
                            <ib-select-entry
                            :id				="field.label"
                            class			="filter-selection"
                            :items			=field.options
                            displayProperty	="label"
                            v-model			=field.value
                            >
                            </ib-select-entry>
                        </div>
                        <div class="col-md-12 col-sm-12 mt-2">
                            <div class="float-right">
                                <button type="button" class="btn btn-success" @click="saveJobRequestInvoiceMapping">Save</button>
                            </div>
                        </div>
                    </div>
                </diV>
            </div>    

            <div style="display:none;">
                <div>
                    <h6>Invoice Types to Include in iMHC Notifications:</h6>
                    <a href="#" @click="selectAllInvoiceTypes">Select All</a> / <a href="#" @click="selectNoneInvoiceTypes">Select None</a>
                </div>
                <div class="boxwide">
                        <div v-for="(invoiceType,invoiceTypeIndex) in invoiceTypes" class="p-2" :key="invoiceTypeIndex">
                        <input type="checkbox" name ="invoiceType" :id="invoiceType.Type" v-model="invoiceType.selected" />&nbsp;{{invoiceType.Type}}
                        <br><span class="text-muted description-text">{{invoiceType.Description}}</span>
                    </div>
                </div>
                <hr>

                <div>
                    <h6>Make Codes to Include in iMHC Notifications:</h6>
                    <a href="#" @click="selectAllMakeCodes">Select All</a> / <a href="#" @click="selectNoneMakeCodes">Select None</a>
                </div>
                <div class="boxwide">
                        <div v-for="(makeCode,makeCodeIndex) in makeCodes" class="p-2" :key="makeCodeIndex">
                        <input type="checkbox" name ="makeCode" :id="makeCode.Prefix" v-model="makeCode.selected" />&nbsp;{{makeCode.Prefix}}
                        <br><span class="text-muted description-text">{{makeCode.Desc}}</span>
                    </div>
                </div>
                <hr>

                <div>
                    <h6>Part Prefixes to Include in iMHC Data Feed:</h6>
                    <a href="#" @click="selectAllPrefixes">Select All</a> / <a href="#" @click="selectNonePrefixes">Select None</a>
                </div>
                <div class="boxwide">
                        <div v-for="(prefix,prefixIndex) in partPrefixes" class="p-2" :key="prefixIndex">
                        <input type="checkbox" name ="prefix" :id="prefix.Prefix" v-model="prefix.selected" />&nbsp;{{prefix.Prefix}}
                        <br><span class="text-muted description-text">{{prefix.Desc}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InvoiceTypeApi       from '@WS/api/invoice-type';
import PartsApi             from '@WS/api/parts';
import JobApi               from '@WS/api/job'
import IbWarningModal       from '@/components/IbWarningModal';
import ToastMixin, { ToastVariant, ToastTarget } from '@/mixins/ToastMixin';
import IbLoadingSpinner     from '@/components/IbLoadingSpinner';
import IbSelectEntry        from '@/components/form/IbSelectEntry';
import {mapState }          from 'vuex';
import {map as _map }		from 'lodash';
import JobRequestInvoiceMappingApi from '@WS/api/job-request-invoice-mapping'
import {DocStoreOptions} from '@/common/docstore-options';
import LicenceMixin     from '@/mixins/LicenceMixin';

const INVOICE_TYPE_DEFAULT_OPTION = {
    label : 'Not Selected',
    value : ''
};

const REQUEST_TYPE = Object.freeze({
    Service : 1,
    Fault : 2,
    JD_Alert : 1001,
    JD_Job : 1002
})

export default {
    name : 'jobrequestconfiguration',

    components: {
        IbLoadingSpinner,
        IbWarningModal,
        IbSelectEntry
    },

    mixins: [
        ToastMixin,
        LicenceMixin
    ],

    data () {
        return {
            invoiceTypes    : [],
            makeCodes       : [],
            partPrefixes    : [],
            loading         : false,
            showWarning     : false,
            emails          : "",
            errorMessage    : " ",
            disabled        : false,
            isServiceValid  : true,
            isFaultValid    : true,
            jobRequestSavedMappings:[]
        }
    },
    async created () {
        await this.refresh();
    },
    computed: {
        ...mapState('system', {
            company: 'company'
        }),
        invoiceTypeOptions() {
            const options = _map(this.invoiceTypes, type => {
                return {
                    label : `${type.Type} - ${type.Description}`,
                    value : type.Type
                };
            });
            options.unshift(INVOICE_TYPE_DEFAULT_OPTION);
            return options;
        },
        jobRequestMappingFields () {
            ////define field metadata
             let fields = [
                {
                    label: "Service",
                    requestType: REQUEST_TYPE.Service,
                    options: this.invoiceTypeOptions,
                    value: INVOICE_TYPE_DEFAULT_OPTION,
                    licenseOptions: [DocStoreOptions.GOLD_JCB_LIVELINK_MULTI_DEPOT, DocStoreOptions.MANITOU_PORTAL]   
                },
                {
                    label: "Fault",
                    requestType: REQUEST_TYPE.Fault,
                    options: this.invoiceTypeOptions,
                    value: INVOICE_TYPE_DEFAULT_OPTION,
                    licenseOptions: [DocStoreOptions.GOLD_JCB_LIVELINK_MULTI_DEPOT, DocStoreOptions.MANITOU_PORTAL]   
                },
                {
                    label: "JD Alert",
                    requestType: REQUEST_TYPE.JD_Alert,
                    options: this.invoiceTypeOptions,
                    value: INVOICE_TYPE_DEFAULT_OPTION,
                    licenseOptions: [DocStoreOptions.GOLD_SVAP_MULTI_DEPOT]   
                },
                {
                    label: "JD Job",
                    requestType: REQUEST_TYPE.JD_Job,
                    options: this.invoiceTypeOptions,
                    value: INVOICE_TYPE_DEFAULT_OPTION,
                    licenseOptions: [DocStoreOptions.GOLD_SVAP_MULTI_DEPOT]   
                },
            ];
            
            //check for value selection
            fields.forEach( field => {
                const savedValue = this.jobRequestSavedMappings.find(item=> item.RequestType === field.requestType);
                if(savedValue)
                {
                    const savedOption = field.options.find(item=> Number(item.value) === Number(savedValue.InvoiceType));
                    if( savedOption )
                    {
                        field.value = savedOption;
                    }
                }
            });

            //check for license
            const licensedFields    = fields.filter((field) => this.isFieldLicensed(field));

            return licensedFields;
        } 
    },
    watch: {
        async 'company.selected'(newCompany) {
            await this.refresh();
        },
    },
    methods : {

        isFieldLicensed(field){
            let isLicensed = true;
            if(field.licenseOptions && field.licenseOptions.length > 0)
            {
                isLicensed = this.hasDocStoreOptions(...field.licenseOptions);
            }
            return isLicensed;
        },

        hideWarning() {
            this.showWarning = false;
        },

        async saveJobRequestInvoiceMapping(){
            var mappings = [];
            this.jobRequestMappingFields.forEach(field => {
                mappings.push({
                    RequestType : field.requestType,
                    InvoiceType : field.value.value
                })
            });
            
            JobRequestInvoiceMappingApi.saveJobRequestInvoiceMappings(mappings).then(response =>{
                this.toast('Job Request Invoice Mappings',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.SUCCESS,
                            'Mappings saved successfully');
            }).catch(error =>{
                this.toast('Job Request Invoice Mappings',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.DANGER,
                            'Unable to save Mappings');
                if (error.response) {
                    console.log(error.response.data.message)
                } else {
                    console.error(error);
                }
            });
        },

        async getInvoiceTypes() {
            const invalidProgCodes = [2, 4, 9, -3, -15, -16, -19, -20, -23, -24];

            let invoiceTypes = await InvoiceTypeApi.getInvoiceTypeNames();
            invoiceTypes.data.data = invoiceTypes.data.data.map(invoiceType => ({...invoiceType,selected:false}));
            invoiceTypes.data.data = invoiceTypes.data.data.filter(item => {
                let result = true;
                // return false if item is in invalid prog codes
                if(invalidProgCodes.includes(item.ProgCode)) {
                    result = false;
                }
                return result;
            });
            this.invoiceTypes = invoiceTypes.data.data;
        },
        async getMakeCodes() {
            let prefixes = await PartsApi.getPrefixes();
            this.makeCodes = prefixes.data.data.map(prefix => ({...prefix,selected:false}));
        },
        async getPrefixes() {
            let prefixes = await PartsApi.getPrefixes();
            this.partPrefixes = prefixes.data.data.map(prefix => ({...prefix,selected:false}));
        },


        selectAllInvoiceTypes() {
            this.invoiceTypes = this.invoiceTypes.map(invoiceType => ({...invoiceType,selected:true}));
        },
        selectNoneInvoiceTypes() {
            this.invoiceTypes = this.invoiceTypes.map(invoiceType => ({...invoiceType,selected:false}));
        },
        selectAllMakeCodes() {
            this.makeCodes = this.makeCodes.map(makeCode => ({...makeCode,selected:true}));
        },
        selectNoneMakeCodes() {
            this.makeCodes = this.makeCodes.map(makeCode => ({...makeCode,selected:false}));
        },
        selectAllPrefixes() {
            this.partPrefixes = this.partPrefixes.map(prefix => ({...prefix,selected:true}));
        },
        selectNonePrefixes() {
            this.partPrefixes = this.partPrefixes.map(prefix => ({...prefix,selected:false}));
        },
        validateEmails() {
            this.emailsValid = /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*(;((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))))*$/.test(this.emails);
            this.disabled = !this.emailsValid
            if (this.emailsValid)
            {
                this.errorMessage=" "
            }
            else
            {
                this.errorMessage="Email field is invalid"
            }
        },
        async refresh()
        {
            this.loading = true;
            this.showWarning = true;
            await this.getInvoiceTypes();
            this.getMakeCodes();
            this.getPrefixes();
            this.loadJobRequestMappings();
            this.loadSettings();
        },

        async loadJobRequestMappings(){
            JobRequestInvoiceMappingApi.getJobRequestInvoiceMappings().then(response =>{
               
                this.jobRequestSavedMappings = response.data.data;

            }).catch(error =>{
                this.jobRequestSavedMappings = [];
                if (error.response) {
                    console.log(error.response.data.message)
                } else {
                     // For other errors, you might still want to log them
                    console.error(error);
                }
            });
        },

        async loadSettings() {
            this.loading = true;
            let configuration = await JobApi.getJobRequestsConfiguration();
            let iMhcInvoiceTypes         = configuration.data.data.ImhcNotificationInvoiceTypes.split(',');
            let iMhcMakeCodes            = configuration.data.data.ImhcNotificationMakeCodes.split(',');
            let imhcDataFeedPartPrefixes = configuration.data.data.ImhcDataFeedPartPrefixes.split(',');

            let invoiceTypes = this.invoiceTypes;
            invoiceTypes = invoiceTypes.map(invoiceType => ({...invoiceType,selected:iMhcInvoiceTypes.includes(invoiceType.Type)}));
            this.invoiceTypes = invoiceTypes;


            let makeCodes = this.makeCodes;
            makeCodes = makeCodes.map(makeCode => ({...makeCode,selected:iMhcMakeCodes.includes(makeCode.Prefix)}));
            this.makeCodes = makeCodes;


            let partPrefixes = this.partPrefixes;
            partPrefixes = partPrefixes.map(partPrefix => ({...partPrefix,selected:imhcDataFeedPartPrefixes.includes(partPrefix.Prefix)}));
            this.partPrefixes = partPrefixes;
            this.emails = configuration.data.data.Emails;
            this.validateEmails();
            this.loading = false;
        },
        async saveSettings() {
            let selectedInvoiceTypes = this.invoiceTypes.filter(function(i) {
                    return i.selected === true;
                });
            selectedInvoiceTypes = selectedInvoiceTypes.map(i => i.Type);

            let selectedMakeCodes = this.makeCodes.filter(function(m) {
                    return m.selected === true;
                });
            selectedMakeCodes = selectedMakeCodes.map(i => i.Prefix);

            let selectedPartPrefixes = this.partPrefixes.filter(function(p) {
                    return p.selected === true;
                });
            selectedPartPrefixes = selectedPartPrefixes.map(p => p.Prefix);

            let settings = {
                'ImhcNotificationInvoiceTypes' : selectedInvoiceTypes.join(), 
                'ImhcNotificationMakeCodes'    : selectedMakeCodes.join(), 
                'ImhcDataFeedPartPrefixes'     : selectedPartPrefixes.join(),
                'Emails'                       : this.emails
            };
            
            try
            {
                await JobApi.setJobRequestsConfiguration(settings);
                this.toast('Job Requests Configuration',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.SUCCESS,
                            'Configuration saved successfully');
            }
            catch
            {
                this.toast('Job Requests Configuration',
                            ToastTarget.TOP_CENTER,
                            ToastVariant.DANGER,
                            'Unable to save configuration');        
            }
        }
    },
}
</script>

<style scoped>
    .boxwide {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    }
    .description-text {
        font-size: 12px;
    }
    .clearfix{
        clear: both;
    }
</style>
