import ApiService	 	from '@/common/api-service';
const CLAAS_DSM_ROOT	= "api/v1/dsm/validation";

const DSMApi = {
	///
    /// GET Requests
	///
	
	/**
	 * Get Claas DSM validation message.
	 */
	getDSMValidationMessges() {
		const url =`${CLAAS_DSM_ROOT}/messages`;
		return ApiService.get(url);
	}
};

export default DSMApi;
