<template>
    <div class="alert alert-warning">
        <h4
            class="alert-heading border border-dark border-top-0 border-left-0 border-right-0"
        >
            Access denied
        </h4>
        <p>
            This requires Roles to be assigned to your user within personnel security, application tab.  Please contact your systems administrator.
        </p>
    </div>
</template>

<script>
export default {
    name: "IbRolesRequired"
}
</script>
