<template>
<div id="login-container"
	class="d-flex justify-content-center">
	<vue-particles id="tsparticles" :particlesInit="particlesInit" :options='particlesConfig'/>
	<div id="login"
		class="card align-self-center">
		<div class="card-header">
			<h3 class="mb-0 slide-in-left">Ibcos Gold</h3>
		</div>
		<div class="card-body">
			<form id="formLogin"
				class="form d-flex flex-column"
				v-on:submit.prevent="doLogin">
				<!-- Error -->
				<b-alert :show="hasError" variant="danger">
					{{ error }}
				</b-alert>
				<!-- Username field -->
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text"
							:class="{ 'bg-danger border-danger text-white': !usernameValid }">
							<i class="fas fa-user fa-fw"></i>
						</span>
					</div>
					<input type="email" class="form-control form-control-lg" id="inputEmail"
						:class="{ 'border-danger': !usernameValid }"
						v-model="username"
						@input="validateEmail">
				</div>
				<!-- Password field -->
				<div class="form-group input-group">
					<div class="input-group-prepend">
						<span class="input-group-text"
							:class="{ 'bg-danger border-danger text-white': !passwordValid }">
							<i class="fas fa-unlock fa-fw"></i>
						</span>
					</div>
					<input type="password" class="form-control form-control-lg" id="inputPassword"
						:class="{ 'border-danger': !passwordValid }"
						v-model="password"
						@input="validatePassword">
				</div>
				<!-- Remember me checkbox -->
				<div class="form-group text-right">
					<label class="form-check-label">
						<input id="inputRemember" type="checkbox" class="form-check-input"
							v-model="rememberMe">Remember me
					</label>
				</div>

				<button id="buttonLogin"
					type="submit"
					class="btn btn-lg btn-dark align-self-end"
					:disabled="canLogin === false">
					<i class="fas fa-sign-in-alt"></i>&nbsp;Log In
				</button>
			</form>
		</div>
		<div class="card-footer text-muted">
			<address class="d-flex justify-content-between">
				<p>
					Abacus House<br/>
					Acorn Business Park<br/>
					Ling Road<br/>
					Poole<br/>
					Dorset<br/>
					BH12 4NZ<br/>
				</p>
				<p>
					<i class="fas fa-phone"></i>&nbsp;&nbsp;<a href="tel:441202714200">01202 714200</a><br/>
					<i class="fas fa-fax"></i>&nbsp;&nbsp;<a href="fax:441202733552">01202 733552</a><br/>
					<i class="fas fa-envelope"></i>&nbsp;&nbsp;<a href="mailto:support@ibcos.co.uk">support@ibcos.co.uk</a>
				</p>
			</address>
		</div>
	</div>
</div>
</template>

<script>
import { 
	mapState,
	mapGetters,
	mapActions,
} 						from 'vuex';
import {
	get as _get
} from 'lodash';
import { loadSlim } from "tsparticles-slim";
import ParticlesConfig 	from '@/assets/particles.json';

const PASSWORD_MIN_LEN = 8;

export default {
	name: 'Login',

	data() {
		return {
			username     : null,
			password     : null,
			rememberMe   : false,

			usernameValid: true,
			passwordValid: true,
			particlesConfig: ParticlesConfig
		};
	},

	mounted() {
		this.loadStoredState();

		this.rememberMe = this.storedRememberMe;
		this.username   = _get(this.storedUser, 'Email') || '';
	},

	computed: {
		...mapState('auth', {
			storedRememberMe  : state => state.rememberMe,
			storedUser        : state => state.user,
			error             : state => state.error,
		}),

		canLogin() {
			const areValid = this.usernameValid && this.passwordValid;
			const areSet   = this.username !== null && this.username.length > 0 &&
						   	 this.password !== null && this.password.length > 0;
			return areValid && areSet;
		},

		hasError() {
			let hasError = this.error !== null;
			return hasError;
		},
	},
	
	methods: {
		...mapActions('auth', [
			'login',
			'loadStoredState'
        ]),

		/**
		 * Render the particlesJS canvas.
		 */
 		async particlesInit(engine) {
    		await loadSlim(engine);
		},

		doLogin() {
			const credentials = {
				'username'  : this.username,
				'password'  : this.password,
				'rememberMe': this.rememberMe
			};
			this.login(credentials);
		},

		validateEmail() {
			this.usernameValid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.username);
		},

		validatePassword() {
			this.passwordValid = (this.password !== null && PASSWORD_MIN_LEN <= this.password.length);
		}
	}
};
</script>

<style lang="less" scoped>
@import '../assets/styles/ib-transitions.less';

.slide-in-left {
	animation-delay: 0.15s;
	animation-duration: 0.75s;

	-webkit-animation-delay: 0.15s;
	-webkit-animation-duration: 0.75s;
}

address {
	margin-bottom: 0;
	p {
		margin-bottom: 0;
	}
}

#login-container {
	height: 100vh;
	width: 100vw;
	overflow: hidden; /* Hide scrollbars on IE */
}
#particles {
	background-color: #fefefe;
	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
}
#login {
	min-width: 500px;
}

.card {
	background-color: #fdfdfd;
}
.form-control:focus {
	box-shadow: none;
}
.border-danger {
	border-color: #dc3545;
}
.card-header {
	background-color: #b39700;
	color: #eee;
	overflow: hidden;
}
</style>
