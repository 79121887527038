<template>
<div>
	<!-- Glyph button -->
	<button class="btn btn-sm delete-button"
		:class="{ 'text-danger' : modalShow }"
		:disabled="disabled"
		@click.stop="openModel"
		:style="{'background-color' : backgroundColour }"
		tabindex="-1"
	>
		<slot>
			<i class="far fa-fw fa-trash-alt fa-lg"></i>
		</slot>
	</button>

	<b-modal v-model="modalShow"
		:title="title">
		<p>{{ message }}</p>

		<div slot="modal-footer" class="w-100 ">
			<b-btn class="float-right"
				variant="danger"
				@click="confirm">
				Delete
			</b-btn>
			<b-btn class="float-right mr-2"
				variant="secondary"
				@click="closeModel">
				Cancel
			</b-btn>
       </div>
	</b-modal>
</div>
</template>

<script>
export default {
	name: 'IbDeleteButton',

	props: {
		title: {
			type:     String,
			required: false,
			default:  'Are you sure?'
		},

		message: {
			type:     String,
			required: false,
			default:  'Do you really want to delete this item? This process cannot be undone.'
		},

		disabled: {
			type:     Boolean,
			required: false,
			default:  false
		},

		backgroundColour: {
			type: String,
			default: 'white'
		}
	},

	data() {
		return {
			modalShow: false
		}
	},

	methods: {
		confirm() {
			this.closeModel();
			this.$emit('delete');
		},

		openModel() {
			this.modalShow = true;
		},

		closeModel() {
			this.modalShow = false;
		}
	}
}
</script>

<style lang="less" scoped>
@import '../assets/colours';

.delete-button {
	border: none;
    background: white;
	color: @dark;
    padding: 0px;
}
.delete-button:hover {
	color:@danger;
}
.delete-button:focus {
	outline: none;
	box-shadow: none;
}
</style>
