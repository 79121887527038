<template>
<div class="d-flex justify-content-center align-items-center p-2">
	<div class="wrapper">
		<div class="success"><h1><i class="fas fa-check"></i></h1></div>
		<p class="message">{{message}}</p>
	</div>
</div>
</template>

<script>
export default {
	name: 'IbProcessSuccessful',
	
	props: {
		message: null
	}
}
</script>

<style lang="less" scoped>
@import '../assets/colours';

.success {
	text-align: center;
	color: green;
}

.message {
	text-align: center;
	margin-top: 10px;
}

.wrapper {
	line-height: 100%;
	vertical-align: middle;
}

@keyframes spin {
	0%   { transform: rotate(0deg);   }
	100% { transform: rotate(360deg); }
}
</style>
